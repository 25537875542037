import { Alert, Button } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { basePath, engineDetailPath } from '../paths';

type EngineErrorProps = {
  engineId?: number;
};

export const EngineErrorAlert = ({ engineId }: EngineErrorProps) => {
  const intl = useIntl();

  const id = `features.engines.error.${
    engineId ? ('fieldError' as const) : ('engineError' as const)
  }` as const;

  return (
    <Alert
      variant="outlined"
      color="error"
      sx={{ my: 4 }}
      action={
        <Button
          component={Link}
          color="inherit"
          to={engineId ? engineDetailPath(engineId) : basePath}
        >
          {intl.formatMessage({ id: 'features.engines.error.back' })}
        </Button>
      }
    >
      {intl.formatMessage({ id })}
    </Alert>
  );
};

import { Alert, CircularProgress, Stack } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { OverviewContent } from './components/OverviewContent';
import { BillingEntity } from './hooks/useBillingEntity';
import { useBillingHistory } from './hooks/useBillingHistory';

type OverviewProps = {
  billingEntity: BillingEntity;
};

const Overview = ({ billingEntity }: OverviewProps) => {
  const intl = useIntl();

  // TODO unpaginated endpoint
  const { data, isSuccess, isLoading, isError } = useBillingHistory(
    billingEntity,
    {
      page: 0,
      pageSize: 100,
    }
  );

  return (
    <Stack spacing={4} sx={{ my: 8 }}>
      {isSuccess && (
        <OverviewContent historyContent={data} billingEntity={billingEntity} />
      )}
      {isLoading && <CircularProgress />}
      {isError && (
        <Alert severity="error">
          {intl.formatMessage({ id: 'containers.billing.overview.error' })}
        </Alert>
      )}
    </Stack>
  );
};

export default Overview;

import { Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { boldText } from '../../../../lib/formaterValues';

const CustomNoRowsOverlay = () => {
  const intl = useIntl();
  return (
    <Stack marginTop={4} textAlign="center" alignItems="center">
      <Typography variant="body2" color="text.secondary" fontWeight="bold">
        {intl.formatMessage({
          id: 'features.queueSettings.fields.formulaPreview.noRowsOverlay.title',
        })}
      </Typography>
      <Typography variant="body2" color="text.secondary" maxWidth={320}>
        {intl.formatMessage(
          {
            id: 'features.queueSettings.fields.formulaPreview.noRowsOverlay.description',
          },
          { bold: boldText }
        )}
      </Typography>
    </Stack>
  );
};

export default CustomNoRowsOverlay;

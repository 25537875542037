import { FilterItem } from '../../document-list-base/mql/types';
import { getModifierValueOptions, isUserField } from '../columns/helpers';
import { useFetchUsers } from './useFetchUsers';

export const useFetchAsyncChipValues = ({
  filterItem,
}: {
  filterItem: FilterItem;
}): FilterItem => {
  const { data: allUsers, isLoading: areValuesLoading } = useFetchUsers({
    enabled: isUserField(filterItem.column.field),
  });

  if (!isUserField(filterItem.column.field)) return filterItem;

  const valueOptions = getModifierValueOptions(allUsers ?? {});

  return {
    ...filterItem,
    column: { ...filterItem.column, valueOptions, areValuesLoading },
  };
};

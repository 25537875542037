import { endpoints, getIDFromUrl } from '@rossum/api-client';
import { useMutation } from '@tanstack/react-query';
import { api } from '../../lib/apiClient';
import { getCurrentAnnotationId } from '../../lib/url';
import { EditDocumentConfig } from './editState';

export const useCancelEditMode = () => {
  return useMutation({
    mutationFn: (config: EditDocumentConfig) => {
      // This is executed when the component is being unmounted after the URL has changed
      // so we have to read pathname directly from window.location
      const targetAnnotationId = getCurrentAnnotationId(
        window.location.pathname
      );

      // TODO confirmPayload.stopReviewing doesn't contain deleted annotations, otherwise it's the same
      // It would be good to unify this logic somehow
      const annotationsToCancel = config.initialParts.flatMap(p =>
        p.annotationType === 'accessible' &&
        p.annotation.started &&
        // There is a separate cancelParent flag for stopping the original annotation
        p.annotation.url !== config.annotationUrl &&
        // If we are redirecting back to a child annotation, we shouldn't cancel it here,
        // otherwise there is a race condition, and the user might be kicked out.
        getIDFromUrl(p.annotation.url) !== targetAnnotationId
          ? [p.annotation.url]
          : []
      );

      return api.request(
        endpoints.annotations.cancelEditPages(
          getIDFromUrl(config.annotationUrl),
          {
            // If there are no child annotations, we still want to cancel the parent
            annotations: annotationsToCancel,
            // We always want to cancel the parent annotation (config.annotationUrl)
            cancelParent: true,
          }
        )
      );
    },
  });
};

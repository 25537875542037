import { Delete } from '@rossum/ui/icons';
import {
  alpha,
  Box,
  BoxProps,
  Fade,
  ListItemText,
  styled,
  Typography,
} from '@rossum/ui/material';
import update from 'immutability-helper';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDocumentStore } from '../../features/annotation-view/document-store/DocumentStore';
import { gridScaleFactorSelector } from '../../features/annotation-view/document-store/documentStoreSelectors';
import { GRID_LINE_WIDTH } from './constants';
import { useGridContext } from './GridContext';
import { LabelSelectControl } from './LabelSelectControl';
import { SeparatorContainer } from './SeparatorContainer';
import { SeparatorHandle } from './SeparatorHandle';
import { SeparatorLabelContainer } from './SeparatorLabelContainer';
import { SeparatorLine } from './SeparatorLine';
import { useSeparatorState } from './useSeparatorState';
import { isRowSafe } from './utils';

const RowHighlight = styled(Box, {
  shouldForwardProp: propName => propName !== 'highlightColor',
})<BoxProps & { highlightColor: string | null }>(({ highlightColor }) => ({
  position: 'absolute',
  width: '100%',
  backgroundColor: highlightColor ? alpha(highlightColor, 0.4) : 'transparent',
  pointerEvents: 'none',
}));

export type HorizontalSeparatorProps = BoxProps & {
  index: number;
  isHovered: boolean;
  onMoved: (separatorIndex: number) => void;
  onDelete: (index: number) => void;
  onRowTypeChanged: (index: number, rowType: string | null) => void;
};

// TODO: There is a lot of similar code as in VerticalSeparator except the dynamic property access
// makes it difficult to write nicely in TS - can it be done?
export const HorizontalSeparator = ({
  index,
  isHovered,
  onMoved,
  onDelete,
  onRowTypeChanged,
}: HorizontalSeparatorProps) => {
  const intl = useIntl();
  const scaleFactor = useDocumentStore(gridScaleFactorSelector);

  const {
    interactionInProgress,
    gridState,
    gridDraftState: [draftState],
    gridUiState: [uiState, setUiState],
    gridSchema,
    rowTypeOptions,
    gridLoading,
    gridDisabled,
    gridFocused,
  } = useGridContext();

  const currentGridState = draftState ?? gridState;

  const willBeDeleted = !isRowSafe(
    currentGridState,
    currentGridState.rows[index]
  );

  const {
    cssPosition: top,
    handleMouseDown,
    isDragging,
    markedForDeletion: [markedForDeletion, setMarkedForDeletion],
    size: rowHeight,
  } = useSeparatorState('horizontal', index, onMoved);

  const rowTypeColors = useMemo(() => {
    return Object.fromEntries<string | null>(
      rowTypeOptions.map(option => [`${option.id}`, option.color])
    );
  }, [rowTypeOptions]);

  const currentRowType = currentGridState.rows[index].type ?? null;

  const handleRowTypeChanged = useCallback(
    (rowType: string | null) => {
      onRowTypeChanged(index, rowType);
    },
    [index, onRowTypeChanged]
  );

  const handleMenuOpen = useCallback(() => {
    setUiState(uiState => update(uiState, { activeRowIndex: { $set: index } }));
  }, [index, setUiState]);

  const handleMenuClose = useCallback(() => {
    setUiState(uiState => update(uiState, { activeRowIndex: { $set: null } }));
  }, [setUiState]);

  const isActive = isDragging || isHovered || uiState.activeRowIndex === index;

  const isInteractive =
    !gridDisabled &&
    !gridLoading &&
    gridFocused &&
    (!interactionInProgress || uiState.activeRowIndex === index || isDragging);

  return (
    <SeparatorContainer orientation="horizontal" style={{ top }}>
      <SeparatorLine
        orientation="horizontal"
        deleteButtonHovered={markedForDeletion}
        willBeDeletedByResize={willBeDeleted}
        isActive={isActive}
        isDragging={isDragging}
        interactive={isInteractive}
        disabled={gridDisabled || !gridFocused || gridLoading}
        onMouseDownCapture={isInteractive ? handleMouseDown : undefined}
        sx={{ display: index === 0 ? 'none' : 'block' }}
        data-cy={`hs-line-${currentGridState.page}-${index}`}
      />
      {index !== 0 && (
        <SeparatorHandle
          orientation="horizontal"
          showButton={markedForDeletion}
          isInteractive={
            !gridDisabled &&
            !gridLoading &&
            gridFocused &&
            !interactionInProgress
          }
          onMouseOver={() => setMarkedForDeletion(true)}
          onMouseOut={() => setMarkedForDeletion(false)}
          onClick={() => {
            onDelete(index);
          }}
        />
      )}
      <SeparatorLabelContainer
        orientation="horizontal"
        isActive={isActive}
        gridHovered={uiState.documentHovered}
        interactionInProgress={interactionInProgress}
        size={rowHeight * scaleFactor - GRID_LINE_WIDTH}
      >
        <Fade
          in={currentRowType !== gridSchema.defaultRowType || isHovered}
          timeout={150}
        >
          <div
            style={{
              maxWidth: '100%',
              width: 'min-content',
              maxHeight: '100%',
              height: 22,
            }}
          >
            <LabelSelectControl
              highlighted={isActive}
              disabled={gridDisabled || gridLoading || !gridFocused}
              buttonLabel={
                currentRowType === gridSchema.defaultRowType ? (
                  rowTypeOptions.length === 2 ? (
                    <Typography
                      variant="body1"
                      component="span"
                      noWrap
                      sx={{
                        mx: 0.5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Delete fontSize="inherit" />
                    </Typography>
                  ) : (
                    intl.formatMessage({
                      id: 'components.magicGrid.rowTypes.defaultLabel',
                    })
                  )
                ) : null
              }
              options={rowTypeOptions}
              value={currentRowType}
              onChange={handleRowTypeChanged}
              onMenuOpen={handleMenuOpen}
              onMenuClose={handleMenuClose}
              renderOption={option => (
                <>
                  <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                    {option.label}
                  </ListItemText>
                  <Box
                    sx={{
                      ml: 2,
                      width: 22,
                      height: 22,
                      backgroundColor: option.color ?? 'transparent',
                      borderRadius: 1,
                    }}
                  />
                </>
              )}
              dataCy={`hs-label-${currentGridState.page}-${index}`}
            />
          </div>
        </Fade>
      </SeparatorLabelContainer>
      {currentRowType !== gridSchema.defaultRowType && (
        <RowHighlight
          highlightColor={rowTypeColors[String(currentRowType)]}
          height={rowHeight * scaleFactor - GRID_LINE_WIDTH}
        />
      )}
    </SeparatorContainer>
  );
};

import { Message } from '@rossum/api-client/shared';
import {
  AttachEmail,
  Check,
  Delete,
  DoDisturb,
  DriveFileMove,
  FileDownload,
  FilePresentRounded,
  FontDownloadOutlined,
  WatchLater,
} from '@rossum/ui/icons';
import { Alert, Box, Button, Grow, Stack, Tooltip } from '@rossum/ui/material';
import { camelCase, compact } from 'lodash';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { SwitchTransition } from 'react-transition-group';
import AnnotationsActionModal from '../../../components/AnnotationsActionModal';
import RejectionMessage from '../../../components/RejectionMessage';
import { isEmbedded } from '../../../constants/config';
import { useAddAttachmentsModal } from '../../../features/annotation-attachment/hooks/useAddAttachmentsModal';
import { useCanPerformAttachmentActions } from '../../../features/annotation-attachment/hooks/useCanAddAttachments';
import { MoveAnnotationDialog } from '../../../features/document-list/selection-panel/MoveAnnotationDialog';
import { attachmentsFeatureSelector } from '../../../features/pricing/selectors';
import { useDownloadOriginalDocuments } from '../../../features/tasks/hooks/useDownloadOriginalDocuments';
import { boldText } from '../../../lib/formaterValues';
import { snakeToCamel } from '../../../lib/keyConvertor';
import { getCurrentAnnotationId } from '../../../lib/url';
import {
  confirmAnnotation as confirmAnnotationAction,
  nextAnnotation as nextAnnotationAction,
  rejectAnnotation as rejectAnnotationAction,
} from '../../../redux/modules/annotation/actions';
import {
  annotationSelector,
  queueHasBypassWorkflowsEnabledSelector,
  queueHasWorkflowsSelector,
  rejectionEnabledSelector,
} from '../../../redux/modules/annotation/selectors';
import {
  deleteAnnotations as deleteAnnotationsAction,
  popAnnotationFromStack,
  postponeAnnotations as postponeAnnotationsAction,
} from '../../../redux/modules/annotations/actions';
import { canResetOnMove } from '../../../redux/modules/annotations/selector';
import { pauseValidation } from '../../../redux/modules/beforeLeave/actions';
import {
  OpenModal,
  openModal as openModalAction,
} from '../../../redux/modules/modal/actions';
import { organizationSelector } from '../../../redux/modules/organization/selectors';
import { hideBlockers as hideBlockersAction } from '../../../redux/modules/ui/actions';
import {
  isUserAnnotatorBetaSelector,
  isUserViewer,
} from '../../../redux/modules/user/selectors';
import { Annotation } from '../../../types/annotation';
import { Document } from '../../../types/document';
import { State } from '../../../types/state';
import { EmbeddedConfig } from '../../../types/ui';
import { DrawerConfig } from '../../DocumentValidation/ValidationEmailDrawer';
import { ModalKey } from '../../Modals/types';
import AnnotationNavigationRedesign from './AnnotationNavigationRedesign';
import FooterButtons from './FooterButtons';
import FooterMessage from './FooterMessage';
import MessageButton from './MessageButton';
import OtherActions, { MenuAction } from './OtherActions';

type OwnProps = {
  readOnly: boolean;
  selectDatapointWithMessage: () => void;
  shouldRenderGradient: boolean;
  fieldAutomationBlockersVisible: boolean;
  onEmailThreadOpen: (drawerConfig?: DrawerConfig) => void;
};

type StateProps = {
  annotation: Annotation | undefined;
  confirming: boolean;
  currentAnnotationIndex: number;
  disableDownloadOriginal: boolean;
  embeddedConfig: EmbeddedConfig | undefined;
  isUserAnnotatorBeta: boolean;
  message: Message | undefined;
  rejectionEnabled: boolean;
  totalAnnotations: number;
  emailThreadUrl: string | null;
  fileDocument: Document | undefined;
};

type DispatchProps = {
  deleteAnnotations: typeof deleteAnnotationsAction;
  hideBlockers: typeof hideBlockersAction;
  nextAnnotation: (nSteps: number) => void;
  confirmAnnotation: typeof confirmAnnotationAction;
  pauseValidation: typeof pauseValidation;
  openModal: OpenModal;
  rejectAnnotation: typeof rejectAnnotationAction;
};

type FooterRedesignProps = OwnProps & StateProps & DispatchProps;

const Footer = ({
  annotation,
  confirming,
  currentAnnotationIndex,
  disableDownloadOriginal,
  embeddedConfig,
  fieldAutomationBlockersVisible,
  isUserAnnotatorBeta,
  hideBlockers,
  message,
  nextAnnotation,
  pauseValidation,
  openModal,
  readOnly,
  rejectionEnabled,
  selectDatapointWithMessage,
  shouldRenderGradient,
  totalAnnotations,
  emailThreadUrl,
  onEmailThreadOpen,
  rejectAnnotation,
  fileDocument,
}: FooterRedesignProps) => {
  const [moveDialogOpen, setMoveDialogOpen] = useState(false);
  const [showActionModal, setShowActionModal] = useState<
    'annotationSkip' | 'reject' | undefined
  >(undefined);
  const isViewer = useSelector(isUserViewer);
  const canPerformAttachmentActions = useCanPerformAttachmentActions();

  const displayAnnotationNavigation =
    totalAnnotations > 1 && currentAnnotationIndex !== -1;

  const intl = useIntl();

  const status = annotation ? snakeToCamel(annotation.status) : undefined;

  const restrictedAccess = !!annotation?.restrictedAccess;

  const deletable =
    [
      'rejected',
      'importing',
      'failedImport',
      'confirmed',
      'exported',
      'failedExport',
    ].includes(camelCase(status)) ||
    !(confirming || readOnly) ||
    restrictedAccess;

  const postponable =
    !restrictedAccess && (status === 'confirmed' || !(confirming || readOnly));

  const shouldRemainReadOnly = status !== 'reviewing';

  const showInformation =
    ({
      type,
      onConfirm,
      values,
    }: {
      type: ModalKey;
      onConfirm: () => void;
      values: unknown;
    }) =>
    () =>
      openModal({ textId: type, values, onConfirm });

  const showDeleteInformation = (annotation: Annotation) =>
    showInformation({
      type: 'annotationDelete',
      onConfirm: () =>
        pauseValidation({
          nextAction: deleteAnnotationsAction([annotation.url]),
          trigger: 'deleteAnnotation',
          reason: 'surveys',
        }),
      values: { count: 1 },
    });

  const { mutate: downloadOriginalDocuments } = useDownloadOriginalDocuments();

  const queueHasWorkflows = useSelector(queueHasWorkflowsSelector);

  const bypassWorkflows = useSelector(queueHasBypassWorkflowsEnabledSelector);

  const isDocAttachmentsEnabled = useSelector(attachmentsFeatureSelector);

  const { node: addAttachmentModal, openModal: openAddAttachmentModal } =
    useAddAttachmentsModal();
  // Other actions
  // conditions deduced from old Footer
  const otherActions: Array<MenuAction & { hidden?: boolean }> = compact([
    !isEmbedded() &&
      annotation &&
      bypassWorkflows &&
      queueHasWorkflows && {
        id: 'confirm',
        label: intl.formatMessage({ id: 'components.sidebar.confirm' }),
        icon: <Check />,
        dataCy: 'annotation-sidebar-confirm',
        buttonProps: {
          onClick: () =>
            pauseValidation({
              nextAction: confirmAnnotationAction(annotation?.url, true),
              trigger: 'confirmAnnotation',
              reason: 'surveys',
            }),
          disabled: restrictedAccess,
        },
        hidden: isViewer,
      },
    !isEmbedded() && {
      id: 'send',
      label: intl.formatMessage({ id: 'components.sidebar.send' }),
      icon: <AttachEmail />,
      dataCy: 'annotation-sidebar-send',
      buttonProps: {
        onClick: () =>
          onEmailThreadOpen({
            defaultFormMode: 'forwardDocument',
            highlightedEmailUrl: annotation?.email ?? undefined,
          }),
        disabled: restrictedAccess,
      },
      hidden: isViewer,
    },
    ((isEmbedded() && !disableDownloadOriginal) ||
      (!isEmbedded() && !disableDownloadOriginal && !isUserAnnotatorBeta)) && {
      id: 'download',
      label: intl.formatMessage({ id: 'components.sidebar.download' }),
      icon: <FileDownload />,
      dataCy: 'annotation-sidebar-download',
      buttonProps: {
        onClick: () =>
          fileDocument &&
          downloadOriginalDocuments({ documents: [fileDocument.url] }),
        disabled: !fileDocument || restrictedAccess,
      },
    },
    !isEmbedded() &&
      annotation && {
        id: 'move',
        label: intl.formatMessage({ id: 'components.sidebar.move' }),
        icon: <DriveFileMove />,
        dataCy: 'annotation-sidebar-move',
        buttonProps: {
          onClick: () => setMoveDialogOpen(true),
        },
        hidden: isViewer,
      },
    !isEmbedded() &&
      annotation && {
        id: 'addAttachment',
        label: intl.formatMessage({
          id: 'components.annotationOverview.actionButtons.addAttachment',
        }),
        icon: <FilePresentRounded />,
        dataCy: 'annotation-sidebar-add-attachment',
        buttonProps: {
          onClick: () => {
            openAddAttachmentModal(annotation.url);
          },
        },
        hidden:
          !isDocAttachmentsEnabled ||
          !canPerformAttachmentActions ||
          restrictedAccess,
      },
    (!isEmbedded() || (isEmbedded() && embeddedConfig?.deleteUrl)) &&
      annotation && {
        id: 'delete',
        label: intl.formatMessage({ id: 'components.sidebar.delete' }),
        icon: <Delete />,
        // TODO: This differs in embedded mode - how?
        dataCy: 'annotation-sidebar-delete',
        buttonProps: {
          disabled: !deletable,
          onClick: () => showDeleteInformation(annotation)(),
        },
        hidden: isViewer,
      },
  ]);

  const modalActions = (annotation: Annotation) => ({
    annotationSkip: {
      onPrimaryActionClick: () => {
        pauseValidation({
          nextAction: postponeAnnotationsAction([annotation.url]),
          trigger: 'postponeAnnotation',
          reason: 'surveys',
        });
      },
      onSecondaryActionClick: () => {
        onEmailThreadOpen({
          defaultFormMode: 'postponeDocument',
          highlightedEmailUrl: annotation?.email ?? undefined,
        });
      },
    },
    reject: {
      onPrimaryActionClick: () => {
        onEmailThreadOpen({
          defaultFormMode: 'rejectDocument',
          highlightedEmailUrl: annotation?.email ?? undefined,
        });
      },
      onSecondaryActionClick: () => {
        rejectAnnotation(annotation.url, true);
      },
    },
  });

  return (
    <Box sx={{ position: 'relative' }}>
      {shouldRenderGradient && (
        <Box
          sx={{
            pointerEvents: 'none',
            position: 'absolute',
            content: '""',
            bottom: `calc(100% - 1px)`,
            height: 70,
            backgroundImage: `linear-gradient(to bottom, rgba(var(--annotation-footer-rgb), 0), var(--annotation-footer))`,
            left: 0,
            right: 0,
          }}
        />
      )}
      <Stack
        spacing={2}
        sx={{
          px: 4,
          py: 2,
          backgroundColor: `var(--annotation-footer)`,
        }}
      >
        {annotation &&
          !fieldAutomationBlockersVisible &&
          annotation.status === 'rejected' &&
          annotation.note && (
            <RejectionMessage
              withHeader
              note={annotation.note}
              onClick={emailThreadUrl ? () => onEmailThreadOpen() : undefined}
            />
          )}

        <SwitchTransition>
          <Grow
            key={
              fieldAutomationBlockersVisible
                ? 'blockers'
                : message
                  ? 'message'
                  : 'none'
            }
            appear
            unmountOnExit
          >
            {fieldAutomationBlockersVisible ? (
              <Alert
                severity="error"
                variant="outlined"
                icon={
                  <FontDownloadOutlined
                    fontSize="medium"
                    sx={{
                      fill: theme => theme.palette.error.main,
                    }}
                  />
                }
                onClose={() => hideBlockers({ readOnly: shouldRemainReadOnly })}
                sx={{ wordBreak: 'break-word' }}
              >
                <FormattedMessage id="components.documentValidation.sidebar.showingAutomationBlockers" />
              </Alert>
            ) : (
              <FooterMessage message={message} />
            )}
          </Grow>
        </SwitchTransition>
        <Stack
          direction="row"
          spacing={0}
          sx={{ width: '100%', position: 'relative' }}
          alignItems="center"
        >
          <Box sx={{ position: 'absolute', left: -30 }}>
            <MessageButton selectDatapoint={selectDatapointWithMessage} />
          </Box>
          <FooterButtons
            confirming={confirming}
            displayAnnotationNavigation={displayAnnotationNavigation}
            onDocumentConfirm={() =>
              annotation &&
              pauseValidation({
                nextAction: confirmAnnotationAction(annotation.url),
                trigger: 'confirmAnnotation',
                reason: 'surveys',
              })
            }
            readOnly={readOnly}
            status={status}
          />
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          sx={{ width: '100%' }}
          justifyContent="space-between"
        >
          {displayAnnotationNavigation ? (
            <AnnotationNavigationRedesign
              total={totalAnnotations}
              current={currentAnnotationIndex + 1}
              next={nextAnnotation}
            />
          ) : (
            <div />
          )}
          <Stack direction="row" spacing={1.5}>
            {/* Reject button */}
            {!isEmbedded() && rejectionEnabled && (
              <Tooltip
                title={
                  status !== 'reviewing' ? (
                    ''
                  ) : (
                    <FormattedMessage
                      id="components.sidebar.reject.tooltip"
                      values={{ boldText }}
                    />
                  )
                }
              >
                <Button
                  data-cy="annotation-detail-reject-btn"
                  color="error"
                  size="small"
                  startIcon={<DoDisturb />}
                  disabled={
                    !annotation || status !== 'reviewing' || restrictedAccess
                  }
                  onClick={() => setShowActionModal('reject')}
                >
                  {intl.formatMessage({ id: 'components.sidebar.reject' })}
                </Button>
              </Tooltip>
            )}
            {/* Postpone button */}
            {((isEmbedded() && embeddedConfig?.postponeUrl) ||
              !isEmbedded()) && (
              <Button
                color="secondary"
                size="small"
                startIcon={<WatchLater />}
                disabled={!annotation || !postponable}
                onClick={() => setShowActionModal('annotationSkip')}
                data-cy="annotation-sidebar-postpone"
              >
                {intl.formatMessage({ id: 'components.sidebar.postpone' })}
              </Button>
            )}
            {/* Other actions */}
            {otherActions.length && (
              <OtherActions
                disabled={!annotation}
                actions={otherActions.filter(action => !action.hidden)}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
      {annotation ? (
        <MoveAnnotationDialog
          key={annotation.id}
          selectedAnnotations={
            moveDialogOpen
              ? [{ ...annotation, status: snakeToCamel(annotation.status) }]
              : []
          }
          canResetData={canResetOnMove([
            { ...annotation, status: snakeToCamel(annotation.status) },
          ])}
          onCancel={() => setMoveDialogOpen(false)}
          onSuccess={() => {
            setMoveDialogOpen(false);
            pauseValidation({
              nextAction: popAnnotationFromStack(annotation),
              trigger: 'moveAnnotation',
              reason: 'surveys',
            });
          }}
        />
      ) : null}
      {!isEmbedded() && addAttachmentModal}
      {showActionModal && annotation && (
        <AnnotationsActionModal
          action={showActionModal}
          annotationsLength={showActionModal ? 1 : 0}
          onClose={() => setShowActionModal(undefined)}
          onPrimaryActionClick={
            modalActions(annotation)[showActionModal]?.onPrimaryActionClick
          }
          onSecondaryActionClick={
            !isEmbedded()
              ? modalActions(annotation)[showActionModal]
                  ?.onSecondaryActionClick
              : undefined
          }
          secondaryButtonText={
            !isEmbedded()
              ? intl.formatMessage({
                  id: `components.modal.${showActionModal}.secondary`,
                })
              : undefined
          }
          dataCyCancel={`${showActionModal}-dialog-cancel-btn`}
          dataCySecondary={
            !isEmbedded()
              ? `${showActionModal}-dialog-secondary-btn`
              : undefined
          }
          dataCyConfirm={`${showActionModal}-dialog-primary-btn`}
        />
      )}
    </Box>
  );
};

const mapStateToProps = (state: State): StateProps => {
  const annotation = annotationSelector(state);

  return {
    fileDocument: state.annotation.sideloads.document,
    annotation,
    confirming: state.ui.confirming,
    currentAnnotationIndex: state.stack.indexOf(
      getCurrentAnnotationId(state.router.location.pathname)
    ),
    disableDownloadOriginal:
      organizationSelector(state).uiSettings?.features
        ?.disableDownloadOriginal ?? false,
    embeddedConfig: state.ui.embeddedConfig,
    isUserAnnotatorBeta: isUserAnnotatorBetaSelector(state),
    message:
      state.datapoints.messages?.all ||
      (annotation && annotation.status === 'failed_export'
        ? annotation.messages?.[0]
        : undefined),
    rejectionEnabled: rejectionEnabledSelector(state),
    totalAnnotations: state.stack.length,
    emailThreadUrl: state.annotation.emailThread,
  };
};

const mapDispatchToProps = {
  deleteAnnotations: deleteAnnotationsAction,
  nextAnnotation: nextAnnotationAction,
  pauseValidation,
  confirmAnnotation: confirmAnnotationAction,
  openModal: openModalAction,
  hideBlockers: hideBlockersAction,
  rejectAnnotation: rejectAnnotationAction,
};

export default connect<StateProps, DispatchProps, OwnProps, State>(
  mapStateToProps,
  mapDispatchToProps
)(Footer);

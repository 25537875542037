import { ValuesBase } from '@rossum/api-client/annotations';
import { ExitToApp, FontDownloadOutlined, Output } from '@rossum/ui/icons';
import clsx from 'clsx';
import humanizeDuration from 'humanize-duration';
import { round } from 'lodash';
import CancelIcon from 'mdi-react/CancelIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import CursorPointerIcon from 'mdi-react/CursorPointerIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import FileClockOutlineIcon from 'mdi-react/FileClockOutlineIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import TimerSandEmptyIcon from 'mdi-react/TimerSandEmptyIcon';
import TimerSandIcon from 'mdi-react/TimerSandIcon';
import { ReactNode } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import AggregateStat from '../../../components/Graphs/components/AggregateStat';
import { humanizeDurationLocaleMap, LocalizationKeys } from '../../../i18n';
import { fallbackLocale } from '../../../i18n/config';
import { getDurationUnit, limitUnits } from '../../../lib/timeUtils';
import styles from '../styles.module.sass';

// TODO:
//   - unify type with props type of AggregateState compoennt
//   - replace ReactNode types with string / number
//   - replace formatted message components with inttl.formatMessage functions
export type AggregateData = Array<{
  value: ReactNode;
  icon: ReactNode;
  description: LocalizationKeys;
}>;

type AggregateProps = {
  data: AggregateData;
  withTopMargin?: boolean;
};

type Props = {
  isTmpState: boolean;
  totals: ValuesBase;
};

type IntlProps = { intl: IntlShape };

type PropsWithIntl = Props & IntlProps;

export const Aggregate = ({ data, withTopMargin = true }: AggregateProps) => (
  <div
    className={clsx(
      styles.AggregateContainer,
      withTopMargin && styles.AggregateContainerMarginTop
    )}
  >
    {data.map(({ value, icon, description }) => (
      <AggregateStat
        key={description}
        icon={icon}
        value={value}
        description={<FormattedMessage id={description} />}
      />
    ))}
  </div>
);

export const UsageAggregate = connect(
  (_, { isTmpState, totals }: Props): AggregateProps => ({
    data: [
      {
        value: totals.importedCount,
        icon: (
          <ExitToApp
            className={clsx(styles.BlueIcon, isTmpState && styles.TmpIcon)}
          />
        ),
        description: 'components.graphs.usage.series.imported',
      },
      {
        value: totals.confirmedCount,
        icon: (
          <CheckIcon
            className={clsx(styles.YellowIcon, isTmpState && styles.TmpIcon)}
          />
        ),
        description: 'components.graphs.usage.series.confirmed',
      },
      {
        value: totals.exportedCount,
        icon: (
          <Output
            className={clsx(styles.GreenIcon, isTmpState && styles.TmpIcon)}
          />
        ),
        description: 'components.graphs.usage.series.exported',
      },
      {
        value: totals.rejectedCount,
        icon: (
          <CancelIcon
            className={clsx(styles.LightRedIcon, isTmpState && styles.TmpIcon)}
          />
        ),
        description: 'components.graphs.usage.series.rejected',
      },
      {
        value: totals.deletedCount,
        icon: (
          <DeleteIcon
            className={clsx(styles.RedIcon, isTmpState && styles.TmpIcon)}
          />
        ),
        description: 'components.graphs.usage.series.deleted',
      },
    ],
  })
)(Aggregate);

export const TimeAggregate = injectIntl<'intl', PropsWithIntl>(
  connect((_, { isTmpState, totals, intl }: PropsWithIntl): AggregateProps => {
    const total = totals.turnaroundAvgS || 0;
    return {
      data: [
        {
          value: humanizeDuration(round(total, 2) * 1000, {
            largest: 2,
            language: intl.locale,
            fallbacks: [humanizeDurationLocaleMap[intl.locale], fallbackLocale],
            units: limitUnits(getDurationUnit(total)),
          }),
          icon: (
            <CheckIcon
              className={clsx(styles.BlueIcon, isTmpState && styles.TmpIcon)}
            />
          ),
          description: 'components.graphs.aggregate.turnaroundAvgS',
        },
      ],
    };
  })(Aggregate)
);

export const CorrectionsAggregate = injectIntl<'intl', PropsWithIntl>(
  connect((_, { isTmpState, totals }: PropsWithIntl): AggregateProps => {
    const total = totals.correctionsPerDocumentAvg || 0;
    return {
      data: [
        {
          value: (
            <FormattedMessage
              id="components.graphs.correctionsPerDocumentAvg.unit"
              values={{ count: round(total, 2) }}
            />
          ),
          icon: (
            <PencilIcon
              className={clsx(styles.BlueIcon, isTmpState && styles.TmpIcon)}
            />
          ),
          description: 'components.graphs.aggregate.corrections',
        },
      ],
    };
  })(Aggregate)
);

export const TimePerDocumentAggregate = injectIntl<'intl', PropsWithIntl>(
  connect((_, { intl, isTmpState, totals }: PropsWithIntl): AggregateProps => {
    const total = totals.timePerDocumentAvgS || 0;
    return {
      data: [
        {
          value: humanizeDuration(total * 1000, {
            largest: 2,
            units: limitUnits(getDurationUnit(total)),
            language: intl.locale,
            fallbacks: [humanizeDurationLocaleMap[intl.locale], fallbackLocale],
            round: true,
          }),
          icon: (
            <FileClockOutlineIcon
              className={clsx(styles.BlueIcon, isTmpState && styles.TmpIcon)}
            />
          ),
          description: 'components.graphs.aggregate.timePerDocumentAvgS',
        },
      ],
    };
  })(Aggregate)
);

export const DelayAggregate = connect(
  (_, { isTmpState, totals }: Props): AggregateProps => ({
    data: [
      {
        value: totals.exportedOnTimeCount,
        icon: (
          <TimerSandIcon
            className={clsx(styles.BlueIcon, isTmpState && styles.TmpIcon)}
          />
        ),
        description: 'components.graphs.delay.series.exportedOnTimeCount',
      },
      {
        value: totals.exportedLateCount,
        icon: (
          <TimerSandEmptyIcon
            className={clsx(styles.RedIcon, isTmpState && styles.TmpIcon)}
          />
        ),
        description: 'components.graphs.delay.series.exportedLateCount',
      },
    ],
  })
)(Aggregate);

export const AutomatedAggregate = connect(
  (_, { isTmpState, totals }: Props): AggregateProps => ({
    data: [
      {
        value: totals.exportedAutomatedCount,
        icon: (
          <FontDownloadOutlined
            fontSize="medium"
            className={clsx(isTmpState && styles.TmpIcon)}
            sx={{
              fill: theme => theme.palette.text.secondary,
            }}
          />
        ),
        description:
          'components.graphs.automated.series.exportedAutomatedCount',
      },
      {
        value: totals.exportedCount,
        icon: (
          <CursorPointerIcon
            className={clsx(styles.BlueIcon, isTmpState && styles.TmpIcon)}
          />
        ),
        description: 'components.graphs.automated.series.exportedNonAutomated',
      },
    ],
  })
)(Aggregate);

export const RejectionAggregate = connect(
  (_, { isTmpState, totals }: Props): AggregateProps => ({
    data: [
      {
        value: totals.rejectedAutomaticallyCount,
        icon: (
          <FontDownloadOutlined
            fontSize="medium"
            className={clsx(isTmpState && styles.TmpIcon)}
            sx={{
              fill: theme => theme.palette.text.secondary,
            }}
          />
        ),
        description: 'components.graphs.rejection.series.automatedRejections',
      },
      {
        value: totals.rejectedManuallyCount,
        icon: (
          <CursorPointerIcon
            className={clsx(styles.BlueIcon, isTmpState && styles.TmpIcon)}
          />
        ),
        description: 'components.graphs.rejection.series.manualRejections',
      },
    ],
  })
)(Aggregate);

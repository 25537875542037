import update from 'immutability-helper';
import {
  compact,
  curry,
  find,
  findIndex,
  first,
  flow,
  get,
  identity,
  includes,
  isArray,
  isEmpty,
  isEqual,
  isFunction,
  isNumber,
  isUndefined,
  last,
  minBy,
  uniq,
  uniqBy,
  zip,
} from 'lodash';
import { replace } from 'redux-first-history';
import { combineEpics } from 'redux-observable';
import { EMPTY, forkJoin, from, merge, of } from 'rxjs';
import {
  buffer,
  bufferWhen,
  catchError,
  concatAll,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  mergeMap,
  pairwise,
  pluck,
  startWith,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { getType } from 'typesafe-actions';
import { isLoggableDatapoint } from '../../../components/Datapoint/helpers';
import { apiUrl } from '../../../constants/config';
import {
  clickApiErrorHandler,
  datapointErrorHandlerIgnoring404,
  errorHandler,
  retryConnectionError,
} from '../../../lib/api';
import { report } from '../../../lib/apiHelpers';
import { BboxParams } from '../../../lib/spaceConvertor';
import { scanSemaphore } from '../../../lib/streams';
import { isNonEmptyArray, isNotNullOrUndefined } from '../../../lib/typeGuards';
import {
  constructDocumentUrl,
  getDatapointPathFromSearch,
  parse,
} from '../../../lib/url';
import { timeSpent } from '../../../timeSpent/timeSpent';
import {
  AddSuggestedOperation,
  AnyDatapointData,
  AnyDatapointDataST,
  DatapointValueDataST,
  MultivalueDatapointData,
  MultivalueDatapointDataST,
  ReplaceSuggestedOperation,
  SimpleDatapointDataST,
  SuggestedOperation,
  SuggestedOperationState,
  ValidationSources,
} from '../../../types/datapoints';
import { startAnnotationFulfilled } from '../annotation/actions';
import { validationEndingActionCreators } from '../annotation/helpers';
import { displayReviewIsNeededSelector } from '../annotation/selectors';
import { popAnnotationFromStack } from '../annotations/actions';
import { updateGridFulfilled } from '../grid/actions';
import { throwError } from '../messages/actions';
import { closePopup } from '../popup/actions';
import { fetchSchemaFulfilled } from '../schema/actions';
import { isFieldSelectable } from '../schema/helpers';
import { schemaMapSelector } from '../schema/schemaMapSelector';
import {
  columnsForMultivaluesSelector,
  timeSpentEnabledSelector,
} from '../schema/selectors';
import { setCurrentFooterColumn } from '../searchAndReplace/actions';
import {
  cancelEditMode,
  leaveValidation,
  startEditMode,
  toggleFooter,
} from '../ui/actions';
import {
  automaticSuggestionsEnabledSelector,
  complexLineItemsEnabledSelector,
} from '../ui/selectors';
import { sortFooterColumnsSelector } from '../user/selectors';
import { isActionOf, locationChange, makeEpic, updateInArray } from '../utils';
import {
  addSchemasToDatapoints,
  batchDatapointConfirm,
  batchDatapointDelete,
  batchDatapointDeleteFulfilled,
  batchDatapointUpdate,
  batchDatapointUpdateFulfilled,
  batchDatapointUpdateV2,
  batchDatapointValuesUpdateFulfilled,
  bulkUpdateDatapointValue,
  changeDatapointsSchemaIds,
  createDatapoint,
  createDatapointsFulfilled,
  createDatapointWithPosition,
  createGhostRow,
  datapointSelectionFulfilled,
  deleteAllDatapoints,
  deleteAllDatapointsFulfilled,
  deleteDatapoint,
  deleteDatapointAndNavigate,
  deleteDatapointFulfilled,
  fetchDatapoints,
  fetchDatapointsFulfilled,
  insertLine,
  lastDatapointsForTimeSpentUpdate,
  materializeVirtualDatapoints,
  materializeVirtualDatapointsFulfilled,
  navigateColumn,
  navigateRow,
  nextDatapoint,
  nextUnvalidatedDatapoint,
  onDatapointSelection,
  previousDatapoint,
  recountDatapointPosition,
  recountDatapointPositionFulfilled,
  resetDatapoint,
  resetDatapointFulfilled,
  selectDatapoint,
  selectNearestDatapoint,
  setValidatedMeta,
  setWaitingForSuggestions,
  sidebarDatapointHasChangedAction,
  suggestTable,
  suggestTableFullfilled,
  updateDatapointMeta,
  updateDatapointMetaFulfilled,
  updateDatapointValue,
  updateDatapointValueFulfilled,
  updatePosition,
  validate,
  validateFulfilled,
  validationStart,
} from './actions';
import {
  addSchemaToUpdatedTreeDatapoints,
  fillInDatapointIds,
  updateDatapoints,
} from './helpers';
import {
  findDatapointById,
  findDatapointIndex,
} from './navigation/findDatapointIndex';
import {
  findNextColumnInTuple,
  getSelectedDatapoint,
  toNavigationStop,
} from './navigation/navigationStop';
import {
  currentNavigationStopSelector,
  nextNavigationStopSelector,
} from './navigation/navigationStopSelectors';
import { resolveAnyDatapointPath } from './navigation/resolvedDatapointPath';
import { initialContent } from './reducer';
import {
  currentDatapointIdSelector,
  currentDatapointSelector,
  currentMultivalueDatapointSelector,
  datapointPathSelector,
  datapointsSelector,
  getCurrentSidebarDatapointId,
  getCurrentTuple,
  getVisibleDatapoints,
  isDeleteRecommendationSelector,
} from './selector';
import { acceptSuggestedOperationsFulfilledAction } from './suggestedOperations/actions';
import { replaceSuggestedOperationsSelector } from './suggestedOperations/selector';
import {
  addMeta,
  addVirtualDatapointsForSuggestedOperations,
  calculateInsertedTuplePosition,
  fillPathHead,
  fillPathTail,
  flattenDatapoints,
  getDataForBatchUpdate,
  getSidebarDatapoint,
  isBoundedDatapoint,
  isDatapointHumanValidated,
  isDatapointValidated,
  isDatapointWithoutPosition,
  isNonEmptyDatapoint,
  isSimpleDatapoint,
  isTableDatapoint,
  isVirtualDatapoint,
} from './typedHelpers';
import { DatapointsFulfilledPayload, ValidateFulfilledPayload } from './types';

// TODO: Add `table_suggester` when backend is ready.
const TABLE_SUGGESTER_SOURCES: ValidationSources[] = ['table_suggester'];

const datapointSelectionObservable$ = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(onDatapointSelection)),
    filter(() => !state$.value.ui.readOnly),
    pluck('meta'),
    // TODO PERF we should retrieve datapoint by index
    map(({ id, datapointPath }) => {
      const annotationUrl = state$.value.annotation.url;

      // Datapoints are selected on an annotation, so it should always be loaded
      if (!annotationUrl) {
        return undefined;
      }

      if (!id) {
        return {
          datapoint: null,
          datapointPath: [] as (AnyDatapointDataST | undefined)[],
          annotationUrl,
        };
      }

      return {
        datapoint: find(state$.value.datapoints.content, { id }),
        datapointPath: datapointPath.map(id =>
          find(state$.value.datapoints.content, { id })
        ) as (AnyDatapointDataST | undefined)[],
        annotationUrl,
      };
    }),
    filter(isNotNullOrUndefined)
  )
);

const lastDatapointsForTimeSpentUpdateEpic = makeEpic(
  (action$, state$, dependencies) =>
    datapointSelectionObservable$(action$, state$, dependencies).pipe(
      map(({ datapointPath, annotationUrl }) =>
        lastDatapointsForTimeSpentUpdate({
          annotationUrl,
          datapoints: datapointPath
            .filter(isNotNullOrUndefined)
            .filter(timeSpentEnabledSelector(state$.value))
            .map(d => ({ id: d.id })),
        })
      )
    )
);

const updateValidatedOnDatapointSelectionEpic = makeEpic(
  (action$, state$, dependencies) =>
    datapointSelectionObservable$(action$, state$, dependencies).pipe(
      map(({ datapoint }) => datapoint),
      filter(isNotNullOrUndefined),
      filter(
        (datapoint): datapoint is SimpleDatapointDataST =>
          'timeSpent' in datapoint &&
          'validationSources' in datapoint &&
          isNumber(datapoint.timeSpent) &&
          isArray(datapoint.validationSources)
      ),
      filter(
        datapoint =>
          !get(state$.value.datapoints.suggestedOperations, datapoint.id)
      ),
      mergeMap(datapoint =>
        action$.pipe(
          filter(isActionOf(onDatapointSelection)),
          take(1),
          filter(() => !state$.value.ui.fieldAutomationBlockersVisible),
          filter(({ meta: { ignoreMeta } }) => !ignoreMeta),
          takeUntil(
            action$.pipe(
              filter(
                isActionOf([
                  ...validationEndingActionCreators,
                  startEditMode,
                  popAnnotationFromStack,
                  deleteAllDatapointsFulfilled,
                ])
              )
            )
          ),
          filter(
            // Filter updating on deleted datapoints
            () =>
              get(
                state$.value.datapoints.content[datapoint.meta.index],
                'id'
              ) === datapoint.id
          ),
          filter(() => !includes(datapoint.validationSources, 'human')),
          map(() =>
            getSidebarDatapoint(state$.value.datapoints.content)(datapoint)
          ),
          filter(sidebarDatapoint => {
            if (!sidebarDatapoint) return false;

            if (sidebarDatapoint.id === datapoint.id) return true;

            const datapoints = state$.value.datapoints.content;

            return isDatapointValidated(
              update(datapoints, {
                [datapoint.meta.index]: {
                  validationSources: {
                    $set: ['human'],
                  },
                },
              })
            )(sidebarDatapoint);
          }),
          map(sidebarDatapoint =>
            setValidatedMeta(
              sidebarDatapoint.meta.index,
              true,
              sidebarDatapoint.id === datapoint.id ||
                isDatapointHumanValidated(
                  update(state$.value.datapoints.content, {
                    [datapoint.meta.index]: {
                      validationSources: {
                        $set: ['human'],
                      },
                    },
                  })
                )(sidebarDatapoint)
            )
          )
        )
      )
    )
);

const updateValidatedAfterUpdateDatapoints = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf([createDatapointsFulfilled, deleteDatapointFulfilled])),
    pluck('meta', 'parentIndex'),
    map(parentIndex => state$.value.datapoints.content[parentIndex]),
    map(sidebarDatapoint => {
      const isHumanValidated = isDatapointHumanValidated(
        state$.value.datapoints.content
      )(sidebarDatapoint);
      return setValidatedMeta(
        sidebarDatapoint.meta.index,
        isHumanValidated ||
          isDatapointValidated(state$.value.datapoints.content)(
            sidebarDatapoint
          ),
        isHumanValidated
      );
    })
  )
);

const EMPTY_DATAPOINT_PATH = {
  datapointPath: [] as (AnyDatapointDataST | undefined)[],
  annotationUrl: '',
};

const createGhostRowEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([
        datapointSelectionFulfilled,
        deleteAllDatapointsFulfilled,
        deleteDatapointFulfilled,
        batchDatapointDeleteFulfilled,
        createGhostRow,
      ])
    ),
    filter(() => complexLineItemsEnabledSelector(state$.value)),
    map(action => {
      if (isActionOf(datapointSelectionFulfilled)(action))
        return action.payload.datapointPath[1];

      return currentMultivalueDatapointSelector(state$.value);
    }),
    filter(isNotNullOrUndefined),
    filter(
      datapoint =>
        isTableDatapoint(datapoint) && datapoint.children.length === 0
    ),
    map(multivalue =>
      suggestTable({
        multivalueId: multivalue.id,
        pagesChunks: [],
      })
    )
  )
);

export const onDatapointSelectionEpic = makeEpic(
  (action$, state$, dependencies) =>
    datapointSelectionObservable$(action$, state$, dependencies).pipe(
      // TODO can I have suggested operations for multivalues? Or only on datapoint level
      filter(({ datapoint }) =>
        complexLineItemsEnabledSelector(state$.value)
          ? true
          : !datapoint ||
            !get(state$.value.datapoints.suggestedOperations, datapoint.id)
      ),
      map(({ datapointPath, annotationUrl }) => ({
        datapointPath,
        annotationUrl,
      })),
      startWith(EMPTY_DATAPOINT_PATH),
      distinctUntilChanged((a, b) =>
        isEqual(
          a.datapointPath.map(d => d?.id),
          b.datapointPath.map(d => d?.id)
        )
      ),
      pairwise(),
      mergeMap(([oldPath, newPath]) => {
        const changedPathParts: [
          AnyDatapointDataST | undefined,
          AnyDatapointDataST | undefined,
        ][] = zip(oldPath.datapointPath, newPath.datapointPath).filter(
          ([oldDatapoint, newDatapoint]) =>
            oldDatapoint?.id !== newDatapoint?.id
        );

        const datapointsToStop = changedPathParts
          .map(p => p[0])
          .filter(isNotNullOrUndefined)
          .filter(timeSpentEnabledSelector(state$.value))
          .filter(dp => !isVirtualDatapoint(dp.id));

        const datapointsToStart = changedPathParts
          .map(p => p[1])
          .filter(isNotNullOrUndefined)
          .filter(timeSpentEnabledSelector(state$.value))
          .filter(dp => !isVirtualDatapoint(dp.id));

        const isVirtualTuple =
          oldPath.datapointPath[2] &&
          isVirtualDatapoint(oldPath.datapointPath[2].id);

        const lastVisitedDatapoint =
          oldPath.datapointPath[3] &&
          findDatapointById(
            state$.value.datapoints.content,
            oldPath.datapointPath[3].id
          );

        const virtualTupleToCreate =
          oldPath.datapointPath[2] &&
          oldPath.datapointPath[3] &&
          isVirtualTuple &&
          // Don't materialize virtual tuple when leaving an empty datapoint
          // (datapoint without value).
          // It should work both for ghost row and add suggested operations.
          isNonEmptyDatapoint(lastVisitedDatapoint) &&
          // The old path might represent a virtual datapoint which was replaced by a newer one
          findDatapointIndex(
            state$.value.datapoints.content,
            oldPath.datapointPath[2].id
          ) !== -1
            ? {
                tupleId: oldPath.datapointPath[2].id,
                validatedDatapointIds: [oldPath.datapointPath[3].id],
                // Only content for the validated datapoint will be used,
                // content for the rest of the datapoints will stay only in the local state.
                // If last datapoint value had been filled manually without a position, materialize all tuple datapoints to preserve correct position in the grid
                createWithContent:
                  isDatapointWithoutPosition(lastVisitedDatapoint),
              }
            : null;

        const leavingLineItem =
          oldPath.datapointPath[1]?.category === 'multivalue' &&
          !oldPath.datapointPath[1]?.meta.isSimpleMultivalue &&
          oldPath.datapointPath[1]?.id !== newPath.datapointPath[1]?.id;

        const existingVirtualTuples =
          oldPath.datapointPath[1]?.category === 'multivalue'
            ? // datapointPath doesn't need to have the latest set of children,
              // so we need to access datapoints from state directly
              (
                (
                  findDatapointById(
                    state$.value.datapoints.content,
                    oldPath.datapointPath[1].id
                  ) as MultivalueDatapointDataST
                )?.children ?? []
              )
                .map(child => child.id)
                .filter(isVirtualDatapoint)
            : [];

        const currentSuggestedOperation = oldPath.datapointPath[3]?.id
          ? replaceSuggestedOperationsSelector(state$.value)[
              oldPath.datapointPath[3]?.id
            ]
          : null;
        const confirmReplaceSuggestedOperation =
          currentSuggestedOperation?.source === 'table'
            ? currentSuggestedOperation
            : undefined;

        const updateActions = compact([
          ...datapointsToStop.map(d => {
            const payload = timeSpent.stopAnyDatapoint(d.id);

            if (!payload) {
              return undefined;
            }

            // datapointsToStop are taken from oldPath and thus can sometimes contain outdated snapshot of the state
            // e.g. when removing empty ghost row, next header datapoint would not be validated correctly because
            // its index has changed in the meantime, try to find updated index in the current state in this case
            const index =
              state$.value.datapoints.content[d.meta.index]?.id === d.id
                ? d.meta.index
                : findDatapointById(state$.value.datapoints.content, d.id)?.meta
                    .index;

            if (index === undefined) {
              return undefined;
            }

            return updateDatapointMeta(
              {
                id: d.id,
                index,
                annotationUrl: oldPath.annotationUrl,
              },
              {
                timeSpent: payload.timeSpent,
                timeSpentOverall: payload.timeSpentOverall,
                timeSpentGrid: payload.timeSpentGrid,
                timeSpentGridOverall: payload.timeSpentGridOverall,
                validationSources: uniq([
                  ...get(
                    state$.value.datapoints.content[index],
                    'validationSources',
                    []
                  ),
                  'human',
                ]),
              }
            );
          }),
          ...(leavingLineItem
            ? compact(
                existingVirtualTuples.map(id => {
                  return deleteDatapoint([
                    oldPath.datapointPath[0]!.id,
                    oldPath.datapointPath[1]!.id,
                    id,
                  ]);
                })
              )
            : []),

          datapointSelectionFulfilled({
            datapointPath: newPath.datapointPath,
          }),
          virtualTupleToCreate || confirmReplaceSuggestedOperation
            ? materializeVirtualDatapoints({
                tuplesToCreate: virtualTupleToCreate
                  ? [virtualTupleToCreate]
                  : [],
                datapointsToReplace: confirmReplaceSuggestedOperation
                  ? [
                      {
                        datapointId: confirmReplaceSuggestedOperation.id,
                        // When we are leaving a datapoint with replace suggested operation,
                        // mark it as validated
                        isValidated: true,
                      },
                    ]
                  : [],
                multivalueId: oldPath.datapointPath[1]!.id,
              })
            : null,
          // Update local state. Backend is updated through materializeVirtualDatapoints
          ...(confirmReplaceSuggestedOperation
            ? [
                updateDatapointValue(
                  {
                    id: confirmReplaceSuggestedOperation.id,
                    index: findDatapointIndex(
                      state$.value.datapoints.content,
                      confirmReplaceSuggestedOperation.id
                    ),
                    oldValue: '',
                    reason: 'input-edit-box',
                    validationSource: 'human',
                  },
                  confirmReplaceSuggestedOperation.value.content.value
                ),
              ]
            : []),
          ...(confirmReplaceSuggestedOperation?.value.content.page
            ? [
                updatePosition(
                  {
                    index: findDatapointIndex(
                      state$.value.datapoints.content,
                      confirmReplaceSuggestedOperation.id
                    ),
                    page: state$.value.pages.pages[
                      confirmReplaceSuggestedOperation.value.content.page - 1
                    ],
                  },
                  {
                    content: {
                      position:
                        confirmReplaceSuggestedOperation.value.content
                          .position ?? undefined,
                    },
                  }
                ),
              ]
            : []),
        ]);

        datapointsToStart.forEach(d => {
          const schema = schemaMapSelector(state$.value).get(d.schemaId);
          timeSpent.startAnyDatapoint(d, schema?.label ?? 'Unknown');
        });

        return updateActions;
      })
    )
);

const materializeVirtualDatapointsEpic = makeEpic(
  (action$, state$, { authPost$ }) =>
    action$.pipe(
      filter(isActionOf(startAnnotationFulfilled)),
      switchMap(() =>
        action$.pipe(
          filter(isActionOf(materializeVirtualDatapoints)),
          takeUntil(
            action$.pipe(filter(isActionOf(validationEndingActionCreators)))
          ),
          concatMap(
            ({
              payload: {
                tuplesToCreate: tupleIds,
                datapointsToReplace,
                multivalueId,
              },
            }) => {
              const tuples = compact(
                tupleIds.map(
                  ({ tupleId, validatedDatapointIds, createWithContent }) => {
                    const tuple = findDatapointById(
                      state$.value.datapoints.content,
                      tupleId
                    );

                    if (tuple?.category !== 'tuple') {
                      return null;
                    }

                    return {
                      ...tuple,
                      children: tuple.children.map(({ index, id }) => ({
                        ...(state$.value.datapoints.content[
                          index
                          // I am pretty sure that child of a tuple is a simple datapoint
                        ] as SimpleDatapointDataST),
                        isValidated: validatedDatapointIds.includes(id),
                      })),
                      createWithContent,
                    };
                  }
                )
              );

              const multivalue = findDatapointById(
                state$.value.datapoints.content,
                multivalueId
              );

              if (!multivalue || multivalue.category !== 'multivalue') {
                return EMPTY;
              }

              const existingTupleIds = new Set(
                multivalue.children.map(c => c.id) ?? []
              );

              // If manually adding a line, the datapoints can have a temporary page
              const removePageIfNoPosition = (data: DatapointValueDataST) => {
                return data.position ? data : { ...data, page: null };
              };

              const createOperations = tuples.map(tuple => ({
                op: 'add',
                id: tuple.meta.parentId,
                value: tuple.children.flatMap(child => {
                  const isEditableField = isFieldSelectable(child.schema);

                  const isEmptyValue =
                    !child.content?.value || child.content.value === '';

                  return isEditableField
                    ? {
                        schemaId: child.schemaId,
                        content:
                          child.content &&
                          (child.isValidated || tuple.createWithContent)
                            ? {
                                ...removePageIfNoPosition(child.content),
                                value: isEmptyValue
                                  ? child.schema?.defaultValue ?? ''
                                  : child.content?.value ?? '',
                                ocrPosition: null,
                              }
                            : undefined,
                        validationSources: child.isValidated
                          ? ['human']
                          : tuple.createWithContent
                            ? isEmptyValue
                              ? []
                              : TABLE_SUGGESTER_SOURCES
                            : [],
                      }
                    : [];
                }),
              }));

              // After a virtual tuple (= add suggested operation) is materialized,
              // all the unvalidated cells should be optimistically turned into replace suggested operations,
              // until the next suggest_table endpoint will finish.
              // This should minimize visual changes for the user.
              const optimisticReplaceSuggestedOperations: SuggestedOperationState<ReplaceSuggestedOperation>[] =
                tuples.flatMap(tuple =>
                  // Tuples created with content shouldn't show replace suggested operation, since the
                  // value is sent to the backend.
                  tuple.createWithContent
                    ? []
                    : tuple.children.flatMap(child =>
                        !child.isValidated &&
                        'content' in child &&
                        child.content?.value
                          ? [
                              {
                                source: 'table',
                                op: 'replace',
                                id: child.id,
                                value: {
                                  id: child.id,
                                  content: child.content!,
                                  validationSources: TABLE_SUGGESTER_SOURCES,
                                },
                              },
                            ]
                          : []
                      )
                );

              const replaceOperations: ReplaceSuggestedOperation[] = compact(
                datapointsToReplace.map(({ datapointId: id, isValidated }) => {
                  const datapoint = findDatapointById(
                    state$.value.datapoints.content,
                    id
                  );

                  if (
                    !datapoint ||
                    datapoint.category !== 'datapoint' ||
                    // Do we really require DP to have content during replace operation?
                    !datapoint.content
                  )
                    return null;

                  // local state is already optimistically updated
                  const { page, value, position } = datapoint.content;

                  // It could happen that DP has page but doesn't have position.
                  // In this case, API will complain that this doesn't make sense.
                  // TODO: It would be great if we will be able to investigate why this is happening
                  // in the first place
                  if (page && !position) return null;

                  return {
                    op: 'replace',
                    id,
                    value: {
                      id,
                      content: { page, value, position },
                      validationSources: isValidated
                        ? ['human']
                        : TABLE_SUGGESTER_SOURCES,
                    },
                  };
                })
              );

              const operations = [...createOperations, ...replaceOperations];

              if (!operations.length) {
                return EMPTY;
              }

              return authPost$<{ results: AnyDatapointData[] }>(
                `${state$.value.annotation.url}/content/operations?update_complex_grid=true`,
                {
                  operations,
                }
              ).pipe(
                mergeMap(response => {
                  const flattenedResponse = flattenDatapoints(
                    {
                      content: response.results,
                    },
                    complexLineItemsEnabledSelector(state$.value)
                  );

                  const parentMultivalue = find(
                    flattenedResponse.content,
                    dp => dp.id === multivalueId
                  ) as MultivalueDatapointDataST;

                  const newlyCreatedTuples = parentMultivalue.children.filter(
                    t => !existingTupleIds.has(t.id)
                  );

                  const virtualToRealIdMap = Object.fromEntries(
                    zip(tuples, newlyCreatedTuples).flatMap(
                      ([tuple, child]) => {
                        if (!tuple || !child) {
                          window.Rollbar?.error(
                            'Possible error: Mismatching tuples count.',
                            {
                              error:
                                'Possible error: Mismatching tuples count.',
                              recent_actions: window.recent_actions || [],
                              tuples: tuples.map(tuple => tuple.id),
                              stateTuples: parentMultivalue.children.map(
                                ch => ch.id
                              ),
                              newlyCreatedTuples: newlyCreatedTuples.map(
                                tuple => tuple.id
                              ),
                            }
                          );
                          throw new Error(
                            'Possible error: Mismatching tuples count.'
                          );
                        }

                        return [
                          [tuple.id, { id: child.id, url: '' }] as const,
                          ...tuple.children.map((c, i) => {
                            const childDatapoint =
                              flattenedResponse.content[child.index + i + 1];
                            return [
                              c.id,
                              {
                                id: childDatapoint.id,
                                validationSources: isSimpleDatapoint(
                                  childDatapoint
                                )
                                  ? childDatapoint.validationSources
                                  : undefined,
                                url: '',
                              },
                            ] as const;
                          }),
                        ];
                      }
                    )
                  );

                  const datapointPath = getDatapointPathFromSearch(
                    state$.value.router.location.search
                  );

                  const newDatapointIdToOCR =
                    tupleIds.length === 1
                      ? tupleIds[0].validatedDatapointIds[0]
                      : null;

                  const newDatapointIndexToOCR = newDatapointIdToOCR
                    ? findDatapointIndex(
                        state$.value.datapoints.content,
                        newDatapointIdToOCR
                      )
                    : null;

                  const acceptedReplaceSuggestedDatapointIds =
                    replaceOperations.map(ro => ro.id);

                  const nextDatapointId = last(datapointPath);

                  return of(
                    ...compact([
                      // create [oldId: newDatapoint] tuples
                      materializeVirtualDatapointsFulfilled({
                        virtualToRealIdMap,
                        optimisticReplaceSuggestedOperations,
                        acceptedReplaceSuggestedDatapointIds,
                      }),
                      // TODO: so that /select is called on new datapoint, temporary hack
                      typeof newDatapointIndexToOCR === 'number' &&
                      newDatapointIndexToOCR > -1
                        ? recountDatapointPosition(newDatapointIndexToOCR, {
                            oldValue: '',
                            reason: 'resize-bbox',
                          })
                        : null,
                      nextDatapointId && virtualToRealIdMap[nextDatapointId]
                        ? selectDatapoint([
                            virtualToRealIdMap[nextDatapointId].id,
                          ])
                        : // If there is no simple dp to select, do not select anything
                          // otherwise it would select parent multivalue and you will be always
                          // scrolled to the first page
                          // TODO: when leaving the last datapoint, do not put #addValue hash in the url
                          // since there is no addValue button to focus anymore
                          datapointPath.length !== 2
                          ? selectDatapoint(datapointPath)
                          : null,
                    ])
                  );
                })
              );
            }
          )
        )
      )
    )
);

const suggestTableEpic = makeEpic((action$, state$, { authPost$ }) =>
  action$.pipe(
    filter(isActionOf(startAnnotationFulfilled)),
    switchMap(() =>
      action$.pipe(
        filter(isActionOf([suggestTable, validate])),
        takeUntil(
          action$.pipe(filter(isActionOf(validationEndingActionCreators)))
        ),
        switchMap(action => {
          // validate action indicates a user action, which means
          // any suggest_table call in progress should be cancelled
          if (action.type === getType(validate)) {
            return EMPTY;
          }

          if (!complexLineItemsEnabledSelector(state$.value)) return EMPTY;

          const {
            payload: { multivalueId, pagesChunks, ghostRow },
          } = action;

          const datapoint = findDatapointById(
            state$.value.datapoints.content ?? [],
            multivalueId
          );

          if (!datapoint) {
            return EMPTY;
          }

          const columns = columnsForMultivaluesSelector(state$.value)[
            datapoint.schemaId
          ];

          return (
            // No pages means we want to create a ghost row, which works the same
            // as add suggested operation, so we reuse the same epic.
            (
              ghostRow
                ? of<{ suggestedOperations: SuggestedOperation[] }>({
                    suggestedOperations: [
                      {
                        op: 'add',
                        id: multivalueId,
                        value: columns.map(c => ({
                          schemaId: c.id,
                          content: {
                            value: '',
                            page: ghostRow.page,
                            ocrPosition: ghostRow.position,
                          },
                          validationSources: [],
                        })),
                      },
                    ],
                  })
                : pagesChunks.length === 0
                  ? of<{ suggestedOperations: SuggestedOperation[] }>({
                      suggestedOperations: [
                        {
                          op: 'add',
                          id: multivalueId,
                          value: columns.map(c => ({
                            schemaId: c.id,
                            content: {
                              value: '',
                              page: null,
                            },
                            validationSources: [],
                          })),
                        },
                      ],
                    })
                  : forkJoin(
                      pagesChunks.map(pageChunk =>
                        authPost$<{
                          suggestedOperations: SuggestedOperation[];
                        }>(
                          `${state$.value.annotation.url}/content/${multivalueId}/suggest_table`,
                          {
                            pageNumbers: pageChunk,
                          }
                        )
                      )
                    ).pipe(
                      map(bulkSuggestedOperations => ({
                        suggestedOperations: bulkSuggestedOperations
                          .map(op => op.suggestedOperations)
                          .flat(),
                      }))
                    )
            ).pipe(
              concatMap(
                ({ suggestedOperations: originalSuggestedOperations }) => {
                  // If AI for any reason fails to predict suggestions,
                  // we keep the current ones, rather then delete them.
                  if (originalSuggestedOperations.length === 0) {
                    return of(setWaitingForSuggestions(false));
                  }

                  const datapointPath = getDatapointPathFromSearch(
                    state$.value.router.location.search
                  );

                  const selectedDatapointId = last(datapointPath);
                  const selectedDatapoint = selectedDatapointId
                    ? findDatapointById(
                        state$.value.datapoints.content,
                        selectedDatapointId
                      )
                    : null;

                  const overlaps = (bbox1: BboxParams, bbox2: BboxParams) => {
                    const separatedOnXAxis =
                      bbox1[0] > bbox2[2] || bbox2[0] > bbox1[2];
                    const separatedOnYAxis =
                      bbox1[1] > bbox2[3] || bbox2[1] > bbox1[3];
                    return !separatedOnXAxis && !separatedOnYAxis;
                  };

                  const bestCandidateForSelectedDatapoint =
                    selectedDatapoint &&
                    isVirtualDatapoint(selectedDatapoint.id) &&
                    selectedDatapoint?.category === 'datapoint' &&
                    selectedDatapoint?.content?.position
                      ? originalSuggestedOperations
                          .filter(
                            (op): op is AddSuggestedOperation => op.op === 'add'
                          )
                          .flatMap(op => op.value)
                          // CLI: For now, pick first suggested virtual bbox which overlaps the current one
                          .find(
                            v =>
                              v.schemaId === selectedDatapoint.schemaId &&
                              selectedDatapoint.content?.position &&
                              v.content.position &&
                              overlaps(
                                v.content.position,
                                selectedDatapoint.content.position
                              )
                          )
                      : null;

                  const { updatedDatapoints, suggestedOperations } =
                    addSchemaToUpdatedTreeDatapoints(
                      addVirtualDatapointsForSuggestedOperations(
                        {
                          suggestedOperations: originalSuggestedOperations,
                          // CLI: fix interface of the function
                          messages: [],
                          matchedTriggerRules: [],
                          updatedDatapoints: [],
                          complexLineItemsEnabled:
                            complexLineItemsEnabledSelector(state$.value),
                        },
                        state$.value,
                        {
                          // Try to preserve ID of the selected virtual datapoint
                          // if we can find a candidate suggestion from the new suggested operations.
                          // Trying to match all new suggestions to previous suggestions
                          // would be much more complicated, and probably not necessary.
                          // Keeping selected datapoint is important so that user won't see
                          // any visual changes (e.g. validation dialog disappearing and appearing)
                          // We also need to preserve tuple ID, so that React component won't get unmounted and mounted again
                          // (which triggers auto focus)
                          idToKeep:
                            bestCandidateForSelectedDatapoint &&
                            selectedDatapoint &&
                            selectedDatapoint.meta.parentId
                              ? {
                                  suggestedDatapoint:
                                    bestCandidateForSelectedDatapoint,
                                  datapointId: selectedDatapoint.id,
                                  tupleId: selectedDatapoint.meta.parentId,
                                }
                              : undefined,
                        },
                        currentDatapointIdSelector(state$.value)
                      )
                    )(state$.value.schema.content ?? []);

                  const newState = updateDatapoints(
                    state$.value.datapoints,
                    updatedDatapoints,
                    complexLineItemsEnabledSelector(state$.value)
                  );

                  // Find the new datapoint corresponding to the previously selected virtual datapoint
                  const dpToSelect = bestCandidateForSelectedDatapoint
                    ? newState.content.find(
                        d =>
                          d.category === 'datapoint' &&
                          isEqual(
                            d.content?.position,
                            bestCandidateForSelectedDatapoint.content.position
                          )
                      )
                    : null;

                  const lastAddedVirtualTuple = minBy(
                    newState.content.filter(
                      dp => dp.category === 'tuple' && isVirtualDatapoint(dp.id)
                    ),
                    dp => dp.id
                  );

                  return of(
                    ...compact([
                      suggestTableFullfilled({
                        updatedDatapoints,
                        newState,
                        suggestedOperations,
                      }),
                      // suggest_table change IDs of virtual datapoints, so we might need to change the datapointPath
                      dpToSelect ? selectDatapoint([dpToSelect.id]) : null,
                      // When adding a ghost row, we want to select the first column
                      ghostRow && lastAddedVirtualTuple
                        ? selectDatapoint([lastAddedVirtualTuple.id])
                        : pagesChunks.length === 0
                          ? selectDatapoint([multivalueId])
                          : null,
                    ])
                  );
                }
              ),
              catchError(err => {
                // Suggestions are "optional", so any error shouldn't prevent user from continuing with their work.
                report(err);
                return of(throwError('suggestionError'));
              })
            )
          );
        })
      )
    )
  )
);

const pendingValidate = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(startAnnotationFulfilled)),
    switchMap(() =>
      action$.pipe(
        filter(isActionOf([validate, validateFulfilled])),
        scanSemaphore(getType(validate), getType(validateFulfilled)),
        filter(([, shouldValidate]) => shouldValidate),
        map(() => validationStart())
      )
    )
  )
);

export const validateEpic = makeEpic((action$, state$, { authPost$ }) =>
  action$.pipe(
    filter(
      isActionOf([startAnnotationFulfilled, ...validationEndingActionCreators])
    ),
    // This switchMap is here for killing concatMaps.
    // If you find out a better concatMap killer put it here.
    switchMap(() =>
      action$.pipe(
        filter(isActionOf(validate)),
        buffer(action$.pipe(filter(isActionOf(validationStart)))),
        concatMap(validateActions => {
          const updatedDatapointIds = uniq(
            validateActions.flatMap(item => item.payload.updatedDatapointIds)
          );

          const actions = uniq(
            validateActions.flatMap(item => item.payload.actions ?? [])
          );

          const currentMultivalue = currentMultivalueDatapointSelector(
            state$.value
          );

          const updatedDatapointsWithPosition = compact(
            updatedDatapointIds.map(id =>
              findDatapointById(state$.value.datapoints.content, id)
            )
          ).filter(isBoundedDatapoint);

          // We are assuming that there are updates for one page only
          const pageForSuggestions = currentMultivalue
            ? updatedDatapointsWithPosition[0]?.content.page
            : undefined;

          // We need to determine if this /validate call will result in a suggest_table call or not,
          // before it's executed, in order to display progress indicator.
          const suggestTableAction =
            currentMultivalue &&
            !currentMultivalue.meta.isSimpleMultivalue &&
            pageForSuggestions &&
            // CLI: RIR can provide predictions only when a simple datapoint with bbox was updated.
            // otherwise we would get empty suggestions
            updatedDatapointsWithPosition.length > 0 &&
            automaticSuggestionsEnabledSelector(state$.value) &&
            suggestTable({
              multivalueId: currentMultivalue.id,
              pagesChunks: [[pageForSuggestions]],
            });

          // Initial validation is started before the annotation is loaded, so the URL doesn't have to be present in the state
          const validateObservable = authPost$<ValidateFulfilledPayload>(
            `${
              state$.value.annotation.url ?? validateActions[0].payload.url
            }/content/validate`,
            {
              updatedDatapointIds,
              actions: actions.length ? actions : undefined,
            }
          ).pipe(
            // Retrying validate call should be safe (see comment in US-1018)
            retryConnectionError(),
            switchMap(response =>
              state$.pipe(
                filter(
                  state =>
                    isNonEmptyArray(state.schema.content) &&
                    state.datapoints.loaded
                ),
                take(1),
                // Merge response and suggested operation induced virtual datapoints
                map(state => {
                  const complexLineItemsEnabled =
                    complexLineItemsEnabledSelector(state$.value);

                  return addSchemaToUpdatedTreeDatapoints({
                    ...response,
                    complexLineItemsEnabled,
                  })(state.schema.content ?? []);
                }),
                concatMap(updatedResponse => {
                  const actions = compact([
                    validateFulfilled(updatedResponse, {
                      validatedDatapointsIds: updatedDatapointIds,
                    }),
                    suggestTableAction,
                  ]);

                  return from(actions);
                })
              )
            ),
            catchError(errorHandler)
          );

          return of(
            suggestTableAction ? of(setWaitingForSuggestions(true)) : EMPTY,
            validateObservable
          ).pipe(concatAll());
        })
      )
    )
  )
);

export const validateDatapointActionsEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(startAnnotationFulfilled)),
    switchMap(({ meta: { url } }) =>
      merge(
        // ---------- updatingActionCreators fulfilled actions --------------
        action$.pipe(
          filter(isActionOf(deleteDatapointFulfilled)),
          map(({ meta: { parentIndex } }) => ({
            ids: [state$.value.datapoints.content[parentIndex].id],
            actions: ['user_update', 'updated'],
          }))
        ),
        action$.pipe(
          filter(isActionOf(resetDatapointFulfilled)),
          map(({ payload: { id } }) => ({
            ids: [id],
            actions: ['user_update', 'updated'],
          }))
        ),
        action$.pipe(
          filter(isActionOf(recountDatapointPositionFulfilled)),
          map(({ payload: { id } }) => ({
            ids: [id],
            actions: ['user_update', 'updated'],
          }))
        ),
        action$.pipe(
          filter(isActionOf(updateDatapointValueFulfilled)),
          map(({ payload }) => ({
            ids: [payload.id],
            actions: ['user_update', 'updated'],
          }))
        ),
        action$.pipe(
          filter(isActionOf(deleteAllDatapointsFulfilled)),
          map(({ payload: { parentIndex } }) => ({
            ids: [state$.value.datapoints.content[parentIndex].id],
            actions: ['user_update', 'updated'],
          }))
        ),
        // Validate immediately after the annotation is started
        of({ ids: [], actions: ['user_update', 'started'] }),

        // -------- Other actions -----------
        action$.pipe(
          filter(isActionOf(closePopup)),
          mapTo({ ids: [], actions: ['user_update', 'updated'] })
        ),
        action$.pipe(
          filter(isActionOf(batchDatapointUpdateFulfilled)),
          map(({ meta: { ids } }) => ({
            ids,
            actions: ['user_update', 'updated'],
          }))
        ),
        action$.pipe(
          filter(isActionOf(batchDatapointDelete)),
          map(({ payload: { tuplesToDelete, datapointsToReset } }) => {
            const parentMultivalueIds = uniq(
              compact(
                tuplesToDelete
                  .map(t => datapointsSelector(state$.value)[t.index])
                  .map(tuple => tuple?.meta.parentId)
              )
            );
            return {
              ids: [
                ...parentMultivalueIds,
                ...datapointsToReset.map(x => x.id),
              ],
              actions: ['user_update', 'updated'],
            };
          })
        ),
        action$.pipe(
          filter(isActionOf(batchDatapointValuesUpdateFulfilled)),
          map(({ meta: { ids } }) => ({
            ids,
            actions: ['user_update', 'updated'],
          }))
        ),
        action$.pipe(
          filter(isActionOf(acceptSuggestedOperationsFulfilledAction)),
          map(({ meta: { ids } }) => ({
            ids,
            actions: ['user_update', 'updated'],
          }))
        ),
        action$.pipe(
          filter(isActionOf(createDatapointsFulfilled)),
          map(({ payload }) => ({
            ids: flattenDatapoints(
              payload,
              complexLineItemsEnabledSelector(state$.value)
            ).content.map(({ id }) => id),
            actions: ['user_update', 'updated'],
          }))
        ),
        action$.pipe(
          filter(isActionOf(updateGridFulfilled)),
          map(({ meta: { updatedDatapointIds } }) => ({
            ids: updatedDatapointIds,
            actions: ['user_update', 'updated'],
          })),
          filter(datapoints => !!datapoints.ids.length)
        ),
        action$.pipe(
          filter(isActionOf(materializeVirtualDatapointsFulfilled)),
          map(({ payload }) => ({
            ids: Object.values(payload.virtualToRealIdMap)
              .map(val => val.id)
              .concat(payload.acceptedReplaceSuggestedDatapointIds),
            actions: ['user_update', 'updated'],
          })),
          filter(datapoints => !!datapoints.ids.length)
        )
      ).pipe(
        takeUntil(
          action$.pipe(filter(isActionOf(validationEndingActionCreators)))
          // eslint-disable-next-line
        ) as any,
        map(
          ({
            ids,
            actions,
          }: {
            ids: number[];
            actions: ('started' | 'user_update' | 'updated')[];
          }) => validate(uniq(compact<number>(ids ?? [])), url, actions)
        )
      )
    )
  )
);

export const updateValidatedAfterValidateEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(validateFulfilled)),
    pluck('payload', 'updatedDatapoints'),
    filter(
      updatedDatapoints => updatedDatapoints && updatedDatapoints.length > 0
    ),
    map(updatedDatapoints =>
      updatedDatapoints.reduce<AnyDatapointDataST[]>((acc, datapoint) => {
        // TODO handle hidden == false
        if (
          isUndefined(datapoint.hidden) &&
          (!('validationSources' in datapoint) ||
            isUndefined(datapoint.validationSources))
        ) {
          return acc;
        }

        const _datapoint = state$.value.datapoints.content.find(
          ({ id }) => datapoint.id === id
        );

        return _datapoint ? [...acc, _datapoint] : acc;
      }, [])
    ),
    map(updatedDatapoints =>
      compact(
        updatedDatapoints.map(updatedDatapoint =>
          getSidebarDatapoint(state$.value.datapoints.content)(updatedDatapoint)
        )
      )
    ),
    map(sidebarDatapoints => uniqBy(sidebarDatapoints, 'id')),
    mergeMap(sidebarDatapoints =>
      from(sidebarDatapoints).pipe(
        map(sidebarDatapoint => {
          const isHumanValidated = isDatapointHumanValidated(
            state$.value.datapoints.content
          )(sidebarDatapoint);
          return setValidatedMeta(
            sidebarDatapoint.meta.index,
            isHumanValidated ||
              isDatapointValidated(state$.value.datapoints.content)(
                sidebarDatapoint
              ),
            isHumanValidated
          );
        })
      )
    )
  )
);

export const deleteDatapointAndNavigateEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(deleteDatapointAndNavigate)),
    pluck('meta', 'path'),
    map(deletePath => [
      deletePath,
      // We can delete only nested datapoint, so datapointPath is always an array
      (
        parse(state$.value.router.location.search).datapointPath as string[]
      ).map(Number),
    ]),
    mergeMap(([deletePath, currentPath]) =>
      of(
        ...compact([
          currentPath[2] === deletePath[2] && selectNearestDatapoint(),
          deleteDatapoint(deletePath),
        ])
      )
    )
  )
);

const fetchDatapointsEpic = makeEpic((action$, state$, { authGetJSON$ }) =>
  action$.pipe(
    filter(isActionOf(fetchDatapoints)),
    switchMap(({ meta: { annotationUrl } }) =>
      authGetJSON$<{
        content: AnyDatapointData[];
      }>(`${annotationUrl}/content`, {
        query: { deprecatedFields: false },
      }).pipe(
        takeUntil(
          action$.pipe(filter(isActionOf(validationEndingActionCreators)))
        ),
        map(datapoints =>
          get(state$.value, 'ui.readOnly')
            ? fillInDatapointIds(datapoints)
            : datapoints
        ),
        // @ts-expect-error
        // TODO fix
        map(flattenDatapoints),
        map(fetchDatapointsFulfilled),
        catchError(errorHandler)
      )
    )
  )
);

const addSchemasToDatapointsEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(fetchDatapointsFulfilled)),
    switchMap(() =>
      state$.pipe(pluck('schema', 'content'), filter(identity), take(1))
    ),
    map(() => {
      const {
        datapoints: { content: datapoints },
        schema: { content: schemas },
      } = state$.value;

      return datapoints.map(datapoint => ({
        ...datapoint,
        schema: find(schemas, { id: datapoint.schemaId }),
      }));
    }),
    // @ts-expect-error
    // TODO fix
    map(datapoints => addMeta(datapoints, state$.value.schema.content)),
    map(addSchemasToDatapoints)
  )
);

export const updateDatapointMetaEpic = makeEpic(
  (action$, state$, { authPatch$ }) =>
    action$.pipe(
      filter(isActionOf(updateDatapointMeta)),
      filter(
        ({ payload }) =>
          ('timeSpent' in payload && typeof payload.timeSpent === 'number') ||
          Boolean(payload.validationSources)
      ),
      filter(() => Boolean(state$.value.annotation.url)),
      filter(
        ({ meta: { index, id } }) =>
          get(state$.value.datapoints.content[index], 'id') === id &&
          !isVirtualDatapoint(id)
      ),
      mergeMap(({ meta: { id, annotationUrl }, payload }) =>
        // Exclude children from the response, because for multivalues,
        // it could be huge (several MBs), and we don't need it here.
        authPatch$<AnyDatapointData>(
          `${annotationUrl}/content/${id}?fields!=children`,
          payload
        ).pipe(
          // Updating validation sources and time spent is idempotent, so we can retry
          retryConnectionError(),
          takeUntil(
            action$.pipe(filter(isActionOf(validationEndingActionCreators)))
          ),
          mapTo(updateDatapointMetaFulfilled()),
          catchError(datapointErrorHandlerIgnoring404)
        )
      )
    )
);

export const updateDatapointEpic = makeEpic((action$, _, { authPatch$ }) =>
  action$.pipe(
    filter(isActionOf(startAnnotationFulfilled)),
    switchMap(({ meta: { url: annotationUrl } }) =>
      action$.pipe(
        filter(isActionOf(onDatapointSelection)),
        mergeMap(() =>
          action$.pipe(
            filter(isActionOf(updateDatapointValue)),
            takeUntil(
              action$.pipe(
                filter(
                  isActionOf([onDatapointSelection, setCurrentFooterColumn])
                )
              )
            ),
            buffer(
              action$.pipe(
                filter(isActionOf(updateDatapointValue)),
                takeUntil(
                  action$.pipe(
                    filter(
                      isActionOf([onDatapointSelection, setCurrentFooterColumn])
                    )
                  )
                ),
                debounceTime(1000)
              )
            ),
            takeUntil(
              action$.pipe(filter(isActionOf(validationEndingActionCreators)))
            ),
            mergeMap(multiData => {
              // multiData is not empty because of the buffer^
              // payload and noRecalculation are being taken from the latest updateDatapointValue because there can be a buffering in progress
              // and we want to be sure that the value and reset information are from the latest update
              const {
                payload,
                meta: { noRecalculation },
              } = last(multiData)!;

              const { meta } = first(multiData)!;

              // Do not call PATCH on virtual datapoints
              if (isVirtualDatapoint(meta.id)) {
                return EMPTY;
              }

              return authPatch$<AnyDatapointData>(
                `${annotationUrl}/content/${meta.id}`,
                {
                  content: payload,
                  validationSources: meta.validationSource
                    ? [meta.validationSource]
                    : [],
                  noRecalculation,
                }
              ).pipe(
                takeUntil(
                  action$.pipe(
                    filter(isActionOf(validationEndingActionCreators))
                  )
                ),
                map(response =>
                  updateDatapointValueFulfilled(
                    response,
                    isLoggableDatapoint(response)
                      ? {
                          schemaId: response.schemaId,
                          reason: meta.reason,
                          oldValue: meta.oldValue,
                          newValue: response.content?.value,
                        }
                      : undefined
                  )
                ),
                catchError(datapointErrorHandlerIgnoring404)
              );
            })
          )
        )
      )
    )
  )
);

export const batchUpdateDatapointaValuesEpic = makeEpic(
  (action$, _, { authPost$ }) =>
    action$.pipe(
      filter(isActionOf(startAnnotationFulfilled)),
      switchMap(({ meta: { url: annotationUrl } }) =>
        action$.pipe(
          filter(isActionOf(setCurrentFooterColumn)),
          switchMap(() =>
            action$.pipe(
              filter(isActionOf(updateDatapointValue)),
              takeUntil(action$.pipe(filter(isActionOf(onDatapointSelection)))),
              bufferWhen(() =>
                action$.pipe(
                  filter(isActionOf(updateDatapointValue)),
                  debounceTime(10)
                )
              ),
              filter(updates => updates.length > 0),
              mergeMap(updates => {
                const operations = updates.map(
                  // TODO: Editable-formulas: I am not really sure whether this is still in use
                  ({ payload: { value }, meta: { id, noRecalculation } }) => ({
                    value: {
                      content: { value },
                      validationSources: ['human'],
                      noRecalculation,
                    },
                    id,
                    op: 'replace',
                  })
                );

                return authPost$(
                  `${annotationUrl}/content/operations`,
                  {
                    operations,
                  },
                  { query: { deprecatedFields: false } }
                ).pipe(
                  takeUntil(
                    action$.pipe(
                      filter(isActionOf(validationEndingActionCreators))
                    )
                  ),
                  map(() =>
                    batchDatapointValuesUpdateFulfilled(
                      updates.map(({ meta: { id } }) => id)
                    )
                  ),
                  catchError(datapointErrorHandlerIgnoring404)
                );
              })
            )
          )
        )
      )
    )
);

const bulkUpdateDatapointValuesEpic = makeEpic((action$, _, { authPost$ }) =>
  action$.pipe(
    filter(isActionOf(startAnnotationFulfilled)),
    switchMap(({ meta: { url: annotationUrl } }) =>
      action$.pipe(
        filter(isActionOf(bulkUpdateDatapointValue)),
        filter(({ meta }) => Object.entries(meta.updates).length > 0),
        mergeMap(({ meta: { updates } }) => {
          const updatesArray = Object.entries(updates);

          const operations = updatesArray.map(([id, update]) => {
            return {
              value: {
                content: { value: update.newValue },
                validationSources: ['human'],
                noRecalculation: update.noRecalculation,
              },
              id,
              op: 'replace',
            };
          });

          return authPost$(
            `${annotationUrl}/content/operations`,
            {
              operations,
            },
            { query: { deprecatedFields: false } }
          ).pipe(
            takeUntil(
              action$.pipe(filter(isActionOf(validationEndingActionCreators)))
            ),
            map(() =>
              batchDatapointValuesUpdateFulfilled(
                updatesArray.map(([id, _]) => Number(id))
              )
            ),
            catchError(datapointErrorHandlerIgnoring404)
          );
        })
      )
    )
  )
);

const changeDatapointSchemaIdEpic = makeEpic(
  (action$, _state$, { authPost$ }) =>
    action$.pipe(
      filter(isActionOf(startAnnotationFulfilled)),
      switchMap(({ meta: { url: annotationUrl } }) =>
        action$.pipe(
          filter(isActionOf(onDatapointSelection)),
          mergeMap(() =>
            action$.pipe(
              filter(isActionOf(changeDatapointsSchemaIds)),
              takeUntil(
                action$.pipe(
                  filter(
                    isActionOf([onDatapointSelection, setCurrentFooterColumn])
                  )
                )
              ),
              // TODO: Buffer?
              // For each source-target pair, we swap their values, resetting the target one to default (if any)
              mergeMap(({ payload: { operations } }) => {
                const apiOperations = compact(
                  operations.flatMap(op =>
                    !isVirtualDatapoint(op.source.id) &&
                    !isVirtualDatapoint(op.target.id)
                      ? [
                          {
                            op: 'replace',
                            id: op.source.id,
                            value: {
                              validationSources: ['human'],
                              content: {
                                value: op.target.schema?.defaultValue ?? '',
                                position: null,
                                page: null,
                              },
                            },
                          },
                          {
                            op: 'replace',
                            id: op.target.id,
                            value: {
                              validationSources: ['human'],
                              content: {
                                value: op.source.content?.value,
                                position: op.source.content?.position,
                                page: op.source.content?.page,
                              },
                            },
                          },
                        ]
                      : []
                  )
                );

                if (!apiOperations.length) {
                  return of(selectDatapoint([operations[0].target.id]));
                }

                return authPost$(
                  `${annotationUrl}/content/operations`,
                  { operations: apiOperations },
                  { query: { deprecatedFields: false } }
                ).pipe(
                  takeUntil(
                    action$.pipe(
                      filter(isActionOf(validationEndingActionCreators))
                    )
                  ),
                  mergeMap(() => {
                    return of(
                      selectDatapoint([operations[0].target.id]),
                      batchDatapointValuesUpdateFulfilled(
                        apiOperations.map(op => op.id)
                      )
                    );
                  }),
                  catchError(datapointErrorHandlerIgnoring404)
                );
              })
            )
          )
        )
      )
    )
);

const recountDatapointPositionEpic = makeEpic(
  (action$, state$, { authPost$ }) =>
    action$.pipe(
      filter(isActionOf(recountDatapointPosition)),
      map(
        ({ payload, meta }) =>
          [
            state$.value.datapoints.content[
              payload.index
            ] as SimpleDatapointDataST,
            meta,
          ] as const
      ),
      filter(([dp]) => isNotNullOrUndefined(dp)),
      mergeMap(([{ id, content, meta: dpMeta }, meta]) => {
        // recountDatapointPosition won't be call on button datapoints, so it shouldn't happen
        if (!content) {
          return EMPTY;
        }

        const { page: pageNumber, position } = content;

        const {
          pages: { pages },
          annotation: { url },
        } = state$.value;

        const page = pages.find(p => p.number === pageNumber);

        // In practice this shouldn't happen, because you can click on suggested box
        // only on loaded page, but TS doesn't know that.
        if (!page) {
          return EMPTY;
        }

        const parent = dpMeta?.parentId
          ? findDatapointById(state$.value.datapoints.content, dpMeta.parentId)
          : undefined;

        // if position was updated on virtual datapoint, we need to create corrsponding tuple,
        // then call `select` on it and re-validate
        if (isVirtualDatapoint(id) && parent && parent.meta.parentId) {
          return of(
            materializeVirtualDatapoints({
              tuplesToCreate: [
                {
                  tupleId: parent.id,
                  validatedDatapointIds: [id],
                  // When a tuple is materialized by changing a position,
                  // only validated datapoint will get the content.
                  // The content for the rest of the datapoints will stay in the local state only.
                  createWithContent: false,
                },
              ],
              datapointsToReplace: [],
              multivalueId: parent.meta.parentId,
            })
          );
        }

        const complexLineItemsEnabled = complexLineItemsEnabledSelector(
          state$.value
        );

        // https://api.elis.rossum.ai/docs/#replace-annotation-data-by-ocr
        return authPost$<AnyDatapointData>(
          `${url}/content/${id}/select${
            complexLineItemsEnabled &&
            !dpMeta.sidebarDatapoint &&
            !parent?.meta.isSimpleMultivalue
              ? '?update_complex_grid=true'
              : ''
          }`,
          {
            page: page.url,
            rectangle: position,
          }
        ).pipe(
          takeUntil(
            action$.pipe(filter(isActionOf(validationEndingActionCreators)))
          ),
          map(response =>
            recountDatapointPositionFulfilled(
              response,
              isLoggableDatapoint(response)
                ? {
                    schemaId: response.schemaId,
                    reason: meta.reason,
                    oldValue: meta.oldValue,
                    newValue: response.content?.value,
                  }
                : undefined
            )
          ),
          catchError(clickApiErrorHandler)
        );
      })
    )
);

const resetDatapointEpic = makeEpic((action$, state$, { authPatch$ }) =>
  action$.pipe(
    filter(isActionOf(resetDatapoint)),
    mergeMap(({ payload, meta }) => {
      const dp = state$.value.datapoints.content[payload.index];

      if (typeof dp.meta.parentId === 'number') {
        const parentDp = findDatapointById(
          state$.value.datapoints.content,
          dp.meta.parentId
        );

        if (!parentDp) {
          return EMPTY;
        }

        // if parent is simple multivalue, it definitely has a parent and we need to make our TS overlord happy
        if (parentDp.meta.isSimpleMultivalue && parentDp.meta.parentId) {
          return of(
            deleteDatapoint([parentDp.meta.parentId, parentDp.id, dp.id])
          );
        }
      }

      const {
        annotation: { url },
      } = state$.value;

      if (isVirtualDatapoint(dp.id)) {
        return EMPTY;
      }

      return authPatch$<AnyDatapointData>(`${url}/content/${dp.id}`, {
        content: initialContent,
      }).pipe(
        takeUntil(
          action$.pipe(filter(isActionOf(validationEndingActionCreators)))
        ),
        map(response =>
          resetDatapointFulfilled(
            response,
            isLoggableDatapoint(response) && meta
              ? {
                  schemaId: response.schemaId,
                  reason: meta.reason,
                  oldValue: meta.oldValue,
                  newValue:
                    response.category === 'datapoint'
                      ? response.content?.value
                      : undefined,
                }
              : undefined
          )
        ),
        catchError(datapointErrorHandlerIgnoring404)
      );
    })
  )
);

export const selectDefaultDatapointEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(addSchemasToDatapoints)),
    switchMap(() =>
      state$.pipe(
        pluck('datapoints', 'initiallyValidated'),
        filter(() => !state$.value.ui.editModeActive),
        distinctUntilChanged(),
        filter(identity),
        take(1)
      )
    ),
    map(() => {
      const datapointPath = datapointPathSelector(state$.value);
      // When delete recommendation for the annotation is present, do not select default datapoint
      if (
        isDeleteRecommendationSelector(state$.value) &&
        !datapointPath.length
      ) {
        const {
          router: {
            location: { pathname, search },
          },
        } = state$.value;

        return replace(
          constructDocumentUrl({
            pathname,
            query: { ...parse(search), deleteRecommendation: true },
          })
        );
      }

      // If there is "Review is needed" message, and no datapoint path is selected
      // don't select default datapoint, to avoid scrolling away from "Review in needed".
      if (
        displayReviewIsNeededSelector(state$.value) &&
        !datapointPath.length
      ) {
        return { type: 'EMPTY_ACTION' };
      }

      if (datapointPath.length)
        return selectDatapoint(datapointPath, { noTail: true });

      const visibleDatapoints = getVisibleDatapoints(state$.value);

      const firstDatapoint = visibleDatapoints.find(
        datapoint =>
          datapoint.category === 'datapoint' &&
          datapoint.schema?.type !== 'button' &&
          isFieldSelectable(datapoint.schema)
      );

      if (!firstDatapoint) return { type: 'EMPTY_ACTION' };

      return selectDatapoint([firstDatapoint.id]);
    })
  )
);

const selectDatapointAfterCancelEditMode = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(cancelEditMode)),
    map(nextUnvalidatedDatapoint)
  )
);

export const selectDatapointEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(selectDatapoint)),
    switchMap(action =>
      isEmpty(get(state$.value, 'schema.content'))
        ? action$.pipe(
            filter(isActionOf(fetchSchemaFulfilled)),
            takeUntil(action$.pipe(filter(isActionOf(leaveValidation)))),
            mapTo(action)
          )
        : of(action)
    ),
    mergeMap(({ payload: datapointPath, meta: { ignoreMeta, noTail } }) => {
      const {
        ui: { footerExpanded },
        datapoints: { content: datapoints },
      } = state$.value;

      const visibleDatapoints = getVisibleDatapoints(state$.value);

      const fillTail = curry(fillPathTail)(visibleDatapoints);
      const fillHead = curry(fillPathHead)(datapoints);
      const path: number[] = flow(
        ...[fillHead, noTail || fillTail, compact].filter(isFunction)
      )(datapointPath);

      const isDatapointHidden =
        !footerExpanded && !find(visibleDatapoints, { id: last(path) });

      // When selecting a tuple, fillTail will select first child in the schema order,
      // however, the table in the UI can be ordered differently.
      // If we detect this situation, we'll try to find a better child datapoint.
      // NOTE: Probably a clearer solution would be to extend NavigationStop to also accept tuples, and then
      // it would be just one step forward, but it would lead to more changes across the codebase
      if (
        datapointPath.length === 1 &&
        findDatapointById(state$.value.datapoints.content, datapointPath[0])
          ?.category === 'tuple'
      ) {
        const original = toNavigationStop(
          resolveAnyDatapointPath(
            state$.value.datapoints.content,
            // path is non-empty, see above
            findDatapointById(state$.value.datapoints.content, last(path)!)
          ),
          false
        );

        if (original.kind === 'table-multivalue-child') {
          let current = original;

          // Avoid infinite loop, more than 100 columns are unlikely
          for (let steps = 0; steps < 100; steps += 1) {
            // Go backwards, until we leave the current tuple
            const candidate = nextNavigationStopSelector(state$.value)({
              currentStop: current,
              isForward: false,
              unvalidated: false,
              skipButtons: true,
            });

            const sameTuple =
              original.kind === 'table-multivalue-child' &&
              candidate.kind === 'table-multivalue-child' &&
              original.path[2].id === candidate.path[2].id;

            if (!sameTuple) {
              break;
            }

            current = candidate;
          }

          return of(
            onDatapointSelection({
              id: current.path[3].id,
              ignoreMeta,
              datapointPath: current.path.map(d => d.id),
            })
          );
        }
      }

      return from(
        compact([
          onDatapointSelection({
            id: last(path),
            ignoreMeta,
            datapointPath: path,
          }),
          isDatapointHidden && toggleFooter(),
        ])
      );
    })
  )
);

export const replaceDatapointPathEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(selectDatapoint)),
    switchMap(({ meta: { addValue } }) =>
      action$.pipe(
        filter(isActionOf(onDatapointSelection)),
        map(({ meta: { datapointPath } }) => {
          const {
            router: {
              location: { pathname, search },
            },
          } = state$.value;
          const { deleteRecommendation, ..._search } = parse(search);
          const hash = addValue ? 'addValue' : '';

          return replace(
            constructDocumentUrl({
              pathname,
              query: { ..._search, datapointPath },
              hash,
            })
          );
        })
      )
    )
  )
);

/*
  isForward: boolean
    - sets direction of the datapoint search

  condition: predicate
    - narrows down the subset of datapoints meeting a condition
*/
export const navigateDatapointsEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([nextDatapoint, previousDatapoint, nextUnvalidatedDatapoint])
    ),
    map(({ meta: { isForward, unvalidated } }) => {
      const current = currentNavigationStopSelector(state$.value);
      const next = nextNavigationStopSelector(state$.value)({
        currentStop: current,
        isForward,
        unvalidated,
        // With TAB/Enter, buttons are always skipped
        skipButtons: true,
      });

      const nextDatapointId =
        next.kind === 'none' ? undefined : getSelectedDatapoint(next)?.id;

      return selectDatapoint(nextDatapointId ? [nextDatapointId] : [], {
        noTail: true,
        addValue:
          next.kind === 'simple-multivalue-add-value' ||
          next.kind === 'table-multivalue-add-value',
      });
    })
  )
);

export const navigateColumnEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(navigateColumn)),
    map(({ meta: { isForward = true } }) => {
      const footerColumnSorting = sortFooterColumnsSelector(state$.value);

      if (footerColumnSorting === 'automatically') {
        const current = currentNavigationStopSelector(state$.value);
        const next = nextNavigationStopSelector(state$.value)({
          currentStop: current,
          isForward,
          unvalidated: false,
          // Existing behavior - when using arrows, button columns are not skipped
          skipButtons: false,
        });

        // Navigation outside of the current tuple is ignored
        if (
          current.kind === 'table-multivalue-child' &&
          next.kind === 'table-multivalue-child' &&
          next.path[2].id === current.path[2].id
        ) {
          return next.path[3].id;
        }

        return null;
      }

      const currentDatapointId = currentDatapointIdSelector(state$.value);
      const currentTuple = getCurrentTuple(state$.value);

      if (!currentTuple) return undefined;

      const startIndex = currentTuple.children.findIndex(
        children => children.id === currentDatapointId
      );
      const datapoints = state$.value.datapoints.content;
      const nextId = findNextColumnInTuple({
        datapoints,
        currentTuple,
        startIndex,
        isForward,
      });

      return nextId;
    }),
    filter(isNotNullOrUndefined),
    map(newId =>
      updateInArray(datapointPathSelector(state$.value), 3, () => newId)
    ),
    map(path => selectDatapoint(path))
  )
);

export const navigateRowEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(navigateRow)),
    map(({ meta: { isForward = true } }) => {
      const currentTuple = getCurrentTuple(state$.value);
      const currentMultivalue = currentMultivalueDatapointSelector(
        state$.value
      );

      if (!currentMultivalue || !currentTuple) return undefined;

      const index = currentMultivalue.children.findIndex(
        children => children.id === currentTuple.id
      );

      const newIndex = isForward ? index + 1 : index - 1;
      const newRow = get(currentMultivalue.children, newIndex);

      if (!newRow) return undefined;

      const currentDatapoint = currentDatapointSelector(state$.value);
      const newRowData = state$.value.datapoints.content[newRow.index];

      if (!newRowData) return undefined;

      const [sectionId, multivalueId] = datapointPathSelector(state$.value);

      const shift = newRowData.meta.index - currentTuple.meta.index;
      const i = currentDatapoint?.meta.index ?? 0;

      const newColumnDatapoint = state$.value.datapoints.content[i + shift];

      return [sectionId, multivalueId, newRow.id, newColumnDatapoint.id];
    }),
    filter(isNotNullOrUndefined),
    map(path => selectDatapoint(path))
  )
);

export const selectNearestDatapointEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(selectNearestDatapoint)),
    map(() => {
      const [sectionId, multivalueId, childId] = datapointPathSelector(
        state$.value
      );
      const currentMultivalue = currentMultivalueDatapointSelector(
        state$.value
      );

      if (!currentMultivalue) {
        return undefined;
      }

      const index = currentMultivalue.children.findIndex(
        ({ id }) => id === childId
      );

      const newChildId = get(
        currentMultivalue.children[index + 1] ||
          currentMultivalue.children[index - 1],
        'id'
      );

      return {
        path: newChildId
          ? [sectionId, multivalueId, newChildId]
          : [sectionId, multivalueId],
        settings: { ignoreMeta: true },
      };
    }),
    filter(isNotNullOrUndefined),
    map(({ path, settings }) => selectDatapoint(path, settings))
  )
);

export const createDatapointEpic = makeEpic((action$, state$, { authPost$ }) =>
  action$.pipe(
    filter(isActionOf(createDatapoint)),
    map(({ payload: { parentIndex } }) => {
      const {
        annotation: { url },
      } = state$.value;

      const parentId = state$.value.datapoints.content[parentIndex].id;

      return { parentIndex, url, parentId };
    }),
    mergeMap(({ parentId, url, parentIndex }) =>
      authPost$<DatapointsFulfilledPayload>(
        `${url}/content/${parentId}/add_empty`,
        {},
        { query: { deprecatedFields: false } }
      ).pipe(
        takeUntil(
          action$.pipe(filter(isActionOf(validationEndingActionCreators)))
        ),
        map(response =>
          createDatapointsFulfilled(
            parentIndex,
            state$.value.schema.content ?? [],
            response,
            complexLineItemsEnabledSelector(state$.value)
          )
        ),
        catchError(errorHandler)
      )
    )
  )
);

export const createDatapointsFulfilledEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(createDatapointsFulfilled)),
    pluck('payload', 'content'),
    filter(datapoints => datapoints && datapoints.length > 0),
    map(newDatapoints => {
      const {
        datapoints: { content: datapoints },
      } = state$.value;

      return fillPathHead(datapoints, [newDatapoints[0].id]);
    }),
    map(path => selectDatapoint(path))
  )
);

export const selectDatapointOnReplaceEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(validateFulfilled)),
    pluck('payload', 'updatedDatapoints'),
    filter(
      updatedDatapoints => updatedDatapoints && updatedDatapoints.length > 0
    ),
    map(updatedDatapoints => {
      const [, multivalueId] = datapointPathSelector(state$.value);

      return find(
        updatedDatapoints,
        ({ id, category }) => id === multivalueId && category === 'multivalue'
      ) as MultivalueDatapointData | undefined;
    }),
    filter(isNotNullOrUndefined),
    filter(updatedDatapoint => {
      const [, , tupleId] = datapointPathSelector(state$.value);

      return !find(updatedDatapoint.children, { id: tupleId });
    }),
    map(updatedDatapoint => [updatedDatapoint.id]),
    map(path => selectDatapoint(path, { ignoreMeta: true }))
  )
);

export const deleteDatapointEpic = makeEpic(
  (action$, state$, { authDelete$ }) =>
    action$.pipe(
      filter(isActionOf(deleteDatapoint)),
      map(({ payload: { path } }) => path),
      distinctUntilChanged<number[]>(isEqual),
      mergeMap(path => {
        const parentIndex = findIndex(state$.value.datapoints.content, {
          id: path[1],
        });

        const datapointId = last(path);

        if (parentIndex === undefined || !datapointId) {
          return EMPTY;
        }

        if (isVirtualDatapoint(last(path)!)) {
          return of(deleteDatapointFulfilled(parentIndex, last(path)!));
        }

        const complexLineItemsEnabled = complexLineItemsEnabledSelector(
          state$.value
        );

        const parent = state$.value.datapoints.content[
          parentIndex
        ] as MultivalueDatapointDataST;

        return authDelete$(
          `${state$.value.annotation.url}/content/${last(path)}${
            complexLineItemsEnabled && !parent?.meta.isSimpleMultivalue
              ? '?update_complex_grid=true'
              : ''
          }`
        ).pipe(
          takeUntil(
            action$.pipe(filter(isActionOf(validationEndingActionCreators)))
          ),
          map(() => {
            return deleteDatapointFulfilled(parentIndex, datapointId);
          }),
          catchError(datapointErrorHandlerIgnoring404)
        );
      })
    )
);

export const deleteAllDatapointsEpic = makeEpic(
  (action$, state$, { authDelete$ }) =>
    action$.pipe(
      filter(isActionOf(deleteAllDatapoints)),
      pluck('payload', 'parentIndex'),
      mergeMap(parentIndex => {
        const datapointId = state$.value.datapoints.content[parentIndex].id;
        const annotationId = state$.value.annotation.id;

        return authDelete$(
          `${apiUrl}/annotations/${annotationId}/content/${datapointId}/children`
        ).pipe(
          takeUntil(
            action$.pipe(filter(isActionOf(validationEndingActionCreators)))
          ),
          mergeMap(() =>
            of(
              deleteAllDatapointsFulfilled(parentIndex),
              selectDatapoint([datapointId], { ignoreMeta: true })
            )
          ),
          catchError(errorHandler)
        );
      })
    )
);

// TODO: use special endpoint for creating datapoint with content/position all at one time
//  once it is available on BE https://rossumai.atlassian.net/browse/EB-279
const createDatapointWithPositionEpic = makeEpic(
  (action$, state$, { authPost$ }) =>
    action$.pipe(
      filter(isActionOf(createDatapointWithPosition)),
      mergeMap(({ payload: { position, page }, meta: { parentIndex } }) => {
        // This action is used only for simple multivalues
        const parentDatapoint = state$.value.datapoints.content[
          parentIndex
        ] as MultivalueDatapointDataST;
        return authPost$<DatapointsFulfilledPayload>(
          `${state$.value.annotation.url}/content/${parentDatapoint.id}/add_empty`,
          {},
          { query: { deprecatedFields: false } }
        ).pipe(
          takeUntil(
            action$.pipe(filter(isActionOf(validationEndingActionCreators)))
          ),
          mergeMap(response => {
            // TODO remove this carymary when creating datapoint is available
            const nextIndex =
              parentDatapoint.meta.index + parentDatapoint.children.length + 1;

            return of(
              createDatapointsFulfilled(
                parentDatapoint.meta.index,
                state$.value.schema.content ?? [],
                response,
                complexLineItemsEnabledSelector(state$.value),
                {
                  isSimpleMultivalue: true,
                }
              ),
              updatePosition(
                { index: nextIndex, page },
                { content: { position } }
              ),
              recountDatapointPosition(nextIndex, {
                oldValue: undefined,
                reason: 'suggested-bbox',
              })
            );
          }),
          catchError(errorHandler)
        );
      })
    )
);

const afterCollapseNavigationEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(toggleFooter)),
    withLatestFrom(state$),
    pluck('1'),
    filter(({ ui }) => !ui.footerExpanded),
    filter(state => {
      const {
        router: {
          location: { search },
        },
      } = state;
      const datapointPath = getDatapointPathFromSearch(search);
      const visibleDatapoints = getVisibleDatapoints(state);
      const expandedDatapointIds = visibleDatapoints.map(({ id }) => id);
      return !expandedDatapointIds.includes(
        datapointPath[datapointPath.length - 1]
      );
    }),
    map(
      ({
        router: {
          location: { search },
        },
      }) => {
        const datapointPath = getDatapointPathFromSearch(search);
        return selectDatapoint(datapointPath.slice(0, 3));
      }
    )
  )
);

const bboxCleanupEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(onDatapointSelection)),
    filter(() => !state$.value.ui.readOnly),
    pluck('meta', 'id'),
    pairwise(),
    map(([id]) =>
      id ? findDatapointById(state$.value.datapoints.content, id) : undefined
    ),
    filter(isNotNullOrUndefined),
    filter(
      (datapoint): datapoint is SimpleDatapointDataST =>
        datapoint.category === 'datapoint'
    ),
    // content is null for button datapoints
    filter(
      ({ content }) => content !== null && !!content.position && !content.value
    ),
    filter(() => !state$.value.datapoints.pendingOCR),
    map(({ meta: { index } }) => resetDatapoint(index))
  )
);

/**
 * New footer specifies batch updated cells differently, so this epic
 * just converts new action to the old one.
 */
const batchDatapointUpdateV2Epic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(batchDatapointUpdateV2)),
    map(
      ({
        meta: {
          columnSchemaId,
          sourceTupleId,
          targetTupleIds,
          noRecalculation,
        },
      }) => {
        const datapointIndex = state$.value.datapoints.content.findIndex(
          d =>
            d.meta.parentId === sourceTupleId && d.schemaId === columnSchemaId
        );

        const ids = state$.value.datapoints.content
          .filter(
            d =>
              d.meta.parentId &&
              targetTupleIds.includes(d.meta.parentId) &&
              d.schemaId === columnSchemaId
          )
          .map(d => d.id);

        return batchDatapointUpdate(ids, datapointIndex, noRecalculation);
      }
    )
  )
);

const batchDatapointUpdateEpic = makeEpic((action$, state$, { authPost$ }) =>
  action$.pipe(
    filter(isActionOf(batchDatapointUpdate)),
    mergeMap(({ meta: { ids, datapointIndex, noRecalculation } }) => {
      const {
        annotation: { url },
      } = state$.value;

      const value = getDataForBatchUpdate(
        state$.value.datapoints.content[datapointIndex] as SimpleDatapointDataST
      );

      const operations = ids.map(id => ({
        value: {
          ...value,
          noRecalculation,
        },
        id,
        op: 'replace',
      }));

      return authPost$(
        `${url}/content/operations`,
        { operations },
        { query: { deprecatedFields: false } }
      ).pipe(
        mapTo(batchDatapointUpdateFulfilled(ids)),
        catchError(errorHandler)
      );
    })
  )
);

const batchDatapointDeleteEpic = makeEpic((action$, state$, { authPost$ }) => {
  return action$.pipe(
    filter(isActionOf(batchDatapointDelete)),
    mergeMap(({ payload: { tuplesToDelete, datapointsToReset } }) => {
      const {
        annotation: { url },
      } = state$.value;

      const operations = [
        ...tuplesToDelete.map(x => ({
          op: 'remove',
          id: x.id,
        })),
        ...datapointsToReset.map(x => ({
          op: 'replace',
          id: x.id,
          value: {
            content: {
              value: '',
              position: null,
              page: null,
            },
          },
        })),
      ];

      return authPost$(
        `${url}/content/operations`,
        { operations },
        { query: { deprecatedFields: false } }
      ).pipe(
        switchMap(() =>
          from(
            compact([
              batchDatapointDeleteFulfilled(),
              ...(tuplesToDelete.length
                ? compact(
                    tuplesToDelete.map(({ id, parentIndex }) =>
                      parentIndex !== null
                        ? deleteDatapointFulfilled(parentIndex, id)
                        : null
                    )
                  )
                : []),
            ])
          )
        ),
        catchError(errorHandler)
      );
    })
  );
});

const batchDatapointConfirmEpic = makeEpic((action$, state$, { authPost$ }) => {
  return action$.pipe(
    filter(isActionOf(batchDatapointConfirm)),
    mergeMap(({ payload: { datapoints } }) => {
      const {
        annotation: { url },
      } = state$.value;

      const operations = datapoints
        // No need to validate when it's already validated.
        .filter(dp => !dp.validationSources.includes('human'))
        // Map to replace operations
        .map(dp => ({
          op: 'replace',
          id: dp.id,
          value: {
            validationSources: [...dp.validationSources, 'human'],
          },
        }));

      return authPost$(
        `${url}/content/operations`,
        { operations },
        { query: { deprecatedFields: false } }
      ).pipe(mapTo({ type: 'EMPTY_ACTION' }), catchError(errorHandler));
    })
  );
});

const sidebarDatapointHasChangedEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(onDatapointSelection)),
    switchMap(() => action$.pipe(filter(isActionOf(locationChange)))),
    map(() => getCurrentSidebarDatapointId(state$.value)),
    distinctUntilChanged(),
    map(() => sidebarDatapointHasChangedAction())
  )
);

const insertLineEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(insertLine)),
    map(({ payload }) => {
      const datapoints = state$.value.datapoints.content;

      // Tuple always has a multivalue parent
      const multivalueId = payload.tuple.meta.parentId!;
      const multivalue = findDatapointById(datapoints, multivalueId);

      if (!multivalue) {
        return { type: 'EMPTY_ACTION' };
      }

      const complexLineItemsEnabled = complexLineItemsEnabledSelector(
        state$.value
      );
      const ghostRow = calculateInsertedTuplePosition(
        datapoints,
        payload.tuple
      );
      return complexLineItemsEnabled && ghostRow
        ? suggestTable({
            // Tuples always have parent.
            multivalueId,
            pagesChunks: [],
            ghostRow,
          })
        : createDatapoint(multivalue.meta.index);
    })
  )
);

// TODO: https://rossumai.atlassian.net/browse/EF-1546
// The order of validation related epics matter because they are interconnected
// since they depend on actions dispatched from each other.
// Preceding epic needs to be already started when an action from the latter one is dispatched.
const validationEpics = combineEpics(
  validateEpic,
  pendingValidate,
  validateDatapointActionsEpic
);

export default combineEpics(
  materializeVirtualDatapointsEpic,
  suggestTableEpic,
  addSchemasToDatapointsEpic,
  afterCollapseNavigationEpic,
  batchDatapointUpdateEpic,
  batchDatapointUpdateV2Epic,
  bulkUpdateDatapointValuesEpic,
  batchUpdateDatapointaValuesEpic,
  batchDatapointDeleteEpic,
  bboxCleanupEpic,
  createDatapointEpic,
  createDatapointWithPositionEpic,
  createDatapointsFulfilledEpic,
  deleteAllDatapointsEpic,
  deleteDatapointAndNavigateEpic,
  deleteDatapointEpic,
  fetchDatapointsEpic,
  lastDatapointsForTimeSpentUpdateEpic,
  navigateColumnEpic,
  navigateDatapointsEpic,
  navigateRowEpic,
  onDatapointSelectionEpic,
  recountDatapointPositionEpic,
  replaceDatapointPathEpic,
  resetDatapointEpic,
  selectDatapointAfterCancelEditMode,
  selectDatapointEpic,
  selectDatapointOnReplaceEpic,
  selectDefaultDatapointEpic,
  selectNearestDatapointEpic,
  sidebarDatapointHasChangedEpic,
  updateDatapointEpic,
  updateDatapointMetaEpic,
  updateValidatedAfterUpdateDatapoints,
  updateValidatedAfterValidateEpic,
  updateValidatedOnDatapointSelectionEpic,
  changeDatapointSchemaIdEpic,
  createGhostRowEpic,
  batchDatapointConfirmEpic,
  insertLineEpic,
  validationEpics
);

import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { setSubmissionTimestamp } from '../timestamp';
import { usePatchSurvey } from './usePatchSurvey';
import { QUERY_KEY_SURVEY } from './useSurvey';

export const useSubmitSurvey = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: patchSurveyAsync } = usePatchSurvey();

  return useCallback(
    async (
      variables: Parameters<typeof patchSurveyAsync>[0],
      shouldSetTimestamp = false
    ) => {
      return patchSurveyAsync(variables).then(survey => {
        if (shouldSetTimestamp) {
          setSubmissionTimestamp();
        }
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_SURVEY, survey.id],
        });
      });
    },
    [patchSurveyAsync, queryClient]
  );
};

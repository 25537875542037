import { Survey } from '@rossum/api-client/surveys';
import { isEmbedded } from '../../constants/config';
import { getUnsafe } from '../../lib/helpers';
import { hasTimeIntervalPassed } from './timestamp';

// general survey blockers
export const isEmbeddedBlocker = () => {
  const embedded = isEmbedded();

  return embedded ? 'embedded' : undefined;
};

export const isInternalUserBlocker = <U extends { email?: string }>(
  user: U
) => {
  const internalUser = user.email && user.email.endsWith('rossum.ai');

  return internalUser ? 'internal_user' : undefined;
};

export const isTrialBlocker = (isTrial: boolean | undefined) => {
  return isTrial ? 'is_trial' : undefined;
};

export const isNotTrialBlocker = (isTrial: boolean | undefined) => {
  return isTrial ? undefined : 'not_trial';
};

// survey dialog blockers
const hasUserAnsweredSurvey = (survey: Survey) =>
  survey.answers?.some(answer => answer.value !== null) ?? false;

export const hasUserAnsweredSurveyBlocker = (survey: Survey) =>
  hasUserAnsweredSurvey(survey) ? 'answered' : undefined;

const isUserAnnoyed = (survey: Survey, limit: number) =>
  Number(getUnsafe(survey.additionalData, 'skipCount')) > limit;

export const isUserAnnoyedBlocker = (survey: Survey, limit: number) =>
  isUserAnnoyed(survey, limit) ? 'annoyed' : undefined;

const enoughUsageInteractions = (survey: Survey, interactionCount: number) =>
  Number(getUnsafe(survey.additionalData, 'usageCount')) >= interactionCount;

export const notEnoughUsageInteractionsBlocker = (
  survey: Survey,
  interactionCount: number
) =>
  enoughUsageInteractions(survey, interactionCount)
    ? undefined
    : 'not_enough_usage_interaction';

export const userNotEngagedBlocker = (userEngaged: boolean) => {
  return userEngaged ? undefined : 'insufficient_engagement';
};

export const isBeforeInterval = (interval?: number) =>
  hasTimeIntervalPassed(interval) ? undefined : 'too_frequent';

import { useDraggable } from '@dnd-kit/core';
import { ListItemButton } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { getSidebarListItemStyles } from '../../sidebar/styles';

type QueueProps = {
  onClick: () => void;
  queueId: number;
  currentQueueId?: number;
  workspaceUrl: string;
  children: ReactNode;
  queueName: string;
};

export const DraggableQueue = ({
  onClick,
  queueId,
  queueName,
  currentQueueId,
  workspaceUrl,
  children,
}: QueueProps) => {
  const draggableId = `draggable-queue-${queueId}`;
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: draggableId,
    data: { id: queueId, name: queueName, workspaceUrl },
  });

  const active = currentQueueId === queueId || isDragging;

  return (
    <ListItemButton
      ref={setNodeRef}
      data-cy="sidebar-queue"
      data-id={queueId}
      onClick={onClick}
      {...listeners}
      {...attributes}
      sx={{
        py: 1,
        color: active ? 'text.primary' : 'text.secondary',
        ...getSidebarListItemStyles(active),
        height: 1,
      }}
    >
      {children}
    </ListItemButton>
  );
};

import equal from 'fast-deep-equal/es6/react';
import { chain, invoke } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { ImmutableObject } from 'seamless-immutable';
import Editor from '../../../../../components/UI/Editor/components/Editor';
import { useTemporaryMessage } from '../../../../../components/UI/Editor/components/hooks';
import TopMenu from '../../../../../components/UI/Editor/components/TopMenu';
import PoweredBy from '../../../../../components/UI/PoweredBy';
import UserPanel from '../../../../../components/UserPanel';
import { OpenModal } from '../../../../../redux/modules/modal/actions';
import { ImmutableArrayObject } from '../../../../../types/CustomTypes/immutable';
import {
  EnumDatapointSchema,
  OriginalAnyDatapointSchema,
} from '../../../../../types/schema';
import scrollingSubject from '../lib/scrollingSubject';
import styles from '../styles.module.sass';
import EnumEditor from './EnumEditor';
import Footer from './Footer';
import SchemaErrors from './SchemaErrors';

type Props = {
  currentPath: string[];
  currentSchemaPart:
    | ImmutableObject<OriginalAnyDatapointSchema>
    | ImmutableObject<EnumDatapointSchema>
    | null;
  JSONisValid: boolean;
  isCurrentPath: (_path: string[]) => boolean;
  isSchemaChanged: boolean;
  isSchemaOutdated: boolean;
  onCurrentSchemaPartChange: (_schema: OriginalAnyDatapointSchema) => void;
  openModal: OpenModal;
  resetSchema: (_callbackFn?: () => void) => void;
  saveSchema: () => void;
  schemaConcept?: ImmutableArrayObject<OriginalAnyDatapointSchema>;
  schemaErrors?: Array<{ message: string; key: string }>;
  schemaValue?: string;
  setCurrentPath: (_path: string[]) => void;
  setJSONisValid: (_isValid: boolean) => void;
  setSchema: (_newSchema: OriginalAnyDatapointSchema[]) => void;
  setSchemaValue: (_schemaValue: string) => void;
  validSchema?: ImmutableArrayObject<OriginalAnyDatapointSchema>;
  validationTimeStamp: number;
};

export const schemaToString = (
  schema?: ImmutableArrayObject<OriginalAnyDatapointSchema>
): string => JSON.stringify(schema, null, 2) || '';

const SchemaEditor = ({
  JSONisValid,
  currentPath,
  currentSchemaPart,
  isCurrentPath,
  isSchemaChanged,
  isSchemaOutdated,
  onCurrentSchemaPartChange,
  openModal,
  resetSchema,
  saveSchema,
  schemaConcept,
  schemaErrors,
  schemaValue,
  setCurrentPath,
  setJSONisValid,
  setSchema,
  setSchemaValue,
  validSchema,
  validationTimeStamp,
}: Props) => {
  const [isValidated, setIsValidated] = useState(true);
  const editor = useRef(null);
  const currentFooterPath = currentPath.slice(0, 3);
  const currentFooterSchemaPart =
    validSchema && currentFooterPath && validSchema.getIn(currentFooterPath);

  const showEnumEditor =
    !isSchemaOutdated &&
    currentSchemaPart &&
    currentSchemaPart.getIn(['type']) === 'enum';

  const showFooter =
    currentFooterSchemaPart &&
    currentFooterSchemaPart.getIn(['category']) === 'multivalue';

  const setNavigationPath = () =>
    setCurrentPath(showFooter ? currentFooterPath : []);

  useEffect(() => {
    const scrollToLineSubscription = scrollingSubject.subscribe(id => {
      if (editor.current && schemaValue) {
        const searchValue = `\"id\": \"${id}\"`; // eslint-disable-line no-useless-escape
        const [start, end] = schemaValue.split(searchValue, 2);
        const startToLabelLines = start.split('\n');

        let rightBracketsCount = 0;

        const datapointStartIndex =
          startToLabelLines.length -
          chain(startToLabelLines)
            .reverse()
            .findIndex(line => {
              if (line.includes('{')) {
                if (rightBracketsCount === 0) return true;
                rightBracketsCount -= 1;
                return false;
              }
              if (line.includes('}')) rightBracketsCount += 1;
              return false;
            })
            .value();

        if (end) {
          invoke(
            editor,
            ['current', 'editor', 'gotoLine'],
            datapointStartIndex,
            0,
            true
          );
        }
      }
    });

    return () => {
      scrollToLineSubscription.unsubscribe();
    };
  });

  useEffect(() => {
    setIsValidated(true);
  }, [validationTimeStamp]);

  useEffect(() => {
    if (editor.current) {
      invoke(editor, ['current', 'editor', 'resize']);
    }
  }, [showFooter]);

  const { temporaryMessage, setTemporarySaved, setTemporaryReverted } =
    useTemporaryMessage();

  const isValueChanged = schemaValue !== schemaToString(schemaConcept);
  const canReset = isSchemaChanged || isValueChanged;
  const isValid = JSONisValid && (!schemaErrors || !schemaErrors.length);
  const canSave = isValidated && isSchemaChanged && isValid;

  const onChange = (value: string) => {
    setSchemaValue(value);

    try {
      const changedSchema = JSON.parse(value);
      setJSONisValid(true);
      // This line is needed as Ace editor is confused on linebreaks
      if (!equal(changedSchema, schemaConcept)) {
        setIsValidated(false);
        // this obviously isn't true, but the type is so deeply baked into the editor components and Redux
        setSchema(changedSchema as OriginalAnyDatapointSchema[]);
      }
    } catch {
      setJSONisValid(false);
      return false;
    }
    return true;
  };

  const onSave = () => {
    if (canSave) {
      saveSchema();
      setTemporarySaved();
    }
  };

  const onReset = () => {
    if (canReset) {
      resetSchema(() => {
        setJSONisValid(true);
        setSchemaValue(schemaToString(schemaConcept));
        setTemporaryReverted();
      });
    }
  };

  const onPrettify = () => {
    if (!JSONisValid || !schemaValue) return false;

    try {
      const schemaObject = JSON.parse(schemaValue);
      // this obviously isn't true, but the type is so deeply baked into the editor components and Redux
      setSchema(schemaObject as OriginalAnyDatapointSchema[]);
    } catch {
      return false;
    }
    return true;
  };

  return (
    <div className={styles.EditorWrapper}>
      <TopMenu
        temporaryMessage={temporaryMessage}
        onPrettify={onPrettify}
        canPrettify={JSONisValid && isValueChanged}
        onSave={onSave}
        canSave={canSave}
        onReset={onReset}
        canReset={canReset}
        isValid={isValid}
        isChanged={isSchemaChanged}
        right={
          <>
            <PoweredBy />
            <UserPanel />
          </>
        }
        editorType="schema"
      />

      <Editor
        name="schemaEditor"
        mode="json"
        forwardRef={editor}
        onChange={onChange}
        value={schemaValue}
        onFocus={setNavigationPath}
        // It seems that React ACE sometimes splits one user action into
        // several operations, and if the change event is debounced,
        // the partial results can be passed to the parent components
        // and then problems happen (such as marking invalid JSON when it's valid)
        // So it should be small non-zero value
        debounceChangePeriod={5}
      >
        {showEnumEditor && (
          <EnumEditor
            currentSchemaPart={
              currentSchemaPart as ImmutableObject<EnumDatapointSchema>
            }
            onCurrentSchemaPartChange={
              onCurrentSchemaPartChange as unknown as (
                schemaPart: ImmutableObject<EnumDatapointSchema>
              ) => void
            }
            onClose={() => setCurrentPath(showFooter ? currentFooterPath : [])}
            openModal={openModal}
          />
        )}
      </Editor>

      <div className={styles.EditorFooter}>
        {schemaErrors && !!schemaErrors.length && (
          <SchemaErrors errors={schemaErrors} />
        )}

        {showFooter && (
          <Footer
            currentPath={currentFooterPath}
            closeFooter={() => setCurrentPath([])}
            setCurrent={setCurrentPath}
            schema={currentFooterSchemaPart}
            isCurrentPath={isCurrentPath}
          />
        )}
      </div>
    </div>
  );
};

export default SchemaEditor;

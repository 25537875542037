import {
  EngineFieldSubtype,
  EngineFieldType,
} from '@rossum/api-client/engineFields';

export const types = [
  'string',
  'number',
  'date',
  'enum',
] as const satisfies EngineFieldType[];

export const subtypes = [
  'alphanumeric',
  'numeric',
  'country_code',
  'currency_code',
  'iban',
  'vat_number',
  'integer',
  'rate',
  'amount',
  'period_end',
  'period_begin',
  // TODO: Temporary, BE should fix
  '',
] as const satisfies EngineFieldSubtype[];

export type EngineFieldSubtypeKey =
  | 'string.default'
  | 'string.alphanumeric'
  | 'string.numeric'
  | 'string.countryCode'
  | 'string.currencyCode'
  | 'string.iban'
  | 'string.vatNumber'
  | 'number.default'
  | 'number.integer'
  | 'number.rate'
  | 'number.amount'
  | 'date.default'
  | 'date.periodEnd'
  | 'date.periodBegin'
  | 'enum.default';

export type DataType = {
  type: EngineFieldType;
  subtype: EngineFieldSubtype | null;
  id?: EngineFieldSubtypeKey;
};

const typesAndSubtypes: DataType[] = [
  { type: 'string', subtype: null, id: 'string.default' },
  { type: 'string', subtype: 'alphanumeric', id: 'string.alphanumeric' },
  { type: 'string', subtype: 'numeric', id: 'string.numeric' },
  { type: 'string', subtype: 'country_code', id: 'string.countryCode' },
  { type: 'string', subtype: 'currency_code', id: 'string.currencyCode' },
  { type: 'string', subtype: 'iban', id: 'string.iban' },
  { type: 'string', subtype: 'vat_number', id: 'string.vatNumber' },
  { type: 'number', subtype: null, id: 'number.default' },
  { type: 'number', subtype: 'integer', id: 'number.integer' },
  { type: 'number', subtype: 'rate', id: 'number.rate' },
  { type: 'number', subtype: 'amount', id: 'number.amount' },
  { type: 'date', subtype: null, id: 'date.default' },
  { type: 'date', subtype: 'period_end', id: 'date.periodEnd' },
  { type: 'date', subtype: 'period_begin', id: 'date.periodBegin' },
  { type: 'enum', subtype: null, id: 'enum.default' },
];

export const useTypesAndSubtypes = () => ({ typesAndSubtypes });

import { IconPhotoOff } from '@rossum/ui/icons/tabler';
import { Paper, Stack, SvgIcon, Typography } from '@rossum/ui/material';
import { IntlShape } from 'react-intl';

type NoPreviewProps = {
  title: string;
  intl: IntlShape;
};

export const NoPreviewState = ({ title, intl }: NoPreviewProps) => {
  return (
    <Stack
      component={Paper}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="466px"
      p={8}
      spacing={2}
      mb={12}
    >
      <SvgIcon
        fontSize="large"
        component={IconPhotoOff}
        opacity={0.5}
        sx={{ fill: 'none', fontSize: 48, strokeWidth: 1 }}
      />

      <Typography variant="h5" align="center" color="text.secondary">
        {title}
      </Typography>
      <Typography variant="body2" align="center" color="text.secondary">
        {intl.formatMessage({
          id: 'features.documentList.preview.error.text',
        })}
      </Typography>
    </Stack>
  );
};

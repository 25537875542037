import { Delete, Edit } from '@rossum/ui/icons';
import {
  CircularProgress,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { PageLayoutV2 } from '../../../components/PageLayoutV2/PageLayoutV2';
import { useRequestUnpaginatedLabels } from '../hooks/useRequestLabels';
import { DeleteLabelDialog, EditLabelDialog } from './LabelDialogs';
import LabelListRow from './LabelListRow';
import { LabelsHeader } from './LabelsHeader';

const LOADER_HEIGHT = 600;

type LabelActionState = {
  id: number;
  name: string;
  action: 'delete' | 'edit';
};

export const LabelList = () => {
  const [labelAction, setLabelAction] = useState<LabelActionState | null>(null);

  const intl = useIntl();

  const {
    data: labels,
    isInitialLoading: isLoading,
    isSuccess,
    isError,
  } = useRequestUnpaginatedLabels();

  const handleOnClose = () => setLabelAction(null);

  const getDialogContent = () => {
    if (!labelAction) return null;

    const { id, name, action } = labelAction;

    if (action === 'delete') {
      return <DeleteLabelDialog id={id} name={name} onCancel={handleOnClose} />;
    }

    return <EditLabelDialog id={id} name={name} onCancel={handleOnClose} />;
  };

  const getContent = () => {
    if (isLoading) {
      return (
        <Stack
          alignItems="center"
          justifyContent="center"
          height={LOADER_HEIGHT}
        >
          <CircularProgress />
        </Stack>
      );
    }

    if (isError) {
      return (
        <Typography color="text.secondary">
          {intl.formatMessage({
            id: 'containers.settings.labels.fetchFailed',
          })}
        </Typography>
      );
    }

    if (isSuccess && labels) {
      return (
        <Stack direction="column" spacing={2}>
          {labels.map(label => (
            <div key={label.id}>
              <LabelListRow>
                <Stack direction="row" alignItems="center">
                  <Typography>{label.name}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <IconButton
                    onClick={() => {
                      setLabelAction({
                        id: label.id,
                        name: label.name,
                        action: 'edit',
                      });
                    }}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setLabelAction({
                        id: label.id,
                        name: label.name,
                        action: 'delete',
                      });
                    }}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Stack>
              </LabelListRow>
            </div>
          ))}
        </Stack>
      );
    }

    return null;
  };

  return (
    <PageLayoutV2
      renderHeader={params => (
        <LabelsHeader {...params} labelsCount={labels?.length ?? 0} />
      )}
    >
      <Stack px={4} py={4}>
        {getContent()}
      </Stack>
      <Dialog open={!!labelAction} onClose={handleOnClose}>
        {getDialogContent()}
      </Dialog>
    </PageLayoutV2>
  );
};

import { Button, Switch } from '@rossum/ui/material';
import clsx from 'clsx';
import { countBy, flattenDeep } from 'lodash';
import CloseIcon from 'mdi-react/CloseIcon';
import SearchAndReplaceIcon from 'mdi-react/FindReplaceIcon';
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { BehaviorSubject } from 'rxjs';
import { isEditableFormulaField } from '../../../components/Datapoint/helpers';
import Input from '../../../components/UI/Input';
import { findAll } from '../../../lib/searchAndReplaceUtils';
import {
  bulkUpdateDatapointValue,
  rerenderDatapoints,
} from '../../../redux/modules/datapoints/actions';
import { schemaMapSelector } from '../../../redux/modules/schema/schemaMapSelector';
import { getFooterSchema } from '../../../redux/modules/schema/selectors';
import { setCurrentFooterColumn } from '../../../redux/modules/searchAndReplace/actions';
import { getDatapointsBySchemaId } from '../../../redux/modules/searchAndReplace/selectors';
import styles from '../style.module.sass';

type SearchAndReplaceProps = {
  currentFooterColumn: string;
};

export const searchInputSubject = new BehaviorSubject<string>('');
export const replaceInputSubject = new BehaviorSubject<string>('');
export const togglePreviewSubject = new BehaviorSubject<boolean>(false);

const SearchAndReplace = ({ currentFooterColumn }: SearchAndReplaceProps) => {
  const dispatch = useDispatch();

  const schemaMap = useSelector(schemaMapSelector);
  const columnSchema = schemaMap.get(currentFooterColumn);

  const currentColumnDatapoints = useSelector(getDatapointsBySchemaId);

  const [search, setSearch] = useState<string>('');
  const [replace, setReplace] = useState<string>('');
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [isSearchAndReplaceSubmitted, setIsSearchAndReplaceSubmitted] =
    useState<boolean>(false);
  const [numberOfItems, setNumberOfItems] = useState<number>(0);
  const [numberOfReplacedItems, setNumberOfReplacedItems] = useState<number>(0);

  const inputSearch = useRef<HTMLInputElement>(null);

  const escapedValue = (value: string) => value.replace(/\n/g, ' ');

  useEffect(() => {
    inputSearch.current?.focus();
  }, [currentFooterColumn]);

  useEffect(() => {
    const currentChunks = flattenDeep(
      currentColumnDatapoints.map(({ content }) =>
        findAll({
          autoEscape: true,
          caseSensitive: true,
          searchWords: [search],
          textToHighlight: content?.value ? escapedValue(content.value) : '',
        })
      )
    );
    setNumberOfItems(countBy(currentChunks, ['highlight', true]).true || 0);
  }, [search, currentColumnDatapoints]);

  const onSearchChange = useCallback(
    (value: string) => {
      if (isSearchAndReplaceSubmitted) {
        setIsSearchAndReplaceSubmitted(false);
      }
      setSearch(value);
      searchInputSubject.next(value);
    },
    [isSearchAndReplaceSubmitted]
  );

  const onReplaceChange = useCallback(
    (value: string) => {
      if (isSearchAndReplaceSubmitted) {
        setIsSearchAndReplaceSubmitted(false);
      }
      setReplace(value);
      replaceInputSubject.next(value);
    },
    [isSearchAndReplaceSubmitted]
  );

  const onToggle = useCallback(() => {
    if (isSearchAndReplaceSubmitted) {
      setIsSearchAndReplaceSubmitted(false);
    }
    setShowPreview(!showPreview);
    togglePreviewSubject.next(!showPreview);
  }, [isSearchAndReplaceSubmitted, showPreview]);

  const onReplaceAll = useCallback(() => {
    const noRecalculation = isEditableFormulaField(columnSchema);

    const datapointsToUpdate = currentColumnDatapoints.reduce<
      Record<
        number,
        { newValue: string; noRecalculation?: boolean | undefined }
      >
    >((updates, dp) => {
      const oldValue = dp.content?.value ? escapedValue(dp.content.value) : '';
      const newValue = oldValue.replaceAll(search, replace);

      return oldValue.includes(search)
        ? {
            ...updates,
            [dp.id]: {
              newValue,
              ...(noRecalculation === true ? { noRecalculation } : {}),
            },
          }
        : updates;
    }, {});

    dispatch(bulkUpdateDatapointValue(datapointsToUpdate));
  }, [columnSchema, currentColumnDatapoints, dispatch, replace, search]);

  const onSubmit = useCallback(() => {
    setIsSearchAndReplaceSubmitted(true);
    setNumberOfReplacedItems(numberOfItems);
    onReplaceAll();
    dispatch(rerenderDatapoints());
  }, [dispatch, numberOfItems, onReplaceAll]);

  const closePortal = useCallback(() => {
    setSearch('');
    setReplace('');
    searchInputSubject.next('');
    replaceInputSubject.next('');
    togglePreviewSubject.next(false);
    return dispatch(setCurrentFooterColumn(''));
  }, [dispatch]);

  const { columns } = useSelector(getFooterSchema);

  const label = useMemo(
    () =>
      columns.find(c => c.id === currentFooterColumn)?.label ??
      currentFooterColumn,
    [columns, currentFooterColumn]
  );

  return (
    <>
      <div className={styles.SearchAndReplaceHeader}>
        <div className={styles.SearchAndReplaceHeaderWithIcon}>
          <SearchAndReplaceIcon size={17} />
          <span>
            <FormattedMessage
              id="components.documentValidation.footer.searchAndReplace.replaceInColumn"
              values={{ column: <strong>{label}</strong> }}
            />
          </span>
        </div>
        <div onClick={closePortal} className={styles.DropdownClose}>
          <CloseIcon size={17} />
        </div>
      </div>
      <div className={styles.SearchAndReplaceInputWrapper}>
        <Input
          value={search}
          setRef={inputSearch}
          className={clsx(
            styles.SearchAndReplaceInput,
            !!search && styles.FilledSearchAndReplace
          )}
          onChange={onSearchChange}
          placeholder="components.documentValidation.footer.searchAndReplace.find"
        />
      </div>
      <div className={styles.SearchAndReplaceInputWrapper}>
        <Input
          value={replace}
          className={clsx(
            styles.SearchAndReplaceInput,
            !!replace && styles.FilledSearchAndReplace
          )}
          onChange={onReplaceChange}
          placeholder="components.documentValidation.footer.searchAndReplace.replace"
        />
      </div>
      <div className={styles.SearchAndReplaceMessage}>
        {isSearchAndReplaceSubmitted ? (
          <FormattedMessage
            id="components.documentValidation.footer.searchAndReplace.replaceDone"
            values={{
              numberOfReplacedItems,
              search,
              replace,
              italic: (msg: ReactNode) => <i>{msg}</i>,
              roboto: (msg: ReactNode) => (
                <span style={{ fontWeight: 700 }}>{msg}</span>
              ),
            }}
          />
        ) : (
          <FormattedMessage
            id="components.documentValidation.footer.searchAndReplace.numberOfItems"
            values={{ numberOfItems }}
          />
        )}
      </div>
      <div className={styles.SearchAndReplaceAction}>
        <Switch checked={showPreview} onChange={onToggle} size="small" />
        <FormattedMessage id="components.documentValidation.footer.searchAndReplace.preview" />
        <Button
          variant="contained"
          onClick={onSubmit}
          size="small"
          disabled={!search || !numberOfItems || isSearchAndReplaceSubmitted}
        >
          <FormattedMessage id="components.documentValidation.footer.searchAndReplace.buttonReplace" />
        </Button>
      </div>
    </>
  );
};

export default SearchAndReplace;

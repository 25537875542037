import { Delete } from '@rossum/ui/icons';
import {
  Box,
  formControlClasses,
  LinearProgress,
  outlinedInputClasses,
  Stack,
} from '@rossum/ui/material';
import {
  DataGridPro,
  gridClasses,
  GridColDef,
  GridEventListener,
} from '@rossum/ui/x-data-grid-pro';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { PageLayoutV2 } from '../../../../components/PageLayoutV2/PageLayoutV2';
import { commonDataGridStyles } from '../../../../ui/data-grid/styles';
import { useDataGridLocaleText } from '../../../document-list/hooks/useDataGridLocaleText';
import { useFieldManagerData } from '../../hooks/useFieldManagerData';
import { dataGridStyles } from '../field-detail/dataGridStyles';
import { filterPanelProps } from '../ui/dataGridStyles';
import { getColumns } from './columns';
import { colorWarning } from './dataGridStyles';
import { useMemoOverviewFields } from './hooks/useMemoOverviewFields';
import { useStoredFilterModel } from './hooks/useStoredFilterModel';
import { OverviewHeader } from './OverviewHeader';
import { DataForQueuesDialog, QueuesDialog } from './QueuesDialog';

export const Overview = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const intl = useIntl();
  const localeText = useDataGridLocaleText();
  const [storedFilterModel, setFilterModel] = useStoredFilterModel();

  const { flatSchemasWithQueues, schemas, queues, workspaces, isLoading } =
    useFieldManagerData();

  const [rowForQueuesDialog, setRowForQueuesDialog] =
    useState<DataForQueuesDialog | null>(null);

  const columns: GridColDef[] = useMemo(
    () =>
      getColumns({
        setRowForQueuesDialog,
        intl,
      }),
    [intl]
  );

  const fields = useMemoOverviewFields(flatSchemasWithQueues);

  const handleRowClick: GridEventListener<'rowClick'> = params =>
    push(`${pathname}/detail/${params.row.fieldId}`);

  return (
    <PageLayoutV2
      fullWidth
      renderHeader={params => (
        <OverviewHeader
          schemas={schemas}
          queues={queues}
          workspaces={workspaces}
          isLoading={isLoading}
          {...params}
        />
      )}
    >
      <Stack px={4} py={4}>
        <Box
          sx={{
            ...dataGridStyles,
            position: 'relative',
          }}
        >
          <DataGridPro
            onRowClick={handleRowClick}
            disableColumnSelector
            hideFooter
            autoHeight
            loading={isLoading}
            localeText={localeText}
            columns={columns}
            slots={{
              filterPanelDeleteIcon: Delete,
              loadingOverlay: LinearProgress,
            }}
            slotProps={{
              baseSelectOption: {
                // @ts-expect-error sx is not in the type definition but it works,
                sx: {
                  // workaround for making first empty option having the same height as other options
                  '&:after': { content: "' '" },
                },
              },
              baseFormControl: {
                sx: {
                  // workaround for making singleSelect input filter full width
                  [`> * .${formControlClasses.root}`]: {
                    width: '100%',
                  },
                },
              },
              baseSelect: {
                native: false,
                sx: {
                  [`.${gridClasses.cell} & .${outlinedInputClasses.notchedOutline}`]:
                    {
                      border: 'unset !important',
                    },

                  [`& .${outlinedInputClasses.input}`]: {
                    pl: 2,
                  },
                },
              },
              filterPanel: filterPanelProps,
            }}
            rows={fields}
            disableColumnReorder
            disableRowSelectionOnClick
            initialState={{ filter: { filterModel: storedFilterModel } }}
            onFilterModelChange={setFilterModel}
            sx={{
              ...commonDataGridStyles,
              border: 'none',
              cursor: 'pointer',
              '.MuiDataGrid-cell:focus': {
                outline: 'none',
              },

              '.MuiDataGrid-columnHeader:focus': {
                outline: 'none',
              },
            }}
            getCellClassName={params =>
              params.value &&
              Array.isArray(params.value) &&
              params.value.length > 1
                ? colorWarning
                : ''
            }
          />
        </Box>
        {rowForQueuesDialog !== null && (
          <QueuesDialog
            rowForQueuesDialog={rowForQueuesDialog}
            setRowForQueuesDialog={setRowForQueuesDialog}
          />
        )}
      </Stack>
    </PageLayoutV2>
  );
};

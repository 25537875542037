import { yupResolver } from '@hookform/resolvers/yup';
import { ThemeType } from '@rossum/rossum-ui/theme';
import { Button, FormLabel, MenuItem, Stack } from '@rossum/ui/material';
import { compact } from 'lodash';
import { useForm } from 'react-hook-form';
import { IntlShape } from 'react-intl';
import * as yup from 'yup';
import TextFieldControl from '../../../components/ReactHookForm/controls/TextFieldControl';
import { DEV_FEATURES_ENABLED } from '../../../constants/config';
import { localeOptions } from '../../../i18n/config';
import { splitFullName } from '../../../redux/modules/user/helpers';
import { User } from '../../../types/user';
import FormRow, { FORM_FIELD_WIDTH } from '../components/FormRow';

type UpdateUserPayload = {
  firstName: string;
  lastName: string;
  uiSettings: {
    locale: string | undefined;
    theme: ThemeType | undefined;
  };
};

type UpdateUserMeta = { withMessage: boolean };

type Props = {
  intl: IntlShape;
  updateUser: (_payload: UpdateUserPayload, _meta: UpdateUserMeta) => void;
  user: User;
};

const getThemeOptions = (intl: IntlShape) => [
  {
    value: 'dark',
    label: intl.formatMessage({ id: 'containers.personalInfo.theme.dark' }),
  },
  {
    value: 'white',
    label: intl.formatMessage({ id: 'containers.personalInfo.theme.white' }),
  },
];

const PersonalInformationForm = ({ intl, user, updateUser }: Props) => {
  const personalInformationValidationSchema = yup.object().shape({
    name: yup.string().required(
      intl.formatMessage({
        id: 'containers.settings.users.addUser.name.error',
      })
    ),
    locale: yup.string().optional(),
    theme: yup.string().optional(),
  });

  const defaultValues = {
    name: compact([user.firstName, user.lastName]).join(' '),
    locale: user.uiSettings.locale,
    theme: user.uiSettings.theme,
  };
  const { handleSubmit, control } = useForm({
    mode: 'onSubmit',
    defaultValues,
    resolver: yupResolver(personalInformationValidationSchema),
  });
  return (
    <form
      onSubmit={handleSubmit(({ name, locale, theme }) =>
        updateUser(
          {
            firstName: splitFullName(name).firstName,
            lastName: splitFullName(name).lastName,
            uiSettings: {
              locale,
              theme,
            },
          },
          { withMessage: true }
        )
      )}
    >
      <Stack spacing={2} justifyContent="center" width={455}>
        <FormRow>
          <FormLabel>
            {intl.formatMessage({
              id: 'containers.settings.users.addUser.name.label',
            })}
          </FormLabel>
          <TextFieldControl
            FieldLabelProps={{ layout: 'none' }}
            id="input"
            type="string"
            ControllerProps={{ control, name: 'name' }}
            placeholder={intl.formatMessage({
              id: 'containers.settings.users.addUser.name.placeholder',
            })}
            inputProps={{
              'data-cy': 'my-profile-name-input',
            }}
          />
        </FormRow>
        <FormRow>
          <FormLabel>
            {intl.formatMessage({
              id: 'containers.settings.users.addUser.locale.label',
            })}
          </FormLabel>
          <TextFieldControl
            FieldLabelProps={{ layout: 'none' }}
            ControllerProps={{ control, name: 'locale' }}
            placeholder={intl.formatMessage({
              id: 'containers.settings.users.addUser.locale.placeholder',
            })}
            select
            autoComplete="off"
          >
            {localeOptions.map(locale => (
              <MenuItem
                key={locale.label}
                value={locale.value}
                data-cy={`queue-form-workspace-item-${locale.label}`}
              >
                {locale.label}
              </MenuItem>
            ))}
          </TextFieldControl>
        </FormRow>
        {DEV_FEATURES_ENABLED && (
          <FormRow>
            <FormLabel>
              {intl.formatMessage({
                id: 'containers.personalInfo.theme',
              })}
            </FormLabel>
            <TextFieldControl
              FieldLabelProps={{ layout: 'none' }}
              ControllerProps={{ control, name: 'theme' }}
              placeholder={intl.formatMessage({
                id: 'containers.personalInfo.theme.placeholder',
              })}
              select
              autoComplete="off"
            >
              {getThemeOptions(intl).map(option => (
                <MenuItem
                  key={option.label}
                  value={option.value}
                  data-cy={`queue-form-workspace-item-${option.label}`}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextFieldControl>
          </FormRow>
        )}
        <Stack
          width={FORM_FIELD_WIDTH}
          alignSelf="flex-end"
          alignItems="center"
        >
          <Button
            type="submit"
            variant="contained"
            data-cy="my-profile-update-settings"
          >
            {intl.formatMessage({
              id: 'containers.settings.users.update',
            })}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default PersonalInformationForm;

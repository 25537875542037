import { getIDFromUrl } from '@rossum/api-client';
import { Schema } from '@rossum/api-client/schemas';
import { DeleteOutline } from '@rossum/ui/icons';
import { Button, CircularProgress } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { SettingsBreadcrumbs } from '../../../../containers/Settings/SettingsBreadcrumbs';
import { Header, HeaderProps } from '../../../../ui/header/Header';
import { FIELD_FORM_ID } from '../../../queue-settings/fields/constants';
import { fieldManagerPath } from '../../constants';

type CreateFieldHeaderProps = {
  isLoading: boolean;
  goBack: () => void;
  schema: Schema;
} & Pick<HeaderProps, 'scrollableDivRef'>;

export const CreateFieldHeader = ({
  isLoading,
  goBack,
  schema,
  ...rest
}: CreateFieldHeaderProps) => {
  const intl = useIntl();

  const queue = schema.queues[0];

  return (
    <Header
      {...rest}
      breadcrumbs={
        <SettingsBreadcrumbs
          breadcrumbs={[
            {
              to: fieldManagerPath(),
              label: intl.formatMessage({
                id: 'features.fieldManager.overview.ui.header.title',
              }),
            },
            {
              label: intl.formatMessage({
                id: 'features.fieldManager.CreateFieldForm.title',
              }),
            },
          ]}
        />
      }
      title={intl.formatMessage({
        id: 'features.fieldManager.CreateFieldForm.title',
      })}
      description={null}
      buttons={[
        <Button
          key="fm-json-schema"
          data-cy="fm-edit-json-schema"
          component={Link}
          to={`/queues/${queue ? getIDFromUrl(queue) : ''}/settings/schema`}
          variant="outlined"
          color="secondary"
          sx={{
            '&:hover': {
              color: theme => theme.palette.secondary.main,
              textDecoration: 'none',
            },
          }}
        >
          {intl.formatMessage({
            id: 'containers.settings.queues.queue.editSchema',
          })}
        </Button>,
        <Button
          variant="contained"
          type="submit"
          form={FIELD_FORM_ID}
          key="fm-create-field-btn"
          data-cy="fm-create-field-btn"
          startIcon={
            isLoading && <CircularProgress size={20} color="secondary" />
          }
        >
          {intl.formatMessage({
            id: 'features.queueSettings.actions.save',
          })}
        </Button>,
        <Button
          key="fm-cancel-field"
          onClick={goBack}
          variant="outlined"
          color="secondary"
          startIcon={<DeleteOutline />}
          data-cy="fm-cancel-field-button"
        >
          {intl.formatMessage({
            id: 'features.queueSettings.actions.delete',
          })}
        </Button>,
      ]}
      onBackButtonClicked={goBack}
    />
  );
};

import { endpoints, getIDFromUrl, ID } from '@rossum/api-client';
import {
  isSchemaDatapoint,
  isSchemaSimpleMultiValue,
  isSchemaTableMultiValue,
  Schema,
  SchemaDatapoint,
} from '@rossum/api-client/schemas';
import { QueryStatus, useQueries } from '@tanstack/react-query';
import { fromPairs } from 'lodash';
import { useMemo } from 'react';
import { api } from '../../lib/apiClient';
import { isNotNullOrUndefined } from '../../lib/typeGuards';
import { useUnpaginatedQueuesByIds } from './useUnpaginatedQueuesByIds';

const QUERY_KEY_SCHEMA = 'schema';

type QueueToSchemaDatapointMap = {
  [key: ID]: {
    queueId: ID;
    queueName: string;
    datapoints: { id: string; label: string }[];
  };
};

type QueueToSchemaDatapointMapWithStatus = {
  status: QueryStatus;
  data: QueueToSchemaDatapointMap;
};

const filterHeaderFields = (
  schemaContent: Schema['content'],
  datapointFilter: (dp: SchemaDatapoint) => boolean
): { id: string; label: string }[] => {
  return (
    (schemaContent || [])
      // unpack schema sections
      .flatMap(content => content.children)
      // we want all header fields (simple and multivalue, but not columns in a tuple)
      .map(dp =>
        isSchemaDatapoint(dp)
          ? dp
          : isSchemaSimpleMultiValue(dp)
            ? dp.children
            : null
      )
      .filter(isNotNullOrUndefined)
      .filter(datapointFilter)
      .filter(isNotNullOrUndefined)
      .map(dp => ({ id: dp.id, label: dp.label }))
      .sort((a, b) => (a.label > b.label ? 1 : -1))
  );
};

const filterLineItemColumns = (
  schemaContent: Schema['content'],
  datapointFilter: (dp: SchemaDatapoint) => boolean
): { id: string; label: string }[] => {
  return (
    (schemaContent || [])
      // unpack schema sections
      .flatMap(content => content.children)
      // get table multivalues
      .filter(isSchemaTableMultiValue)
      // unpack their children all through tuple all the way to datapoint
      .flatMap(dp => dp.children.children)
      .filter(datapointFilter)
      .map(dp => ({ id: dp.id, label: dp.label }))
      .sort((a, b) => (a.label > b.label ? 1 : -1))
  );
};

/**
 *
 * @param queueIds Which Queues you want the datapoints for
 * @param fieldType Can be `'headerField'` for simple datapoints or `'lineItemColumn'` for columns of LI
 * @param datapointFilter Optional filter on datapoints you're interested in (e.g. no buttons - default)
 * @returns
 */
export const useQueueToSchemaDatapointMap = (
  queueIds: ID[],
  fieldType: 'headerField' | 'lineItemColumn',
  datapointFilter: (dp: SchemaDatapoint) => boolean = dp => dp.type !== 'button'
) => {
  const queuesResult = useUnpaginatedQueuesByIds(
    { pageSize: 100, id: queueIds },
    {
      staleTime: 60 * 1000 * 60,
      select: queues =>
        queues.map(q => ({
          queueId: q.id,
          queueName: q.name,
          schemaId: getIDFromUrl(q.schema)!,
        })),
      // don't fetch all schemas when none are requested
      enabled: queueIds.length > 0,
    }
  );

  // we have to fetch schemas individually because /list does not return `content`
  // what happens if queueId is different but queryKey is the same?
  const schemasResult = useQueries({
    queries: (queuesResult.data ?? []).map(
      ({ queueId, queueName, schemaId }) => ({
        queryKey: [QUERY_KEY_SCHEMA, schemaId] as const,
        queryFn: ({ queryKey }: { queryKey: readonly [unknown, ID] }) =>
          api.request(endpoints.schemas.get(queryKey[1])),
        enabled: Boolean(schemaId),
        select: (schema: Schema) => ({
          queueId,
          queueName,
          datapoints:
            fieldType === 'lineItemColumn'
              ? filterLineItemColumns(schema.content, datapointFilter)
              : filterHeaderFields(schema.content, datapointFilter),
        }),
        staleTime: 60 * 1000 * 60,
      })
    ),
  });

  // memoed for many usage instances, though not sure if it will help with useQueries as dependency
  // TODO: Architect the data fetching better
  const returnValue = useMemo<QueueToSchemaDatapointMapWithStatus>(() => {
    return queuesResult.status === 'success' &&
      schemasResult.every(result => result.status === 'success')
      ? {
          status: 'success' as const,
          data: fromPairs(schemasResult.map(r => [r.data?.queueId, r.data])),
        }
      : schemasResult.some(result => result.status === 'error')
        ? {
            status: 'error' as const,
            data: {},
          }
        : {
            status: 'loading' as const,
            data: {},
          };
  }, [queuesResult.status, schemasResult]);

  // only return if both queues and all schemas have loaded correctly
  return returnValue;
};

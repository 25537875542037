import { Survey } from '@rossum/api-client/surveys';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@rossum/ui/material';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { throwInfo } from '../../../../redux/modules/messages/actions';
import DialogTitle from '../../../../ui/dialog-title/DialogTitle';
import { SmileyRating } from '../../../../ui/smiley-rating/SmileyRating';
import { SurveyName } from '../../surveysUuidMap';
import { useSurveyActions } from '../hooks/useSurveyActions';

export type FieldsSurveyDialogProps = {
  survey: Survey;
  open: boolean;
  surveyName: Extract<
    SurveyName,
    | 'fieldManagerSurvey'
    | 'fieldsToCaptureRedesignSurvey'
    | 'formulaFieldsSurvey'
  >;
  viewBlockers?: string[];
};

/**
 * Shared survey dialog components used for Field manager,
 * Fields to capture and Formula fields surveys.
 *
 * Automatically increments usage count on mounting.
 * */
export const FieldsSurveyDialog = ({
  survey,
  open,
  surveyName,
  viewBlockers = [],
}: FieldsSurveyDialogProps) => {
  const intl = useIntl();
  const [currentlySkipped, setCurrentlySkipped] = useState(false);

  const { increaseSkipCount, increaseUsageCount, answer } =
    useSurveyActions(survey);

  const hasIncreasedUsageCount = useRef(false);

  const needsToIncreaseUsageInteraction = viewBlockers.includes(
    'not_enough_usage_interaction'
  );

  useEffect(() => {
    if (needsToIncreaseUsageInteraction && !hasIncreasedUsageCount.current) {
      hasIncreasedUsageCount.current = true;
      increaseUsageCount().catch(() => {});
    }
  }, [needsToIncreaseUsageInteraction, increaseUsageCount]);

  const handleSkip = () => {
    increaseSkipCount().catch(() => {});
    setCurrentlySkipped(true);
  };

  const dispatch = useDispatch();
  const handleSubmit = () => {
    answer([{ value: rating }, { value: reason }, { value: null }]).then(() => {
      dispatch(throwInfo('surveyAnswered'));
    });
  };

  const handleSubmitWithoutComment = (rating: number) => {
    answer([{ value: rating }, { value: '' }, { value: null }]);
  };

  const [rating, setRating] = useState<number | null>(null);
  const [reason, setReason] = useState<string>('');

  const isFirstStep = rating === null;

  const title = isFirstStep
    ? intl.formatMessage({
        id: `components.surveys.dataCaptureSurvey.questions.${surveyName}.rating`,
      })
    : intl.formatMessage({
        id: `components.surveys.dataCaptureSurvey.questions.${surveyName}.reason`,
      });

  return (
    <Dialog
      open={currentlySkipped ? false : open}
      PaperProps={{
        elevation: 2,
        sx: {
          maxWidth: 450,
        },
      }}
    >
      <DialogTitle title={title} />
      {isFirstStep ? (
        <>
          <DialogContent
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: 0,
            }}
          >
            <SmileyRating
              name={surveyName}
              sx={{
                mt: 4,
              }}
              onChange={(_e: unknown, value: number | null) => {
                if (value !== null) {
                  setRating(value);
                }
              }}
            />
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleSkip}
              data-cy={`${surveyName}-step-1-skip`}
            >
              {intl.formatMessage({
                id: 'components.surveys.dataCaptureSurvey.buttons.skip',
              })}
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent>
            <TextField
              sx={{ width: '100%', mt: 3 }}
              multiline
              variant="outlined"
              value={reason}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setReason(e.currentTarget.value)}
            />
          </DialogContent>
          <DialogActions sx={{ p: 3, pt: 1 }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleSubmitWithoutComment(rating)}
              data-cy={`${surveyName}-step-2-skip`}
            >
              {intl.formatMessage({
                id: 'components.surveys.dataCaptureSurvey.buttons.close',
              })}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              data-cy={`${surveyName}-step-2-submit`}
            >
              {intl.formatMessage({
                id: 'components.surveys.dataCaptureSurvey.buttons.send',
              })}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

import { ID } from '../../utils/codecUtils';
import { EngineField } from '../models/engineField';
import { engineFieldSchema } from '../models/engineField.schema';
import { engineFieldPatchPayloadSchema } from './patch.schema';

export type EngineFieldPatchPayload = Pick<
  Partial<EngineField>,
  'label' | 'preTrainedFieldId' | 'type' | 'subtype' | 'tabular' | 'multiline'
>;

export const patch = (engineFieldId: ID, payload: EngineFieldPatchPayload) => {
  return {
    endpoint: `/engine_fields/${engineFieldId}`,
    method: 'PATCH',
    responseSchema: engineFieldSchema,
    payloadSchema: engineFieldPatchPayloadSchema,
    payload,
  } as const;
};

import { isEqual } from 'lodash';
import { dataGridOperatorsMap } from './constants';
import { FilterOperator } from './operators';
import {
  AdvancedFilterItem,
  FilterContext,
  FilterItem,
  FilterValues,
  SimpleFilterItem,
  TypedGridColDef,
} from './types';

// we do not want to perform requests or store filters in URL with empty values, such as empty strings, undefined, null or empty arrays
export const isEmptyValue = (value: unknown) =>
  value === null ||
  value === '' ||
  value === undefined ||
  (Array.isArray(value) && value.length === 0);

// BE needs at least 2 characters for startsWith operator, it throws an error otherwise
export const STARTS_WITH_OPERATOR_MIN_LENGTH = 2;

export const isStartsWithTooShort = (valueLength: number, operator: string) =>
  operator === 'startsWith' && valueLength < STARTS_WITH_OPERATOR_MIN_LENGTH;

export const getArrayValuesWithDefault = <T>(
  value?: T
): (T & unknown[]) | never[] => {
  if (!isEmptyValue(value) && Array.isArray(value)) return value;
  return [];
};

export const getStringValueWithDefault = (value?: unknown) => {
  if (!isEmptyValue(value) && typeof value === 'string') return value;
  return '';
};

export const getColumn = (field: string, columns: TypedGridColDef[]) =>
  columns.find(col => col.field === field);

export const getOperator = (operator: string, operators?: FilterOperator[]) =>
  operators?.find(op => op.value === operator);

export const isFilterAdvanced = (
  filterItem: FilterItem
): filterItem is AdvancedFilterItem => Array.isArray(filterItem.filterContext);

export const isFilterSimple = (
  filterItem: FilterItem
): filterItem is SimpleFilterItem => !Array.isArray(filterItem.filterContext);

export const isFilterContextSimple = (
  filterContext: FilterContext
): filterContext is FilterValues => !Array.isArray(filterContext);

// this function is onyl to restrict the union type of filter operator properties
// so when we use a combination of containers.filtering.operators.{type}.{value} TS does not complain
export const getOperatorLocalisationKey = (op: FilterOperator) => {
  if (op.type === 'string') return `${op.type}.${op.value}` as const;
  if (op.type === 'multiSelect') return `${op.type}.${op.value}` as const;
  if (op.type === 'date') return `${op.type}.${op.value}` as const;
  if (op.type === 'number') return `${op.type}.${op.value}` as const;

  return null;
};

const areArraysOfValuesEquivalent = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
};

export const doUnsavedValuesExist = (
  localValues: string | string[],
  existedValues?: unknown
) => {
  const onlyNotSavedValuesExists = !existedValues && !!localValues.length;

  if (typeof localValues === 'string') {
    return onlyNotSavedValuesExists || localValues !== existedValues;
  }

  const existedValuesAreNotEquivalent =
    existedValues &&
    Array.isArray(existedValues) &&
    !areArraysOfValuesEquivalent(existedValues, localValues);

  return onlyNotSavedValuesExists || !!existedValuesAreNotEquivalent;
};

export const areColumnsEqual = (...columns: TypedGridColDef[]) => {
  const [firstColumn, secondColumn] = columns.map(col =>
    Object.fromEntries(
      Object.entries(col).flatMap(([key, value]) =>
        typeof value === 'function' ? [] : [[key, value]]
      )
    )
  );
  return isEqual(firstColumn, secondColumn);
};

export const isKeyofDataGridOperatorsMap = (
  val: string
): val is keyof typeof dataGridOperatorsMap => val in dataGridOperatorsMap;

import { ArrowBack } from '@rossum/ui/icons';
import {
  Box,
  Collapse,
  IconButton,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { throttle } from 'lodash';
import { ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import * as R from 'remeda';

const DESCRIPTION_MAX_LENGTH = 175;

export const HeaderTitle = ({
  hasScrolled,
  title,
}: {
  hasScrolled: boolean;
  title: string;
}) => {
  return <Typography variant={hasScrolled ? 'h4' : 'h3'}>{title}</Typography>;
};

export type HeaderProps = {
  breadcrumbs: ReactNode;
  tabs?: ReactNode;
  scrollableDivRef: React.RefObject<HTMLDivElement>;
  buttons?: ReactNode[];
  title: string | ((hasScrolled: boolean) => ReactNode);
  description: ReactNode;
  onBackButtonClicked?: () => void;
};

export const Header = ({
  breadcrumbs,
  tabs,
  scrollableDivRef,
  buttons,
  title,
  description,
  onBackButtonClicked,
}: HeaderProps) => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const [expandedDescription, setExpandedDescription] = useState(false);

  const intl = useIntl();

  const handleScroll = throttle(() => {
    if (scrollableDivRef.current) {
      const scrollY = scrollableDivRef.current.scrollTop;
      if (!hasScrolled) {
        setHasScrolled(scrollY > 170);
        return;
      }

      setHasScrolled(scrollY > 0);
    }
  }, 150);

  useEffect(() => {
    const div = scrollableDivRef.current;
    if (div) {
      div.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (div) {
        div.removeEventListener('scroll', handleScroll);
      }
      handleScroll.cancel();
    };
  }, [handleScroll, scrollableDivRef]);

  return (
    <Stack
      direction="row"
      id="queue-settings-header"
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 2,
        borderBottom: t => `1px solid ${t.palette.divider}`,
        backgroundColor: t => t.palette.background.paper,
      }}
    >
      <Stack width={1} px={4} mx="auto" direction="row" alignItems="center">
        <Stack
          pt={2}
          pb={hasScrolled || !tabs ? 2 : 0}
          spacing={hasScrolled ? 0 : breadcrumbs ? 2 : 0}
          sx={{
            transition: t =>
              t.transitions.create(['padding'], {
                duration: 300,
              }),
          }}
        >
          <Collapse in={!hasScrolled} unmountOnExit>
            <Stack direction="row" alignItems="center" gap={1.5}>
              {onBackButtonClicked ? (
                <IconButton
                  onClick={onBackButtonClicked}
                  size="small"
                  data-cy="exit-route-button"
                >
                  <ArrowBack
                    fontSize="small"
                    color="secondary"
                    sx={{ fill: t => t.palette.text.secondary }}
                  />
                </IconButton>
              ) : null}
              {breadcrumbs}
            </Stack>
          </Collapse>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack flexGrow={2} flexShrink={1} flexBasis={0} spacing={0}>
              <Stack direction="row" alignItems="center">
                {onBackButtonClicked ? (
                  <Collapse
                    orientation="horizontal"
                    in={hasScrolled}
                    unmountOnExit
                  >
                    <IconButton
                      onClick={onBackButtonClicked}
                      size="small"
                      data-cy="exit-route-button"
                      sx={{ mr: 1 }}
                    >
                      <ArrowBack
                        fontSize="small"
                        color="secondary"
                        sx={{ fill: t => t.palette.text.secondary }}
                      />
                    </IconButton>
                  </Collapse>
                ) : null}
                {R.isString(title) ? (
                  <HeaderTitle hasScrolled={hasScrolled} title={title} />
                ) : (
                  title(hasScrolled)
                )}
              </Stack>
              <Collapse in={!hasScrolled}>
                <Box
                  width="auto"
                  display="flex"
                  flexGrow={0}
                  alignSelf="flex-start"
                >
                  {R.isString(description) ? (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      component="div"
                    >
                      {description.length >= DESCRIPTION_MAX_LENGTH &&
                      !expandedDescription ? (
                        <>
                          <Box component="span">{`${description.slice(0, DESCRIPTION_MAX_LENGTH)}...`}</Box>
                          <Box
                            component="span"
                            color="text.disabled"
                            onClick={() => setExpandedDescription(true)}
                            ml={1}
                            sx={{ cursor: 'pointer' }}
                          >
                            {intl.formatMessage({
                              id: 'containers.settings.queues.queue.settings.expandDescription',
                            })}
                          </Box>
                        </>
                      ) : (
                        description
                      )}
                    </Typography>
                  ) : (
                    // It is a ReactNode | null - display as-is.
                    description
                  )}
                </Box>
              </Collapse>
            </Stack>
          </Stack>
          {tabs ? <Collapse in={!hasScrolled}>{tabs}</Collapse> : null}
        </Stack>
        <Stack
          flexGrow={1}
          flexShrink={1}
          flexBasis={0}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          gap={1}
          height={42}
        >
          {buttons}
        </Stack>
      </Stack>
    </Stack>
  );
};

import { Typography } from '@rossum/ui/material';
import createDOMPurify from 'dompurify';
import { sanitizeLinks } from '../../../../lib/htmlSanitization';

const DOMPurify = createDOMPurify();

DOMPurify.addHook('afterSanitizeAttributes', node => {
  sanitizeLinks(node);

  if (node instanceof HTMLElement && node.hasAttribute('style')) {
    // Allowing style attribute can be risky, we want to allow only color in the style

    const allowedCSSProperties = ['color'];
    const styles = node.style.cssText.split(';').filter(style => {
      const [property] = style.split(':').map(s => s.trim());
      return property && allowedCSSProperties.includes(property);
    });

    node.setAttribute('style', styles.join(';'));
  }
});

export const Summary = ({ content }: { content: string }) => {
  return (
    <Typography
      variant="body2"
      color="text.primary"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(content, {
          ALLOWED_TAGS: ['span', 'strong'],
          ALLOWED_ATTR: ['style'],
        }),
      }}
    />
  );
};

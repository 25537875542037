import { Dialog, Stack, Typography } from '@rossum/ui/material';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from '@rossum/ui/x-data-grid-pro';
import { compact, includes } from 'lodash';
import TrashIcon from 'mdi-react/TrashIcon';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { PageLayoutV2 } from '../../components/PageLayoutV2/PageLayoutV2';
import { pageSizes } from '../../constants/values';
import { parse, stringify } from '../../lib/url';
import { isSSOEnable } from '../../redux/modules/organization/selectors';
import { updateUserDetail } from '../../redux/modules/users/actions';
import { usersWithCountsSelector } from '../../redux/modules/users/selectors';
import { GroupRole } from '../../types/group';
import { State } from '../../types/state';
import { OrganizationUser } from '../../types/user';
import { commonDataGridStyles } from '../../ui/data-grid/styles';
import DeleteUserDialog from './components/DeleteUser/DeleteUserDialog';
import Toggle from './components/Toggle';
import UsersFooter from './components/UsersFooter';
import { UsersHeader } from './components/UsersHeader';
import { userDetailsAccessPath } from './helpers';

type OwnProps = {
  toggleUserActive: (id: number, isActive: boolean) => void;
} & RouteComponentProps;

type DispatchProps = {
  toggleUserActive: (id: number, isActive: boolean) => void;
};
type StateProps = {
  SSOEnabled: boolean;
  activeUsersCount?: number;
  loggedInUserId: number;
  users: Array<OrganizationUser>;
};

type Props = DispatchProps & OwnProps & StateProps;

const defaultQuery = { pageSize: 15, page: 1 };

const Users = ({
  SSOEnabled,
  activeUsersCount,
  history,
  location,
  loggedInUserId,
  toggleUserActive,
  users,
}: Props) => {
  const [selectedUser, setSelectedUser] = useState<OrganizationUser | null>(
    null
  );

  useEffect(() => {
    const { pageSize, page } = parse(location.search) || {};
    if (!page || !pageSize || !includes(pageSizes, pageSize)) {
      history.replace({ search: stringify(defaultQuery) });
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const isActiveToggleDisabled = (id: number, role: GroupRole) =>
    loggedInUserId === id || role === 'organization_group_admin';

  const intl = useIntl();

  const defaultConfig: Partial<GridColDef> = {
    sortable: false,
    pinnable: false,
    hideable: false,
    filterable: false,
  };

  const baseColumns: GridColDef<OrganizationUser>[] = compact([
    {
      field: 'active',
      type: 'actions',
      width: 50,
      getActions: ({ row }) => [
        <Toggle
          key={`active-${row.id}`}
          disabled={isActiveToggleDisabled(row.id, row.role)}
          active={row.isActive}
          onToggle={() => toggleUserActive(row.id, row.isActive)}
          isUserOrgGroupAdmin={row.role === 'organization_group_admin'}
        />,
      ],
    },
    {
      field: 'name',
      headerName: intl.formatMessage({
        id: 'containers.settings.users.addUser.name.label',
      }),
      width: 200,
    },
    {
      field: 'username',
      headerName: intl.formatMessage({
        id: 'containers.settings.users.addUser.email.label',
      }),
      width: 200,
    },
    {
      field: 'role',
      headerName: intl.formatMessage({
        id: 'containers.settings.users.addUser.role.label',
      }),
      width: 200,
      valueGetter: ({ row }) =>
        intl.formatMessage({
          id: `containers.settings.users.roles.${row.role}`,
        }),
    },
    SSOEnabled
      ? {
          field: 'authType',
          headerName: intl.formatMessage({
            id: 'containers.settings.users.addUser.authType',
          }),
          width: 200,
          valueGetter: ({ row }) =>
            row.authType
              ? intl.formatMessage({
                  id: `containers.settings.users.authType.${row.authType}`,
                })
              : '',
        }
      : undefined,
    {
      field: 'workspacesCount',
      headerName: '',
      width: 200,
      valueGetter: ({ row }) =>
        intl.formatMessage(
          {
            id: 'containers.settings.users.workspaces',
          },
          { count: row.workspacesCount }
        ),
    },
    {
      field: 'queuesCount',
      headerName: '',
      width: 200,
      valueGetter: ({ row }) =>
        intl.formatMessage(
          {
            id: 'containers.settings.users.queues',
          },
          { count: row.queuesCount }
        ),
    },
    {
      field: 'actions',
      type: 'actions',
      width: 50,
      getActions: ({ row }) => [
        <GridActionsCellItem
          key={`actions-${row.id}`}
          data-cy={`delete-user-${row.id}`}
          icon={<TrashIcon />}
          onClick={() => {
            setSelectedUser(row);
          }}
          label="Delete"
        />,
      ],
    },
  ]);

  const columns: GridColDef[] = baseColumns.map(config => ({
    ...defaultConfig,
    ...config,
  }));

  return (
    <PageLayoutV2
      fullWidth
      renderFooter={() => <UsersFooter />}
      renderHeader={params => (
        <UsersHeader {...params} activeUsersCount={activeUsersCount ?? 0} />
      )}
    >
      <Stack px={4} py={4}>
        {users.length ? (
          <DataGridPro
            columns={columns}
            rows={
              users.map(user => ({
                ...user,
                name: `${user.firstName} ${user.lastName}`,
                username: user.email || user.username,
              })) ?? []
            }
            hideFooter
            disableColumnMenu
            disableColumnResize
            hideFooterRowCount
            disableRowSelectionOnClick
            initialState={{ pinnedColumns: { right: ['actions'] } }}
            onRowClick={({ row }: GridRowParams<OrganizationUser>) =>
              history.push(userDetailsAccessPath(row.id), {
                backlinkSearch: history.location.search,
              })
            }
            getCellClassName={({ colDef }) =>
              colDef.type === 'actions' ? 'MuiDataGrid-actionCell' : ''
            }
            sx={{
              // pointer cursor on ALL rows
              '& .MuiDataGrid-row:hover': {
                cursor: 'pointer',
              },
              '& .MuiDataGrid-actionCell': {
                cursor: 'default',
              },
              '& .MuiDataGrid-pinnedColumnHeaders--right': {
                // Where is this padding coming from?
                pr: '0 !important',
              },
              ...commonDataGridStyles,
            }}
          />
        ) : (
          <Typography variant="body1" color="text.secondary">
            {intl.formatMessage({
              id: 'containers.settings.users.noUsersFound',
            })}
          </Typography>
        )}

        <Dialog
          open={selectedUser !== null}
          PaperProps={{
            sx: { width: 480, minHeight: 213, position: 'fixed' },
            elevation: 2,
          }}
          onClose={() => setSelectedUser(null)}
          sx={{ transition: 'smooth' }}
        >
          {selectedUser !== null ? (
            <DeleteUserDialog
              user={selectedUser}
              onDelete={() => setSelectedUser(null)}
              onCancel={() => setSelectedUser(null)}
            />
          ) : null}
        </Dialog>
      </Stack>
    </PageLayoutV2>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  SSOEnabled: isSSOEnable(state),
  activeUsersCount: state.users.activeUsersCount,
  loggedInUserId: state.user.id,
  users: usersWithCountsSelector(state),
});

const mapDispatchToProps = {
  toggleUserActive: (id: number, isActive: boolean) =>
    updateUserDetail(id, { isActive: !isActive }),
};

export default connect<StateProps, DispatchProps, OwnProps, State>(
  mapStateToProps,
  mapDispatchToProps
)(Users);

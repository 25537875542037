import { Url } from '@rossum/api-client';
import { tablePaginationClasses, Theme } from '@rossum/ui/material';
import { gridClasses } from '@rossum/ui/x-data-grid-pro';

const {
  columnHeader,
  columnHeaders,
  cell,
  footerContainer,
  row,
  virtualScrollerContent,
} = gridClasses;

export const MAX_HEIGHT_FOR_SMALL_SCREENS = 330;
export const PAGE_SIZE = 5;
export const ROW_HEIGHT = 48;
export const FETCH_ATTACHMENT_INTERVAL = 10 * 1000;

export const AttachmentModalDataGridStyles = {
  maxHeight: `max(${MAX_HEIGHT_FOR_SMALL_SCREENS}px, 50vh)`,
  borderColor: 'transparent',
  [`& .${columnHeader}`]: {
    color: 'text.secondary',
  },
  [`& .${tablePaginationClasses.displayedRows}`]: {
    m: 0,
  },
  [`& .${cell} ,& .${columnHeaders} , & .${footerContainer}`]: {
    borderColor: 'transparent',
  },
  [`& .${virtualScrollerContent}`]: {
    height: '260px !important',
  },
  [`.${row}`]: {
    backgroundColor: (t: Theme) => t.palette.background.paper,
    mb: 0.5,
  },
};

export const getFilterItemForExcludeAnnotations = (
  rootAnnotationUrl: Url | null,
  existingAttachments: Url[]
) => ({
  field: 'annotation',
  value: [rootAnnotationUrl || '', ...existingAttachments],
  operator: 'notIn',
});

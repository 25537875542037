import { Dialog } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import DialogTitle from '../../../../ui/dialog-title/DialogTitle';
import AddUserDialogContent from './AddUserDialogContent';

const MODAL_WIDTH = 436;

type AddUserDialogProps = {
  open: boolean;
  onClose: () => void;
};

const AddUserDialog = ({ open, onClose }: AddUserDialogProps) => {
  const intl = useIntl();
  return (
    <Dialog
      data-cy="add-user-modal"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: MODAL_WIDTH, position: 'fixed' },
        elevation: 2,
      }}
    >
      {open && (
        <>
          <DialogTitle
            title={intl.formatMessage({
              id: 'containers.settings.sidebar.users.addUser',
            })}
            onClose={onClose}
            closeDataCy="add-user-close-icon"
          />
          <AddUserDialogContent onCancel={onClose} />
        </>
      )}
    </Dialog>
  );
};

export default AddUserDialog;

import { z } from 'zod';
import { ID } from '../../utils/codecUtils';

export const preview = (id: ID) => {
  return {
    endpoint: `/pages/${id}/preview`,
    responseType: 'blob',
    method: 'GET',
  } as const;
};

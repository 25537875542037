import { gridClasses } from '@rossum/ui/x-data-grid-pro';

const { columnHeader, columnHeaders, cell, footerContainer } = gridClasses;

export const drawerDataGridStyles = {
  borderColor: 'transparent',
  [`& .${columnHeader}`]: {
    color: 'text.secondary',
  },
  [`& .${cell} ,& .${columnHeaders} , & .${footerContainer}`]: {
    borderColor: 'transparent',
  },
};

import { Survey } from '@rossum/api-client/surveys';
import { getUnsafe } from '../../../lib/helpers';
import { isNotNullOrUndefined } from '../../../lib/typeGuards';
import {
  hasUserAnsweredSurveyBlocker,
  isBeforeInterval,
  isUserAnnoyedBlocker,
  userNotEngagedBlocker,
} from '../blockers';

export const getDataCaptureSurveysBlockersFromSurvey = ({
  survey,
  userEngaged,
}: {
  survey: Survey;
  userEngaged: boolean;
}) =>
  [
    hasUserAnsweredSurveyBlocker(survey),
    isUserAnnoyedBlocker(survey, 1),
    userNotEngagedBlocker(userEngaged),
    isBeforeInterval(),
  ].filter(isNotNullOrUndefined);

const enoughAuroraInteractions = (survey: Survey) =>
  Number(getUnsafe(survey.additionalData, 'auroraUsageCount')) > 5;

const notEnoughAuroraInteractions = (survey: Survey) =>
  enoughAuroraInteractions(survey) ? undefined : 'not_enough_cli_interaction';

export const getCLISurveyBlockersFromSurvey = ({
  survey,
  userEngaged,
}: {
  survey: Survey;
  userEngaged: boolean;
}) =>
  [
    ...getDataCaptureSurveysBlockersFromSurvey({ survey, userEngaged }),
    notEnoughAuroraInteractions(survey),
  ].filter(isNotNullOrUndefined);

import { EditRelation } from '@rossum/api-client/relations';
import {
  Box,
  Divider,
  dividerClasses,
  Stack,
  Typography,
} from '@rossum/ui/material';
import clsx from 'clsx';
import SkipNextIcon from 'mdi-react/SkipNextIcon';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import NavigationIndicator from '../../../components/NavigationIndicator';
import { AutomationIconWithTooltip } from '../../../components/UI/AutomationIcon';
import ControlBar from '../../../components/UI/ControlBar';
import PoweredBy from '../../../components/UI/PoweredBy';
import UserPanel from '../../../components/UserPanel';
import { isEmbedded } from '../../../constants/config';
import AttachmentsIndicatorForValidationScreen from '../../../features/annotation-view/AttachmentsIndicatorForValidationScreen';
import { useDocumentStore } from '../../../features/annotation-view/document-store/DocumentStore';
import { useDocumentContext } from '../../../features/annotation-view/DocumentContext';
import { DocumentWasEdited } from '../../../features/document-list-base/components/DocumentWasEdited';
import SuggestedEdit from '../../../features/document-list-base/components/SuggestedEdit';
import AnnotationEmailBadge from '../../../features/emails/annotation-email-badge/AnnotationEmailBadge';
import { attachmentsFeatureSelector } from '../../../features/pricing/selectors';
import { snakeToCamel } from '../../../lib/keyConvertor';
import { constructDocumentUrl, getCurrentAnnotationId } from '../../../lib/url';
import { annotationSelector } from '../../../redux/modules/annotation/selectors';
import {
  isTrialSelector,
  safeOrganizationSelector,
} from '../../../redux/modules/organization/selectors';
import { State } from '../../../types/state';
import CountBadge from '../../../ui/count-badge/CountBadge';
import AutomationBlockers from '../../AutomationBlockers';
import CallToActionButton from '../../CallToAction';
import { DrawerConfig } from '../../DocumentValidation/ValidationEmailDrawer';
import styles from '../style.module.sass';

type TopBarProps = {
  navigateToPage: () => void;
  visiblePageNumber: number;
  onEmailThreadOpen: (drawerConfig?: DrawerConfig) => void;
};

const TopBar = ({
  navigateToPage,
  visiblePageNumber,
  onEmailThreadOpen,
}: TopBarProps) => {
  const { canvasActionInProgress } = useDocumentContext();
  const dispatch = useDispatch();

  const annotation = useSelector(annotationSelector);
  const isTrial = useSelector(isTrialSelector);
  const organization = useSelector(safeOrganizationSelector);
  const pages = useSelector((state: State) => state.pages.pages);
  const edit = useSelector((state: State) =>
    state.annotation.sideloads.relations.find(
      (r): r is EditRelation => r.type === 'edit'
    )
  );
  const isAttachmentsEnabled = useSelector(attachmentsFeatureSelector);

  const annotationStatus = useSelector((state: State) =>
    snakeToCamel(state.annotation.status!)
  );
  const automated = useSelector((state: State) => !!state.annotation.automated);
  const automaticallyRejected = useSelector(
    (state: State) => !!state.annotation.automaticallyRejected
  );
  const currentAnnotationId = useSelector((state: State) =>
    getCurrentAnnotationId(state.router.location.pathname)
  );
  const currentAnnotationUrl = useSelector(
    (state: State) => state.annotation.url
  );
  const currentRelation = useSelector(
    (state: State) => state.relations.currentRelation
  );
  const annotationPart = useSelector((state: State) => ({
    relatedEmails: state.annotation.relatedEmails ?? [],
    email: state.annotation.email ?? null,
    status: state.annotation.status ?? null,
  }));
  const suggestedEditCount = useSelector((state: State) =>
    state.annotation.suggestedEdit &&
    typeof state.annotation.suggestedEdit !== 'string' &&
    'documents' in state.annotation.suggestedEdit
      ? state.annotation.suggestedEdit.documents.length
      : 0
  );

  const nPages = pages.length;

  const brandName = organization?.uiSettings?.branding?.brandName;

  const disableEditMode = !!organization?.uiSettings?.features?.disableEditMode;

  const showAutomatedIcon = automated || automaticallyRejected;

  const openDrawer = useDocumentStore(state => state.openDrawer);
  const intl = useIntl();

  const canAccess = useSelector(
    (state: State) => !state.annotation.restrictedAccess
  );

  return (
    <ControlBar dynamic hidden={canvasActionInProgress} id="controlBar">
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          py: 1,
          px: 0.5,
          ml: 0.5,
          // to not display two dividers in a row
          [`.${dividerClasses.root} + .${dividerClasses.root}`]: {
            display: 'none',
          },
        }}
        divider={
          <Divider
            orientation="vertical"
            sx={{ height: t => t.spacing(3), mx: 1 }}
          />
        }
      >
        <NavigationIndicator
          label="components.documentOverview.page"
          nPages={nPages}
          page={visiblePageNumber}
          navigateToNext={navigateToPage}
          displayNext={nPages > 1}
          displayPrevious={false}
          nextIcon={props => (
            <SkipNextIcon
              {...props}
              className={clsx(
                styles.NextPageIcon,
                nPages === visiblePageNumber && styles.FirstPageIcon
              )}
            />
          )}
        />

        {canAccess && (
          <>
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                // it would probably be unnecessarily tricky to hide the divider when there is no content
                '&:empty': {
                  display: 'none',
                  [`+ .${dividerClasses.root}`]: {
                    display: 'none',
                  },
                },
              }}
              spacing={1.5}
            >
              {!!suggestedEditCount && (
                <SuggestedEdit
                  annotationId={currentAnnotationId}
                  onClick={() =>
                    dispatch(
                      push(
                        constructDocumentUrl({
                          id: currentAnnotationId,
                          nestedPath: '/edit',
                        })
                      )
                    )
                  }
                  dataCySuffix="top-bar"
                />
              )}
              {edit &&
                !isEmbedded() &&
                !disableEditMode &&
                annotationStatus !== 'importing' && (
                  <DocumentWasEdited
                    edit={edit}
                    childAnnotationId={currentAnnotationId}
                    dataCySuffix="top-bar"
                  />
                )}
              {showAutomatedIcon && (
                <AutomationIconWithTooltip
                  isAutomaticallyRejected={automaticallyRejected}
                />
              )}
              <AutomationBlockers />
            </Stack>
            {!isEmbedded() && currentRelation && annotation && (
              <CountBadge
                label={intl.formatMessage({
                  id: 'components.duplicates.title',
                })}
                count={currentRelation.annotations.length}
                onClick={() =>
                  openDrawer({
                    drawer: 'duplicates',
                    annotationId: annotation.id,
                  })
                }
              />
            )}
            {isAttachmentsEnabled && currentAnnotationUrl ? (
              <AttachmentsIndicatorForValidationScreen
                annotationUrl={currentAnnotationUrl}
              />
            ) : null}
            {!isEmbedded() ? (
              <AnnotationEmailBadge
                annotation={annotationPart}
                handleOnClick={email =>
                  onEmailThreadOpen({ highlightedEmailUrl: email.url })
                }
              />
            ) : null}
          </>
        )}
      </Stack>

      <div className={styles.UserPanel}>
        {brandName && (
          <Typography fontSize={13} sx={{ marginRight: 2 }} fontWeight="bold">
            {brandName}
          </Typography>
        )}
        {isTrial && !isEmbedded() && (
          <Box sx={{ marginRight: 2 }}>
            <CallToActionButton ctaLocation="annotation-screen" />
          </Box>
        )}
        <PoweredBy />
        <UserPanel />
      </div>
    </ControlBar>
  );
};

export default TopBar;

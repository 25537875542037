import { useMemo, useState } from 'react';
import { AddAttachmentsModal } from '../AddAttachmentsModal';

export const useAddAttachmentsModal = ({
  onClose,
}: {
  onClose?: () => void;
} = {}) => {
  const [rootAnnotationUrl, setRootAnnotationUrl] = useState<string | null>(
    null
  );

  const openModal = (url: string) => setRootAnnotationUrl(url);

  const node = useMemo(
    () => (
      <AddAttachmentsModal
        onClose={() => {
          setRootAnnotationUrl(null);
          onClose?.();
        }}
        rootAnnotationUrl={rootAnnotationUrl}
      />
    ),
    [onClose, rootAnnotationUrl]
  );

  return { node, openModal };
};

import {
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  format,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns';
import { isEqual } from 'lodash';
import {
  isRangedDateOperator,
  SelectedDates,
} from '../../../../document-list-base/mql/dateUtils';
import { FilterOperator } from '../../../../document-list-base/mql/operators';

export const getIsActive = (
  selectedDates: SelectedDates,
  shortcutDates: [Date, Date]
) => {
  const datesWithoutTime = selectedDates.flatMap(date =>
    date ? format(date, 'dd-MM-yyyy') : []
  );
  const shortcutDatesWithoutTime = shortcutDates.map(date =>
    format(date, 'dd-MM-yyyy')
  );

  return isEqual(datesWithoutTime, shortcutDatesWithoutTime);
};

export const getCustomFixedDateTime = (date: Date, isEndOfDay: boolean) =>
  isEndOfDay ? endOfDay(date) : startOfDay(date);

export const getCustomDateRangeTime = (
  dates: [Date, Date],
  isRangedDate: boolean
): [Date, Date] => {
  const from = dates[0];
  const to = dates[1];

  return [startOfDay(from), isRangedDate ? endOfDay(to) : startOfDay(to)];
};

export const getFixedDateShortcuts: (operator: FilterOperator['value']) => {
  labelKey: 'today' | 'yesterday';
  getValue: () => [Date, Date];
}[] = operator => [
  {
    labelKey: 'today',
    getValue: () => {
      const today = new Date();
      const isRangedDate = isRangedDateOperator(operator);
      const shouldBeEndOfDay =
        operator === 'after' || operator === 'onOrBefore';

      return shouldBeEndOfDay
        ? [endOfDay(today), endOfDay(today)]
        : getCustomDateRangeTime([today, today], isRangedDate);
    },
  },
  {
    labelKey: 'yesterday',
    getValue: () => {
      const today = new Date();
      const yesterday = subDays(today, 1);
      const isRangedDate = isRangedDateOperator(operator);
      const shouldBeEndOfDay =
        operator === 'after' || operator === 'onOrBefore';

      return shouldBeEndOfDay
        ? [endOfDay(yesterday), endOfDay(yesterday)]
        : getCustomDateRangeTime([yesterday, yesterday], isRangedDate);
    },
  },
];

export const rangedDateShortcuts = [
  {
    labelKey: 'thisWeek',
    getValue: () => {
      const today = new Date();
      return getCustomDateRangeTime(
        [startOfWeek(today), endOfWeek(today)],
        true
      );
    },
  },
  {
    labelKey: 'lastWeek',
    getValue: () => {
      const today = new Date();
      const lastWeekToday = subWeeks(today, 1);

      return getCustomDateRangeTime(
        [startOfWeek(lastWeekToday), endOfWeek(lastWeekToday)],
        true
      );
    },
  },
  {
    labelKey: 'thisMonth',
    getValue: () => {
      const today = new Date();

      return getCustomDateRangeTime(
        [startOfMonth(today), endOfMonth(today)],
        true
      );
    },
  },
  {
    labelKey: 'lastMonth',
    getValue: () => {
      const today = new Date();
      const lastMonthToday = subMonths(today, 1);

      return getCustomDateRangeTime(
        [startOfMonth(lastMonthToday), endOfMonth(lastMonthToday)],
        true
      );
    },
  },
  {
    labelKey: 'thisQuarter',
    getValue: () => {
      const today = new Date();

      return getCustomDateRangeTime([startOfQuarter(today), today], true);
    },
  },
  {
    labelKey: 'lastQuarter',
    getValue: () => {
      const today = new Date();

      const startLastQuarter = startOfQuarter(subMonths(today, 3));
      const endLastQuarter = endOfQuarter(subMonths(today, 3));

      return getCustomDateRangeTime([startLastQuarter, endLastQuarter], true);
    },
  },
  {
    labelKey: 'lastSevenDays',
    getValue: () => {
      const today = new Date();
      const from = subDays(today, 6);

      return getCustomDateRangeTime([from, today], true);
    },
  },
  {
    labelKey: 'lastThirtyDays',
    getValue: () => {
      const today = new Date();
      const from = subDays(today, 29);

      return getCustomDateRangeTime([from, today], true);
    },
  },
  {
    labelKey: 'lastNintyDays',
    getValue: () => {
      const today = new Date();
      const from = subDays(today, 89);

      return getCustomDateRangeTime([from, today], true);
    },
  },
] as const;

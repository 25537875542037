// Generated by ts-to-zod
import { z } from 'zod';
import { inboxSchema } from '../../inboxes/models/inbox.schema';

export const queueListQuerySchema = z.object({
  pageSize: z.number().optional(),
  page: z.number().optional(),
  ordering: z.array(z.string()).optional(),
  id: z.array(z.number()).optional(),
  deleting: z.boolean().optional(),
  name: z.string().optional(),
  dedicatedEngine: z.number().optional(),
  genericEngine: z.number().optional(),
  engine: z.number().optional(),
});

export const queueListSideloadSchema = z.object({
  inboxes: z.array(inboxSchema),
});

import { Control } from 'react-hook-form';
import { useIntl } from 'react-intl';
import TextFieldControl from '../../../components/ReactHookForm/controls/TextFieldControl';
import { ContentGroup } from '../../../ui/content-group/ContentGroup';
import { Paragraphs } from './Paragraphs';

type EngineFormProps = {
  control: Control<{
    engine: {
      name: string;
      description: string;
    };
  }>;
  disabled?: boolean;
};

export const EngineForm = ({ control, disabled }: EngineFormProps) => {
  const intl = useIntl();

  return (
    <ContentGroup
      title={intl.formatMessage({
        id: 'features.engines.engineDetail.basicInformation.title',
      })}
      description={
        <Paragraphs>
          {intl.formatMessage({
            id: 'features.engines.engineDetail.basicInformation.description',
          })}
        </Paragraphs>
      }
    >
      <TextFieldControl
        autoFocus
        disabled={disabled}
        ControllerProps={{ control, name: 'engine.name' }}
        label={intl.formatMessage({
          id: 'features.engines.engineDetail.basicInformation.field.name',
        })}
        FieldLabelProps={{
          layout: 'floating',
        }}
        size="medium"
      />
      <TextFieldControl
        disabled={disabled}
        ControllerProps={{ control, name: 'engine.description' }}
        label={intl.formatMessage({
          id: 'features.engines.engineDetail.basicInformation.field.description',
        })}
        FieldLabelProps={{
          layout: 'floating',
        }}
        size="medium"
      />
    </ContentGroup>
  );
};

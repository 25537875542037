import { z } from 'zod';
import { ID } from '../../utils/codecUtils';

export const getContent = (id: ID) => {
  return {
    endpoint: `/pages/${id}/content`,
    responseType: 'blob',
    method: 'GET',
  } as const;
};

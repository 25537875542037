// Generated by ts-to-zod
import { z } from 'zod';

export const engineCheckTemplateCompatibilityPayloadSchema = z.object({
  name: z.string(),
});

export const engineCheckTemplateCompatibilityResponseSchema = z.object({
  compatible: z.boolean(),
});

import { Relation } from '@rossum/api-client/relations';
import { isEmpty, negate } from 'lodash';
import {
  ActionsObservable,
  combineEpics,
  StateObservable,
} from 'redux-observable';
import { forkJoin } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { apiUrl } from '../../../constants/config';
import { isProperDuplicateRelation } from '../../../features/document-list-base/helpers/resolveAnnotationDuplicates';
import { getDuplicatesQuery } from '../../../features/duplicates/helpers';
import { errorHandler } from '../../../lib/api';
import { EpicDependencies } from '../../../lib/apiTypes';
import { Url } from '../../../types/basic';
import { State } from '../../../types/state';
import { RootActionType } from '../../rootActions';
import { fetchAnnotationFulfilled } from '../annotation/actions';
import { isActionOf } from '../utils';
import { fetchRelationsFulfilled, RelationData } from './actions';

const fetchRelationEpic = (
  action$: ActionsObservable<RootActionType>,
  _state$: StateObservable<State>,
  { authGetJSON$ }: EpicDependencies
) =>
  action$.pipe(
    filter(isActionOf(fetchAnnotationFulfilled)),
    map(({ payload: { annotation } }) => annotation),
    filter(annotation => negate(isEmpty)(annotation.relations)),
    switchMap(annotation =>
      forkJoin(
        annotation.relations.map((relation: Url) =>
          authGetJSON$<Relation>(relation)
        )
      ).pipe(map(relations => ({ relations, annotation })))
    ),
    map(({ relations, annotation }) =>
      relations.filter(isProperDuplicateRelation(annotation.url))
    ),
    filter(relations => relations.length > 0),
    switchMap(relations => {
      const query = getDuplicatesQuery(relations);
      return authGetJSON$<RelationData>(`${apiUrl}/annotations`, {
        query,
      }).pipe(
        map(response =>
          fetchRelationsFulfilled(
            response,
            relations.map(r => r.url)
          )
        ),
        catchError(errorHandler)
      );
    })
  );

export default combineEpics(fetchRelationEpic);

const SCHEMAS_PARAM_KEY = 'schemaId';
const PARENTS_PARAM_KEY = 'parentId';

export type FieldManagerCreateFieldParams = {
  [SCHEMAS_PARAM_KEY]: string;
  [PARENTS_PARAM_KEY]: string;
};

export const fieldManagerPathBase = () => '/field-manager' as const;

export const fieldManagerPath = () =>
  `/settings${fieldManagerPathBase()}` as const;

export const fieldDetailRoute = () =>
  `${fieldManagerPath()}/detail/:${SCHEMAS_PARAM_KEY}`;

export const createFieldRoute = () =>
  `${fieldManagerPath()}/schema/:${SCHEMAS_PARAM_KEY}/parent/:${PARENTS_PARAM_KEY}/create-field`;

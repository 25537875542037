import { ID, Url } from '@rossum/api-client';

type StateOnWorkspace<A> = {
  action: A;
  workspaceId: ID;
  workspaceUrl: Url;
  queueId?: number;
  queueName?: string;
};

type SidebarEditState = StateOnWorkspace<'editing'>;
type SidebarDeletingState = StateOnWorkspace<'deleting'>;
type SidebarAddingState = StateOnWorkspace<'adding'>;

type SidebarMoveQueueState = {
  action: 'moveQueue';
  queueId: number;
  targetWorkspaceUrl?: string;
};

export type SidebarDropdownState = StateOnWorkspace<'dropdown'> & {
  anchorEl: Element;
};

export const sidebarIdleState = {
  action: 'idle',
} as const;

type SidebarDuplicateQueueState = {
  action: 'duplicate';
  queueId: number;
};

type SidebarIdleState = typeof sidebarIdleState;

export type SidebarState =
  | SidebarEditState
  | SidebarDeletingState
  | SidebarDropdownState
  | SidebarMoveQueueState
  | SidebarIdleState
  | SidebarDuplicateQueueState
  | SidebarAddingState;

import { Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { DocumentModifier } from '../../document-list-base/helpers/resolveModifierFromUrl';
import { CellProps } from '../types';
import { getModifierLabel } from './helpers';

type RenderUserCellProps = CellProps<DocumentModifier>;

const RenderUser = (props: RenderUserCellProps) => {
  const intl = useIntl();
  const { value } = props;

  return (
    <Typography
      component="div"
      sx={{
        fontSize: 'inherit',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '100%',
      }}
    >
      {!value || value === 'none'
        ? ''
        : value === 'unknown'
          ? intl.formatMessage({
              id: `containers.documents.unknownModifier`,
            })
          : getModifierLabel(value)}
    </Typography>
  );
};

export default RenderUser;

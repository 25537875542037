// I created this /utils folder to start collecting all general, reusable 'utils' functions
// that could be reusable across apps

import { useMemo } from 'react';
import { jsonParse } from '../jsonParse';

/** Tries parsing `jsonString`, returns `defaultValue` if it fails. Memoized. */
export const useJsonParse = <O = unknown>(
  jsonString: string,
  defaultValue: O | null = null,
  reviver?: Parameters<typeof JSON.parse>[1]
) =>
  useMemo<readonly [O | null, 'success' | 'error']>(() => {
    return jsonParse(jsonString, defaultValue, reviver);
  }, [jsonString, defaultValue, reviver]);

/** Tries stringifying `jsonObject`, returns `defaultValue` if it fails. Memoized */
const useJsonStringify = (
  jsonObject: unknown,
  defaultValue = '',
  replacer?: Parameters<typeof JSON.stringify>[1],
  space?: string | number
) =>
  useMemo<readonly [string, 'success' | 'error']>(() => {
    try {
      return [JSON.stringify(jsonObject, replacer, space), 'success'] as const;
    } catch (_e) {
      return [defaultValue, 'error'] as const;
    }
  }, [jsonObject, replacer, space, defaultValue]);

// alternative to JSON.stringify(JSON.parse())
export const usePrettyJsonString = (jsonString: string) =>
  useJsonStringify(useJsonParse(jsonString)[0], '', undefined, 2);

import { Button, Popover } from '@rossum/ui/material';
import { ReactNode, useCallback, useState } from 'react';
import { IAceEditor } from 'react-ace/lib/types';
import { useIntl } from 'react-intl';
import { handleInsertion, OnInsertCallback } from './insertion-helpers';

const SUGGESTER_WIDTH = 400;
const SUGGESTER_MAX_HEIGHT = 465;

const TemplateSuggesterButton = ({
  editor,
  disabled,
  renderContent,
}: {
  editor: IAceEditor;
  disabled: boolean;
  renderContent: (params: { onInsert: OnInsertCallback }) => ReactNode;
}) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onInsert = useCallback<OnInsertCallback>(
    (value, meta) => {
      handleInsertion({ value, meta, editor });

      setAnchorEl(null);

      // need to use setTimeout because closing the menu
      // steals focus from editor back to the button
      setTimeout(() => {
        editor.focus();
      });
    },
    [editor]
  );

  return (
    <>
      <Button
        color="secondary"
        onClick={e => setAnchorEl(e.currentTarget)}
        data-cy="template-suggester-button"
        disabled={disabled}
      >
        {intl.formatMessage({
          id: 'features.queueSettings.fields.formulaEditor.insertField',
        })}
      </Button>
      <Popover
        open={!!anchorEl}
        onClose={onClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorEl={anchorEl}
        slotProps={{
          paper: {
            elevation: 4,
            sx: {
              mt: 0.5,
              width: SUGGESTER_WIDTH,
              maxHeight: SUGGESTER_MAX_HEIGHT,
            },
          },
        }}
      >
        {renderContent({ onInsert })}
      </Popover>
    </>
  );
};

export default TemplateSuggesterButton;

import { get } from 'lodash';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { currentTagVersion, isEmbedded } from '../constants/config';
import {
  hasBrandingSelector,
  hideArticleLinksSelector,
  isTrialSelector,
  organizationSelector,
  supportMenuItemsSelector,
} from '../redux/modules/organization/selectors';
import { organizationGroupSelector } from '../redux/modules/organizationGroup/selectors';
import {
  userRoleNameSelector,
  userSelector,
} from '../redux/modules/user/selectors';

const initBeamer = () => {
  const beamer = document.createElement('script');
  beamer.type = 'text/javascript';
  beamer.src = 'https://app.getbeamer.com/js/beamer-embed.js';
  beamer.defer = true;
  document.body.appendChild(beamer);
};

const BeamerContext = createContext<{ isBeamerEnabled: boolean }>({
  isBeamerEnabled: false,
});

type Props = {
  children: ReactNode;
};

export const BeamerProvider = ({ children }: Props) => {
  const [isInitialised, setIsInitialised] = useState<boolean>(false);

  const userRole = useSelector(userRoleNameSelector);
  const hasBranding = useSelector(hasBrandingSelector);
  const user = useSelector(userSelector);
  const organization = useSelector(organizationSelector);
  const isTrial = useSelector(isTrialSelector);
  const hideArticleLinks = useSelector(hideArticleLinksSelector);
  const hasSupportMenuItems = !!useSelector(supportMenuItemsSelector);
  const organizationGroup = useSelector(organizationGroupSelector);

  const hideWhenBranding = organization.id
    ? !get(organization, 'uiSettings.branding.poweredBy', true) ||
      hasSupportMenuItems ||
      organization.uiSettings?.branding?.hideArticleLinks
    : true;

  const isBeamerEnabled = !isEmbedded() && !hideWhenBranding;

  const isOrgCreator = organization.creator === user.url;

  useEffect(() => {
    if (!isInitialised && isBeamerEnabled) {
      initBeamer();
      setIsInitialised(true);
    }
  }, [isInitialised, isBeamerEnabled]);

  useEffect(() => {
    if (isBeamerEnabled && user.id !== -1) {
      window.Beamer?.update({
        user_id: user.id,
        user_firstname: user.firstName,
        user_lastname: user.lastName,
        // Custom user attributes
        user_role: userRole,
        date_joined: user.dateJoined || '',
        last_login: user.lastLogin || '',
        user_theme: user.uiSettings.theme ?? 'dark',
        user_localization: user.uiSettings.locale,
        is_org_creator: isOrgCreator,
      });
    }
  }, [userRole, isOrgCreator, isBeamerEnabled, user]);

  useEffect(() => {
    if (isBeamerEnabled && organization.id)
      window.Beamer?.update({
        org_id: organization.id,
        org_theme: organization.uiSettings?.theme,
        is_trial: isTrial,
        is_embedded: isEmbedded(),
        app_version: currentTagVersion,
        trial_expires_at: organization.trialExpiresAt,
        ui_branding: hasBranding,
        ui_hide_article_links: !!hideArticleLinks,
        ui_support_menu: hasSupportMenuItems,
        ui_custom_colors: !!organization.uiSettings?.branding?.colors,
        created_at: organization.createdAt,
        cs_account_classification:
          organization.internalInfo?.csAccountClassification,
      });
  }, [
    hasBranding,
    hasSupportMenuItems,
    hideArticleLinks,
    isTrial,
    organization,
    isBeamerEnabled,
  ]);

  useEffect(() => {
    if (isBeamerEnabled && organizationGroup) {
      window.Beamer?.update({
        is_production: organizationGroup.isProduction,
        is_trial: organizationGroup.isTrial,
        deployment_identifier: organizationGroup.deploymentLocation,
        formula_fields_enabled:
          !!organizationGroup?.features?.formulaFields?.enabled,
      });
    }
  }, [organizationGroup, isBeamerEnabled]);

  return (
    <BeamerContext.Provider value={{ isBeamerEnabled }}>
      {children}
    </BeamerContext.Provider>
  );
};

export const useBeamerContext = () => useContext(BeamerContext);

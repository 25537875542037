import { IntlShape } from 'react-intl';
import * as z from 'zod';
import {
  minLessThanMaxMessage,
  noExactAndConstrainedLengthMessage,
} from '../errors/errorMessages';

type TypedRefinement<T, R> = (arg: T, ctx: z.RefinementCtx) => R;

export const stringFieldValidations =
  <
    T extends {
      minLength?: number | null;
      maxLength?: number | null;
      exactLength?: number | null;
    },
  >(
    intl: IntlShape
  ): TypedRefinement<T, boolean> =>
  ({ minLength, maxLength, exactLength }, ctx) => {
    const minLengthDefined = typeof minLength === 'number';
    const maxLengthDefined = typeof maxLength === 'number';
    const exactLengthDefined = typeof exactLength === 'number';

    if (exactLengthDefined && (minLengthDefined || maxLengthDefined)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        fatal: true,
        path: ['exactLength'],
        message: noExactAndConstrainedLengthMessage(intl)(
          intl.formatMessage({
            id: 'features.queueSettings.fields.form.exactLength.label',
          }),
          intl.formatMessage({
            id: 'features.queueSettings.fields.form.minLength.label',
          }),
          intl.formatMessage({
            id: 'features.queueSettings.fields.form.maxLength.label',
          })
        ),
      });

      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        fatal: true,
        path: ['minLength'],
        message: noExactAndConstrainedLengthMessage(intl)(
          intl.formatMessage({
            id: 'features.queueSettings.fields.form.exactLength.label',
          }),
          intl.formatMessage({
            id: 'features.queueSettings.fields.form.minLength.label',
          }),
          intl.formatMessage({
            id: 'features.queueSettings.fields.form.maxLength.label',
          })
        ),
      });

      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        fatal: true,
        path: ['maxLength'],
        message: noExactAndConstrainedLengthMessage(intl)(
          intl.formatMessage({
            id: 'features.queueSettings.fields.form.exactLength.label',
          }),
          intl.formatMessage({
            id: 'features.queueSettings.fields.form.minLength.label',
          }),
          intl.formatMessage({
            id: 'features.queueSettings.fields.form.maxLength.label',
          })
        ),
      });

      return false;
    }
    if (minLengthDefined && maxLengthDefined) {
      if (minLength > maxLength) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          fatal: true,
          path: ['minLength'],
          message: minLessThanMaxMessage(intl)(
            intl.formatMessage({
              id: 'features.queueSettings.fields.form.minLength.label',
            }),
            intl.formatMessage({
              id: 'features.queueSettings.fields.form.maxLength.label',
            })
          ),
        });
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          fatal: true,
          path: ['maxLength'],
          message: minLessThanMaxMessage(intl)(
            intl.formatMessage({
              id: 'features.queueSettings.fields.form.minLength.label',
            }),
            intl.formatMessage({
              id: 'features.queueSettings.fields.form.maxLength.label',
            })
          ),
        });

        return false;
      }
    }
    return true;
  };

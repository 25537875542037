// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../types.schema';
import { multilineSchema } from '../models/engineFieldType.schema';

export const engineFieldCreatePayloadSchema = z.object({
  name: z.string(),
  engine: urlSchema,
  label: z.string(),
  type: z.string(),
  subtype: z.string().optional().nullable(),
  preTrainedFieldId: z.string().optional().nullable(),
  tabular: z.boolean().optional(),
  multiline: multilineSchema.optional(),
});

import { get } from 'lodash';
import { connect } from 'react-redux';
import { RefTrackerProps } from '../../decorators/DatapointRefTracker';
import { getIDFromString } from '../../lib/url';
import { datapointBlockerSelector } from '../../redux/modules/annotation/selectors';
import { DatapointAutomationBlocker } from '../../redux/modules/annotation/types';
import {
  datapointPathSelector,
  validationInProgressSelector,
} from '../../redux/modules/datapoints/selector';
import {
  MatchedTriggerRuleDatapoint,
  SidebarDatapointST,
} from '../../types/datapoints';
import { State as StateT } from '../../types/state';
import Datapoint, { OwnProps } from './index';

type Props = Omit<OwnProps, keyof ConnectedProps> & RefTrackerProps;

type ConnectedProps = {
  active: boolean;
  automationBlockers: DatapointAutomationBlocker[];
  childSelected: boolean;
  currentQueue: number;
  data: SidebarDatapointST;
  documentAutomated: boolean;
  matchedRules: MatchedTriggerRuleDatapoint | undefined;
  hideCapturedSoftWarning: boolean;
  hideDataSoftWarning: boolean;
  formulaValidationInProgress: boolean;
};

const mapStateToProps = (state: StateT, props: Props): ConnectedProps => {
  const data = state.datapoints.content[
    // nobody cares
    props.datapointIndex!
  ] as SidebarDatapointST;
  const currentQueue = state.annotation.queue
    ? getIDFromString(state.annotation.queue)
    : undefined;

  const automationBlockers = datapointBlockerSelector(state)[data.schemaId];

  return {
    active: datapointPathSelector(state).includes(get(data, 'id')),
    childSelected: datapointPathSelector(state).length > 2,
    matchedRules:
      state.datapoints.matchedTriggerRules.datapointLevel[data.id]?.[0],
    data,
    // on validation screen you definitely have a queue
    currentQueue: currentQueue!,
    automationBlockers,
    documentAutomated: state.annotation.automated ?? false,
    hideCapturedSoftWarning:
      state.user.uiSettings.hideWarningEditableFields?.captured ?? false,
    hideDataSoftWarning:
      state.user.uiSettings.hideWarningEditableFields?.data ?? false,
    formulaValidationInProgress:
      validationInProgressSelector(state) &&
      state.datapoints.loadingDatapointIds.includes(data.id),
  };
};

const areStatePropsEqual = (next: ConnectedProps, prev: ConnectedProps) =>
  !next.active &&
  !(prev.active && !next.active) &&
  prev.data.meta.isValidated === next.data.meta.isValidated &&
  prev.data.meta.isHumanValidated === next.data.meta.isHumanValidated &&
  prev.currentQueue === next.currentQueue &&
  prev.documentAutomated === next.documentAutomated;

export default connect(mapStateToProps, null, null, { areStatePropsEqual })(
  Datapoint
);

import { alpha, Drawer, Stack } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { NoPreviewState } from './NoPreviewState';
import { PagePreview } from './PagePreview';

type PagePreviewDrawerProps = {
  previewImage: string | null | undefined;
  originalFileName: string | undefined;
  pageCount: number;
  isNested?: boolean;
  errorStatusCode?: number;
};

const PREVIEW_DRAWER_WIDTH = 400;

const PagePreviewDrawer = ({
  previewImage,
  originalFileName,
  pageCount,
  isNested,
  errorStatusCode,
}: PagePreviewDrawerProps) => {
  const intl = useIntl();

  return (
    <Drawer
      sx={{
        position: 'static',
        pointerEvents: 'none',
      }}
      hideBackdrop
      PaperProps={{
        elevation: 2,
        sx: {
          right: isNested ? 800 : undefined,
          backgroundColor: t => alpha(t.palette.background.default, 0.9),
          justifyContent: 'center',
        },
      }}
      open
    >
      <Stack
        direction="column"
        alignItems="center"
        px={2}
        spacing={1}
        sx={{ width: PREVIEW_DRAWER_WIDTH, maxWidth: '100%' }}
      >
        {errorStatusCode === 500 ? (
          <NoPreviewState
            title={intl.formatMessage({
              id: 'features.documentList.preview.error.title',
            })}
            intl={intl}
          />
        ) : (
          <PagePreview
            previewImage={previewImage}
            originalFileName={originalFileName}
            pageCount={pageCount}
            intl={intl}
          />
        )}
      </Stack>
    </Drawer>
  );
};

export default PagePreviewDrawer;

import { Survey } from '@rossum/api-client/surveys';
import { CheckCircleOutline } from '@rossum/ui/icons';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../../redux/modules/user/selectors';
import DialogTitle from '../../../../ui/dialog-title/DialogTitle';
import { useSurveyActions } from '../hooks/useSurveyActions';

export type ResearchCallSurveyDialogProps = {
  survey: Survey;
  open: boolean;
  surveyPlacement: 'statistics' | 'documents' | 'queueSettings' | 'extensions';
};

export const ResearchCallSurveyDialog = ({
  survey,
  open,
  surveyPlacement,
}: ResearchCallSurveyDialogProps) => {
  const intl = useIntl();

  const { increaseSkipCount, answer } = useSurveyActions(
    survey,
    surveyPlacement
  );

  const handleSkip = () => {
    increaseSkipCount().catch(() => {});
  };

  // used to overwrite blockers like "answered" or "too_frequent" after submission and keep dialog open
  const [submitted, setSubmitted] = useState(false);
  // needed to keep submitted state content while closing the dialog
  const [closing, setClosing] = useState(false);

  const handleSubmit = () => {
    answer([{ value: email }]).then(() => {
      setSubmitted(true);
    });
  };

  const user = useSelector(userSelector);
  const [email, setEmail] = useState<string>(user.email);

  const isOpen = closing ? false : submitted || open;

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        elevation: 2,
        sx: {
          width: 490,
        },
      }}
    >
      <DialogTitle
        title={intl.formatMessage({
          id: `components.surveys.dataCaptureSurvey.questions.researchCall.${surveyPlacement}.title`,
        })}
      />

      <DialogContent>
        {submitted ? (
          <Stack sx={{ py: 3 }} alignItems="center" spacing={2}>
            <CheckCircleOutline sx={{ fontSize: 64 }} />
            <Stack spacing={1} alignItems="center">
              <Typography variant="h5">
                {intl.formatMessage({
                  id: 'components.surveys.dataCaptureSurvey.questions.researchCall.success.title',
                })}
              </Typography>
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'components.surveys.dataCaptureSurvey.questions.researchCall.success.text',
                })}
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <Stack sx={{ pt: 2 }} spacing={2}>
            <Typography variant="body2">
              {intl.formatMessage({
                id: 'components.surveys.dataCaptureSurvey.questions.researchCall.text_1',
              })}
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              {intl.formatMessage({
                id: 'components.surveys.dataCaptureSurvey.questions.researchCall.text_2',
              })}
            </Typography>
            <Stack>
              <TextField
                sx={{ width: '100%', mt: 1 }}
                multiline
                variant="outlined"
                value={email}
                label={intl.formatMessage({
                  id: 'components.surveys.dataCaptureSurvey.questions.researchCall.emailInput',
                })}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => setEmail(e.currentTarget.value)}
              />
            </Stack>
          </Stack>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 3, pt: 1 }}>
        {submitted ? (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setClosing(true)}
            data-cy={`${surveyPlacement}-close`}
          >
            {intl.formatMessage({
              id: 'components.surveys.dataCaptureSurvey.buttons.close',
            })}
          </Button>
        ) : (
          <>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleSkip}
              data-cy={`${surveyPlacement}-skip`}
            >
              {intl.formatMessage({
                id: 'components.surveys.dataCaptureSurvey.questions.researchCall.skip',
              })}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              data-cy={`${surveyPlacement}-submit`}
            >
              {intl.formatMessage({
                id: 'components.surveys.dataCaptureSurvey.questions.researchCall.submit',
              })}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

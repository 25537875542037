import { Queue } from '@rossum/api-client/queues';
import { SchemaSection } from '@rossum/api-client/schemas';
import { useState } from 'react';
import { Control, useController, useWatch } from 'react-hook-form';
import { FormulaFieldSection } from '../../../../formulas/FormulaFieldSection/FormulaFieldSection';
import FormulaFieldsSurvey from '../../../../surveys/FieldsSurveys/FormulaFieldsSurvey';
import { FieldsFormValues } from '../../form-model/formModels';

type FormulaSectionProps = {
  control: Control<FieldsFormValues> | undefined;
  queues: Queue[];
  schemaContent: SchemaSection[];
};

export const FormulaSection = ({
  control,
  queues,
  schemaContent,
}: FormulaSectionProps) => {
  const formulaController = useController({ control, name: 'field.formula' });

  const formulaSchemaId = useWatch({ control, name: 'field.id' });
  const formulaLabel = useWatch({ control, name: 'field.label' });
  const [focused, setFocused] = useState(false);

  return (
    <>
      <FormulaFieldsSurvey focused={focused} />
      <FormulaFieldSection
        schemaContent={schemaContent}
        queueUrls={queues.map(queue => queue.url)}
        formulaSchemaId={formulaSchemaId}
        formula={formulaController.field.value}
        setFormula={v => {
          setFocused(true);
          formulaController.field.onChange(v);
        }}
        disabled={!formulaSchemaId || !formulaLabel}
      />
    </>
  );
};

import { endpoints } from '@rossum/api-client';
import { SchemaSection } from '@rossum/api-client/schemas';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../../../lib/apiClient';

export const useFormulaSummary = (
  payload:
    | {
        schemaContent: SchemaSection[];
        fieldSchemaId: string;
      }
    | undefined,
  { enabled }: { enabled: boolean }
) => {
  const api = useApiClient();
  return useQuery({
    queryKey: ['formula-summary', payload] as const,
    queryFn: () =>
      api.request(endpoints.internal.schemas.formulaSummary(payload!)),
    enabled: payload !== undefined && enabled,
    select: data => data?.results[0]?.summary,
  });
};

import { Url } from '@rossum/api-client';
import { AnnotationStatus } from '@rossum/api-client/shared';
import { SnakeToCamel } from '@rossum/api-client/utils';
import { GridRenderCellParams } from '@rossum/ui/x-data-grid-pro';
import { useSelector } from 'react-redux';
import { isStatusVisible } from '../../../containers/AnnotationList/types';
import { State } from '../../../types/state';
import { RequestDetailChip } from '../../../ui/status/RequestDetailChip';
import StatusChip from '../../../ui/status/StatusChip';
import { useWorkflowStepNames } from '../hooks/useWorkflowStepNames';

const RenderStatus = (
  props: GridRenderCellParams<
    {
      modifier:
        | {
            id: number;
            username: string;
          }
        | string;
      id: number;
      url: Url;
    },
    SnakeToCamel<AnnotationStatus>
  >
) => {
  const {
    value: status,
    row: { modifier, id, url },
  } = props;

  const userId = useSelector((state: State) => state.user.id);

  const { data: workflowStepNames } = useWorkflowStepNames(id, status);

  const stepName =
    status === 'inWorkflow' && workflowStepNames?.stepNames
      ? workflowStepNames.stepNames?.[url]
      : undefined;

  const isValidModifer = typeof modifier !== 'string';

  const isOwned = isValidModifer ? modifier.id === userId : false;

  if (status === undefined || !isStatusVisible(status)) {
    return null;
  }

  return stepName && status === 'inWorkflow' ? (
    <RequestDetailChip name={stepName} status="progress" />
  ) : (
    <StatusChip
      status={status}
      isOwned={isOwned}
      modifier={isValidModifer ? modifier.username : undefined}
    />
  );
};

export default RenderStatus;

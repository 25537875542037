import apiAxios from 'axios';
import { z } from 'zod';
import { annotations } from './src/annotations/endpoints';
import { authentication } from './src/authentication/endpoints';
import { automationBlockers } from './src/automationBlockers/endpoints';
import { connectors } from './src/connectors/endpoints';
import { dedicatedEngines } from './src/dedicatedEngines/endpoints';
import { engineSchemas } from './src/dedicatedEngineSchema/endpoints';
import { deleteRecommendations } from './src/deleteRecommendations/endpoints';
import { documents } from './src/documents/endpoints';
import { email } from './src/email/endpoints';
import { emailTemplates } from './src/emailTemplates/endpoints';
import { emailTemplatesStats } from './src/emailTemplatesStats/endpoints';
import { emailThreads } from './src/emailThreads/endpoints';
import { genericEngines } from './src/genericEngines/endpoints';
import { groups } from './src/groups/endpoints';
import { hooks } from './src/hooks/endpoints';
import { hookTemplates } from './src/hookTemplates/endpoints';
import { inboxes } from './src/inboxes/endpoints';
import { internal } from './src/internal/endpoints';
import { labels } from './src/labels/endpoints';
import { memberships } from './src/memberships/endpoints';
import { organization } from './src/organization/endpoints';
import { organizationGroups } from './src/organizationGroups/endpoints';
import { pages } from './src/pages/endpoints';
import { queues } from './src/queues/endpoints';
import { relations } from './src/relations/endpoints';
import { schemaFields } from './src/schemaFields/endpoints/index';
import { schemas } from './src/schemas/endpoints';
import { suggestedEdit } from './src/suggestedEdit/endpoints';
import { surveys } from './src/surveys/endpoints';
import { tasks } from './src/tasks/endpoints';
import { triggers } from './src/triggers/endpoints';
import { users } from './src/users/endpoints';
import { ElisClientConfig } from './src/utils/client';
import { PaginationQuery } from './src/utils/listQuery';
import { ListResponse } from './src/utils/listResponse';
import { fullRequest, request } from './src/utils/request';
import { ElisRequestConfig } from './src/utils/requestConfig';
import { unpaginated } from './src/utils/unpaginated';
import { workflowActivities } from './src/workflowActivities/endpoints';
import { workflowRuns } from './src/workflowRuns/endpoints';
import { workflows } from './src/workflows/endpoints';
import { workflowSteps } from './src/workflowSteps/endpoints';
import { workspaces } from './src/workspaces/endpoints';
import { uploads } from './src/uploads/endpoints';
import { engines } from './src/engines/endpoints';
import { engineFields } from './src/engineFields/endpoints';

export const axios = apiAxios;

export { ID, Url, getIDFromUrl } from './src/utils/codecUtils';
export { getEtag, loadEtag } from './src/utils/etag';
export type { WithEtag } from './src/utils/etag';

export { withFields } from './src/utils/withFields';
export { withSideload } from './src/utils/withSideload';

export const createElisClient = (config: ElisClientConfig) => ({
  request: <R = never, Q = never, P = never>(
    endpoint: ElisRequestConfig<R, Q, P>,
    configOverride: Partial<ElisClientConfig> = {}
  ) => request<R, Q, P>({ ...config, ...configOverride }, endpoint),
  fullRequest: <R = never, Q = never, P = never>(
    endpoint: ElisRequestConfig<R, Q, P>,
    configOverride: Partial<ElisClientConfig> = {}
  ) => fullRequest<R, Q, P>({ ...config, ...configOverride }, endpoint),
  // TODO: Refactor unpaginated function using endpoints, not endpoint functions.
  unpaginatedRequest: <Args, R = never, Q = never, P = never>(
    endpointArgs: (
      query: Partial<Args & PaginationQuery>
    ) => ElisRequestConfig<ListResponse<R>, Q, P>
  ) =>
    unpaginated((query: Partial<Args & PaginationQuery>) =>
      request<ListResponse<R>, Q, P>(config, endpointArgs(query))
    ),
});

export const unknownSchema = z.unknown();

// Typescript is very sad when the types are too big.
// And does not have to have the whole B/E api schema in one declaration file.
// “The inferred type of this node exceeds the maximum length the compiler will serialize. An explicit type annotation is needed.(7056)”
// Splitting our endpoints object in more parts works.

const endpoints1 = {
  annotations,
  authentication,
  automationBlockers,
  connectors,
  dedicatedEngines,
  deleteRecommendations,
  documents,
  email,
  emailTemplates,
  emailTemplatesStats,
  emailThreads,
  engines,
  engineFields,
  engineSchemas,
  genericEngines,
  groups,
};

const endpoints2 = {
  hooks,
  hookTemplates,
  inboxes,
  labels,
  memberships,
  // TODO: Should be renamed to `organizations`
};

const endpoints3 = {
  organization,
  organizationGroups,
  pages,
  relations,
  queues,
};

const endpoints4 = {
  schemaFields,
  suggestedEdit,
  surveys,
  tasks,
  triggers,
  users,
  uploads,
  workflowActivities,
  workflowRuns,
  workflows,
  workflowSteps,
  workspaces,
};

const internalEndpoints = {
  internal,
};

type InternalEndpoints = { internal: typeof internal };
type Endpoints1 = typeof endpoints1;
type Endpoints2 = typeof endpoints2;
type Endpoints3 = typeof endpoints3;
type Endpoints4 = typeof endpoints4;
type SchemaEndpoints = { schemas: typeof schemas };

export const endpoints: Endpoints1 &
  Endpoints2 &
  Endpoints3 &
  Endpoints4 &
  SchemaEndpoints &
  InternalEndpoints = {
  ...endpoints1,
  ...endpoints2,
  ...endpoints3,
  ...endpoints4,
  ...internalEndpoints,
  schemas,
};

import { SchemaSection } from '../../../schemas/models/schemaSection';
import {
  formulaSummaryPayloadSchema,
  formulaSummaryResponseSchema,
} from './formulaSummary.schema';

export type FormulaSummaryPayload = {
  schemaContent: SchemaSection[];
  fieldSchemaId: string;
};

export type FormulaSummaryResult = {
  summary: string;
};

export type FormulaSummaryResponse = {
  results: Array<FormulaSummaryResult>;
};

export const formulaSummary = (payload: FormulaSummaryPayload) =>
  ({
    endpoint: `internal/schemas/formula_summary`,
    method: 'POST',
    responseSchema: formulaSummaryResponseSchema,
    payloadSchema: formulaSummaryPayloadSchema,
    payload,
  }) as const;

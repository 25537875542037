import { Check } from '@rossum/ui/icons';
import {
  Skeleton,
  Slide,
  Stack,
  Typography,
  useTheme,
} from '@rossum/ui/material';
import { GridRenderCellParams, GridRowModel } from '@rossum/ui/x-data-grid-pro';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { AuroraIcon } from '../../../ui/aurora/Icons';
import { useFeatureFlag } from '../../../unleash/useFeatureFlag';
import { appendSchemaColumns } from '../helpers';
import { useHeatmapColor } from './hooks/useHeatmapColor';

type DatapointFieldData = {
  annotationId: number;
  datapointId: number;
  value: string | null | undefined;
  validationSources: undefined | string[];
  confidenceScore: number | null | undefined;
};

const RenderDatapointField = (
  props: GridRenderCellParams<
    GridRowModel<ReturnType<typeof appendSchemaColumns>>,
    DatapointFieldData
  >
) => {
  const {
    value: {
      annotationId,
      datapointId,
      value = '',
      validationSources = [],
      confidenceScore = null,
    } = {
      annotationId: -1,
      datapointId: -1,
      value: '',
      validationSources: [],
      confidenceScore: null,
    },
    colDef,
    row,
  } = props;

  const theme = useTheme();

  const history = useHistory();

  const confidenceIndicatorEnabled = useFeatureFlag(
    'ac-4336-confidence-indicator'
  );

  const confidenceColor = useHeatmapColor(
    confidenceScore ?? 0,
    validationSources
  );

  const checkmarkColor = validationSources.includes('human')
    ? theme.palette.success.light
    : theme.palette.aurora.light;

  const Icon = validationSources.includes('human')
    ? Check
    : value
      ? AuroraIcon
      : null;

  const shouldShowScore =
    !!value &&
    typeof confidenceScore === 'number' &&
    confidenceScore > 0 &&
    !validationSources.includes('human');

  const oldValue = useRef(value);

  const oldConfidence = useRef(confidenceScore);

  const [inTransition, setInTransition] = useState(false);

  useEffect(() => {
    if (
      oldValue.current !== value ||
      oldConfidence.current !== confidenceScore
    ) {
      oldValue.current = value;
      oldConfidence.current = confidenceScore;
      setInTransition(true);
      setTimeout(() => setInTransition(false), 200);
    }
  }, [confidenceScore, value]);

  const skeletonWidth = useRef(Math.random() * 30 + 80);

  if (row.restricted_access)
    // no need to translate this text here as it is not inteded to be seen
    return (
      <Stack sx={{ filter: 'blur(3px)' }}>
        <Typography>Restricted</Typography>
      </Stack>
    );

  if (!row.datapointsReady) {
    return <Skeleton width={skeletonWidth.current} />;
  }

  if (!confidenceIndicatorEnabled) {
    return <span>{value ?? ''}</span>;
  }

  return (
    <Stack
      sx={{
        cursor: 'pointer',
        position: 'relative',
        height: '100%',
        width: '100%',
        mx: 1,
        ...(inTransition
          ? {
              backgroundColor: theme => theme.palette.action.selected,
              transition: 'background-color 0.2s ease-in-out',
            }
          : {}),
      }}
    >
      <Stack
        direction={colDef.type === 'number' ? 'row-reverse' : 'row'}
        spacing={2}
        alignItems="center"
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
          color: checkmarkColor,
        }}
        onClick={() => {
          history.push(
            `/document/${annotationId}?datapointPath=${datapointId}`
          );
        }}
      >
        {Icon && <Icon fontSize="small" />}
        <Stack direction="column" sx={{ color: confidenceColor }}>
          <Slide
            in={!!value}
            direction="right"
            appear
            unmountOnExit
            key={value}
          >
            <Typography variant="body2" color="text.primary">
              {value}
            </Typography>
          </Slide>
          <Slide
            in={shouldShowScore}
            key={confidenceScore}
            appear
            unmountOnExit
          >
            <Typography
              variant="caption"
              textAlign={colDef.type === 'number' ? 'right' : 'left'}
            >
              {`${Math.floor((confidenceScore ?? 0) * 100)}%`}
            </Typography>
          </Slide>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default RenderDatapointField;

import {
  Collapse,
  Dialog,
  DialogContent,
  List,
  Stack,
} from '@rossum/ui/material';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { prop, sortBy } from 'remeda';
import DialogTitle from '../../ui/dialog-title/DialogTitle';
import { AnnotationProgress } from './AnnotationProgress';
import { AsyncTaskProgress } from './AsyncTaskProgress';
import { classifyTasks } from './helpers/classifyTasks';
import {
  getTaskDialogCounter,
  getTaskDialogTitle,
} from './helpers/getTaskDialogTitle';
import { useTaskContext } from './TaskContext';
import { UploadsTaskProgress } from './UploadsTaskProgress';
import { useImportingAnnotations } from './useImportingAnnotations';

export const TasksDialog = () => {
  const intl = useIntl();
  const {
    tasks: { asyncTasks, uploadsTasks, importTasks },
    clearAllTasks,
  } = useTaskContext();

  const {
    childAnnotationsQuery: { data: childAnnotations },
    uploadedAnnotationsQuery: { data: uploadedAnnotations },
  } = useImportingAnnotations({
    annotationUrls: importTasks.map(importTask => importTask.annotationUrl),
  });

  const [dialogCollapsed, setDialogCollapsed] = useState(false);

  const allTasks = useMemo(() => {
    return sortBy(
      [
        ...(childAnnotations ?? []).map(
          annotationInfo =>
            ({
              type: 'childAnnotation',
              key: `annotation-${annotationInfo.annotationUrl}`,
              taskData: annotationInfo,
              name: annotationInfo.fileName?.toLowerCase() ?? '-',
            }) as const
        ),
        ...importTasks.map(importTask => {
          const freshInfo = uploadedAnnotations?.find(
            annotation => annotation.annotationUrl === importTask.annotationUrl
          );
          return {
            type: 'importTask',
            key: `annotation-${importTask.annotationUrl}`,
            taskData: { ...importTask, ...freshInfo },
            name: importTask.fileName?.toLowerCase() ?? '-',
          } as const;
        }),
        ...asyncTasks.map(
          (task, index) =>
            ({
              type: 'asyncTask' as const,
              key: task.data ? `async-task-${task.data.id}` : `index-${index}`,
              taskData: task,
              name: task.data?.content?.fileName?.toLowerCase() ?? '-',
            }) as const
        ),
        ...uploadsTasks.map(
          task =>
            ({
              type: 'uploadTask' as const,
              key: `uploads-task-${task.taskId}`,
              taskData: task,
              name: task.data.fileName?.toLowerCase() ?? '-',
            }) as const
        ),
      ],
      [prop('name'), 'asc']
    );
  }, [
    asyncTasks,
    childAnnotations,
    importTasks,
    uploadedAnnotations,
    uploadsTasks,
  ]);

  const tasks = classifyTasks({
    importTasks,
    asyncTasks,
    uploadsTasks,
    childAnnotations,
    uploadedAnnotations,
  });

  const intlId = getTaskDialogTitle(tasks);
  const counter = getTaskDialogCounter(tasks);

  return (
    <Dialog
      open={tasks.length > 0}
      hideBackdrop
      disableAutoFocus
      disableEscapeKeyDown
      disableRestoreFocus
      disableScrollLock
      disableEnforceFocus
      sx={{
        position: 'initial',
      }}
      PaperProps={{
        'data-cy': 'async-tasks-dialog',
        sx: {
          position: 'fixed !important',
          bottom: -30,
          right: 0,
          zIndex: theme => theme.zIndex.drawer,
          width: 430,
        },
        elevation: 2,
      }}
    >
      <DialogTitle
        title={`${intl.formatMessage({ id: intlId })} (${counter}/${
          tasks.length
        })`}
        onClose={() => clearAllTasks()}
        onToggleMinimize={() => setDialogCollapsed(collapsed => !collapsed)}
      />
      <Collapse in={!dialogCollapsed} unmountOnExit>
        <DialogContent
          sx={{ p: '0px !important', overflow: 'auto', maxHeight: '300px' }}
        >
          <Stack spacing={3}>
            <List sx={{ p: 1 }}>
              <Stack spacing={1}>
                {allTasks.map(task => {
                  switch (task.type) {
                    case 'childAnnotation':
                    case 'importTask':
                      return (
                        <AnnotationProgress
                          key={task.key}
                          importTask={task.taskData}
                        />
                      );
                    case 'asyncTask':
                      return (
                        <AsyncTaskProgress
                          key={task.key}
                          task={task.taskData}
                        />
                      );

                    case 'uploadTask':
                      return (
                        <UploadsTaskProgress
                          key={task.key}
                          task={task.taskData}
                        />
                      );

                    default:
                      return null;
                  }
                })}
              </Stack>
            </List>
          </Stack>
        </DialogContent>
      </Collapse>
    </Dialog>
  );
};

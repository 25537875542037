import { Engine } from '@rossum/api-client/engines';
import { Button, CircularProgress } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { AutomationBreadcrumbs } from '../../../containers/Automation/AutomationBreadcrumbs';
import { automationEnginesPath } from '../../../containers/Automation/helpers';
import { Header, HeaderProps } from '../../../ui/header/Header';
import { FIELD_FORM_ID } from '../constants';
import { useEngine } from '../hooks/useEngine';
import { engineDetailPath } from '../paths';

type FieldAddHeaderProps = { engine: Engine; isSaving: boolean } & Pick<
  HeaderProps,
  'scrollableDivRef'
>;

export const FieldAddHeader = ({
  engine,
  isSaving,
  ...rest
}: FieldAddHeaderProps) => {
  const intl = useIntl();
  const history = useHistory();

  const { data } = useEngine(engine.id);

  return (
    <Header
      {...rest}
      breadcrumbs={
        <AutomationBreadcrumbs
          breadcrumbs={[
            {
              label: intl.formatMessage({
                id: 'components.appBar.menu.automation.aiEngines',
              }),
              to: automationEnginesPath(),
            },
            {
              to: engineDetailPath(engine.id),
              label: data?.name ?? `${engine.id}`,
            },
            {
              label: intl.formatMessage({
                id: 'features.engines.header.title.newField',
              }),
            },
          ]}
        />
      }
      title={intl.formatMessage({
        id: 'features.engines.header.title.newField',
      })}
      description=""
      buttons={[
        <Button
          key="new_field"
          variant="contained"
          type="submit"
          form={FIELD_FORM_ID}
          startIcon={isSaving && <CircularProgress color="inherit" size={20} />}
          data-cy="submit-form"
          disabled={isSaving}
        >
          {intl.formatMessage({ id: 'features.engines.header.buttons.save' })}
        </Button>,
      ]}
      onBackButtonClicked={() => history.push(engineDetailPath(engine.id))}
    />
  );
};

import { domainSettings } from './domainSettings';
import { generateRejectionEmail } from './generateRejectionEmail';
import { requestDemo } from './requestDemo';
import { requestExtension } from './requestExtension';
import { evaluateFormulas } from './schemas/evaluateFormulas';
import { formulasInfo } from './schemas/formulasInfo';
import { suggestFormula } from './schemas/suggestFormula';
import { formulaSummary } from './schemas/formulaSummary';
export { type RequestDemoPayload } from './requestDemo';
export { type GenerateRejectionPayload } from './generateRejectionEmail';
export { type RequestExtensionPayload } from './requestExtension';
export { type DomainSettingsSsoProvider } from './domainSettings';
export { type SuggestFormulaResponse } from './schemas/suggestFormula';
export {
  type AutomationBlocker,
  type EvaluateFormulasResponse,
} from './schemas/evaluateFormulas';

const schemasEndpoints1 = {
  evaluateFormulas,
  formulasInfo,
  suggestFormula,
  formulaSummary,
};

/**
 * @noSchema
 */
type SchemasEndpoints1 = typeof schemasEndpoints1;

const internalSchemas: { schemas: SchemasEndpoints1 } = {
  schemas: {
    ...schemasEndpoints1,
  },
};

export const internal = {
  requestDemo,
  requestExtension,
  domainSettings,
  generateRejectionEmail,
  ...internalSchemas,
};

import { getIDFromUrl } from '@rossum/api-client';
import { WorkspacesOutlined } from '@rossum/ui/icons';
import { find, get, inRange, map, omit, sum, values } from 'lodash';
import AccountSupervisorOutline from 'mdi-react/AccountSupervisorOutlineIcon';
import Check from 'mdi-react/CheckIcon';
import FileDocumentOutline from 'mdi-react/FileDocumentOutlineIcon';
import ImageFilterCenterFocus from 'mdi-react/ImageFilterCenterFocusIcon';
import { connect } from 'react-redux';
import { useUsers } from '../../../business/users/useUsers';
import { useExtensionsFeatureSubscription } from '../../../features/pricing/hooks/useExtensionsFeatureSubscription';
import { organizationSelector } from '../../../redux/modules/organization/selectors';
import { Queue } from '../../../types/queue';
import { AnyDatapointSchema } from '../../../types/schema';
import { State } from '../../../types/state';
import { myExtensionsPath } from '../../Extensions/lib/routes';
import Stats, { StatsType } from '../components/Stats';

type OwnProps = {
  selectedQueue: Queue;
};

type StateProps = {
  admins: Array<string>;
  enabledFieldsCount: number;
  fieldsCount: number;
};

type Props = StateProps & OwnProps;

// algoritmus
const getNumber = (n: number) =>
  (find<[number, string]>(
    [
      [0, n.toString()],
      [10000, '>10k'],
      [100000, '>100k'],
      [1000000, '>1M'],
      [10000000, '>10M'],
      [100000000, '>100M'],
    ],
    ([lowerLimit]) => n < 10000 || inRange(n, lowerLimit, lowerLimit * 10)
  ) || [null, '>1B'])[1];

const getDocumentsCount = (n: Record<string, number>) =>
  getNumber(sum(values(omit(n, ['purged', 'split']))));

const QueueStats = ({
  enabledFieldsCount,
  fieldsCount,
  selectedQueue,
  admins,
}: Props) => {
  const extensionsSubscription = useExtensionsFeatureSubscription();
  const { data } = useUsers({
    id: [...admins.map(getIDFromUrl), ...selectedQueue.users.map(getIDFromUrl)],
    deleted: false,
  });

  const accessRoute = `/queues/${selectedQueue.id}/settings/access`;
  const fieldsRoute = `/queues/${selectedQueue.id}/settings/fields`;

  const stats: StatsType[] = [
    {
      value: data?.pagination.total ?? 0,
      Icon: AccountSupervisorOutline,
      title: 'containers.settings.stats.queue.assignedUsers' as const,
      type: 'assignedUsers',
      link: accessRoute,
    },
    {
      value: getDocumentsCount(selectedQueue.counts),
      Icon: FileDocumentOutline,
      title: 'containers.settings.stats.queue.documents' as const,
      type: 'numberOfDocuments',
      link: `/annotations/${selectedQueue.id}`,
    },
    {
      value: enabledFieldsCount,
      Icon: Check,
      title: 'containers.settings.stats.queue.fieldsEnabled' as const,
      type: 'fieldsEnabled',
      link: fieldsRoute,
    },
    {
      value: fieldsCount,
      Icon: ImageFilterCenterFocus,
      title: 'containers.settings.stats.queue.fieldsTotal' as const,
      type: 'fieldsTotal',
      link: fieldsRoute,
    },
    ...(extensionsSubscription.canAccess
      ? [
          {
            value: selectedQueue.hooks.length,
            Icon: WorkspacesOutlined,
            title: 'containers.settings.stats.queue.extensionsTotal' as const,
            type: 'extensionsTotal',
            link: selectedQueue.hooks.length
              ? myExtensionsPath({ queues: selectedQueue.id.toString() })
              : undefined,
          },
        ]
      : []),
  ];

  return <Stats stats={stats} />;
};

const mapStateToProps = (state: State) => {
  const fields = get(state, 'schema.content', []).filter(
    (item: AnyDatapointSchema) => item.category === 'datapoint'
  );
  const enabledFieldsCount = fields.filter(
    (item: AnyDatapointSchema) => !item.hidden
  ).length;

  return {
    admins: map(organizationSelector(state).admins, 'url'),
    fieldsCount: fields.length,
    enabledFieldsCount,
  };
};

const ConnectedStats = connect<StateProps, null, OwnProps, State>(
  mapStateToProps
)(QueueStats);

export default (props: OwnProps) =>
  props.selectedQueue ? <ConnectedStats {...props} /> : null;

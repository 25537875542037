import { Box } from '@rossum/ui/material';
import { VALUE_INPUT_WIDTH } from '../../../../document-list-base/mql/constants';
import { isOperatorEmpty } from '../Operators/utils';
import { MultiSelectInput } from './MultiSelect';
import { MultiString } from './MultiString';
import { TextFieldInput } from './TextField';
import { InputSelectorProps } from './types';

export const InputSelector = (props: InputSelectorProps) => {
  if (isOperatorEmpty(props.filterContext.operator))
    return <Box sx={{ width: VALUE_INPUT_WIDTH }} />;
  const {
    filterContext: {
      operator: { type, value },
    },
  } = props;
  const isMultiSelect = type === 'multiSelect';

  const isMultiString = value === 'isAnyOf' || value === 'notIn';

  return isMultiSelect ? (
    <MultiSelectInput {...props} />
  ) : isMultiString ? (
    <MultiString {...props} />
  ) : (
    <TextFieldInput {...props} />
  );
};

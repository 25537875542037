import { useEffect, useMemo, useState } from 'react';
import { DEV_FEATURES_ENABLED } from '../../../constants/config';
import { DevToolsSync } from '../DevToolsSync';
import { useSurveyIfEnabled } from '../hooks/useSurveyIfEnabled';
import { SurveyName } from '../surveysUuidMap';
import { fieldsSurveysBlockersSelector } from './blockersSelectors';
import { getFieldsSurveysBlockersFromSurvey } from './dialogBlockers';
import { FieldsSurveyDialog } from './dialogs/FieldsSurveyDialog';

const SURVEY_NAME = 'fieldsToCaptureRedesignSurvey' satisfies SurveyName;

const FieldsToCaptureRedesignSurvey = () => {
  const [userEngaged, setUserEngaged] = useState(false);

  const surveyInfo = useSurveyIfEnabled({
    surveyName: SURVEY_NAME,
    userEngaged,
    blockersSelector: fieldsSurveysBlockersSelector,
    getBlockersFromSurvey: getFieldsSurveysBlockersFromSurvey,
  });

  // we have sufficient engagement for survey feedback after 10s spent w/ this component rendered
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setUserEngaged(true);
    }, 10 * 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  // For debugging.
  const surveyDevToolsAction = useMemo(
    () => ({
      type: `@@ELIS_DEVTOOLS/SET_SURVEY_STATE` as const,
      payload: [
        {
          label: 'Fields To Capture Redesign Survey',
          ...surveyInfo,
        },
      ],
    }),
    [surveyInfo]
  );

  return (
    <>
      {DEV_FEATURES_ENABLED && <DevToolsSync action={surveyDevToolsAction} />}
      {surveyInfo.survey && (
        <FieldsSurveyDialog
          open={surveyInfo.shouldViewSurvey}
          survey={surveyInfo.survey}
          viewBlockers={surveyInfo.viewBlockers}
          surveyName={SURVEY_NAME}
        />
      )}
    </>
  );
};

export default FieldsToCaptureRedesignSurvey;

import { isString, some } from 'lodash';

export const prevent = <E extends { preventDefault: () => void }>(event: E) =>
  event.preventDefault();

export const isElementPresent = (selector: string) =>
  !!document.querySelector(selector);

const getParents = ({ parentNode }: Node, memo: Node[] = []): Node[] =>
  parentNode ? getParents(parentNode, memo.concat(parentNode)) : memo;

interface CustomEvent extends Event {
  path?: EventTarget[];
}

export const getEventPath = (event: CustomEvent): Array<EventTarget> => {
  const path = event.path || (event.composedPath && event.composedPath());
  const { target } = event;

  if (path) {
    return path.indexOf(window) < 0 ? path.concat(window) : path;
  }

  if (!target) {
    return [window];
  }

  if (target === window) {
    return [window];
  }

  return [target].concat(getParents(target as Node), window);
};

export const isInModal = (event: CustomEvent) =>
  some(
    getEventPath(event),
    el =>
      'className' in el &&
      isString(el.className) &&
      el.className.includes('MuiModal')
  );

import { useIntl } from 'react-intl';
import {
  Breadcrumb,
  Breadcrumbs,
} from '../../components/BreadcrumbsV2/Breadcrumbs';
import { automationPath } from './helpers';

type AutomationBreadcrumbsProps = {
  breadcrumbs: Breadcrumb[];
};

export const AutomationBreadcrumbs = ({
  breadcrumbs,
}: AutomationBreadcrumbsProps) => {
  const intl = useIntl();

  const automationBreadcrumbs = [
    {
      to: automationPath(),
      label: intl.formatMessage({ id: 'components.appBar.automation' }),
    },
    ...breadcrumbs,
  ];

  return <Breadcrumbs breadcrumbs={automationBreadcrumbs} />;
};

import z from 'zod';

/** Tries parsing `jsonString`, returns `defaultValue` if it fails. */
export const jsonParse = <O = unknown>(
  jsonString: string,
  defaultValue: O | null = null,
  reviver?: Parameters<typeof JSON.parse>[1]
) => {
  try {
    return [JSON.parse(jsonString, reviver) as O, 'success'] as const;
  } catch (_e) {
    return [defaultValue, 'error'] as const;
  }
};

/** Parses `data` with provided Zod schema. Uses `fallbackValue` in .catch method */
const safeZodParseWithCatch = <T>(
  schema: z.ZodSchema<T>,
  data: unknown,
  fallbackValue: T
): T => {
  return schema.catch(fallbackValue).parse(data);
};

export const parseAndValidate = <T>(
  jsonString: string | null,
  schema: z.ZodSchema<T>,
  fallbackValue: T
): T => {
  const [parsedData] = jsonParse(jsonString ?? '');
  return safeZodParseWithCatch(schema, parsedData, fallbackValue);
};

import { Alert, MenuItem, Stack } from '@rossum/ui/material';
import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import TextFieldControl from '../../components/ReactHookForm/controls/TextFieldControl';
import BillingBarChart from './components/BillingChart/BillingBarChart';
import LabeledValueInRow from './components/LabeledValueInRow';
import StatisticsFilter, {
  DatepickerDateRange,
  StatisticsFilterFormState,
} from './components/StatisticsFilter';
import StatisticsQueueTable from './components/StatisticsQueueTable';
import { BillingEntity } from './hooks/useBillingEntity';
import { useUnpaginatedOrganizations } from './hooks/useUnpaginatedOrganizations';
import { useUsageValidity } from './hooks/useUsageValidity';
import { useFilterValues } from './useFilterValues';

const checkDateRange = (range: DatepickerDateRange) => {
  const to = new Date(range.to);
  const from = new Date(range.from);
  const oneYear = 1000 * 3600 * 24 * 366;
  return +to - +from <= oneYear;
};

type UsageProps = {
  billingEntity: BillingEntity;
};

const Usage = ({ billingEntity }: UsageProps) => {
  const intl = useIntl();
  const { defaultValues, setStoredValues } = useFilterValues();

  const { control, setValue } = useForm<StatisticsFilterFormState>({
    defaultValues,
  });

  const organizationUrl = useWatch({ control, name: 'organizationUrl' });

  useEffect(() => {
    setValue('queues', undefined);
  }, [organizationUrl, setValue]);

  const { data: organizations } = useUnpaginatedOrganizations(billingEntity, {
    pageSize: 10,
  });

  const usageValidity = useUsageValidity();

  // @ts-expect-error useWatch returns Partial<StatisticsFilterFormState> for some reason
  const filters: StatisticsFilterFormState = useWatch({ control });

  useEffect(() => {
    setStoredValues(filters);
  }, [filters, setStoredValues]);

  return (
    <Stack spacing={4} my={8}>
      <Stack direction="row" justifyContent="space-between">
        {usageValidity && (
          <LabeledValueInRow
            label="containers.billing.validity"
            valueColor="text.secondary"
            value={new Date(usageValidity).toUTCString()}
          />
        )}
      </Stack>

      <Stack spacing={2}>
        {organizations && (
          <Stack direction="row">
            <TextFieldControl
              ControllerProps={{ control, name: 'organizationUrl' }}
              FieldLabelProps={{ layout: 'floating' }}
              label={intl.formatMessage({
                id: 'containers.billing.overview.organization',
              })}
              SelectProps={{
                MenuProps: {
                  sx: { maxHeight: '500px' },
                },
              }}
              select
            >
              {organizations.map(organization => (
                <MenuItem key={organization.url} value={organization.url}>
                  {organization.name}
                </MenuItem>
              ))}
            </TextFieldControl>
          </Stack>
        )}

        <StatisticsFilter control={control} />
      </Stack>

      {checkDateRange(filters.dateRange) ? (
        <>
          <BillingBarChart billingEntity={billingEntity} filters={filters} />
          <StatisticsQueueTable
            billingEntity={billingEntity}
            filters={filters}
          />
        </>
      ) : (
        <Alert severity="error">
          {intl.formatMessage({
            id: 'containers.billing.usage.maxOneYearAllowed',
          })}
        </Alert>
      )}
    </Stack>
  );
};

export default Usage;

import { Page } from '@rossum/api-client/pages';
import { BoxProps } from '@rossum/ui/material';
import { partition } from 'lodash';
import React, { Ref } from 'react';
import { useSelector } from 'react-redux';
import { DatapointBoundingBox } from '../../features/annotation-view/document-canvas/components/DatapointBoundingBox';
import { SearchResultBox } from '../../features/annotation-view/document-canvas/components/SearchResultBox';
import { SuggestedOperationBox } from '../../features/annotation-view/document-canvas/components/SuggestedOperationBox';
import { SuggestionBox } from '../../features/annotation-view/document-canvas/components/SuggestionBox';
import { positionBasedKey } from '../../features/annotation-view/document-canvas/utils/boundingBoxes';
import { useDocumentStore } from '../../features/annotation-view/document-store/DocumentStore';
import { useDocumentContext } from '../../features/annotation-view/DocumentContext';
import { suggestedBboxesForPage } from '../../redux/modules/bboxes/selector';
import {
  canCreateRectangleSelector,
  currentDatapointSelector,
  getBoundedDatapointsPerPage,
} from '../../redux/modules/datapoints/selector';
import { schemaMapSelector } from '../../redux/modules/schema/schemaMapSelector';
import { complexLineItemsEnabledSelector } from '../../redux/modules/ui/selectors';
import { State } from '../../types/state';
import { useBoundingBoxCallbacks } from './useBoundingBoxCallbacks';
import { useBoundingBoxes } from './useBoundingBoxes';

type BoundingBoxesLayerProps = BoxProps & {
  page: Page;
  activeBboxRef: Ref<SVGRectElement> | undefined;
  suggestionsEnabled: boolean;
};

export const BoundingBoxesLayer = React.memo(
  ({ page, activeBboxRef, suggestionsEnabled }: BoundingBoxesLayerProps) => {
    const { canvasActionInProgress, setCanvasActionInProgress } =
      useDocumentContext();

    const selectedDatapointIds = useDocumentStore(
      state => state.selectedBboxes
    );

    const complexLineItemsEnabled = useSelector(
      complexLineItemsEnabledSelector
    );

    const schemaMap = useSelector(schemaMapSelector);

    const boundedDatapoints = useSelector(getBoundedDatapointsPerPage)[
      page.number
    ];

    // IBB: These are here so we are not drilling them, would be nicer if we solved it some other way
    const currentDatapoint = useSelector(currentDatapointSelector);

    const [[activeDatapoint]] = partition(
      boundedDatapoints,
      dp => dp.id === currentDatapoint?.id
    );

    const readOnly = useSelector((state: State) => state.ui.readOnly);
    const suggestedBboxes = useSelector(suggestedBboxesForPage(page.number));
    const canCreateRectangle = useSelector(canCreateRectangleSelector);

    const canShowSuggestions = !canvasActionInProgress && canCreateRectangle;

    // Search results
    const searchResults = useSelector((state: State) => state.search.results);

    const currentSearchResultIndex = useSelector(
      (state: State) => state.search.currentResultIndex
    );

    const {
      handleSuggestedOperationMoved,
      handleSuggestedOperationResized,
      handleBoundingBoxMoved,
      handleBoundingBoxResized,
      handleSuggestedBoxClicked,
      handleSelectBbox,
    } = useBoundingBoxCallbacks(page);

    const showingSuggestions = canShowSuggestions && suggestionsEnabled;

    const { boundingBoxes: boundingBoxesX } = useBoundingBoxes(
      showingSuggestions,
      page.number
    );

    return (
      <>
        {suggestedBboxes.map(sb => (
          <SuggestionBox
            key={`${sb.rectangle[0]}-${sb.rectangle[1]}-${sb.rectangle[2]}-${sb.rectangle[3]}}`}
            position={sb.rectangle}
            outlineStyle={showingSuggestions ? 'dashed' : 'none'}
            onClick={handleSuggestedBoxClicked}
          />
        ))}
        {searchResults.map((result, index) =>
          result.page === page.number ? (
            <SearchResultBox
              key={positionBasedKey(result.rectangle, index)}
              position={result.rectangle}
              current={index === currentSearchResultIndex}
            />
          ) : null
        )}

        {boundingBoxesX.map(box => {
          if (box.type === 'datapoint') {
            return box.active ? (
              <DatapointBoundingBox
                ref={activeBboxRef}
                key={`activeDatapoint-${box.id}`}
                datapointId={box.id}
                position={box.position}
                interactive
                disabled={readOnly}
                active={selectedDatapointIds.length < 2}
                label={schemaMap.get(activeDatapoint.schemaId)?.label ?? ''}
                onMoveStart={() => setCanvasActionInProgress(true)}
                onMoveEnd={handleBoundingBoxMoved}
                onResizeStart={() => setCanvasActionInProgress(true)}
                onResizeEnd={handleBoundingBoxResized}
                boundingBoxProps={box.style}
              />
            ) : (
              <DatapointBoundingBox
                key={`passiveDatapoint-${box.id}`}
                datapointId={box.id}
                position={box.position}
                interactive={!canvasActionInProgress}
                disabled={readOnly}
                active={false}
                label={schemaMap.get(box.schemaId)?.label ?? ''}
                onClick={handleSelectBbox}
                boundingBoxProps={box.style}
              />
            );
          }

          if (box.type === 'tableSuggestion') {
            return (
              <SuggestedOperationBox
                key={`tableSuggestion-${box.id}`}
                ref={
                  complexLineItemsEnabled && box.id === activeDatapoint?.id
                    ? activeBboxRef
                    : undefined
                }
                operationId={box.id}
                position={box.position}
                boundingBoxProps={box.style}
                active={currentDatapoint?.id === box.id}
                onClick={e => handleSelectBbox(e, box.id)}
                onMoveEnd={handleSuggestedOperationMoved}
                onResizeEnd={handleSuggestedOperationResized}
              />
            );
          }

          if (box.type === 'extensionSuggestion') {
            return (
              <SuggestedOperationBox
                key={`extensionSuggestion-${box.id}`}
                ref={
                  complexLineItemsEnabled && box.id === activeDatapoint?.id
                    ? activeBboxRef
                    : undefined
                }
                operationId={box.id}
                position={box.position}
                boundingBoxProps={box.style}
                active={false}
                onClick={e => handleSelectBbox(e, box.id)}
                onMoveEnd={handleSuggestedOperationMoved}
                onResizeEnd={handleSuggestedOperationResized}
              />
            );
          }

          return null;
        })}
      </>
    );
  }
);

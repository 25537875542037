import { User } from '@rossum/api-client/users';

export type DocumentModifier = User | 'none' | 'unknown';

export const resolveModifierFromUrl = <Modifiers extends Record<string, User>>(
  url: string | null,
  modifiers: Modifiers
): DocumentModifier => {
  if (!url) return 'none';
  return modifiers[url] ?? 'unknown';
};

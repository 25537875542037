import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { SurveyName, surveysUuidMap } from '../surveysUuidMap';
import { useCreateSurvey } from './useCreateSurvey';
import { QUERY_KEY_SURVEY, useSurvey } from './useSurvey';
import { QUERY_KEY_SURVEYS, useSurveys } from './useSurveys';

type UseLastSurveyProps = {
  surveyName: SurveyName;
  enabled: boolean;
};

export const useLatestSurvey = ({
  surveyName,
  enabled,
}: UseLastSurveyProps) => {
  const queryClient = useQueryClient();

  // we need all surveys ordered by lastModifiedAt to get the relevant one (last one if there are more)
  const { data: surveys, status: surveysStatus } = useSurveys(
    surveyName,
    enabled
  );

  const { mutateAsync } = useCreateSurvey(surveysUuidMap[surveyName]);

  // get the survey we are interested in
  const latestRelevantSurveyId = surveys?.results?.[0]?.id ?? null;

  // get the relevant survey and whenever successful, evaluate whether user
  // has already passed based on survey data and save to DC survey redux module
  // this flag is used when determining whether to ask for feedback
  const { data: relevantSurvey, status: relevantSurveyStatus } = useSurvey(
    latestRelevantSurveyId,
    { enabled: enabled && latestRelevantSurveyId !== null }
  );

  // if user hasn't answered this survey yet, create an empty one and save in the cache
  useEffect(() => {
    // Do not create surveys for non-trial and embedded users at all
    if (
      enabled &&
      surveysStatus === 'success' &&
      surveys?.results?.length === 0
    ) {
      mutateAsync()
        .then(survey => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY_SURVEYS],
          });
          queryClient.setQueryData([QUERY_KEY_SURVEY, survey.id], survey);
        })
        .catch(() => {
          // Do nothing if this fails (uuid not found for example). We do not want to interrupt user
          // App should function as normal if survey fails
        });
    }
  }, [
    enabled,
    mutateAsync,
    queryClient,
    surveys?.results?.length,
    surveysStatus,
  ]);

  return {
    status: relevantSurveyStatus,
    survey: relevantSurvey,
  } as const;
};

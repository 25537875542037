import { Survey } from '@rossum/api-client/surveys';
import * as R from 'remeda';
import {
  hasUserAnsweredSurveyBlocker,
  isBeforeInterval,
  isUserAnnoyedBlocker,
  notEnoughUsageInteractionsBlocker,
  userNotEngagedBlocker,
} from '../blockers';

export const getFieldsSurveysBlockersFromSurvey = ({
  survey,
  userEngaged,
}: {
  survey: Survey;
  userEngaged: boolean;
}) =>
  [
    notEnoughUsageInteractionsBlocker(survey, 3),
    hasUserAnsweredSurveyBlocker(survey),
    isUserAnnoyedBlocker(survey, 2),
    userNotEngagedBlocker(userEngaged),
    isBeforeInterval(),
  ].filter(R.isTruthy);

import { endpoints, ID } from '@rossum/api-client';
import { SurveyPatchPayload } from '@rossum/api-client/surveys';
import { useMutation } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';

export const usePatchSurvey = () => {
  return useMutation({
    mutationFn: ({
      surveyId,
      surveyPatchModel,
    }: {
      surveyId: ID;
      surveyPatchModel: SurveyPatchPayload;
    }) => api.request(endpoints.surveys.patch(surveyId, surveyPatchModel)),
  });
};

import { SchemaItem, SchemaSection } from '@rossum/api-client/schemas';

const addEmptyFormulaToContent = <T extends SchemaItem | SchemaSection>(
  schemaId: string,
  item: T,
  newFormula: string
): T => {
  if (item.category === 'datapoint' && item.id === schemaId) {
    return { ...item, formula: newFormula };
  }

  if (item.category === 'multivalue') {
    return {
      ...item,
      children: addEmptyFormulaToContent(schemaId, item.children, newFormula),
    };
  }

  if ('children' in item) {
    return {
      ...item,
      children: item.children.map(c =>
        addEmptyFormulaToContent(schemaId, c, newFormula)
      ),
    };
  }

  return item;
};

// Takes SchemaSection[] and replaces formula of a field identified by `fieldSchemaId`
export const updateFieldFormula = (
  schemaContent: SchemaSection[],
  fieldSchemaId: string,
  newFormula: string
) => {
  return schemaContent.map(c =>
    addEmptyFormulaToContent(fieldSchemaId, c, newFormula)
  );
};

import {
  alpha,
  Box,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { button } from '../../../../lib/formaterValues';
import { AuroraSuggest } from '../../../../ui/icons/AuroraSuggest';
import { Summary } from './Summary';

type SummaryBoxProps =
  | {
      variant: 'emptyFormula';
      callback: () => void;
      value?: undefined;
    }
  | {
      variant:
        | 'emptyRule'
        | 'editingCode'
        | 'missingInfo'
        | 'suggestingSummary';
      value?: undefined;
      callback?: undefined;
    }
  | {
      value: string;
      variant?: undefined;
      callback?: undefined;
    };

export const SummaryBox = ({ value, variant, callback }: SummaryBoxProps) => {
  const theme = useTheme();
  const intl = useIntl();
  return (
    <Stack width="100%" alignItems="center">
      <Box
        py={1}
        px={2}
        sx={{
          width: '100%',
          border: `1px solid ${value ? theme.palette.aurora.light : theme.palette.grey[500]}`,
          borderRadius: 1,
          backgroundColor: alpha(
            value ? theme.palette.aurora.main : theme.palette.grey[500],
            0.1
          ),
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack width="100%" textAlign={value ? 'left' : 'center'} p={2}>
            {value !== undefined ? (
              <Stack direction="row" alignItems="start" spacing={1}>
                <AuroraSuggest
                  fontSize="small"
                  stroke={theme.palette.aurora.light}
                />

                <Summary content={value} />
              </Stack>
            ) : (
              <Stack sx={{ opacity: 0.7 }}>
                {variant === 'suggestingSummary' ? (
                  <>
                    <Skeleton />
                    <Skeleton />
                  </>
                ) : (
                  <>
                    <Typography variant="h6">
                      {intl.formatMessage({
                        id: `features.formulas.summaryBox.${variant}.title`,
                      })}
                    </Typography>
                    <Typography variant="body2">
                      {intl.formatMessage(
                        {
                          id: `features.formulas.summaryBox.${variant}.description`,
                        },

                        {
                          button: callback ? button(callback) : undefined,
                        }
                      )}
                    </Typography>
                  </>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

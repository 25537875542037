// TODO: How to make it to not need the /automation/engines and have paths relative in the module?
// This currently duplicates path in automation/helpers.tsx :(
export const basePath = `/automation/engines`;

export const engineDetailPath = (engineId: number | 'new') =>
  `${basePath}/${engineId}`;
export const engineDetailRoute = `${basePath}/:engineId` as const;
export const engineNewRoute = `${basePath}/new` as const;

export const engineFieldDetailPath = (
  engineId: number,
  fieldId: number | 'new'
) => `${basePath}/${engineId}/fields/${fieldId}`;

export const engineFieldDetailRoute =
  `${basePath}/:engineId/fields/:fieldId` as const;

export const engineFieldNewRoute = `${basePath}/:engineId/fields/new` as const;

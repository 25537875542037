import { getIDFromUrl } from '@rossum/api-client';
import { EditRelation } from '@rossum/api-client/relations';
import { Link, SvgIcon, Tooltip } from '@rossum/ui/material';
import { MouseEventHandler } from 'react';
import { useIntl } from 'react-intl';
import { EditDocumentsRestrictor } from '../../../components/Restrictors';
import { constructDocumentUrl } from '../../../lib/url';

export const DocumentWasEdited = ({
  edit,
  onClick,
  childAnnotationId,
  dataCySuffix,
}: {
  edit: EditRelation;
  onClick?: MouseEventHandler;
  childAnnotationId: number;
  dataCySuffix: string;
}) => {
  const intl = useIntl();

  const editParentUrl = constructDocumentUrl({
    id: getIDFromUrl(edit.parent),
    nestedPath: '/edit',
    query: { from: childAnnotationId },
  });

  return (
    <EditDocumentsRestrictor>
      <Tooltip
        title={intl.formatMessage({
          id: 'containers.editDocument.documentWasEdited.tooltip',
        })}
        placement="top"
      >
        <div onClick={(...args) => onClick && onClick(...args)}>
          <Link
            href={editParentUrl}
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'inherit',
              '&:hover': {
                color: 'inherit',
              },
            }}
            data-cy={`edited-document-indicator-${dataCySuffix}`}
          >
            <SvgIcon fontSize="small" color="inherit">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.9811 5.55017C10.0611 6.31017 9.9311 7.02017 9.6411 7.64017L12.4797 10.5611H21.3597C22.155 10.5611 22.7997 11.2058 22.7997 12.0011C22.7997 12.7964 22.155 13.4411 21.3597 13.4411H12.4797L9.6311 16.3502C9.9211 16.9802 10.0511 17.6802 9.9711 18.4402C9.7811 20.1702 8.4311 21.6302 6.7111 21.9302C3.9511 22.4102 1.5811 20.0502 2.0611 17.2802C2.3511 15.5602 3.8211 14.2102 5.5511 14.0202C6.3111 13.9402 7.0211 14.0702 7.6411 14.3602L10.0011 12.0002L7.6411 9.63017C7.0111 9.92017 6.3111 10.0502 5.5511 9.97017C3.8211 9.78017 2.3611 8.43017 2.0611 6.71017C1.5811 3.95017 3.9511 1.58017 6.7211 2.06017C8.4411 2.35017 9.7911 3.82017 9.9811 5.55017ZM4.0011 6.00017C4.0011 7.11017 4.9011 8.00017 6.0011 8.00017C7.1011 8.00017 8.0011 7.11017 8.0011 6.00017C8.0011 4.89017 7.1011 4.00017 6.0011 4.00017C4.9011 4.00017 4.0011 4.89017 4.0011 6.00017ZM4.0011 18.0002C4.0011 19.1102 4.9011 20.0002 6.0011 20.0002C7.1011 20.0002 8.0011 19.1102 8.0011 18.0002C8.0011 16.8902 7.1011 16.0002 6.0011 16.0002C4.9011 16.0002 4.0011 16.8902 4.0011 18.0002ZM11.5011 12.0002C11.5011 12.2802 11.7211 12.5002 12.0011 12.5002C12.2811 12.5002 12.5011 12.2802 12.5011 12.0002C12.5011 11.7202 12.2811 11.5002 12.0011 11.5002C11.7211 11.5002 11.5011 11.7202 11.5011 12.0002Z"
              />
              <path d="M21.9215 13.8H12.72L11.8795 14.64H21.5804C21.8952 14.64 22.1916 14.4918 22.3805 14.2399L22.8101 13.6669C22.8776 13.577 22.762 13.4518 22.6649 13.5085C22.5201 13.5933 22.3649 13.6785 22.231 13.7394C22.1331 13.7839 22.029 13.8 21.9215 13.8Z" />
            </SvgIcon>
          </Link>
        </div>
      </Tooltip>
    </EditDocumentsRestrictor>
  );
};

// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';
import {
  engineFieldTypeSchema,
  multilineSchema,
} from './engineFieldType.schema';

export const engineFieldSubtypeSchema = z.union([
  z.literal('alphanumeric'),
  z.literal('numeric'),
  z.literal('country_code'),
  z.literal('currency_code'),
  z.literal('iban'),
  z.literal('vat_number'),
  z.literal('integer'),
  z.literal('rate'),
  z.literal('amount'),
  z.literal('period_end'),
  z.literal('period_begin'),
  z.literal(''),
]);

export const engineFieldSchema = z.object({
  id: idSchema,
  url: urlSchema,
  engine: urlSchema,
  name: z.string(),
  label: z.string(),
  type: engineFieldTypeSchema,
  subtype: engineFieldSubtypeSchema.nullable(),
  preTrainedFieldId: z.string().nullable(),
  tabular: z.boolean(),
  multiline: multilineSchema,
});

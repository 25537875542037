import { Buffer } from 'buffer';
import { toHumanString as _toHumanString } from 'human-readable-numbers';
import {
  endsWith,
  every,
  findIndex,
  first,
  get,
  includes,
  initial,
  last,
  lowerCase,
  startsWith,
  trim,
} from 'lodash';

/**
 * keyof (A | B) will return keys that A and B have in common.
 * This type returns all possible keys from both types: keyof A | keyof B
 */
type PossibleKeyOf<T> = T extends unknown ? keyof T : never;

export const getFromUnion = <
  T,
  P extends PossibleKeyOf<T>,
  R extends T extends { [K in P]: unknown }
    ? T[P]
    : T extends { [K in P]?: unknown }
      ? T[P] | undefined
      : undefined,
>(
  object: T,
  path: P
): R => {
  const value: R = get(object, path);
  return value;
};

/**
 * lodash.get(unknown, 'some.path') has return type inferred as `undefined`,
 * which isn't correct. This wrapper enforces proper types.
 */
export const getUnsafe = (
  data: unknown,
  path: (number | string)[] | string | number
): unknown => get(data, path);

export const minimizeString = (
  string: string,
  max: number,
  separator: Array<string> = ['.', '.', '.']
) => {
  const array = string.split('');
  const halfMax = Math.floor(max / 2);
  return string.length > max
    ? [
        ...array.slice(0, halfMax),
        ...separator,
        ...array.slice(array.length - halfMax, array.length),
      ].join('')
    : string;
};

export const mapLinebreaks = (
  originalValue: string,
  strippedValue: string
): string => {
  if (!includes(originalValue, '\n')) return strippedValue;

  const lines = originalValue.split('\n');

  const contentMatches = every(lines, line => includes(strippedValue, line));
  const lengthWithoutLinebreak = lines.reduce(
    (length, line) => length + line.length,
    lines.length - 2
  );
  const linebreakRemoved =
    contentMatches && lengthWithoutLinebreak === strippedValue.length;
  if (linebreakRemoved) return strippedValue;

  const changedLineIndex = findIndex(lines, (line, index: number) =>
    index === 0
      ? !startsWith(strippedValue, `${line} `)
      : index === lines.length - 1
        ? !endsWith(strippedValue, ` ${line}`)
        : !includes(strippedValue, ` ${line} `)
  );

  const head = lines.slice(0, changedLineIndex);
  const tail = lines.slice(changedLineIndex + 1);

  const headToJoin = head.join('\n') + (head.length ? '\n' : '');
  const tailToJoin = (tail.length ? '\n' : '') + tail.join('\n');

  const changedLine = strippedValue
    .replace(head.join(' '), '')
    .replace(tail.join(' '), '');
  const strippedChangedLine = changedLine.slice(
    head.length ? 1 : 0,
    tail.length ? changedLine.length - 1 : changedLine.length
  );

  return `${headToJoin}${strippedChangedLine}${tailToJoin}`;
};

export const appendToFilename = (target: string, tail: string) => {
  const segments = target.split('.');
  const filename =
    segments.length === 1 ? first(segments) : initial(segments).join('.');
  const ext = segments.length === 1 ? '' : `.${last(segments)}`;
  return `${filename}${tail}${ext}`;
};

type GetIndex = (_resetCount?: boolean) => number;
const initCounter = (
  initialValue: number,
  updateFn: (value: number) => number
): GetIndex => {
  let counter = initialValue;
  return (resetCount = false) => {
    counter = resetCount ? initialValue : updateFn(counter);
    return counter;
  };
};

const _getIndex: GetIndex = initCounter(-1, (x: number) => x - 1);

type Node<T> = { children?: Array<T>; id: number };
export const mapTree = <T extends Node<T>>(
  tree: Array<T>,
  mapper: (_t: T, _index: number) => T,
  isTail = false,
  getIndex: GetIndex = _getIndex
): Array<T> =>
  tree.map((node: T, i): T => {
    const tree = mapper(node, getIndex(i === 0 && !isTail));
    const children = node.children
      ? mapTree(node.children, mapper, true, getIndex)
      : undefined;
    return children ? { ...tree, children } : tree;
  });

export const generateInboxPrefix = (organizationName: string) => {
  const asciiName = Buffer.from(
    organizationName.normalize('NFKD'),
    'ascii'
  ).toString();
  const allowedCharsName = trim(lowerCase(asciiName)).replace(/[^\w\s.]/g, '');
  return allowedCharsName.replace(/\s/g, '-');
};

export const toHumanString = (number: number) => {
  const result: string = _toHumanString(number);
  return last(result) === 'm' ? `${number}` : result;
};

export const formatNumberToLocale = (number: number, locale: string) =>
  new Intl.NumberFormat(locale).format(number);

export const toAbsoluteUrl = (url: string) =>
  url.indexOf('http') === 0 ? url : new URL(url, window.location.origin).href;

import { MenuItem, Select, Stack, Typography } from '@rossum/ui/material';
import { FormattedMessage } from 'react-intl';
import { pageSizes } from '../../../constants/values';
import { asScalar } from '../../../lib/url';

const options = pageSizes.map(option => ({
  label: option,
  value: option,
}));

type Props = {
  pageSize: string | string[] | null;
  setPageSize: (pageSize: string) => void;
};

const PageSizeSelector = ({ pageSize, setPageSize }: Props) => {
  const normalisedPageSize = asScalar(pageSize);

  const currentValue = options.find(
    ({ value }) => value === normalisedPageSize
  )?.value;

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Select
        key={currentValue}
        onChange={event => setPageSize(event.target.value)}
        id="pageSizeSelector"
        value={currentValue ?? ''}
        size="small"
      >
        {options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
      <Typography variant="body2">
        <FormattedMessage id="containers.annotationList.paginatorSelector.unit" />
      </Typography>
    </Stack>
  );
};

export default PageSizeSelector;

import { getIDFromUrl } from '@rossum/api-client';
import { Button, Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { assertNever } from '../../lib/typeUtils';
import { constructDocumentUrl } from '../../lib/url';
import { currentAnnotationQueueSelector } from '../../redux/modules/annotation/currentAnnotationQueueSelector';
import { EditDocumentConfig } from './editState';
import { FilenameWorkspaceQueueInfo } from './FilenameWorkspaceQueueInfo';

export const OriginalDocumentHeader = ({
  config,
}: {
  config: EditDocumentConfig;
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const queue = useSelector(currentAnnotationQueueSelector);

  if (
    config.mode.type === 'single-annotation' ||
    config.mode.type === 'parent-annotation'
  ) {
    return (
      <Stack>
        <Typography variant="h6">
          {intl.formatMessage({ id: 'containers.editDocument.original.title' })}
        </Typography>
        <FilenameWorkspaceQueueInfo
          queueUrl={queue?.url ?? null}
          filename={null}
        />
      </Stack>
    );
  }

  if (config.mode.type === 'child-annotation') {
    if (config.mode.parentIsAccessible) {
      return (
        <Stack>
          <Typography variant="h6">
            {intl.formatMessage({
              id: 'containers.editDocument.document.title',
            })}
          </Typography>
          <Typography variant="caption">
            {intl.formatMessage({
              id: 'containers.editDocument.document.description',
            })}
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            sx={{ alignSelf: 'end', mt: 3 }}
            onClick={() => {
              if (config.mode.type === 'child-annotation') {
                // TODO extract
                const editParentUrl = `${constructDocumentUrl({
                  id: getIDFromUrl(config.mode.parentUrl),
                  nestedPath: '/edit',
                  query: { from: getIDFromUrl(config.annotationUrl) },
                })}`;

                dispatch(push(editParentUrl));
              }
            }}
            data-cy="document-edit-child-annotation-edit-original-btn"
          >
            {intl.formatMessage({
              id: 'containers.editDocument.document.editOriginal',
            })}
          </Button>
        </Stack>
      );
    }

    return (
      <Stack>
        <Typography variant="h6">
          {intl.formatMessage({ id: 'containers.editDocument.document.title' })}
        </Typography>
        <Typography variant="caption">
          {intl.formatMessage({
            id: 'containers.editDocument.document.noAccessDescription',
          })}
        </Typography>
      </Stack>
    );
  }

  return assertNever(config.mode);
};

import { LicenseInfo } from '@rossum/ui/x-license-pro';
import { Route, Switch } from 'react-router';
import { muiDataGridLicenseKey } from '../../../constants/config';
import FieldManagerSurvey from '../../surveys/FieldsSurveys/FieldManagerSurvey';
import {
  createFieldRoute,
  fieldDetailRoute,
  fieldManagerPath,
} from '../constants';
import { useFieldManagerData } from '../hooks/useFieldManagerData';
import { CreateFieldForm } from './create-field/CreateFieldForm';
import { FieldDetail } from './field-detail/FieldDetail';
import { Overview } from './overview/Overview';

LicenseInfo.setLicenseKey(muiDataGridLicenseKey);

export const FieldManagerRoutes = () => {
  const { isLoading } = useFieldManagerData();

  return (
    <>
      <Switch>
        <Route exact path={fieldDetailRoute()}>
          <FieldDetail />
        </Route>
        <Route exact path={createFieldRoute()}>
          <CreateFieldForm />
        </Route>
        <Route exact path={fieldManagerPath()}>
          <Overview />
        </Route>
      </Switch>
      <FieldManagerSurvey allDataLoaded={!isLoading} />
    </>
  );
};

import { endpoints } from '@rossum/api-client';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OutOfTheBoxDocumentType } from '../../features/queues/add-queue/data';
import { api } from '../../lib/apiClient';
import { assertNever } from '../../lib/typeUtils';
import { safeOrganizationSelector } from '../../redux/modules/organization/selectors';
import {
  createQueueFulfilled,
  fetchQueueFulfilled,
} from '../../redux/modules/queues/actions';
import { createWorkspaceFulfilled } from '../../redux/modules/workspaces/actions';
import { createConfiguredQueue } from '../queues/createQueueWithSettings';

const regions = ['us', 'eu', 'uk', 'other'] as const;
type Region = (typeof regions)[number];

const defaultUSTemplate = {
  templateName: 'Tax Invoice US Demo Template',
  firstQueueName: 'Tax invoices (US)',
};

const getTemplateConfig = (
  documentType: OutOfTheBoxDocumentType['id'],
  region: Region | ''
) => {
  switch (documentType) {
    case 'tax_invoice': {
      switch (region) {
        case 'eu': {
          return {
            templateName: 'Tax Invoice EU Demo Template',
            firstQueueName: 'Tax invoices (EU)',
          };
        }
        case 'us': {
          return {
            templateName: 'Tax Invoice US Demo Template',
            firstQueueName: 'Tax invoices (US)',
          };
        }
        case 'uk': {
          return {
            templateName: 'Tax Invoice UK Demo Template',
            firstQueueName: 'Tax invoices (UK)',
          };
        }
        case 'other': {
          return defaultUSTemplate;
        }
        // TODO: Controversial
        case '': {
          return defaultUSTemplate;
        }
        default: {
          return assertNever(region);
        }
      }
    }
    case 'purchase_order': {
      return {
        templateName: 'Purchase Order Demo Template',
        firstQueueName: 'Purchase orders',
      };
    }
    case 'credit_note': {
      return {
        templateName: 'Credit Note Demo Template',
        firstQueueName: 'Credit notes',
      };
    }

    case 'debit_note': {
      return {
        templateName: 'Debit Note Demo Template',
        firstQueueName: 'Debit notes',
      };
    }

    case 'pro_forma': {
      return {
        templateName: 'Proforma Invoice Demo Template',
        firstQueueName: 'Pro forma invoices',
      };
    }

    case 'delivery_note': {
      return {
        templateName: 'Delivery Note Demo Template',
        firstQueueName: 'Delivery notes',
      };
    }

    case 'custom_document_type': {
      return {
        templateName: 'Custom Document Type Template',
        firstQueueName: 'Custom Document Type',
      };
    }

    // This never gets called
    default: {
      return assertNever(documentType);
    }
  }
};

const documentTypes = [
  { id: 'credit_note', region: '' },
  { id: 'tax_invoice', region: 'us' },
  { id: 'tax_invoice', region: 'uk' },
  { id: 'tax_invoice', region: 'eu' },
  { id: 'purchase_order', region: '' },
  { id: 'delivery_note', region: '' },
  { id: 'debit_note', region: '' },
  { id: 'pro_forma', region: '' },
] as const;

export const useCreateInitialWorkspaceAndAllDocTypesQueues = () => {
  const dispatch = useDispatch();
  const organization = useSelector(safeOrganizationSelector);
  const [successCount, setSuccessCount] = useState<number>(0);

  const {
    mutateAsync: createInitialWorkspaceAndAllDocTypesQueues,
    isLoading: mutationAllDocTypesLoading,
  } = useMutation({
    mutationFn: () => {
      if (!organization) {
        throw new Error(
          'Organization: Cannot create workspace with no organization.'
        );
      }
      return api
        .request(
          endpoints.workspaces.create({
            name: organization.name,
            organization: organization.url,
          })
        )
        .then(workspace => {
          dispatch(createWorkspaceFulfilled(workspace, { withMessage: false }));

          return Promise.all(
            documentTypes.map(documentType => {
              const { templateName, firstQueueName } = getTemplateConfig(
                documentType.id,
                documentType.region
              );

              return createConfiguredQueue({
                payload: {
                  name: firstQueueName,
                  templateName,
                  workspace: workspace.url,
                  includeDocuments: true,
                },
              }).then(data => {
                setSuccessCount(prev => prev + 1);
                return data;
              });
            })
          ).then(queues =>
            queues.forEach((queue, index) => {
              dispatch(fetchQueueFulfilled(queue));
              dispatch(
                createQueueFulfilled(queue, {
                  withMessage: false,
                  skipRedirect: index > 0,
                })
              );
            })
          );
        });
    },
  });
  return {
    createInitialWorkspaceAndAllDocTypesQueues,
    mutationAllDocTypesLoading,
    mutationPrgoress: 100 * (successCount / documentTypes.length),
  };
};

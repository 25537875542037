import { PopperInPortal } from '@rossum/rossum-ui/PopperInPortal';
import { ScienceRounded } from '@rossum/ui/icons';
import {
  ListItemButton,
  ListItemIcon,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { get } from 'lodash';
import MenuRightIcon from 'mdi-react/MenuRightIcon';
import OfficeBuildingIcon from 'mdi-react/OfficeBuildingIcon';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { partition } from 'remeda';
import { filterUserOrganizations } from '../../redux/modules/user/helpers';
import { GroupOrganizationUser } from '../../types/groupOrganizationUser';
import { Organization } from '../../types/organization';
import { ItemPropsT } from '../UI/Dropdown';
import NoItemsFound from '../UI/NoItemsFound';
import SearchFilter from '../UI/SearchFilter/SearchFilter';
import styles from './styles.module.sass';

type ItemProps = {
  active: boolean;
  onClick: () => void;
  organization: Partial<Organization>;
  dataCy?: string;
} & Partial<ItemPropsT>;

const Item = ({ onClick, organization, dataCy, active }: ItemProps) => {
  const iconColor = active ? 'primary.main' : 'text.disabled';

  return (
    <ListItemButton
      onClick={onClick}
      data-cy={dataCy}
      sx={{ alignItems: 'center' }}
    >
      <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
        {organization.sandbox ? (
          <ScienceRounded sx={{ color: iconColor }} />
        ) : (
          <Stack sx={{ color: iconColor }}>
            <OfficeBuildingIcon />
          </Stack>
        )}
      </ListItemIcon>
      <Stack direction="row" justifyContent="space-between" flex={1}>
        <Typography variant="body2">{organization.name}</Typography>
        <Typography
          variant="body2"
          whiteSpace="nowrap"
          sx={{ color: 'text.disabled' }}
        >{`ID ${organization.id}`}</Typography>
      </Stack>
    </ListItemButton>
  );
};

type Props = {
  onOrganizationClick: (orgUrl: string, orgName: string) => void;
  organizationUser: GroupOrganizationUser;
  organization: Partial<Organization>;
  className?: string;
};

const OrganizationSwitcher = ({
  onOrganizationClick,
  organizationUser,
  organization,
  className,
}: Props) => {
  const intl = useIntl();
  const [organizationSearchValue, setOrganizationSearchValue] = useState('');
  const [filteredOrganizations, setFilteredOrganizations] = useState(
    organizationUser.organizationGroupOrganizations
  );

  const [sandboxOrganizations, prodOrganizations] = useMemo(
    () => partition(filteredOrganizations, org => org.sandbox),
    [filteredOrganizations]
  );

  const renderOrganizations = (orgs: Organization[]) => {
    return orgs.map(_organization => (
      <Item
        key={`organization-${_organization.name}-id-${_organization.id}`}
        className={className}
        onClick={() =>
          onOrganizationClick(_organization.url, get(_organization, 'name'))
        }
        organization={_organization}
        active={_organization.id === organization.id}
        dataCy="organizations-list-item"
      />
    ));
  };

  useEffect(() => {
    if (!organizationSearchValue) {
      setFilteredOrganizations(organizationUser.organizationGroupOrganizations);
    }
  }, [
    organizationUser.organizationGroupOrganizations,
    organizationSearchValue,
  ]);

  const onMouseEnterPortal = () => setOrganizationSearchValue('');

  const shouldDisplayOrganizationSwitcher =
    organizationUser.organizationGroupOrganizations.length > 1;

  return shouldDisplayOrganizationSwitcher ? (
    <div
      data-cy="personal-organization-info"
      className={styles.DropdownOrganizationInfo}
    >
      <div className={styles.OrganizationTitle}>
        <FormattedMessage id="containers.personalInfo.organizations.title" />
      </div>
      <PopperInPortal
        placement="left-start"
        offset={[0, 1]}
        closeOnClick
        key="personal-info-group-organizations"
        render={() => (
          <div className={styles.OrganizationGroupOrganizations}>
            <div
              key="organizations-search"
              onClick={e => {
                e.nativeEvent.stopImmediatePropagation();
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <SearchFilter
                className={styles.Search}
                placeholderID="containers.personalInfo.organizations.search.placeholder"
                setFilteredEntities={setFilteredOrganizations}
                searchValue={organizationSearchValue}
                setSearchValue={setOrganizationSearchValue}
                entities={filteredOrganizations}
                filterFunction={filterUserOrganizations}
              />
            </div>
            {sandboxOrganizations.length ? (
              <Stack
                sx={{
                  borderBottom: t => `1px solid ${t.palette.other.muted}`,
                  maxHeight: 200,
                  overflowY: 'auto',
                }}
              >
                {renderOrganizations(sandboxOrganizations)}
              </Stack>
            ) : null}
            <div className={styles.OrganizationList}>
              {prodOrganizations.length ? (
                renderOrganizations(prodOrganizations)
              ) : (
                <NoItemsFound
                  title={intl.formatMessage({
                    id: 'containers.personalInfo.organizations.search.noItems.title',
                  })}
                  subtitle={intl.formatMessage({
                    id: 'containers.personalInfo.organizations.search.noItems.subtitle',
                  })}
                />
              )}
            </div>
          </div>
        )}
      >
        <a
          className={styles.CurrentOrganizationWrapper}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onMouseEnter={onMouseEnterPortal}
          href="#"
        >
          {organization.sandbox ? (
            <ScienceRounded />
          ) : (
            <OfficeBuildingIcon size={18} />
          )}
          <div className={styles.CurrentOrganization}>
            <span>{organization.name}</span>
            <span className={styles.OrganizationId}>
              {`ID ${organization.id}`}
            </span>
          </div>
          <MenuRightIcon />
        </a>
      </PopperInPortal>
      <div className={styles.SeparatorLine} />
    </div>
  ) : null;
};

export default OrganizationSwitcher;

import { Box, Paper, Stack, StackProps } from '@rossum/ui/material';

type LabelListRowProps = {
  children: React.ReactNode;
} & StackProps;

const LabelListRow = ({ children, ...props }: LabelListRowProps) => {
  return (
    <Box
      sx={{
        color: 'inherit',
      }}
    >
      <Stack
        {...props}
        component={Paper}
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        sx={{
          px: 2,
          py: 1,
          borderRadius: 1,
          transition: t => [t.transitions.create('background-color')],
          '&:hover': {
            textDecoration: 'none',
            backgroundColor: t => t.palette.action.hover,
          },
        }}
      >
        {children}
      </Stack>
    </Box>
  );
};

export default LabelListRow;

import { AddCircle } from '@rossum/ui/icons';
import { Autocomplete, Chip, IconButton, TextField } from '@rossum/ui/material';
import { useState } from 'react';
import { doUnsavedValuesExist } from '../../../document-list-base/mql/helpers';
import { SimpleFilterComponentProps } from '../../../document-list-base/mql/types';
import FilterActionsWrapper from './FilterActionsWrapper';
import { useOperators } from './Operators/useOperators';

export const FilterMultiString = ({
  filterItem,
  onClose,
  onBackClick,
  applyFilter,
  convertToAdvancedFilter,
  onOperatorChange,
}: SimpleFilterComponentProps) => {
  const { filterContext } = filterItem;

  const existingValues = Array.isArray(filterContext.value)
    ? filterContext.value
    : typeof filterContext.value === 'string' && filterContext.value
      ? [filterContext.value]
      : [];

  const [selectedOptions, setSelectedOptions] =
    useState<string[]>(existingValues);

  const [value, setValue] = useState<string>('');

  const { node: Operators, operator } = useOperators({
    filterItem,
    onChange: onOperatorChange,
    value: value.length ? value : undefined,
  });

  const handleOnClickOnMore = convertToAdvancedFilter
    ? () =>
        convertToAdvancedFilter(
          operator,
          doUnsavedValuesExist(selectedOptions, filterContext.value)
            ? selectedOptions
            : undefined
        )
    : undefined;

  const valueIsAlreadySelected = selectedOptions.includes(value);

  return (
    <FilterActionsWrapper
      onBackClick={onBackClick}
      onCancel={onClose}
      onSubmit={() =>
        applyFilter({
          operator,
          value: selectedOptions,
        })
      }
      isSubmitDisabled={!selectedOptions.length}
      onClickOnMore={handleOnClickOnMore}
      columnField={filterItem.column.field}
    >
      <Operators />
      <Autocomplete
        clearIcon={false}
        fullWidth
        multiple
        freeSolo
        value={selectedOptions}
        onChange={(_, values) => setSelectedOptions(values)}
        inputValue={value}
        renderTags={(values, getTagProps) =>
          values.map((option: string, index: number) => (
            <Chip
              {...getTagProps({ index })}
              key={option}
              variant="outlined"
              label={option}
              size="small"
            />
          ))
        }
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: value.length ? (
                <IconButton
                  size="small"
                  disabled={valueIsAlreadySelected}
                  onClick={() => {
                    setSelectedOptions(prev => [...prev, value]);
                    setValue('');
                  }}
                  sx={{ ml: 'auto' }}
                >
                  <AddCircle fontSize="small" color="primary" />
                </IconButton>
              ) : null,
            }}
          />
        )}
        onInputChange={(_, value) => setValue(value)}
        options={[]}
      />
    </FilterActionsWrapper>
  );
};

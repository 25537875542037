import { NavBar, NavBarTab, NavBarTabs } from '@rossum/rossum-ui/NavBar';
import { ScienceRounded, WarningAmber } from '@rossum/ui/icons';
import { Box, Chip, Stack, TabProps, Tooltip } from '@rossum/ui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, LinkProps } from 'react-router-dom';
import { push } from 'redux-first-history';
import { DEV_FEATURES_ENABLED } from '../../constants/config';
import CallToActionButton from '../../containers/CallToAction';
import { useLeavingModalDispatch } from '../../containers/Queue/containers/QueueSchema/lib/leavingModalHooks';
import { useBillingEnabled } from '../../features/billing';
import { PricingBanner } from '../../features/pricing/components/PricingBanner';
import { usePricingBannerWarning } from '../../features/pricing/hooks/usePricingBannerWarning';
import { useQueues } from '../../features/queues/hooks/useQueues';
import {
  isTrialSelector,
  safeOrganizationSelector,
} from '../../redux/modules/organization/selectors';
import { approvalWorkflowsEnabledSelector } from '../../redux/modules/organizationGroup/selectors';
import {
  currentTabSelector,
  hideAppBarSelector,
} from '../../redux/modules/router/selectors';
import {
  canUserApproveSelector,
  userRoleNameSelector,
} from '../../redux/modules/user/selectors';
import { State } from '../../types/state';
import PoweredBy from '../UI/PoweredBy';
import UserPanel from '../UserPanel';
import Logo, { hasCustomLogoSelector } from './Logo';
import { isTabVisible, Tab, tabs, tabToLink } from './navigationStructure';
import PopperMenu from './PopperMenu';

const LinkTab = NavBarTab as React.ComponentType<TabProps & LinkProps>;

type NavigationTabsProps = {
  handleMenuOpen: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    currentTab: Tab
  ) => void;
  handleNavigation: (event: React.MouseEvent, pathname: string) => void;
};

const NavigationTabs = ({
  handleMenuOpen,
  handleNavigation,
}: NavigationTabsProps) => {
  const currentPathsTab = useSelector(currentTabSelector);
  const userRole = useSelector(userRoleNameSelector);

  const {
    data: queues,
    isSuccess: areQueuesLoaded,
    isFetched,
  } = useQueues({
    fields: ['id'],
    pageSize: 1,
  });

  const workflowsEnabled = useSelector(approvalWorkflowsEnabledSelector);
  const canUserApprove = useSelector(canUserApproveSelector);

  const organization = useSelector(safeOrganizationSelector);

  const intl = useIntl();

  const approverHasNoQueues =
    userRole === 'approver' && areQueuesLoaded && !queues.results.length;

  const visibleTabs = tabs.filter(tab => {
    if (tab.name === 'requests') return workflowsEnabled && canUserApprove;
    if (tab.name === 'documents') return !approverHasNoQueues;

    return true;
  });

  // the user might refresh the page in annotations path, and annotations might be temporarily excluded from the visible paths.
  // To make MUI happy here we make sure that the currentTab value being passed is always visible.
  const currentTab =
    visibleTabs.find(tab => tab.name === currentPathsTab?.name)?.name ?? false;

  return isFetched
    ? userRole && (
        <Stack direction="row">
          {organization?.sandbox ? (
            <Tooltip
              title={intl.formatMessage({
                id: 'features.pricing.sandbox.tooltip',
              })}
            >
              <Chip
                label={intl.formatMessage({
                  id: 'features.pricing.sandbox.iconText',
                })}
                icon={<ScienceRounded />}
                color="primary"
                sx={{ my: 'auto', mr: 4, px: 1 }}
                size="small"
              />
            </Tooltip>
          ) : null}

          {DEV_FEATURES_ENABLED ? (
            <Tooltip
              title={intl.formatMessage({
                id: 'features.pricing.developerMode.tooltip',
              })}
            >
              <Chip
                label="Developer mode"
                icon={<WarningAmber />}
                color="warning"
                sx={{ my: 'auto', mr: 4, px: 1 }}
                size="small"
              />
            </Tooltip>
          ) : null}

          <NavBarTabs value={currentTab} aria-label="nav-bar-tabs">
            {visibleTabs.map(tab => {
              // TODO @unified-dashboard: temporarily disable external url mutations when o /documents route https://rossumai.atlassian.net/browse/AC-4257
              const isDisabled =
                currentTab === 'documents' && tab.name === 'documents';

              return (
                isTabVisible(tab, userRole) && (
                  <LinkTab
                    icon={tab.icon}
                    data-cy={`${tab.name}-navtab`}
                    iconPosition="start"
                    key={tab.name}
                    label={intl.formatMessage({
                      id: `components.appBar.${tab.name}`,
                    })}
                    value={tab.name}
                    onClick={e => handleNavigation(e, tabToLink(tab))}
                    onMouseEnter={e => handleMenuOpen(e, tab)}
                    to={tabToLink(tab)}
                    component={Link}
                    disabled={isDisabled}
                  />
                )
              );
            })}
          </NavBarTabs>
        </Stack>
      )
    : null;
};

const AppBar = () => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLElement) | null>(
    null
  );
  const [hoveredTab, setHoveredTab] = useState<Tab | null>(null);
  const leaveSafely = useLeavingModalDispatch();
  const menuOpen = Boolean(anchorEl);

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    currentTab: Tab
  ) => {
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
    setHoveredTab(currentTab);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setHoveredTab(null);
  };

  const handleNavigation = (e: React.MouseEvent, pathname: string) => {
    if (e.metaKey || e.ctrlKey) return;

    e.preventDefault();
    handleMenuClose();
    leaveSafely(push(pathname));
  };

  const isTrial = useSelector((state: State) => isTrialSelector(state));

  const hasCustomLogo = useSelector(hasCustomLogoSelector);

  const hideAppBar = useSelector(hideAppBarSelector);

  const bannerWarning = usePricingBannerWarning();
  const { isEnabled: billingEnabled } = useBillingEnabled();

  if (hideAppBar) {
    return null;
  }

  return (
    <Box>
      <NavBar
        position="sticky"
        additionalInfo={
          <>
            {isTrial && <CallToActionButton ctaLocation="navigation-panel" />}
            {hasCustomLogo && <PoweredBy />}
          </>
        }
        logo={<Logo />}
        navigation={
          <Stack onMouseLeave={handleMenuClose}>
            <NavigationTabs
              handleMenuOpen={handleMenuOpen}
              handleNavigation={handleNavigation}
            />
            <PopperMenu
              open={menuOpen}
              hoveredTab={hoveredTab}
              anchorEl={anchorEl}
              handleNavigation={handleNavigation}
            />
          </Stack>
        }
        user={<UserPanel />}
      />
      {bannerWarning ? (
        <PricingBanner
          warningText={bannerWarning}
          billingEnabled={billingEnabled}
        />
      ) : null}
    </Box>
  );
};

export default AppBar;

import { Hook } from '@rossum/api-client/hooks';
import { Log } from '@rossum/api-client/hooks';
import {
  Accordion,
  Box,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { format } from 'date-fns';
import { compact, last, mapKeys } from 'lodash';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import z from 'zod';
import { camelToSnake } from '../../../../lib/keyConvertor';
import { parseAndValidate } from '../../../../utils/jsonParse';
import { EmptyList } from './EmptyList';
import { ListHead } from './ListHead';
import { LogDetail } from './LogDetail';
import { LogOverview } from './LogOverview';

const formatToJSONString = (value: Record<string, unknown>) =>
  JSON.stringify(value, null, 2);

type Props = {
  logs: Array<Log>;
  extensions: Array<Hook>;
  isLoadingExtensions: boolean;
};

const resolveLogValue = (value: string | null) => {
  const plainValue = {
    value,
    editorProps: {
      mode: 'plain_text',
    },
  } as const;

  const parsedValue = parseAndValidate(
    value,
    z.record(z.unknown()).nullable(),
    null
  );

  return parsedValue === null
    ? plainValue
    : ({ value: formatToJSONString(parsedValue) } as const);
};

// We need to append fraction of second manually
// since JS does not allow fractional milliseconds (only first 3 are taken into account).
const formatTimestamp = (timestamp: string) => {
  const fractionOfSecond = (last(timestamp.split('.')) || '').replace('Z', '');

  return compact([
    format(new Date(timestamp), 'yyyy-MM-dd HH:mm:ss'),
    fractionOfSecond,
  ]).join('.');
};

export const LogsList = ({ logs, extensions, isLoadingExtensions }: Props) => {
  const intl = useIntl();

  const extensionNames = useMemo(
    () =>
      extensions.reduce<Record<Hook['id'], Hook['name']>>(
        (acc, { id, name }) => ({
          ...acc,
          [id]: name,
        }),
        {}
      ),
    [extensions]
  );

  return (
    <>
      <ListHead />
      {logs.length > 0 ? (
        <>
          {logs.map(log => {
            const {
              timestamp,
              hookId: extensionId,
              hookType: extensionType,
              logLevel,
              message,
              event,
              request,
              requestId,
              response,
              action,
            } = log;
            const snakeCasedLog = mapKeys(log, (_, key) => camelToSnake(key));

            const getExtensionValue = () => {
              const skeletonWidth = `${Math.random() * 30 + 80}px`;
              const extensionName = extensionNames[extensionId] || (
                <Box
                  component="span"
                  sx={{
                    mb: 0,
                    fontStyle: 'italic',
                    fontFamily: 'Roboto',
                    lineHeight: 0,
                  }}
                >
                  {intl.formatMessage({
                    id: 'containers.settings.extensions.logs.unknownName',
                  })}
                </Box>
              );

              const extensionNameValue = isLoadingExtensions ? (
                <Skeleton sx={{ width: skeletonWidth }} variant="text" />
              ) : (
                <Tooltip title={extensionName} placement="top">
                  <Box component="div" width="100%">
                    {extensionName}
                  </Box>
                </Tooltip>
              );

              return (
                <Stack direction="row" gap={0.25}>
                  <span>{extensionId}</span>
                  {extensionNameValue && (
                    <>
                      <span>:</span>
                      <Box
                        component="span"
                        sx={{
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          mb: 0,
                        }}
                      >
                        {extensionNameValue}
                      </Box>
                    </>
                  )}
                </Stack>
              );
            };

            return (
              <Accordion
                TransitionProps={{ unmountOnExit: true }}
                key={requestId + timestamp + extensionId}
                disableGutters
                sx={{ '&::before': { display: 'none' } }}
              >
                <LogOverview
                  columns={{
                    timestamp: formatTimestamp(timestamp),
                    logLevel,
                    event: `${event}.${action}`,
                    extension: getExtensionValue(),
                    message,
                  }}
                  extensionType={extensionType}
                />
                <LogDetail
                  detail={{
                    request: resolveLogValue(request),
                    response: resolveLogValue(response),
                    detail: { value: formatToJSONString(snakeCasedLog) },
                  }}
                  message={message}
                />
              </Accordion>
            );
          })}
          {logs.length >= 100 && (
            <Typography variant="body2" color="text.secondary" sx={{ py: 1 }}>
              {intl.formatMessage({
                id: 'containers.settings.extensions.logs.resultsCountLimitation',
              })}
            </Typography>
          )}
        </>
      ) : (
        <EmptyList translationKey="emptyState" />
      )}
    </>
  );
};

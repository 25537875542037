import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { boldText } from '../../../lib/formaterValues';
import { Header, HeaderProps } from '../../../ui/header/Header';
import { QueueSettingsBreadcrumbs } from '../components/QueueSettingsBreadcrumbs';
import { QueueSettingsTabs } from '../components/QueueSettingsTabs';

type BasicSettingsHeaderProps = {
  parentPath: string;
  queueName: string;
  emailAddress: string | null | undefined;
} & Pick<HeaderProps, 'scrollableDivRef'>;

export const BasicSettingsHeader = ({
  parentPath,
  queueName,
  emailAddress,
  scrollableDivRef,
}: BasicSettingsHeaderProps) => {
  const intl = useIntl();

  const history = useHistory();

  // TODO: This should probably not be copy-pasted around
  const onBackButtonClicked = useCallback(() => {
    // If the queue settings page was opened in a new tab, there is no history stack to go back, so fallbacking to "/documents" path instead
    if (history.length > 1) {
      return history.goBack();
    }
    return history.push('/documents');
  }, [history]);

  return (
    <Header
      scrollableDivRef={scrollableDivRef}
      tabs={<QueueSettingsTabs value="basic" parentPath={parentPath} />}
      breadcrumbs={
        <QueueSettingsBreadcrumbs
          queueName={queueName}
          settingsPath={parentPath}
          breadcrumbs={[
            {
              label: intl.formatMessage({
                id: 'containers.settings.queues.queue.basic',
              }),
              to: `${parentPath}/basic`,
            },
          ]}
        />
      }
      title={intl.formatMessage({
        id: 'containers.settings.queues.queue.basic',
      })}
      description={intl.formatMessage(
        {
          id: 'containers.settings.queues.queue.basic.description',
        },
        {
          boldText,
          email: emailAddress,
        }
      )}
      onBackButtonClicked={onBackButtonClicked}
    />
  );
};

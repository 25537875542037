import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, useLocation, useRouteMatch } from 'react-router';
import { enterUser, enterUserList } from '../../redux/modules/ui/actions';
import { selectedUserSelector } from '../../redux/modules/users/selectors';
import Route from '../../routes/Route';
import User from '../User';
import Access from '../User/containers/Access';
import Info from '../User/containers/Info';
import Users from '.';
import {
  userDetailsAccessPath,
  userDetailsAccessRoute,
  userDetailsInfoRoute,
  userDetailsRoute,
  UserParams,
  usersPath,
} from './helpers';

export const UsersRoutes = () => {
  const dispatch = useDispatch();
  const location = useLocation<{ backlinkSearch?: string }>();
  const match = useRouteMatch<UserParams>(userDetailsRoute);
  const selectedUser = useSelector(selectedUserSelector);

  return (
    <Switch>
      <Route
        path={usersPath()}
        exact
        component={Users}
        onEnter={() => dispatch(enterUserList())}
      />
      {match && Number(match.params.userId) ? (
        <Route onEnter={() => dispatch(enterUser())} path={userDetailsRoute}>
          {!selectedUser ? null : (
            <User location={location} selectedUser={selectedUser}>
              <Switch>
                <Route path={userDetailsAccessRoute} exact>
                  <Access selectedUser={selectedUser} />
                </Route>
                <Route path={userDetailsInfoRoute} exact>
                  <Info selectedUser={selectedUser} />
                </Route>
                <Redirect
                  to={userDetailsAccessPath(Number(match.params.userId))}
                />
              </Switch>
            </User>
          )}
        </Route>
      ) : (
        <Redirect to={usersPath()} />
      )}
    </Switch>
  );
};

import { endpoints } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../../lib/apiClient';
import { pretrainedFieldKeys } from '../keys';

export const usePretrainedFields = () => {
  const api = useApiClient();
  return useQuery({
    queryKey: pretrainedFieldKeys.list(),
    queryFn: () => api.request(endpoints.engineFields.listPreTrainedFields()),
  });
};

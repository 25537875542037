import { UniqueIdentifier } from '@dnd-kit/core';
import { getEtag, WithEtag } from '@rossum/api-client';
import { ElisClientError } from '@rossum/api-client/errors';
import {
  isSchemaDatapoint,
  isSchemaDatapointButton,
  isSchemaSection,
  Schema,
} from '@rossum/api-client/schemas';
import { Button, Stack, Typography } from '@rossum/ui/material';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { RouteChildrenProps, useHistory } from 'react-router';
import * as R from 'remeda';
import { v4 as uuidv4 } from 'uuid';
import { usePatchSchema } from '../../../../business/schema/usePatchSchema';
import {
  throwError,
  throwInfo,
} from '../../../../redux/modules/messages/actions';
import { useSectionsAccordions } from '../../hooks/useSectionsAccordions';
import { FieldsList, FieldsListProps } from '../components/FieldsList';
import { SchemaFieldTileContent } from '../components/SchemaFieldTileContent';
import { UpdateIndicator } from '../components/UpdateIndicator';
import { toSchemaPatchPayload } from '../form-model/transformations/toSchema';
import { SchemaActionHandler } from '../form-model/transformations/toSchema.utils';
import {
  getDnDItems,
  getFlattenSchemaFields,
  isQuickActionDisabled,
  resolveCanExport,
} from '../helpers';

type FieldsPageProps = RouteChildrenProps & {
  schema: WithEtag<Schema>;
};

export const FieldsPage = ({ schema }: FieldsPageProps) => {
  const history = useHistory();
  const intl = useIntl();

  const sectionsAccordionsProps = useSectionsAccordions({
    schemaId: `${schema.id}`,
  });

  const flattenSchemaFields = useMemo(
    () => getFlattenSchemaFields(schema.content || []),
    [schema.content]
  );

  const { mutateAsync, isLoading: schemaIsPatching } = usePatchSchema({
    etag: getEtag(schema),
  });

  const dispatch = useDispatch();

  const executeSchemaAction: SchemaActionHandler = useCallback(
    options => {
      const payload = toSchemaPatchPayload(schema, options);
      if (payload) {
        mutateAsync({ id: payload.id, payload })
          .then(() => {
            dispatch(throwInfo('schemaUpdated'));
          })
          .catch((_error: ElisClientError) => {
            dispatch(throwError('schemaUpdateFailed'));
          });
      }
    },
    [dispatch, mutateAsync, schema]
  );

  const onAddSection = useCallback(() => {
    const temporarySchemaSectionId = uuidv4();

    history.push({
      pathname: `${history.location.pathname}/${temporarySchemaSectionId}`,
      state: { backLink: history.location.pathname },
    });
  }, [history]);

  const renderFieldItem: FieldsListProps['renderFieldItem'] = useCallback(
    (fieldId, isActive, isDragging, parentId) => {
      const item = flattenSchemaFields[fieldId]?.field;

      if (!item || isSchemaSection(item)) return null;

      const canExportValue = resolveCanExport(item);

      return (
        <SchemaFieldTileContent
          field={item}
          isActive={isActive}
          isDragging={isDragging}
          parentId={parentId}
          isFaded={!!item.hidden}
          // TODO: Refactor this
          quickActions={[
            {
              key: 'hidden',
              value: !item.hidden,
              disabled: false,
              onClick: () => {
                if (parentId) {
                  executeSchemaAction({
                    op: 'quick-action',
                    parentId,
                    items: {
                      [item.id]: {
                        prop: 'hidden',
                        value: !item.hidden,
                      },
                    },
                  });
                }
              },
            },
            {
              key: 'exported',
              value: canExportValue,
              disabled: isQuickActionDisabled(item),
              onClick: () => {
                if (parentId) {
                  executeSchemaAction({
                    op: 'quick-action',
                    parentId,
                    items: {
                      [item.id]: {
                        prop: 'canExport',
                        value: !canExportValue,
                      },
                    },
                  });
                }
              },
            },
            {
              key: 'required',
              value:
                isSchemaDatapoint(item) && !isSchemaDatapointButton(item)
                  ? !!item.constraints?.required
                  : false,
              disabled: isQuickActionDisabled(item),
              onClick: () => {
                if (parentId) {
                  executeSchemaAction({
                    op: 'quick-action',
                    parentId,
                    items: {
                      [item.id]: {
                        prop: 'required',
                        value:
                          isSchemaDatapoint(item) &&
                          !isSchemaDatapointButton(item)
                            ? !item.constraints?.required
                            : false,
                      },
                    },
                  });
                }
              },
            },
          ]}
        />
      );
    },
    [flattenSchemaFields, executeSchemaAction]
  );

  const items = useMemo(
    () => (schema.content ? getDnDItems(schema.content) : {}),
    [schema.content]
  );

  const sectionData = useMemo(
    () =>
      schema.content
        ? schema.content.reduce<
            Record<string, { label: string; isHidden: boolean }>
          >((acc, section) => {
            return {
              ...acc,
              [section.id]: {
                label: section.label,
                isHidden: !!section.hidden,
              },
            };
          }, {})
        : {},
    [schema.content]
  );

  // generates path to new field page for a section
  const newFieldPathForSection = useCallback(
    (sectionId: UniqueIdentifier) => {
      return `${history.location.pathname}/${sectionId}/${uuidv4()}`;
    },
    [history.location.pathname]
  );

  // generates path to detail page for a section
  const detailPathForSection = useCallback(
    (sectionId: UniqueIdentifier) => {
      return `${history.location.pathname}/${sectionId}`;
    },
    [history.location.pathname]
  );

  // generates bulk actions for a section
  // TODO: Simplify this
  const bulkActionsForSection = useCallback(
    (sectionId: UniqueIdentifier) => {
      const itemsInSection = items[sectionId];

      if (!itemsInSection) return [];

      return [
        {
          key: 'hidden',
          value: itemsInSection.every(
            itemId => !flattenSchemaFields[itemId]?.field.hidden
          ),
          onClick: () => {
            executeSchemaAction({
              op: 'quick-action',
              parentId: sectionId,
              items: R.fromEntries(
                itemsInSection.map(
                  item =>
                    [
                      item,
                      {
                        prop: 'hidden' as const,
                        value: itemsInSection.every(
                          itemId => !flattenSchemaFields[itemId]?.field.hidden
                        ),
                      },
                    ] as const
                )
              ),
            });
          },
        },
        {
          key: 'exported',
          value: itemsInSection
            .flatMap(itemId => {
              const item = flattenSchemaFields[itemId]?.field;

              return isSchemaDatapoint(item) && !isSchemaDatapointButton(item)
                ? item
                : [];
            })
            .every(item => item.canExport),
          onClick: () => {
            executeSchemaAction({
              op: 'quick-action',
              parentId: sectionId,
              items: R.fromEntries(
                itemsInSection.map(item => [
                  item,
                  {
                    id: item,
                    prop: 'canExport' as const,
                    value: !itemsInSection
                      .flatMap(itemId => {
                        const item = flattenSchemaFields[itemId]?.field;

                        return isSchemaDatapoint(item) &&
                          !isSchemaDatapointButton(item)
                          ? item
                          : [];
                      })
                      .every(item => item.canExport),
                  },
                ])
              ),
            });
          },
        },
        {
          key: 'required',
          value: itemsInSection
            .flatMap(itemId => {
              const item = flattenSchemaFields[itemId]?.field;

              return isSchemaDatapoint(item) && !isSchemaDatapointButton(item)
                ? item
                : [];
            })
            .every(item => item.constraints?.required),
          onClick: () => {
            executeSchemaAction({
              op: 'quick-action',
              parentId: sectionId,
              items: R.fromEntries(
                itemsInSection.map(item => [
                  item,
                  {
                    id: item,
                    prop: 'required' as const,
                    value: !itemsInSection
                      .flatMap(itemId => {
                        const item = flattenSchemaFields[itemId]?.field;

                        return isSchemaDatapoint(item) &&
                          !isSchemaDatapointButton(item)
                          ? item
                          : [];
                      })
                      .every(item => item.constraints?.required),
                  },
                ])
              ),
            });
          },
        },
      ] as const;
    },
    [flattenSchemaFields, items, executeSchemaAction]
  );

  const handleFieldsReorder: FieldsListProps['onItemsReorder'] = useCallback(
    (from, to) => {
      executeSchemaAction({
        op: 'move',
        from,
        to,
      });
    },
    [executeSchemaAction]
  );

  if (!schema.content || schema.content.length === 0) {
    return (
      <Stack alignItems="center" px={4} py={8} spacing={2}>
        <Typography variant="h5" color="text.secondary">
          {intl.formatMessage({
            id: 'features.queueSettings.fields.emptyState.title',
          })}
        </Typography>

        <Typography component="div" color="text.secondary" textAlign="center">
          {intl.formatMessage({
            id: 'features.queueSettings.fields.emptyState.description',
          })}
        </Typography>

        <Button key="add-section" variant="contained" onClick={onAddSection}>
          {intl.formatMessage({
            id: 'features.queueSettings.actions.addSection',
          })}
        </Button>
      </Stack>
    );
  }

  return (
    <>
      {schemaIsPatching ? <UpdateIndicator /> : null}
      <Stack p={4} spacing={4}>
        <FieldsList
          items={items}
          renderFieldItem={renderFieldItem}
          sectionsData={sectionData}
          onItemsReorder={handleFieldsReorder}
          newFieldPathForSection={newFieldPathForSection}
          detailPathForSection={detailPathForSection}
          bulkActionsForSection={bulkActionsForSection}
          sectionsAccordionsProps={sectionsAccordionsProps}
        />
      </Stack>
    </>
  );
};

import { Grid } from '@rossum/ui/material';
import FileCabinet from 'mdi-react/FileCabinetIcon';
import FolderOutline from 'mdi-react/FolderOutlineIcon';
import { connect } from 'react-redux';
import { userWithCountsSelector } from '../../../redux/modules/users/selectors';
import { State } from '../../../types/state';
import { OrganizationUser, User } from '../../../types/user';
import Stats, { StatsType } from '../components/Stats';

type StateProps = {
  user: OrganizationUser;
};

type OwnProps = {
  selectedUser: User;
};

type Props = OwnProps & StateProps;

const UserStats = ({ user: { queuesCount, workspacesCount } }: Props) => {
  const stats: StatsType[] = [
    {
      value: workspacesCount,
      Icon: FileCabinet,
      title: 'containers.settings.stats.user.activeWorkspaces' as const,
      type: 'workspaces',
    },
    {
      value: queuesCount,
      Icon: FolderOutline,
      title: 'containers.settings.stats.user.assignedQueues' as const,
      type: 'queues',
    },
  ];

  return (
    <Grid container spacing={2}>
      <Stats stats={stats} />
    </Grid>
  );
};

const mapStateToProps = (state: State, { selectedUser }: OwnProps) => ({
  user: userWithCountsSelector({ user: selectedUser })(state),
});

const ConnectedStats = connect<StateProps, null, OwnProps, State>(
  mapStateToProps
)(UserStats);

export default (props: OwnProps) =>
  props.selectedUser ? <ConnectedStats {...props} /> : null;

import { yupResolver } from '@hookform/resolvers/yup';
import { ID, Url } from '@rossum/api-client';
import {
  Alert,
  Button,
  CircularProgress,
  Grow,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { SwitchTransition } from 'react-transition-group';
import * as yup from 'yup';
import { TriggerClientModel } from '../../business/triggers/mappers/validationMapper';
import { useSaveValidationTrigger } from '../../business/triggers/useSaveValidationTrigger';
import TextFieldControl from '../../components/ReactHookForm/controls/TextFieldControl';
import FormTypeahead from '../../components/ReactHookForm/FormTypeahead';
import { GITHUB_REGEX2_DOCS } from '../../constants/values';
import { linebreak, link } from '../../lib/formaterValues';
import { throwInfo } from '../../redux/modules/messages/actions';
import { EnumOption } from '../../types/schema';

type TriageCondititionEnumField = {
  label: string;
  id: string;
  options: EnumOption[];
};

type TriageFormProps = {
  enumDatapoints: TriageCondititionEnumField[];
  queueUrl: Url;
  trigger: (TriggerClientModel & { id: ID }) | undefined;
  onClose: (...args: unknown[]) => void;
};

type TriageConditionsFormState = {
  enumFields: (TriageCondititionEnumField & {
    values: string[];
  })[];
  numberOfPages: number;
  filenameRegex: string;
};

const triageConditionsFormSchema = (intl: IntlShape) =>
  yup.object({
    enumFields: yup.array(
      yup.object({
        id: yup.string(),
        values: yup.array(yup.string()),
      })
    ),
    numberOfPages: yup
      .number()
      .min(
        0,
        intl.formatMessage({
          id: 'components.triageConditionsForm.groups.numberOfPages.validationError',
        })
      )
      .transform(
        (
          currentValue: string | number | null,
          originalValue: string | number | null
        ) => (originalValue === '' ? null : currentValue)
      )
      .nullable(true)
      .typeError(
        intl.formatMessage({
          id: 'components.triageConditionsForm.groups.numberOfPages.validationError',
        })
      ),
    filenameRegex: yup.string(),
  });

const TriageConditionsForm = ({
  queueUrl,
  trigger,
  enumDatapoints,
  onClose,
}: TriageFormProps) => {
  const intl = useIntl();

  const defaultValues: TriageConditionsFormState = {
    enumFields: enumDatapoints.map(datapoint => ({
      ...datapoint,
      values:
        trigger?.enumFields.find(field => field.field === datapoint.id)
          ?.values ?? [],
    })),
    numberOfPages: trigger?.numberOfPages ?? 0,
    filenameRegex: trigger?.filenameRegex ?? '',
  };

  const {
    control,
    setError,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(triageConditionsFormSchema(intl)),
  });

  const { fields } = useFieldArray({
    control,
    name: 'enumFields',
  });

  const dispatch = useDispatch();
  const {
    mutate: save,
    isLoading,
    isError,
    regexError,
  } = useSaveValidationTrigger(queueUrl, () => {
    dispatch(throwInfo('queueUpdated'));
    onClose();
  });

  useEffect(() => {
    if (regexError)
      setError('filenameRegex', {
        message: intl.formatMessage(
          {
            id: 'components.triageConditionsForm.filename.regexError',
          },
          { message: regexError }
        ),
      });
  }, [intl, regexError, setError]);

  const submitForm = (formModel: TriageConditionsFormState) => {
    save({
      id: trigger?.id,
      clientModel: {
        numberOfPages: formModel.numberOfPages,
        filenameRegex: formModel.filenameRegex,
        enumFields: formModel.enumFields.map(field => ({
          field: field.id,
          values: field.values,
        })),
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Stack spacing={3}>
        {fields.length ? (
          <Stack spacing={1}>
            <Typography variant="h6">
              {intl.formatMessage({
                id: 'components.triageConditionsForm.groups.enumFields.title',
              })}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {intl.formatMessage({
                id: 'components.triageConditionsForm.groups.enumFields.text',
              })}
            </Typography>
          </Stack>
        ) : null}
        {fields.map((field, index) => (
          <FormTypeahead
            key={field.id}
            name={`enumFields.${index}.values`}
            control={control}
            options={field.options}
            label={field.label}
            disablePortal
            getOptionValue={option => option.value}
            getOptionLabel={option =>
              typeof option === 'string' ? option : option.label
            }
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            multiple
          />
        ))}
        <Stack spacing={1}>
          <Typography variant="h6">
            {intl.formatMessage({
              id: 'components.triageConditionsForm.groups.numberOfPages.title',
            })}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {intl.formatMessage({
              id: 'components.triageConditionsForm.groups.numberOfPages.text',
            })}
          </Typography>
          <TextFieldControl
            type="number"
            ControllerProps={{ control, name: 'numberOfPages' }}
          />
        </Stack>
        <Stack spacing={1}>
          <Typography variant="h6">
            {intl.formatMessage({
              id: 'components.triageConditionsForm.groups.filename.title',
            })}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {intl.formatMessage(
              {
                id: 'components.triageConditionsForm.groups.filename.text',
              },
              {
                linebreak,
                link: link(GITHUB_REGEX2_DOCS),
              }
            )}
          </Typography>
          <TextFieldControl
            ControllerProps={{ control, name: 'filenameRegex' }}
            multiline
            minRows={2}
            maxRows={10}
          />
        </Stack>
        <SwitchTransition>
          <Grow key={isError && !regexError ? 'error' : 'all-good'}>
            {isError && !regexError ? (
              <Alert variant="filled" severity="error">
                {intl.formatMessage({
                  id: 'components.triageConditionsForm.error',
                })}
              </Alert>
            ) : (
              <span style={{ display: 'none' }} />
            )}
          </Grow>
        </SwitchTransition>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            data-cy="email-template-cancel-btn"
          >
            {intl.formatMessage({
              id: 'components.triageConditionsForm.cancel',
            })}
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            startIcon={
              isLoading && <CircularProgress size={16} color="inherit" />
            }
            disabled={isLoading || !isValid}
            data-cy="email-template-save-btn"
          >
            {intl.formatMessage({
              id: 'components.triageConditionsForm.submit',
            })}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default TriageConditionsForm;

import { CircularProgress, Typography } from '@rossum/ui/material';
import { IntlShape } from 'react-intl';

type PagePreviewProps = {
  previewImage: string | null | undefined;
  originalFileName: string | undefined;
  intl: IntlShape;
  pageCount: number;
};

export const PagePreview = ({
  previewImage,
  intl,
  originalFileName,
  pageCount,
}: PagePreviewProps) => {
  return (
    <>
      {previewImage ? (
        <img
          src={previewImage}
          alt="Document preview"
          data-cy="file-preview"
          width="90%"
        />
      ) : (
        <CircularProgress />
      )}

      <Typography variant="body1">{originalFileName ?? ''}</Typography>
      <Typography variant="body2">
        {intl.formatMessage(
          { id: 'components.annotationOverview.pageCount' },
          { pageCount }
        )}
      </Typography>
    </>
  );
};

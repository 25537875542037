import { SvgIcon, SvgIconProps } from '@rossum/ui/material';

export const FormulaPaused = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      fill="none"
      d="M2.5 15.8333C2.5 16.2754 2.67559 16.6993 2.98816 17.0118C3.30072 17.3244 3.72464 17.5 4.16667 17.5C5.83333 17.5 5.83333 14.1667 6.66667 10C7.5 5.83333 7.5 2.5 9.16667 2.5C9.60869 2.5 10.0326 2.67559 10.3452 2.98816C10.6577 3.30072 10.8333 3.72464 10.8333 4.16667M4.16667 10H9.16667"
    />
    <path fill="none" d="M14.1666 14.1666V18.3333M17.5 14.1666V18.3333" />
  </SvgIcon>
);

import { Relation } from '@rossum/api-client/relations';
import { Url } from '../../../types/basic';

export const isProperDuplicateRelation =
  (annotationUrl: Url) =>
  (relation: Relation): boolean => {
    // Checks whether duplicates haven't been purged.
    const isEmptyDuplicate = (annotations: Url[]) =>
      annotations.length === 1 && annotations[0] === annotationUrl;
    const { type, annotations } = relation;
    return type === 'duplicate' && !isEmptyDuplicate(annotations);
  };

export const resolveAnnotationDuplicates = (
  annotationUrl: Url,
  annotationRelations: Array<Url>,
  relations: Array<Relation>
) =>
  annotationRelations.filter(relation => {
    return relations.some(
      relationData =>
        relationData.url === relation &&
        isProperDuplicateRelation(annotationUrl)(relationData)
    );
  });

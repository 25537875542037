export const SURVEY_SUBMISSION_TIMESTAMP = 'surveys:submission-timestamp';

export const setSubmissionTimestamp = () =>
  localStorage.setItem(SURVEY_SUBMISSION_TIMESTAMP, Date.now().toString());

const TIME_INTERVAL = 24 * 60 * 60 * 1000; // default 24 hours limit in milliseconds

export const hasTimeIntervalPassed = (interval = TIME_INTERVAL): boolean => {
  const savedTimestamp = localStorage.getItem(SURVEY_SUBMISSION_TIMESTAMP);
  const timestampAsNumber = Number(savedTimestamp);

  if (!savedTimestamp || Number.isNaN(timestampAsNumber)) {
    return true;
  }

  const elapsedTime = Date.now() - timestampAsNumber;

  return elapsedTime > interval;
};

import * as R from 'remeda';
import { createSelector } from 'reselect';
import { isTrialSelector } from '../../../redux/modules/organization/selectors';
import { userSelector } from '../../../redux/modules/user/selectors';
import { isFeatureFlagEnabled } from '../../../unleash/useFeatureFlag';
import {
  isEmbeddedBlocker,
  isInternalUserBlocker,
  isTrialBlocker,
} from '../blockers';

const areSurveysDisabledBlocker = () =>
  isFeatureFlagEnabled('ac-5360-research-call-surveys')
    ? undefined
    : 'research_call_surveys_disabled';

export const researchCallSurveyBlockersSelector = createSelector(
  userSelector,
  isTrialSelector,
  (currentUser, isTrial) => {
    return [
      // no survey in embedded mode
      isEmbeddedBlocker(),
      // do not show to internal users
      isInternalUserBlocker(currentUser),
      // do not show to trial users
      isTrialBlocker(isTrial),
      areSurveysDisabledBlocker(),
    ].filter(R.isTruthy);
  }
);

const isNotSelected = <U extends { id: number }>(currentUser: U) =>
  // only 10 % of users should see this survey
  currentUser.id % 10 === 0 ? undefined : 'not_selected';

export const dashboardResearchCallSurveyBlockersSelector = createSelector(
  researchCallSurveyBlockersSelector,
  userSelector,
  (blockers, currentUser) => {
    return [...blockers, isNotSelected(currentUser)].filter(R.isTruthy);
  }
);

import { useDispatch } from 'react-redux';
import { throwError, throwInfo } from '../../../redux/modules/messages/actions';

type EngineInfoMessageKey =
  | 'engineCreated'
  | 'engineDeleted'
  | 'engineDuplicated'
  | 'engineUpdated'
  | 'engineFieldCreated'
  | 'engineFieldDeleted'
  | 'engineFieldUpdated';

type EngineErrorMessageKey = 'queueScheduledForDeletion';

export const useToastMessage = () => {
  const dispatch = useDispatch();

  const showToastMessage = (key: EngineInfoMessageKey) =>
    dispatch(throwInfo(key));

  const showErrorMessage = (key: EngineErrorMessageKey) =>
    dispatch(throwError(key));

  return { showToastMessage, showErrorMessage };
};

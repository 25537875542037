import { Relation } from '@rossum/api-client/relations';
import { take, uniq } from 'lodash';
import { getAnnotationId } from '../../lib/url';
import { SnakeCaseStatus } from '../../types/annotationStatus';

const MAX_ANNOTATIONS_COUNT = 100;

export const getDuplicatesQuery = (relations: Array<Relation>) => {
  const annotationIds = take(
    uniq(relations.flatMap(r => r.annotations)),
    MAX_ANNOTATIONS_COUNT + 1
  ).map(annotation => getAnnotationId(annotation));

  return {
    pageSize: MAX_ANNOTATIONS_COUNT + 1,
    sideload: ['documents' as const, 'modifiers' as const],
    id: annotationIds,
  };
};

export const getCurrentRelationShape = <
  A extends {
    url: string;
    modifier: string | null;
    id: number;
    createdAt: string;
    queue: string;
    status: SnakeCaseStatus;
  },
  U extends { url: string; id: number; username?: string },
  D extends { annotations: Array<string>; originalFileName: string | null },
>(
  {
    results,
    documents,
    modifiers,
  }: { results: Array<A>; modifiers: Array<U>; documents: Array<D> },
  urls: Array<string>
) => {
  const bigRelation = results.length > MAX_ANNOTATIONS_COUNT;

  const _annotations = take(results, MAX_ANNOTATIONS_COUNT).map(annotation => ({
    ...annotation,
    document: documents.find(
      ({ annotations }) => annotations[0] === annotation.url
    ),
    modifier: modifiers.find(({ url }) => url === annotation.modifier) || {
      id: undefined,
      username: undefined,
    },
  }));

  return {
    bigRelation,
    annotations: _annotations,
    urls,
  };
};

import { endpoints } from '@rossum/api-client';
import { EngineFieldListQuery } from '@rossum/api-client/engineFields';
import { EngineField } from '@rossum/api-client/engineFields';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';
import { engineFieldKeys } from '../keys';

export const useUnpaginatedEngineFields = <T = EngineField[]>(
  filters: EngineFieldListQuery,
  {
    select,
    enabled,
  }: { select?: (data: EngineField[]) => T; enabled?: boolean }
) => {
  return useQuery({
    queryKey: engineFieldKeys.unpaginatedList({ filters }),
    queryFn: () => api.unpaginatedRequest(endpoints.engineFields.list)(filters),
    select,
    enabled,
  });
};

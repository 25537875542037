import { Schema } from '@rossum/api-client/schemas';
import { DeleteOutline } from '@rossum/ui/icons';
import { Button } from '@rossum/ui/material';
import { Location } from 'history';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { DELETE_FIELD_FORM_ID, FIELD_FORM_ID } from '../fields/constants';
import { getFlattenSchemaFields, replaceUrlSegment } from '../fields/helpers';

export const useFieldsHeaderMeta = (
  parentPath: string,
  subPageKeys: string[],
  schema: Schema | undefined
) => {
  const intl = useIntl();

  const history = useHistory();

  const isListPage = subPageKeys.length === 0;

  const fieldsIdsMap = getFlattenSchemaFields(schema?.content || []);

  const currentField = subPageKeys.length
    ? fieldsIdsMap[subPageKeys.at(-1) ?? '']?.field
    : null;

  const isNewField = currentField === null || currentField === undefined;

  const dynamicFieldsBreadcrumbs = subPageKeys.map(key => {
    const field = fieldsIdsMap[key];
    return {
      value: key,
      label: field
        ? field.field.label
        : subPageKeys.length === 1
          ? intl.formatMessage({
              id: 'features.queueSettings.fields.breadcrumbs.newSection',
            })
          : intl.formatMessage({
              id: 'features.queueSettings.fields.breadcrumbs.newField',
            }),
      to: field
        ? `${parentPath}/${field.path?.map(field => field.id).join('/')}`
        : `${parentPath}`,
      isLink: true,
    };
  });

  const backLink =
    subPageKeys.length === 3 && dynamicFieldsBreadcrumbs[1]
      ? dynamicFieldsBreadcrumbs[1].to
      : `queues/${parentPath}/settings/fields`;

  const getFieldLabel = (fieldId: string) => {
    const field = fieldsIdsMap[fieldId];

    return field ? field.field.label : '';
  };

  const title = isListPage
    ? intl.formatMessage({
        id: 'features.queueSettings.fields.title',
      })
    : getFieldLabel(subPageKeys.at(-1) ?? '');

  const description = isListPage
    ? intl.formatMessage({
        id: 'features.queueSettings.fields.description',
      })
    : '';

  const onAddSection = useCallback(() => {
    const temporarySchemaSectionId = uuidv4();

    history.push({
      pathname: `${history.location.pathname}/${temporarySchemaSectionId}`,
      state: { backLink: history.location.pathname },
    });
  }, [history]);

  const buttons = isListPage
    ? [
        <Button
          key="json-schema"
          data-cy="edit-json-schema"
          component={Link}
          to={(location: Location) => ({
            pathname: `/${replaceUrlSegment(location.pathname ?? '', 'schema', 1)}`,
            state: { backLink: location.pathname },
          })}
          variant="outlined"
          color="secondary"
          sx={{
            '&:hover': {
              color: theme => theme.palette.secondary.main,
              textDecoration: 'none',
            },
          }}
        >
          {intl.formatMessage({
            id: 'containers.settings.queues.queue.editSchema',
          })}
        </Button>,
        <Button key="add-section" variant="contained" onClick={onAddSection}>
          {intl.formatMessage({
            id: 'features.queueSettings.actions.addSection',
          })}
        </Button>,
      ]
    : [
        // Edit JSON Button should be hidden when field is created from field manager
        !subPageKeys ? null : (
          <Button
            key="json-schema"
            data-cy="edit-json-schema"
            component={Link}
            to={(location: Location) => {
              const searchQuery = currentField?.id
                ? `id=${currentField.id}`
                : '';

              const allSegments = location.pathname.split('/');
              const fieldsIndex = allSegments.indexOf('fields');

              const segmentsToDrop = allSegments.slice(fieldsIndex).length;

              return {
                pathname: `/${replaceUrlSegment(
                  location.pathname ?? '',
                  `schema`,
                  segmentsToDrop
                )}`,
                state: { backLink: location.pathname },
                search: searchQuery,
              };
            }}
            variant="outlined"
            color="secondary"
            sx={{
              '&:hover': {
                color: theme => theme.palette.secondary.main,
                textDecoration: 'none',
              },
            }}
          >
            {intl.formatMessage({
              id: 'containers.settings.queues.queue.editSchema',
            })}
          </Button>
        ),

        // In case that field is not created user should be redirected back when clicks on the button
        isNewField ? (
          <Button
            key="cancel-field"
            component={Link}
            to={() => {
              return { pathname: backLink };
            }}
            variant="outlined"
            color="secondary"
            startIcon={<DeleteOutline />}
            data-cy="cancel-field-button"
          >
            {intl.formatMessage({
              id: 'features.queueSettings.actions.delete',
            })}
          </Button>
        ) : (
          <Button
            key="remove-field"
            variant="outlined"
            color="secondary"
            startIcon={<DeleteOutline />}
            type="submit"
            data-cy="remove-field-button"
            form={DELETE_FIELD_FORM_ID}
          >
            {intl.formatMessage({
              id: 'features.queueSettings.actions.delete',
            })}
          </Button>
        ),
        <Button
          key="submit-field-form"
          variant="contained"
          type="submit"
          form={FIELD_FORM_ID}
        >
          {intl.formatMessage({
            id: 'features.queueSettings.actions.save',
          })}
        </Button>,
      ];

  // TODO: Now we can play with this to improve functionality
  const onBackButtonClicked = useCallback(() => {
    // If the queue settings page was opened in a new tab, there is no history stack to go back, so fallbacking to "/documents" path instead
    if (history.length > 1) {
      return history.goBack();
    }
    return history.push('/documents');
  }, [history]);

  return {
    breadcrumbs: dynamicFieldsBreadcrumbs,
    title,
    description,
    buttons,
    onBackButtonClicked,
  };
};

import { useIntl } from 'react-intl';
import {
  Breadcrumb,
  Breadcrumbs,
} from '../../components/BreadcrumbsV2/Breadcrumbs';
import { settingsPath } from './helpers';

type SettingsBreadcrumbsProps = {
  breadcrumbs: Breadcrumb[];
};

export const SettingsBreadcrumbs = ({
  breadcrumbs,
}: SettingsBreadcrumbsProps) => {
  const intl = useIntl();

  const settingsBreadcrumbs = [
    {
      to: settingsPath(),
      label: intl.formatMessage({ id: 'components.appBar.settings' }),
    },
    ...breadcrumbs,
  ];

  return <Breadcrumbs breadcrumbs={settingsBreadcrumbs} />;
};

import { Paper, Stack } from '@rossum/ui/material';
import { ReactNode } from 'react';

type ContentWrapperProps = {
  children: ReactNode;
};

export const ContentWrapper = ({ children }: ContentWrapperProps) => (
  <Stack
    component={Paper}
    direction="column"
    px={4}
    py={3}
    width={1}
    sx={{
      borderRadius: 2,
    }}
  >
    {children}
  </Stack>
);

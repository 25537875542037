import { Survey } from '@rossum/api-client/surveys';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import z from 'zod';
import { State } from '../../../types/state';
import { parseAndValidate } from '../../../utils/jsonParse';
import { SurveyName } from '../surveysUuidMap';
import { useLatestSurvey } from './useLatestSurvey';

type UseSurveyIfEnabledProps = {
  surveyName: SurveyName;
  userEngaged: boolean;
  blockersSelector: (state: State, surveyType: SurveyName) => string[];
  getBlockersFromSurvey: (params: {
    survey: Survey;
    surveyName: SurveyName;
    userEngaged: boolean;
  }) => string[];
};

const SURVEY_OVERRIDES_KEY = 'surveyOverrides';
const surveyOverridesSchema = z.record(
  z.union([z.literal('true'), z.literal('')])
);

export const useSurveyIfEnabled = ({
  surveyName,
  userEngaged,
  blockersSelector,
  getBlockersFromSurvey,
}: UseSurveyIfEnabledProps) => {
  const surveyOverrides = parseAndValidate(
    localStorage.getItem(SURVEY_OVERRIDES_KEY),
    surveyOverridesSchema,
    {}
  );

  // Blockers for enabling / displaying surveys.
  const getSurveyDialogBlockers = useCallback(
    (survey: Survey) =>
      getBlockersFromSurvey({ survey, surveyName, userEngaged }),
    [surveyName, userEngaged, getBlockersFromSurvey]
  );

  const blockers = useSelector((state: State) =>
    blockersSelector(state, surveyName)
  );

  const { survey } = useLatestSurvey({
    surveyName,
    enabled: blockers.length === 0 || surveyOverrides[surveyName] === 'true',
  });

  const viewBlockers = survey ? getSurveyDialogBlockers(survey) : undefined;

  const shouldViewSurvey = viewBlockers
    ? surveyOverrides[surveyName] === 'true' || viewBlockers.length === 0
    : false;

  return {
    survey,
    blockers,
    viewBlockers,
    shouldViewSurvey,
    surveyName,
  };
};

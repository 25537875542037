import { FolderOpen } from '@rossum/ui/icons';
import { Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

type Props = {
  description: string;
};

export const NoWorkspacesPlaceholder = ({ description }: Props) => {
  const intl = useIntl();
  return (
    <Stack flex={1}>
      <Stack alignItems="center" spacing={1} my="auto">
        <FolderOpen />
        <Typography variant="h6">
          {intl.formatMessage({
            id: 'containers.settings.queues.noWorkspaces.title',
          })}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </Stack>
    </Stack>
  );
};

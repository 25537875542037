import { IconPhotoOff } from '@rossum/ui/icons/tabler';
import {
  alpha,
  Box,
  Paper,
  Stack,
  SvgIcon,
  Typography,
} from '@rossum/ui/material';
import { IntlShape } from 'react-intl';
import { link } from '../../../../lib/formaterValues';

type EmptyStateTextsProps = {
  title: string;
  intl: IntlShape;
};

export const EmptyStateTexts = ({ title, intl }: EmptyStateTextsProps) => {
  return (
    <Stack
      component={Paper}
      width="100%"
      height="100%"
      sx={{ bgcolor: t => alpha(t.palette.grey[600], 0.5) }}
      borderRadius={0}
    >
      <Box
        component={Stack}
        position="relative"
        top="25%"
        alignItems="center"
        textAlign="center"
        spacing={2}
        mb="50vh"
      >
        <SvgIcon
          component={IconPhotoOff}
          opacity={0.5}
          sx={{ fill: 'none', fontSize: 64, strokeWidth: 1 }}
        />

        <Stack width={250} spacing={2}>
          <Typography variant="h5" color="text.secondary">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {intl.formatMessage(
              {
                id: 'features.document.preview.error.text',
              },
              { link: link('https://status.rossum.ai/') }
            )}
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};

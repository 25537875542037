import { createSelector } from 'reselect';
import { State } from '../../../types/state';
import { currentAnnotationQueueSelector } from '../annotation/currentAnnotationQueueSelector';
import { safeOrganizationSelector } from '../organization/selectors';
import { complexTablesFeatureSelector } from '../organizationGroup/selectors';

export const showNetworkAlertSelector = (state: State) =>
  state.ui.showNetworkAlert;

export const repeatedStatusSelector = (state: State) =>
  state.ui.repeatedRequestStatus;

export const complexTablesEnabledOnOrganization = createSelector(
  complexTablesFeatureSelector,
  safeOrganizationSelector,
  currentAnnotationQueueSelector,
  (complexTablesFeature, organization, currentQueue) => {
    if (!complexTablesFeature?.requiresEnablement) {
      return !!complexTablesFeature?.enabled;
    }

    return !!(
      currentQueue?.settings.complexTables ||
      organization?.uiSettings?.complexTables
    );
  }
);

export const complexLineItemsEnabledSelector = (state: State) =>
  complexTablesEnabledOnOrganization(state) &&
  !!state.user.uiSettings.complexLineItems;

export const automaticSuggestionsEnabledSelector = (state: State) =>
  complexTablesEnabledOnOrganization(state) &&
  (state.user.uiSettings.automaticSuggestions ?? true);

export const auroraPromoCompletedSelector = (state: State) => {
  // Completing CLI tour also counts as seeing aurora promotion.
  return (
    state.user.uiSettings.auroraTourCompleted ||
    state.user.uiSettings.auroraPromoCompleted
  );
};

export const auroraTourCompletedSelector = (state: State) => {
  return state.user.uiSettings.auroraTourCompleted;
};

export const readOnlySelector = (state: State): boolean => state.ui.readOnly;

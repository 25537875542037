import { endpoints, Url } from '@rossum/api-client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@rossum/ui/material';
import { useQuery } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { useApiClient } from '../../lib/apiClient';
import DialogTitle from '../../ui/dialog-title/DialogTitle';
import { AttachmentsDrawer } from '../annotation-attachment/AttachmentsDrawer';
import { DuplicatesDrawer } from '../annotation-duplicates/DuplicatesDrawer';
import { SupportedAnnotationView } from '../document-list-base/supportedAnnotationViews';
import { WorkflowActivityDrawer } from '../workflows';
import DocumentEmailDrawer from './DocumentEmailDrawer';

type DocumentDrawerProps = {
  onClose: () => void;
  selectedAnnotationId: number | undefined;
  selectedAnnotationView: SupportedAnnotationView | undefined;
  handleSelectAnnotation: (params: {
    annotationUrl: string;
    view: SupportedAnnotationView;
  }) => void;
  onAnnotationDelete?: (annotation: Url) => void;
};

export const DocumentDrawer = ({
  selectedAnnotationId,
  selectedAnnotationView,
  onClose,
  handleSelectAnnotation,
  onAnnotationDelete,
}: DocumentDrawerProps) => {
  const api = useApiClient();

  const { data, isError, isSuccess } = useQuery({
    queryKey: ['annotation', selectedAnnotationId],
    enabled: selectedAnnotationId !== undefined,
    queryFn: () => {
      return selectedAnnotationId
        ? api.request(
            endpoints.annotations.list({
              id: [selectedAnnotationId],
            })
          )
        : null;
    },
  });

  const selectedAnnotation = data?.results?.[0];
  const intl = useIntl();

  return isError || (isSuccess && !selectedAnnotation) ? (
    <Dialog open PaperProps={{ elevation: 2 }}>
      <DialogTitle
        title={intl.formatMessage({
          id: 'components.modal.annotationNotFound.title',
        })}
      />
      <DialogContent sx={{ m: 3, p: 0, mb: 0 }}>
        <Typography>
          {intl.formatMessage({
            id: 'components.modal.annotationNotFound.text',
          })}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button onClick={onClose} variant="outlined" color="secondary">
          {intl.formatMessage({
            id: 'components.modal.annotationNotFound.cancel',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  ) : selectedAnnotation ? (
    <>
      {selectedAnnotationView === 'attachments' ? (
        <AttachmentsDrawer
          onClose={onClose}
          annotationUrl={selectedAnnotation.url}
          handleSelectAnnotation={handleSelectAnnotation}
        />
      ) : null}
      {selectedAnnotationView === 'duplicates' ? (
        <DuplicatesDrawer
          onClose={onClose}
          parentAnnotationUrl={selectedAnnotation.url}
          handleSelectAnnotation={handleSelectAnnotation}
          onAnnotationDelete={onAnnotationDelete}
        />
      ) : null}
      {selectedAnnotationView === 'activities' ? (
        <WorkflowActivityDrawer
          annotationUrl={selectedAnnotation.url}
          onClose={onClose}
        />
      ) : null}
      {selectedAnnotationView === 'emails' ? (
        <DocumentEmailDrawer
          emailThreadUrl={selectedAnnotation.emailThread}
          queueUrl={selectedAnnotation.queue}
          onClose={onClose}
        />
      ) : null}
    </>
  ) : null;
};

import { ExpandLess, ExpandMore, KeyboardArrowDown } from '@rossum/ui/icons';
import {
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Skeleton,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { partition } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { filter, isString } from 'remeda';
import { filterWorkspaces } from '../../../../redux/modules/workspaces/helpers';
import SearchInput from '../../../../ui/search-input/SearchInput';
import {
  doUnsavedValuesExist,
  getArrayValuesWithDefault,
  isEmptyValue,
} from '../../../document-list-base/mql/helpers';
import { SimpleFilterComponentProps } from '../../../document-list-base/mql/types';
import { useWorkspacesWithQueues } from '../../../queues/hooks/useWorkspacesWithQueues';
import FilterActionsWrapper from './FilterActionsWrapper';
import { operatorWrapperStyles } from './Operators';
import { useOperators } from './Operators/useOperators';
import SelectionHandlers from './SelectionHandlers';

// escapeRegExp is to avoid crashing errors
// it basically ensures that any special characters coming in inside value are considered to be a string
// as opposed to a regexp command (e.g. $/~ etc.)
const escapeRegExp = (value: string) =>
  value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const FilterQueueSelect = ({
  filterItem,
  applyFilter,
  onBackClick,
  onClose,
  convertToAdvancedFilter,
  isInAdvancedFilter,
}: SimpleFilterComponentProps) => {
  const [queuesSearch, setQueuesSearch] = useState<string>('');
  const [expandedWorkspaces, setExpandedWorkspaces] = useState<number[]>([]);

  const { filterContext } = filterItem;

  const topSelections = useRef<string[]>(
    filter(getArrayValuesWithDefault(filterContext.value), isString)
  ).current;
  const [selectedQueues, setSelectedQueues] = useState<string[]>(topSelections);

  const { workspacesWithQueues: queuesByWorkspace, isLoading } =
    useWorkspacesWithQueues({
      enableQueries: true,
    });

  const isSearchActive = (search: string) => search.length > 1;

  const queueOptions = useMemo(
    () =>
      queuesByWorkspace &&
      // If the search input has more than 1 character, filter the workspaces by the search input.
      (queuesSearch && isSearchActive(queuesSearch)
        ? filterWorkspaces(queuesSearch, queuesByWorkspace)
        : queuesByWorkspace
      )
        .filter(({ queues }) => queues.length)
        .map(({ name, queues, id }) => ({
          id,
          label: name,
          value: queues.map(queue => ({
            value: `${queue.id}`,
            label: queue.name,
          })),
        })),
    [queuesByWorkspace, queuesSearch]
  );

  const sortedQueueOptions = useMemo(
    () =>
      partition(queueOptions, ({ value }) =>
        value.some(({ value }) => topSelections.includes(value))
      ).flat(),
    [queueOptions, topSelections]
  );

  const queuesIds = queuesByWorkspace?.flatMap(w =>
    w.queues.map(queue => `${queue.id}`)
  );

  const handleOnClickOnMore = convertToAdvancedFilter
    ? () =>
        convertToAdvancedFilter(
          operator,
          doUnsavedValuesExist(selectedQueues, filterContext.value)
            ? selectedQueues
            : undefined
        )
    : undefined;

  const { node: Operators, operator } = useOperators({ filterItem });

  const highlightSearchedValue = (label: string, highlightValue: string) => {
    // Highlight the value only if the length of the search input is greater than 1 character.
    if (!isSearchActive(highlightValue)) return label;
    try {
      const safeHighlightValue = escapeRegExp(highlightValue);
      const labelParts = label.split(
        new RegExp(`(${safeHighlightValue})`, 'gi')
      );

      return labelParts.map(part =>
        part.toLowerCase() === highlightValue.toLowerCase() ? (
          <strong key={Math.random().toFixed(16)}>{part}</strong>
        ) : (
          <React.Fragment key={Math.random().toFixed(16)}>
            {part}
          </React.Fragment>
        )
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Regexp error: ', e);
      return label;
    }
  };

  // expand searched workspaces
  useEffect(() => {
    // Expand workspaces only if search input has more than 1 character
    const shouldUpdateExpandedWorkspaces =
      isSearchActive(queuesSearch) && queueOptions;

    if (shouldUpdateExpandedWorkspaces) {
      const workspacesToExpand = queueOptions.map(workspace => workspace.id);

      if (workspacesToExpand.length) {
        setExpandedWorkspaces(prev => [
          ...new Set([...prev, ...workspacesToExpand]),
        ]);
      }
    }

    // Collapse all workspaces if the search is not available
    if (!isSearchActive(queuesSearch)) setExpandedWorkspaces([]);
  }, [queueOptions, queuesSearch]);

  return (
    <FilterActionsWrapper
      onSubmit={() => {
        applyFilter({
          operator,
          value: selectedQueues,
        });
      }}
      onBackClick={onBackClick}
      isSubmitDisabled={isLoading || isEmptyValue(selectedQueues)}
      onCancel={onClose}
      onClickOnMore={handleOnClickOnMore}
      columnField={filterItem.column.field}
      disableOnMoreClick={isLoading}
    >
      <Stack sx={operatorWrapperStyles}>
        {!isInAdvancedFilter ? <Operators /> : null}
        {queuesIds && (
          <SelectionHandlers
            selectedValues={selectedQueues}
            valueOptions={queuesIds}
            setValues={setSelectedQueues}
          />
        )}
      </Stack>
      <SearchInput
        onChange={setQueuesSearch}
        value={queuesSearch}
        withStartAdornment={false}
        sx={{ mb: 2, width: '100%' }}
        inputProps={{
          'data-cy': 'queue-filter-component-search-input',
        }}
      />
      {isLoading ? (
        <Stack spacing={0.3} width="100%">
          {[...Array(5)].map((_, index) => (
            <Stack
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              flexDirection="row"
              alignItems="center"
              gap={2}
            >
              <Checkbox disabled sx={{ p: 0 }} />
              <Skeleton height={30} width="100%" variant="text" />
              <KeyboardArrowDown />
            </Stack>
          ))}
        </Stack>
      ) : (
        <Stack spacing={1} width="100%">
          <List
            disablePadding
            dense
            sx={{ maxHeight: 'min(600px, 40vh)', overflow: 'auto' }}
          >
            {sortedQueueOptions?.map(workspace => {
              const unfilteredWorkspace = queuesByWorkspace?.find(
                ws => ws.id === workspace.id
              );
              const isWorkspaceFullySelected =
                !!selectedQueues.length &&
                unfilteredWorkspace?.queues.every(({ id }) =>
                  selectedQueues.includes(id.toString())
                );
              const isWorkspacePartiallySelected =
                !!selectedQueues.length &&
                unfilteredWorkspace?.queues.some(({ id }) =>
                  selectedQueues.includes(id.toString())
                );

              return (
                <Stack key={workspace.id}>
                  <ListItem disablePadding disableGutters sx={{ my: 0.5 }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                      <Checkbox
                        indeterminate={Boolean(
                          !isWorkspaceFullySelected &&
                            isWorkspacePartiallySelected
                        )}
                        checked={Boolean(isWorkspaceFullySelected)}
                        onClick={() => {
                          const queuesOfThisWorkspace = workspace.value.map(
                            ({ value }) => value
                          );
                          const updatedQueues = isWorkspaceFullySelected
                            ? selectedQueues.filter(
                                id => !queuesOfThisWorkspace.includes(id)
                              )
                            : [...selectedQueues, ...queuesOfThisWorkspace];

                          setSelectedQueues(updatedQueues);
                        }}
                        sx={{ p: 0 }}
                      />
                    </ListItemIcon>
                    <ListItemButton
                      disableGutters
                      onClick={() =>
                        setExpandedWorkspaces(prev =>
                          prev.includes(workspace.id)
                            ? prev.filter(id => id !== workspace.id)
                            : [...prev, workspace.id]
                        )
                      }
                      sx={{ p: 0, pl: 1 }}
                    >
                      <Typography
                        mr="auto"
                        variant="body2"
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {highlightSearchedValue(workspace.label, queuesSearch)}
                      </Typography>

                      {expandedWorkspaces.includes(workspace.id) ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </ListItemButton>
                  </ListItem>
                  <Collapse in={expandedWorkspaces.includes(workspace.id)}>
                    <List disablePadding dense sx={{ pb: 1 }}>
                      {workspace.value.map(queue => {
                        const doesAlreadyExist = selectedQueues.includes(
                          queue.value
                        );
                        return (
                          <ListItem
                            disablePadding
                            disableGutters
                            key={queue.value}
                            dense
                          >
                            <ListItemButton
                              disableRipple
                              onClick={() => {
                                const updatedQueues = doesAlreadyExist
                                  ? selectedQueues.filter(
                                      id => id !== queue.value
                                    )
                                  : selectedQueues.concat(queue.value);

                                setSelectedQueues(updatedQueues);
                              }}
                            >
                              <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
                                <Checkbox
                                  checked={doesAlreadyExist}
                                  sx={{ p: 0 }}
                                />
                              </ListItemIcon>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {highlightSearchedValue(
                                  queue.label,
                                  queuesSearch
                                )}
                              </Typography>
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </Stack>
              );
            })}
          </List>
        </Stack>
      )}
    </FilterActionsWrapper>
  );
};

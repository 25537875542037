import { Workspace } from '@rossum/api-client/workspaces';
import { ExpandMore } from '@rossum/ui/icons';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@rossum/ui/material';
import { groupBy, some, sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useGetWorkspaces } from '../../hooks/useGetWorkspaces';
import { Queue } from '../../types/queue';
import { Dialog } from '../ui/Dialog';
import { LinkWithNameAndId } from '../ui/LinkWithNameAndId';

export type DataForQueuesDialog = {
  fieldId: string;
  queues: Queue[];
};

type QueueDialogProps = {
  rowForQueuesDialog: DataForQueuesDialog;
  setRowForQueuesDialog: React.Dispatch<
    React.SetStateAction<DataForQueuesDialog | null>
  >;
};

export const QueuesDialog = ({
  rowForQueuesDialog,
  setRowForQueuesDialog,
}: QueueDialogProps) => {
  const workspaceQueries = useGetWorkspaces();
  const intl = useIntl();
  const workspaces: Workspace[] = useMemo(
    () => workspaceQueries.data?.pages.flatMap(page => page.results) || [],
    [workspaceQueries.data]
  );
  const sortedNonEmptyWorkspaces = sortBy(workspaces, 'name').filter(
    workspace =>
      some(
        rowForQueuesDialog.queues,
        queue => queue.workspace === workspace.url
      )
  );
  const queuesByWorkspace = groupBy(rowForQueuesDialog.queues, 'workspace');

  return (
    <Dialog
      onClose={() => setRowForQueuesDialog(null)}
      open
      title={intl.formatMessage(
        {
          id: 'features.fieldManager.overview.queuesDialog.title',
        },
        { fieldId: rowForQueuesDialog.fieldId }
      )}
    >
      {sortedNonEmptyWorkspaces.map(workspace => (
        <Accordion
          key={workspace.id}
          defaultExpanded
          elevation={4}
          sx={{ '&:before': { display: 'none' } }}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">{workspace.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {sortBy(queuesByWorkspace[workspace.url] || [], 'name').map(
              queue => {
                return (
                  <LinkWithNameAndId
                    link={`/queues/${queue.id}/settings/fields/${rowForQueuesDialog.fieldId}`}
                    name={queue.name}
                    id={String(queue.id)}
                    key={queue.id}
                  />
                );
              }
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </Dialog>
  );
};

import { Typography } from '@rossum/ui/material';
import { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import { AUTOMATION_DATA_CAPTURE_LINK } from '../../constants/values';
import { link } from '../../lib/formaterValues';
import { Header, HeaderProps } from '../../ui/header/Header';
import SearchInput from '../../ui/search-input/SearchInput';
import { AutomationBreadcrumbs } from './AutomationBreadcrumbs';
import { AutomationTabs } from './AutomationTabs';

type Props = {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
} & Pick<HeaderProps, 'scrollableDivRef'>;

export const AutomationHeader = ({
  searchValue,
  setSearchValue,
  ...params
}: Props) => {
  const intl = useIntl();

  return (
    <Header
      {...params}
      title={intl.formatMessage({
        id: 'containers.settings.automation.header.title',
      })}
      description={
        <Typography variant="body2" color="text.secondary">
          {intl.formatMessage(
            {
              id: 'containers.settings.automation.header.description',
            },
            {
              link: link(AUTOMATION_DATA_CAPTURE_LINK),
            }
          )}
        </Typography>
      }
      breadcrumbs={
        <AutomationBreadcrumbs
          breadcrumbs={[
            {
              label: intl.formatMessage({
                id: 'components.appBar.menu.automation.automationSettings',
              }),
            },
          ]}
        />
      }
      tabs={<AutomationTabs value="automationSettings" />}
      buttons={[
        <SearchInput
          key="search-input"
          value={searchValue}
          onChange={value => setSearchValue(value)}
        />,
      ]}
    />
  );
};

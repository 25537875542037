import { ActionType, createAction } from 'typesafe-actions';
import { BboxParams } from '../../../lib/spaceConvertor';
import { ID, Url } from '../../../types/basic';
import {
  AnyDatapointData,
  AnyDatapointDataST,
  DatapointMetaData,
  DatapointsST,
  ReplaceSuggestedOperation,
  SimpleDatapointDataST,
  SuggestedOperation,
  TupleDatapointDataST,
  ValidationSources,
} from '../../../types/datapoints';
import { AnyDatapointSchema } from '../../../types/schema';
import {
  BatchDatapointUpdateMeta,
  CreateDatapointWithContentPayload,
  DatapointsFulfilledMeta,
  DatapointsFulfilledPayload,
  DeleteDatapointPayload,
  FetchDatapointsFulfilledPayload,
  FetchDatapointsMeta,
  NavigateDatapointMeta,
  NavigateTableMeta,
  OnDatapointSelectionMeta,
  SelectDatapointMeta,
  SetValidatedMeta,
  SetValidatedMetaPayload,
  UpdateDatapointMeta,
  UpdateDatapointPayload,
  UpdateDatapointValueMeta,
  UpdateDatapointValuePayload,
  UpdateMultivalueDatapoints,
  UpdatePositionMeta,
  UpdatePositionPayload,
  ValidateFulfilledMeta,
  ValidateFulfilledPayload,
} from './types';

export const lastDatapointsForTimeSpentUpdate = createAction(
  'LAST_DATAPOINTS_FOR_TIME_SPENT_UPDATE',
  (payload: { annotationUrl: string; datapoints: { id: number }[] }) => payload
)<{ annotationUrl: string; datapoints: { id: number }[] }>();

export const navigateRow = createAction(
  'NAVIGATE_ROW',
  undefined,
  (meta: { isForward?: boolean } = {}) => meta
)<undefined, NavigateTableMeta>();

export const navigateColumn = createAction(
  'NAVIGATE_COLUMN',
  undefined,
  (meta: { isForward?: boolean } = {}) => meta
)<undefined, NavigateTableMeta>();

export const validate = createAction(
  'VALIDATE',
  (
    updatedDatapointIds: number[],
    url?: string,
    actions?: Array<'started' | 'user_update' | 'updated'>
  ) => ({
    updatedDatapointIds,
    url,
    actions,
  })
)();

export const validationStart = createAction('VALIDATION_START')<void>();

export const validateFulfilled = createAction(
  'VALIDATE_FULFILLED',
  (payload: ValidateFulfilledPayload, _: ValidateFulfilledMeta) => payload,
  (_, meta) => meta
)<ValidateFulfilledPayload, ValidateFulfilledMeta>();

export const setValidatedMeta = createAction(
  'SET_VALIDATED_META',
  (_index: number, isValidated = true, isHumanValidated = true) => ({
    isValidated,
    isHumanValidated,
  }),
  (index, _isValidated, _isHumanValidated) => ({ index })
)<SetValidatedMetaPayload, SetValidatedMeta>();

export const nextRow = () => navigateRow();

export const previousRow = () => navigateRow({ isForward: false });

export const nextColumn = () => navigateColumn();

export const previousColumn = () => navigateColumn({ isForward: false });

export const nextDatapoint = createAction(
  'NAVIGATE_DATAPOINTS',
  undefined,
  () => ({
    unvalidated: false,
    isForward: true,
  })
)<undefined, NavigateDatapointMeta>();

export const previousDatapoint = createAction(
  'NAVIGATE_DATAPOINTS',
  undefined,
  () => ({
    unvalidated: false,
    isForward: false,
  })
)<undefined, NavigateDatapointMeta>();

export const nextUnvalidatedDatapoint = createAction(
  'NAVIGATE_DATAPOINTS',
  undefined,
  () => ({
    unvalidated: true,
    isForward: true,
  })
)<undefined, NavigateDatapointMeta>();

export const createDatapoint = createAction(
  'CREATE_DATAPOINTS',
  (parentIndex: number) => ({
    parentIndex,
  })
)<UpdateMultivalueDatapoints>();

export const deleteDatapoint = createAction(
  'DELETE_DATAPOINT',
  (path: number[]) => ({ path })
)<DeleteDatapointPayload>();

export const deleteDatapointFulfilled = createAction(
  'DELETE_DATAPOINT_FULFILLED',
  undefined,
  (parentIndex: number, deletedDatapointId: number) => ({
    parentIndex,
    deletedDatapointId,
  })
)<undefined, UpdateMultivalueDatapoints>();

export const createDatapointsFulfilled = createAction(
  'CREATE_DATAPOINTS_FULFILLED',
  (
    _parentIndex: number,
    _schemas: AnyDatapointSchema[],
    payload: DatapointsFulfilledPayload,
    _complexLineItemsEnabled: boolean,
    _meta?: Partial<DatapointMetaData>
  ) => payload,
  (parentIndex, schemas, _payload, complexLineItemsEnabled, meta?) => ({
    parentIndex,
    schemas,
    complexLineItemsEnabled,
    meta,
  })
)<DatapointsFulfilledPayload, DatapointsFulfilledMeta>();

export const selectDatapoint = createAction(
  'SELECT_DATAPOINT',
  (
    payload: number[],
    _meta?: { ignoreMeta?: boolean; noTail?: boolean; addValue?: boolean }
  ) => payload,
  (_payload, { ignoreMeta, noTail, addValue } = {}) => ({
    addValue,
    ignoreMeta,
    noTail,
  })
)<number[], SelectDatapointMeta>();

export const fetchDatapoints = createAction(
  'FETCH_DATAPOINTS',
  undefined,
  (annotationUrl: string) => ({ annotationUrl })
)<undefined, FetchDatapointsMeta>();

export const fetchDatapointsFulfilled = createAction(
  'FETCH_DATAPOINTS_FULFILLED',
  (payload: FetchDatapointsFulfilledPayload) => payload
)<FetchDatapointsFulfilledPayload>();

export const rerenderDatapoints = createAction('RERENDER_DATAPOINTS')<void>();

export const addSchemasToDatapoints = createAction(
  'ADD_SCHEMAS_TO_DATAPOINTS',
  (payload: AnyDatapointData[]) => payload
)<AnyDatapointData[]>();

export const deleteAllDatapoints = createAction(
  'DELETE_ALL_DATAPOINTS',
  (parentIndex: number) => ({ parentIndex })
)<UpdateMultivalueDatapoints>();

export const deleteAllDatapointsFulfilled = createAction(
  'DELETE_ALL_DATAPOINTS_FULFILLED',
  (parentIndex: number) => ({ parentIndex })
)<UpdateMultivalueDatapoints>();

export const updateDatapointMetaFulfilled = createAction(
  'UPDATE_DATAPOINT_META_FULFILLED'
)<void>();

type LogDatapointValueChangedMeta = {
  schemaId: string | undefined;
  reason:
    | 'input-sidebar'
    | 'input-edit-box'
    | 'suggested-bbox'
    | 'create-bbox'
    | 'resize-bbox'
    | 'move-bbox'
    | 'reset'
    | 'manual-formula-override-sidebar'
    | 'reconnect-formula-field';
  oldValue: string | undefined;
  newValue: string | undefined;
};

export const recountDatapointPositionFulfilled = createAction(
  'RECOUNT_DATAPOINT_POSITION_FULFILLED',
  (payload: AnyDatapointData, _meta?: LogDatapointValueChangedMeta) => payload,
  (_payload: AnyDatapointData, meta?: LogDatapointValueChangedMeta) => meta
)();

export const updateDatapointValueFulfilled = createAction(
  'UPDATE_DATAPOINT_CONTENT_FULFILLED',
  (payload: AnyDatapointData, _meta?: LogDatapointValueChangedMeta) => payload,
  (_payload: AnyDatapointData, meta?: LogDatapointValueChangedMeta) => meta
)();

export const resetDatapointFulfilled = createAction(
  'RESET_DATAPOINT_FULFILLED',
  (payload: AnyDatapointData, _meta?: LogDatapointValueChangedMeta) => payload,
  (_payload: AnyDatapointData, meta?: LogDatapointValueChangedMeta) => meta
)();

export const resetDatapoint = createAction(
  'RESET_DATAPOINT',
  (
    index: number,
    _meta?: { reason: 'reset'; oldValue: string | undefined }
  ) => ({ index }),
  (_index: number, meta?: { reason: 'reset'; oldValue: string | undefined }) =>
    meta
)();

export const recountDatapointPosition = createAction(
  'RECOUNT_DATAPOINT_POSITION',
  (index: number, _meta) => ({
    index,
  }),
  (
    _index,
    {
      oldValue,
      reason,
    }: {
      oldValue: string | undefined;
      reason: 'suggested-bbox' | 'resize-bbox' | 'create-bbox' | 'move-bbox';
    }
  ) => ({ oldValue, reason })
)();

export const updatePosition = createAction(
  'UPDATE_POSITION',
  (_meta: UpdatePositionMeta, payload: UpdatePositionPayload) => payload,
  ({ index, page }, _payload) => ({ index, page })
)<UpdatePositionPayload, UpdatePositionMeta>();

export const updateDatapointValue = createAction(
  'UPDATE_DATAPOINT_CONTENT',
  (
    _meta: {
      id: number;
      index: number;
      validationSource?: string;
      oldValue: string | number | undefined;
      reason:
        | 'input-sidebar'
        | 'input-edit-box'
        | 'manual-formula-override-sidebar'
        | 'reconnect-formula-field';
      noRecalculation?: boolean;
    },
    value: string
  ) => ({ value }),
  (
    { id, index, validationSource, oldValue, reason, noRecalculation },
    _payload
  ) => ({
    id,
    index,
    oldValue,
    reason,
    validationSource,
    noRecalculation,
  })
)<UpdateDatapointValuePayload, UpdateDatapointValueMeta>();

export const updateDatapointMeta = createAction(
  'UPDATE_DATAPOINT_META',
  (
    _meta: {
      id: number;
      index: number;
      annotationUrl: Url;
    },
    payload: UpdateDatapointPayload
  ) => payload,
  ({ id, index, annotationUrl }, _payload) => ({
    id,
    index,
    annotationUrl,
  })
)<UpdateDatapointPayload, UpdateDatapointMeta>();

export const onDatapointSelection = createAction(
  'ON_DATAPOINT_SELECTION',
  undefined,
  ({ id, ignoreMeta, datapointPath, schemaId }: OnDatapointSelectionMeta) => ({
    id,
    ignoreMeta,
    datapointPath,
    schemaId,
  })
)();

export const datapointSelectionFulfilled = createAction(
  'DATAPOINT_SELECTION_FULFILLED',
  ({
    datapointPath,
  }: {
    datapointPath: (AnyDatapointDataST | undefined)[];
  }) => ({
    datapointPath,
  })
)();

export const deleteDatapointAndNavigate = createAction(
  'DELETE_AND_NAVIGATE',
  undefined,
  (path: number[]) => ({ path })
)<undefined, DeleteDatapointPayload>();

export const selectNearestDatapoint = createAction(
  'SELECT_NEAREST_DATAPOINT'
)<void>();

export const createDatapointWithPosition = createAction(
  'CREATE_DATAPOINT_WITH_POSITION',
  (_: number, { position, page }: CreateDatapointWithContentPayload) => ({
    page,
    position,
  }),
  (parentIndex, _payload) => ({ parentIndex })
)<CreateDatapointWithContentPayload, UpdateMultivalueDatapoints>();

export const batchDatapointUpdate = createAction(
  'BATCH_DATAPOINT_UPDATE',
  undefined,
  (
    ids: number[],
    datapointIndex: number,
    noRecalculation?: boolean | undefined
  ) => ({
    datapointIndex,
    ids,
    noRecalculation,
  })
)<undefined, BatchDatapointUpdateMeta>();

export const batchDatapointUpdateV2 = createAction(
  'BATCH_DATAPOINT_UPDATE_V2',
  undefined,
  (
    columnSchemaId: string,
    sourceTupleId: number,
    targetTupleIds: number[],
    noRecalculation?: boolean | undefined
  ) => ({ columnSchemaId, sourceTupleId, targetTupleIds, noRecalculation })
)();

export const bulkUpdateDatapointValue = createAction(
  'BULK_UPDATE_DATAPOINT_VALUE',
  undefined,
  (updates: {
    [id: number]: { newValue: string; noRecalculation?: boolean | undefined };
  }) => ({ updates })
)();

export const batchDatapointUpdateFulfilled = createAction(
  'BATCH_DATAPOINT_UPDATE_FULFILLED',
  undefined,
  (ids: number[]) => ({ ids })
)();

export const batchDatapointValuesUpdateFulfilled = createAction(
  'BATCH_DATAPOINT_VALUES_UPDATE_FULFILLED',
  undefined,
  (ids: ID[]) => ({
    ids,
  })
)<undefined, { ids: ID[] }>();

export const sidebarDatapointHasChangedAction = createAction(
  'SIDEBAR_DATAPOINT_HAS_CHANGED'
)<void>();

export const bboxCreatedAction = createAction(
  'BBOX_CREATED',
  (schemaId: string | undefined) => ({ schemaId })
)();

export const setUnvalidatedContent = createAction(
  'SET_UNVALIDATED_CONTENT',
  (payload: boolean) => payload
)();

export const changeDatapointsSchemaIds = createAction(
  'CHANGE_DATAPOINTS_SCHEMA_IDS',
  (
    operations: Array<{
      source: SimpleDatapointDataST;
      target: SimpleDatapointDataST;
    }>,
    _: unknown
  ) => ({ operations }),
  (_, schemaMap: ReadonlyMap<string, AnyDatapointSchema>) => ({
    schemaMap,
  })
)();

export const materializeVirtualDatapoints = createAction(
  'MATERIALIZE_VIRTUAL_DATAPOINTS',
  (payload: {
    tuplesToCreate: {
      tupleId: number;
      validatedDatapointIds: number[];
      createWithContent?: boolean;
    }[];
    datapointsToReplace: { datapointId: number; isValidated: boolean }[];
    multivalueId: number;
  }) => payload
)();

export const materializeVirtualDatapointsFulfilled = createAction(
  'MATERIALIZE_VIRTUAL_DATAPOINTS_FULLFILLED',
  (payload: {
    virtualToRealIdMap: {
      [key: number]: {
        id: number;
        url: string;
        validationSources?: ValidationSources[];
      };
    };
    optimisticReplaceSuggestedOperations: ReplaceSuggestedOperation[];
    acceptedReplaceSuggestedDatapointIds: number[];
  }) => payload
)();

export const setWaitingForSuggestions = createAction(
  'SET_WAITING_FOR_SUGGESTIONS',
  (payload: boolean) => payload
)();

export const suggestTable = createAction(
  'SUGGEST_TABLE',
  (payload: {
    multivalueId: number;
    pagesChunks: number[][];
    ghostRow?: { page: number; position: BboxParams };
  }) => payload
)();

export const discardSuggestions = createAction('DISCARD_SUGGESTIONS')<void>();

export const suggestTableFullfilled = createAction(
  'SUGGEST_TABLE_FULLFILLED',
  (payload: {
    updatedDatapoints: AnyDatapointData[];
    suggestedOperations: SuggestedOperation[];
    newState: DatapointsST;
  }) => payload
)();

export const batchDatapointDelete = createAction(
  'BATCH_DATAPOINT_DELETE',
  (payload: {
    tuplesToDelete: { id: number; index: number; parentIndex: number | null }[];
    datapointsToReset: { id: number; index: number }[];
  }) => payload
)();

export const batchDatapointDeleteFulfilled = createAction(
  'BATCH_DATAPOINT_DELETE_FULFILLED'
)<void>();

export const batchDatapointConfirm = createAction(
  'BATCH_DATAPOINT_CONFIRM',
  (payload: { datapoints: SimpleDatapointDataST[] }) => payload
)();

export const insertLine = createAction(
  'INSERT_LINE',
  (payload: { tuple: TupleDatapointDataST }) => payload
)();

export const createGhostRow = createAction('CREATE_GHOST_ROW')<void>();

export const setPendingValidationForFormulaDatapoint = createAction(
  'SET_PENDING_VALIDATION_FOR_FORMULA_DATAPOINT',
  undefined,
  (datapointId: number) => datapointId
)();

export type PreviousRow = typeof previousRow;
export type NextRow = typeof nextRow;
export type NextColumn = typeof nextColumn;
export type PreviousColumn = typeof previousColumn;
export type NextDatapoint = typeof nextDatapoint;
export type PreviousDatapoint = typeof previousDatapoint;
export type NextUnvalidatedDatapoint = typeof nextUnvalidatedDatapoint;
export type CreateDatapoint = typeof createDatapoint;
export type SelectDatapoint = typeof selectDatapoint;
export type DeleteAllDatapoints = typeof deleteAllDatapoints;
export type ResetDatapoint = typeof resetDatapoint;
export type RecountDatapointPosition = typeof recountDatapointPosition;
export type UpdatePosition = typeof updatePosition;
export type UpdateDatapointValue = typeof updateDatapointValue;
export type DeleteDatapointAndNavigate = typeof deleteDatapointAndNavigate;
export type CreateDatapointWithContent = typeof createDatapointWithPosition;
export type BatchDatapointUpdate = typeof batchDatapointUpdate;
export type RerenderDatapoints = typeof rerenderDatapoints;

export type DatapointsActions = ActionType<
  | typeof navigateColumn
  | typeof navigateRow
  | typeof validate
  | typeof validationStart
  | typeof validateFulfilled
  | NextRow
  | PreviousRow
  | NextColumn
  | PreviousColumn
  | NextDatapoint
  | PreviousDatapoint
  | NextUnvalidatedDatapoint
  | CreateDatapoint
  | typeof deleteDatapoint
  | typeof deleteDatapointFulfilled
  | typeof createDatapointsFulfilled
  | SelectDatapoint
  | typeof fetchDatapoints
  | typeof fetchDatapointsFulfilled
  | typeof addSchemasToDatapoints
  | DeleteAllDatapoints
  | typeof deleteAllDatapointsFulfilled
  | typeof updateDatapointMetaFulfilled
  | typeof recountDatapointPositionFulfilled
  | typeof updateDatapointValueFulfilled
  | typeof resetDatapointFulfilled
  | ResetDatapoint
  | RecountDatapointPosition
  | UpdatePosition
  | UpdateDatapointValue
  | typeof updateDatapointMeta
  | typeof onDatapointSelection
  | DeleteDatapointAndNavigate
  | typeof selectNearestDatapoint
  | CreateDatapointWithContent
  | BatchDatapointUpdate
  | typeof batchDatapointUpdateV2
  | typeof bulkUpdateDatapointValue
  | typeof batchDatapointUpdateFulfilled
  | RerenderDatapoints
  | typeof batchDatapointValuesUpdateFulfilled
  | typeof setValidatedMeta
  | typeof sidebarDatapointHasChangedAction
  | typeof lastDatapointsForTimeSpentUpdate
  | typeof bboxCreatedAction
  | typeof setUnvalidatedContent
  | typeof changeDatapointsSchemaIds
  | typeof materializeVirtualDatapoints
  | typeof materializeVirtualDatapointsFulfilled
  | typeof suggestTable
  | typeof suggestTableFullfilled
  | typeof setWaitingForSuggestions
  | typeof batchDatapointDelete
  | typeof batchDatapointDeleteFulfilled
  | typeof batchDatapointConfirm
  | typeof discardSuggestions
  | typeof insertLine
  | typeof createGhostRow
  | typeof setPendingValidationForFormulaDatapoint
>;

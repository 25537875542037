import { endpoints, ID } from '@rossum/api-client';
import { HttpError } from '@rossum/api-client/errors';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';
import { engineKeys, mutationKey } from '../keys';
import { useToastMessage } from './useToastMessage';

export const useDeleteEngine = () => {
  const queryClient = useQueryClient();
  const { showToastMessage, showErrorMessage } = useToastMessage();

  return useMutation({
    mutationKey: mutationKey(['delete_engine'] as const),
    mutationFn: (id: ID) => api.request(endpoints.engines.delete(id)),
    onSuccess: (_, id) => {
      showToastMessage('engineDeleted');
      queryClient.removeQueries(engineKeys.detail(id));
    },
    onError: e => {
      // TODO: So far we only need to throw an error in this case :shrug:
      if (
        e instanceof HttpError &&
        e.code === 400 &&
        // TODO: First semblance of localized BE errors? This is a hack until we design a nicer solution
        (e.data as { code: string; detail: string }).code ===
          'engine_attached_to_queues_waiting_for_deletion'
      ) {
        showErrorMessage('queueScheduledForDeletion');
      }
    },
  });
};

import { endpoints, getIDFromUrl, Url } from '@rossum/api-client';
import {
  DocumentsDownloadsPayload,
  DocumentsDownloadsType,
} from '@rossum/api-client/documents';
import { HttpError } from '@rossum/api-client/errors';
import { useMutation } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { api } from '../../../lib/apiClient';
import { throwError } from '../../../redux/modules/messages/actions';
import { useTaskContext } from '../TaskContext';

/** Returns a mutation that triggers an async download of original documents */
export const useDownloadOriginalDocuments = () => {
  const { registerAsyncTask } = useTaskContext();

  const dispatch = useDispatch();

  return useMutation({
    mutationFn: ({
      documents,
      type = 'source_document',
    }: {
      documents: Url[];
      type?: DocumentsDownloadsType;
    }) => {
      const firstDocument = documents[0];

      const fileName = `rossum-documents-${format(
        new Date(),
        'yyyy-MM-dd-HH-mm'
      )}.zip`;

      const payload: DocumentsDownloadsPayload =
        documents.length === 1 && firstDocument
          ? {
              zip: false,
              documents: [firstDocument],
              type,
            }
          : {
              documents,
              fileName,
              type,
            };

      return api.request(endpoints.documents.downloads(payload));
    },

    onSuccess: response => {
      if (response) {
        const taskId = getIDFromUrl(response.url);

        // in this handler we want to fetch download data and trigger streaming download
        registerAsyncTask({ taskId });
      }
    },

    // This request is throttled at 10 requests/minute, then causes a 429 to return
    onError: error => {
      if (error instanceof HttpError && error.code === 429) {
        dispatch(throwError('bulkDownloadError'));
      }
    },
  });
};

import { Button, Stack } from '@rossum/ui/material';
import { GridFilterItem, GridFilterModel } from '@rossum/ui/x-data-grid-pro';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { isFilterContextSimple } from '../../document-list-base/mql/helpers';
import {
  getInitialFilter,
  getUpdatedExistingFilter,
} from '../../document-list-base/mql/transformers';
import {
  FilterItem,
  FiltersState,
  TypedGridColDef,
} from '../../document-list-base/mql/types';
import { AddFilter, VERTICAL_SPACING } from './AddFilterButton';
import { FilterChips } from './FilterChips';

type Props = {
  columns: TypedGridColDef[];
  existingFilter: GridFilterModel | null;
  handleFilterModelChange: (fm: GridFilterItem[]) => void;
  handleClearAllFilters: () => void;
  isLoading?: boolean;
};

export const FilterPanel = ({
  columns,
  existingFilter,
  handleFilterModelChange,
  handleClearAllFilters,
  isLoading,
}: Props) => {
  const intl = useIntl();

  const filters = useMemo<FiltersState | null>(() => {
    return existingFilter ? getInitialFilter(existingFilter, columns) : null;
  }, [columns, existingFilter]);

  const changeFilterModel = useCallback(
    (gridFilterItems: GridFilterItem[], isReplaceAction?: boolean) => {
      const updatedFilterModel = getUpdatedExistingFilter({
        existingFilter,
        incomingFilters: gridFilterItems,
        isReplaceAction,
      });
      handleFilterModelChange(updatedFilterModel);
    },
    [existingFilter, handleFilterModelChange]
  );

  const removeFilter = (field: string) => {
    if (existingFilter) {
      const newFilterModel = existingFilter.items.filter(
        item => item.field !== field
      );
      handleFilterModelChange(newFilterModel);
    }
  };

  const applyFilter = ({
    column,
    filterContext,
    isReplaceAction,
  }: FilterItem & { isReplaceAction?: boolean }) => {
    const contextArray = isFilterContextSimple(filterContext)
      ? [filterContext]
      : filterContext;

    const gridFilterItems: GridFilterItem[] = contextArray.map(
      ({ operator, value }) => ({
        field: column.field,
        operator: operator.value,
        value,
      })
    );

    changeFilterModel(gridFilterItems, isReplaceAction);
  };

  const areFiltersReady = !isLoading && filters;

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      justifyContent="space-between"
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        flexWrap="wrap"
        sx={{ my: VERTICAL_SPACING }}
      >
        {areFiltersReady ? (
          <FilterChips
            filters={filters}
            applyFilter={(filterContext, column) =>
              applyFilter({
                column,
                filterContext,
                isReplaceAction: true,
              })
            }
            removeFilter={removeFilter}
          />
        ) : null}

        <AddFilter
          columns={columns.filter(c => !filters?.[c.field])}
          onConfirm={applyFilter}
          isLoading={isLoading}
        />
      </Stack>
      {areFiltersReady && (
        <Button
          variant="text"
          color="secondary"
          sx={{ textWrap: 'nowrap' }}
          onClick={handleClearAllFilters}
          data-cy="all-documents-clear-all-filters-button"
        >
          {intl.formatMessage({ id: 'containers.filtering.buttons.clear' })}
        </Button>
      )}
    </Stack>
  );
};

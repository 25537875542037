import UserAvatarGroup from '../../../ui/user-avatar/UserAvatarGroup';
import { DocumentModifier } from '../../document-list-base/helpers/resolveModifierFromUrl';
import { CellProps } from '../types';

export const RenderAssignees = ({ value }: CellProps<DocumentModifier[]>) => {
  const users = value?.flatMap(modifier =>
    typeof modifier !== 'string' ? [modifier] : []
  );

  if (!users || users.length === 0) return null;

  return <UserAvatarGroup users={users} />;
};

import { useIntl } from 'react-intl';
import * as R from 'remeda';
import {
  Breadcrumb,
  Breadcrumbs,
} from '../../../components/BreadcrumbsV2/Breadcrumbs';

// TODO: is this too optimistic?? change it after upgrade of react-router
const getQueuePath = (settingsPath: string) => {
  return R.dropLast(settingsPath.split('/'), 1).join('/');
};

type QueueSettingsBreadcrumbsProps = {
  queueName: string;
  settingsPath: string;
  breadcrumbs: Breadcrumb[];
};

export const QueueSettingsBreadcrumbs = ({
  queueName,
  settingsPath,
  breadcrumbs,
}: QueueSettingsBreadcrumbsProps) => {
  const intl = useIntl();

  const QueueSettingsBreadcrumbs = [
    {
      label: intl.formatMessage({
        id: 'features.queueSettings.breadcrumbs.queues',
      }),
    },
    { label: queueName, to: getQueuePath(settingsPath) },
    {
      label: intl.formatMessage({
        id: 'features.queueSettings.breadcrumbs.settings',
      }),
      to: settingsPath,
    },
    ...breadcrumbs,
  ];

  return <Breadcrumbs breadcrumbs={QueueSettingsBreadcrumbs} />;
};

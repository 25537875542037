import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEV_FEATURES_ENABLED } from '../../../constants/config';
import { resumeValidation } from '../../../redux/modules/beforeLeave/actions';
import { State } from '../../../types/state';
import { DevToolsSync } from '../DevToolsSync';
import { useSurveyIfEnabled } from '../hooks/useSurveyIfEnabled';
import {
  cliSurveyBlockersSelector,
  dcSatisfactionSurveyBlockersSelector,
} from './blockersSelectors';
import {
  getCLISurveyBlockersFromSurvey,
  getDataCaptureSurveysBlockersFromSurvey,
} from './dialogBlockers';
import { AuroraSurveyDialog } from './dialogs/AuroraSurveyDialog';
import { SatisfactionSurveyDialog } from './dialogs/SatisfactionSurveyDialog';

type DataCaptureSurveyProps = {
  annotationId: number;
  onConfirm?: () => void;
  onSkip?: () => void;
};

const DataCaptureSurvey = ({ annotationId }: DataCaptureSurveyProps) => {
  // Redux stuff
  const dispatch = useDispatch();

  const [userEngaged, setUserEngaged] = useState(false);

  // we have sufficient engagement for survey feedback after 10s spent w/ this component rendered
  // with particular annotationId
  // clear state when rerendered
  useEffect(() => {
    const timeoutId = setTimeout(() => setUserEngaged(true), 10 * 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [annotationId]);

  const auroraSurveyInfo = useSurveyIfEnabled({
    surveyName: 'auroraForLineItemsSurvey',
    userEngaged,
    blockersSelector: cliSurveyBlockersSelector,
    getBlockersFromSurvey: getCLISurveyBlockersFromSurvey,
  });

  const satisfactionSurveyInfo = useSurveyIfEnabled({
    surveyName: 'dataCaptureSurvey',
    userEngaged,
    blockersSelector: dcSatisfactionSurveyBlockersSelector,
    getBlockersFromSurvey: getDataCaptureSurveysBlockersFromSurvey,
  });

  const leavingAnnotationScreen = useSelector(
    (state: State) =>
      state.beforeLeave.current !== undefined &&
      state.beforeLeave.current.reason === 'surveys'
  );

  const activeSurvey = auroraSurveyInfo.shouldViewSurvey
    ? ('aurora' as const)
    : satisfactionSurveyInfo.shouldViewSurvey
      ? ('satisfaction' as const)
      : undefined;

  useEffect(() => {
    // Navigate away from validation screen if there is no pending survey.
    if (leavingAnnotationScreen && !activeSurvey) {
      dispatch(resumeValidation());
    }
  }, [activeSurvey, dispatch, leavingAnnotationScreen]);

  // For debugging.
  const surveyDevToolsAction = useMemo(
    () => ({
      type: `@@ELIS_DEVTOOLS/SET_SURVEY_STATE` as const,
      payload: [
        {
          label: 'Aurora for CLI',
          ...auroraSurveyInfo,
        },
        {
          label: 'Satisfaction',
          ...satisfactionSurveyInfo,
        },
      ],
    }),
    [auroraSurveyInfo, satisfactionSurveyInfo]
  );

  return (
    <>
      {DEV_FEATURES_ENABLED && <DevToolsSync action={surveyDevToolsAction} />}
      {auroraSurveyInfo.survey && (
        <AuroraSurveyDialog
          open={leavingAnnotationScreen && activeSurvey === 'aurora'}
          survey={auroraSurveyInfo.survey}
        />
      )}
      {satisfactionSurveyInfo.survey && (
        <SatisfactionSurveyDialog
          open={leavingAnnotationScreen && activeSurvey === 'satisfaction'}
          survey={satisfactionSurveyInfo.survey}
        />
      )}
    </>
  );
};

export default DataCaptureSurvey;

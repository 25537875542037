import { endpoints } from '@rossum/api-client';
import { SchemaSection } from '@rossum/api-client/schemas';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { api } from '../../../../lib/apiClient';
import { throwError } from '../../../../redux/modules/messages/actions';

export const useSuggestFormula = () => {
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: ({
      hint,
      fieldSchemaId,
      schemaContent,
    }: {
      hint: string;
      schemaContent: SchemaSection[];
      fieldSchemaId: string;
    }) =>
      api.request(
        endpoints.internal.schemas.suggestFormula({
          schemaContent,
          fieldSchemaId,
          hint,
        })
      ),
    onError: () => {
      dispatch(throwError('clientError'));
    },
  });

  return mutation;
};

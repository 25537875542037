import { useIntl } from 'react-intl';
import { Header, HeaderProps } from '../../ui/header/Header';

type SettingsHeaderProps = Pick<HeaderProps, 'scrollableDivRef'>;

export const SettingsHeader = (props: SettingsHeaderProps) => {
  const intl = useIntl();

  return (
    <Header
      {...props}
      breadcrumbs={null}
      title={intl.formatMessage({
        id: 'components.appBar.settings',
      })}
      description={null}
    />
  );
};

import { isEmpty } from 'lodash';
import { Reducer } from 'redux';
import * as R from 'remeda';
import Immutable from 'seamless-immutable';
import { getType } from 'typesafe-actions';
import { RootActionType } from '../../rootActions';
import { clearAnnotationData } from '../annotation/actions';
import { logoutUser } from '../auth/actions';
import { enterQueueEditor, exitQueue, leaveValidation } from '../ui/actions';
import {
  fetchSchemaFulfilled,
  updateSchemaFulfilled,
  validateSchemaFulfilled,
} from './actions';
import { generateErrors } from './helpers';
import { SchemaState } from './types';

const initialState = Immutable<SchemaState>({
  content: undefined,
  elis_to_rir_mapping: undefined,
  id: undefined,
  name: undefined,
  originalContent: undefined,
  url: undefined,
  validSchema: [],
  validationErrors: undefined,
  validationTimeStamp: 0,
  workspaces: undefined,
  queues: [],
});

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchSchemaFulfilled):
    case getType(updateSchemaFulfilled):
      return state.merge(R.omit(action.payload, ['metadata']));

    case getType(validateSchemaFulfilled): {
      const {
        payload: {
          response: { content },
        },
        meta: { schema },
      } = action;

      const errorMessages = generateErrors(content);

      return state
        .set('validationErrors', errorMessages)
        .set('validationTimeStamp', Date.now())
        .update('validSchema', validSchema =>
          isEmpty(errorMessages) ? schema : validSchema
        );
    }

    case getType(leaveValidation):
    case getType(logoutUser):
    case getType(clearAnnotationData):
    case getType(enterQueueEditor):
    case getType(exitQueue):
      return initialState;

    default:
      return state;
  }
};

export default reducer;

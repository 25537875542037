import { createSelector } from 'reselect';
import { State } from '../../../types/state';
import { queuesSelector } from '../queues/selector';

const currentQueueUrlSelector = (state: State) => state.annotation.queue;

// In a separate file to resolve circular dependency.
export const currentAnnotationQueueSelector = createSelector(
  currentQueueUrlSelector,
  queuesSelector,
  (queueUrl, queues) => queues.find(({ url }) => url === queueUrl)
);

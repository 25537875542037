import {
  IconGoGame,
  IconTag,
  IconUserSquareRounded,
} from '@rossum/ui/icons/tabler';
import { Stack, SvgIcon } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { PageLayoutV2 } from '../../components/PageLayoutV2/PageLayoutV2';
import { fieldManagerPath } from '../../features/field-manager/constants';
import { labelsPath } from '../../features/labels/components/constants';
import { useLabelsEnabled } from '../../features/labels/hooks/useLabelsEnabled';
import { useFeatureFlag } from '../../unleash/useFeatureFlag';
import { usersPath } from '../Users/helpers';
import Tile from './components/Tile';
import { SettingsHeader } from './SettingsHeader';

const SettingsPage = () => {
  const intl = useIntl();

  const iconProps = {
    fontSize: 'large',
    color: 'info',
    sx: { fill: 'none' },
  } as const;

  const isLabelsEnabled = useLabelsEnabled();
  const isFieldManagerEnabled = useFeatureFlag(
    'ac-4198-field-manager-improvements'
  );

  const tiles = [
    {
      title: intl.formatMessage({
        id: 'containers.settings.users.header.title',
      }),
      description: intl.formatMessage({
        id: 'containers.settings.users.description',
      }),
      icon: <SvgIcon {...iconProps} component={IconUserSquareRounded} />,
      to: usersPath(),
    },
    ...(isFieldManagerEnabled
      ? [
          {
            title: intl.formatMessage({
              id: 'features.fieldManager.overview.ui.header.title',
            }),
            description: intl.formatMessage({
              id: 'features.fieldManager.description',
            }),
            icon: <SvgIcon {...iconProps} component={IconGoGame} />,
            to: fieldManagerPath(),
          },
        ]
      : []),
    ...(isLabelsEnabled
      ? [
          {
            title: intl.formatMessage({
              id: 'containers.settings.labels.header.title',
            }),
            description: intl.formatMessage({
              id: 'containers.settings.labels.description',
            }),
            icon: <SvgIcon {...iconProps} component={IconTag} />,
            to: labelsPath(),
          },
        ]
      : []),
  ];

  return (
    <PageLayoutV2 renderHeader={params => <SettingsHeader {...params} />}>
      <Stack px={4} py={4}>
        <Stack direction="row" spacing={4} alignItems="stretch">
          {tiles.map(tile => (
            <Tile key={tile.title} {...tile} />
          ))}
        </Stack>
      </Stack>
    </PageLayoutV2>
  );
};

export default SettingsPage;

import {
  Button,
  Checkbox,
  Collapse,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { getOptions } from '../helpers';
import { EnumPreview } from './EnumPreview';
import { FormulaPreview } from './FormulaPreview';

type ExpandableItemProps = {
  onClick: () => void;
  fieldVariant: string;
  countOfQueuesWithVariant: number;
  optionSelected: boolean;
  label: string;
  variant: 'options' | 'formula';
};

export const ExpandableItem = ({
  label,
  onClick,
  fieldVariant,
  optionSelected,
  countOfQueuesWithVariant,
  variant,
}: ExpandableItemProps) => {
  const [open, setOpen] = useState(false);

  const options = getOptions(fieldVariant);
  const intl = useIntl();

  return (
    <>
      <ListItem
        secondaryAction={
          <Tooltip
            title={intl.formatMessage(
              {
                id: 'features.fieldManager.fieldDetail.enumFormulaEditing.expandableItem.enum.tooltip',
              },
              { count: countOfQueuesWithVariant }
            )}
          >
            <ListItemSecondaryAction>
              {countOfQueuesWithVariant}
            </ListItemSecondaryAction>
          </Tooltip>
        }
        disablePadding
      >
        <ListItemButton onClick={onClick} dense>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={optionSelected}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={label} />
          {variant === 'options' && options.length > 0 ? (
            <Button
              variant="text"
              onClick={e => {
                e.stopPropagation();
                setOpen(!open);
              }}
            >
              {open
                ? intl.formatMessage({
                    id: 'features.fieldManager.fieldDetail.enumFormulaEditing.expandableItem.enum.button.hide',
                  })
                : intl.formatMessage({
                    id: 'features.fieldManager.fieldDetail.enumFormulaEditing.expandableItem.enum.button.show',
                  })}
            </Button>
          ) : fieldVariant === 'undefined' ? null : (
            <Button
              variant="text"
              onClick={e => {
                e.stopPropagation();
                setOpen(!open);
              }}
            >
              {open
                ? intl.formatMessage({
                    id: 'features.fieldManager.fieldDetail.enumFormulaEditing.expandableItem.formula.button.hide',
                  })
                : intl.formatMessage({
                    id: 'features.fieldManager.fieldDetail.enumFormulaEditing.expandableItem.formula.button.show',
                  })}
            </Button>
          )}
        </ListItemButton>
      </ListItem>
      <Collapse in={open} unmountOnExit>
        {variant === 'options' ? (
          <EnumPreview options={options} />
        ) : variant === 'formula' ? (
          <FormulaPreview value={fieldVariant} />
        ) : null}
      </Collapse>
    </>
  );
};

import { CSSObject } from '@rossum/ui/material';
import * as mime from 'mime-types';
import { IntlShape } from 'react-intl';
import { DocumentUploadWithoutStatus } from '../../../types/documents';
import { WorkspaceWithQueues } from '../../queues/select-queue/types';

export const absoluteMaxSizeStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
} as const satisfies CSSObject;

export const findQueueInWorkspace = (
  workspaces: WorkspaceWithQueues[],
  queueUrl: string
) => {
  const targetWorkspace = workspaces.find(w =>
    w.queues.some(q => q.url === queueUrl)
  );

  return targetWorkspace?.queues.find(q => q.url === queueUrl);
};

export type AdditionalUploadValues = {
  values?: Record<string, string>;
  metadata?: Record<string, string>;
};
export type UploadDocumentsPayload = Array<
  AdditionalUploadValues &
    DocumentUploadWithoutStatus & {
      file: File;
    }
>;

export const createFileSignature = ({ name, size }: File) => `${name}${size}`;

export const isValidFile = (
  file: File,
  acceptedMimeTypes: string[] | undefined
) => {
  if (!acceptedMimeTypes) return true;

  return (
    !!mime.contentType(file.type) &&
    acceptedMimeTypes.some(mimeType => {
      if (mimeType === file.type) return true;

      if (mimeType.includes('/*')) {
        return mimeType.split('/')?.[0] === file.type.split('/')?.[0];
      }

      return false;
    })
  );
};

export const determineSelectInputError = (
  submitCount: number,
  selectedQueue: boolean,
  intl: IntlShape
) => {
  if (submitCount <= 0) {
    return '';
  }

  if (!selectedQueue) {
    return intl.formatMessage({
      id: 'containers.annotationList.upload.queueSelect.required',
    });
  }

  return '';
};

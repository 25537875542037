import {
  FilterContext,
  FiltersState,
  TypedGridColDef,
} from '../../document-list-base/mql/types';
import { FilterChip } from './components/FilterChip';
import { FilterComponentSelector } from './components/FilterComponentSelector';

type Props = {
  filters: FiltersState;
  applyFilter: (filterContext: FilterContext, column: TypedGridColDef) => void;
  removeFilter: (field: string) => void;
};

export const FilterChips = ({ filters, applyFilter, removeFilter }: Props) => {
  return (
    <>
      {Object.values(filters).map(filter => {
        const { column } = filter;

        return (
          <FilterChip
            key={column.field}
            onRemove={() => removeFilter(column.field)}
            incomingFilterItem={filter}
          >
            {({
              onClose,
              onOperatorChange,
              convertToAdvancedFilter,
              filterItem,
            }) => {
              return (
                <FilterComponentSelector
                  applyFilter={filterContext => {
                    applyFilter(filterContext, column);
                    onClose();
                  }}
                  onClose={onClose}
                  filterItem={filterItem}
                  onOperatorChange={onOperatorChange}
                  convertToAdvancedFilter={convertToAdvancedFilter}
                />
              );
            }}
          </FilterChip>
        );
      })}
    </>
  );
};

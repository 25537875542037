import { IntlShape } from 'react-intl';
import * as z from 'zod';
import {
  editingOptions,
  formulaEditingOptions,
  numberFieldFormatOptions,
} from '../constants';
import {
  getErrorLabel,
  maxLengthMessage,
  requiredMessage,
  slugMessage,
} from './errors/errorMessages';
import { numericString } from './validations/numericString';
import { stringFieldValidations } from './validations/stringFieldValidations';

// TODO: Add validations and error messages
const editingOptionsSchema = (intl: IntlShape) =>
  z.enum(editingOptions, {
    invalid_type_error: intl.formatMessage(
      {
        id: 'features.queueSettings.fields.form.errors.invalidOption',
      },
      {
        field: getErrorLabel(intl, 'editing'),
      }
    ),
  });

// TODO: Add validations and error messages
const formulaEditingOptionsSchema = (intl: IntlShape) =>
  z.enum(formulaEditingOptions, {
    invalid_type_error: intl.formatMessage(
      {
        id: 'features.queueSettings.fields.form.errors.invalidOption',
      },
      {
        field: getErrorLabel(intl, 'editing'),
      }
    ),
  });

// Shared by all fields
export const sharedFieldSchema = (intl: IntlShape) =>
  z.object({
    id: z
      .string()
      .min(1, { message: requiredMessage(intl)(getErrorLabel(intl, 'id')) })
      .max(50, {
        message: maxLengthMessage(intl)(getErrorLabel(intl, 'id'), 50),
      })
      .regex(/^[-a-zA-Z0-9_]+$/, slugMessage(intl)(getErrorLabel(intl, 'id'))),
    label: z
      .string()
      .min(1, { message: requiredMessage(intl)(getErrorLabel(intl, 'label')) }),
    description: z.string(),
    hidden: z.boolean(),
    exported: z.boolean(),
    required: z.boolean(),
  });

// variations based on `valueSource`
export const capturedFieldSchema = (_intl: IntlShape) =>
  z.object({
    valueSource: z.literal('captured'),
    editing: editingOptionsSchema(_intl),
    aiEngineFields: z.array(z.string()),
    threshold: numericString(_intl),
  });

export const formulaFieldSchema = (intl: IntlShape) =>
  z.object({
    valueSource: z.literal('formula'),
    editing: formulaEditingOptionsSchema(intl),
    // TODO: Add max length validation + error message
    formula: z.string().min(1, {
      message: requiredMessage(intl)(getErrorLabel(intl, 'formula')),
    }),
  });

export const dataFieldSchema = (_intl: IntlShape) =>
  z.object({
    valueSource: z.literal('data'),
    editing: editingOptionsSchema(_intl),
    aiEngineFields: z.array(z.string()),
  });

export const manualFieldSchema = (_intl: IntlShape) =>
  z.object({
    valueSource: z.literal('manual'),
  });

export const unsetFieldSchema = (_intl: IntlShape) =>
  z.object({
    valueSource: z.literal('unset'),
    aiEngineFields: z.array(z.string()),
    threshold: numericString(_intl),
  });

export const valueSourceSchemas = (_intl: IntlShape) =>
  z.discriminatedUnion('valueSource', [
    capturedFieldSchema(_intl),
    manualFieldSchema(_intl),
    unsetFieldSchema(_intl),
    dataFieldSchema(_intl),
    formulaFieldSchema(_intl),
  ]);

// variations based on `dataType`
export const stringFieldSchema = (_intl: IntlShape) =>
  z.object({
    dataType: z.literal('string'),
    minLength: numericString(_intl),
    maxLength: numericString(_intl),
    exactLength: numericString(_intl),
    regex: z.string(),
  });

export const numberFieldSchema = (_intl: IntlShape) =>
  z.object({
    dataType: z.literal('number'),
    format: z
      .enum(
        numberFieldFormatOptions.map(o => o.value) as unknown as readonly [
          string,
          ...string[],
        ]
      )
      .nullable(),
  });

export const dateFieldSchema = (_intl: IntlShape) =>
  z.object({
    dataType: z.literal('date'),
    format: z.string().nullable(),
  });

export const enumFieldOptionSchema = (_intl: IntlShape) =>
  z.object({
    value: z.string(),
    label: z.string(),
  });

export const enumFieldSchema = (_intl: IntlShape) =>
  z.object({
    dataType: z.literal('enum'),
    options: z.array(enumFieldOptionSchema(_intl)),
  });

export const dataTypeSchemas = (_intl: IntlShape) =>
  z
    .discriminatedUnion('dataType', [
      stringFieldSchema(_intl),
      numberFieldSchema(_intl),
      dateFieldSchema(_intl),
      enumFieldSchema(_intl),
    ])
    .superRefine((arg, ctx) => {
      if (arg.dataType === 'string') {
        return stringFieldValidations(_intl)(arg, ctx);
      }

      return true;
    });

// Final schema for header fields
export const simpleValueSchema = (_intl: IntlShape) =>
  sharedFieldSchema(_intl)
    .and(valueSourceSchemas(_intl))
    .and(dataTypeSchemas(_intl));

// Final schema for multivalue fields
export const multivalueSchema = (_intl: IntlShape) =>
  sharedFieldSchema(_intl)
    .and(valueSourceSchemas(_intl))
    .and(dataTypeSchemas(_intl));

export const buttonSchema = (_intl: IntlShape) =>
  sharedFieldSchema(_intl).and(
    z.object({ url: z.string(), canObtainToken: z.boolean() })
  );

export const lineItemsSchema = (_intl: IntlShape) =>
  sharedFieldSchema(_intl).and(
    z.object({ aiEngineFields: z.array(z.string()) })
  );

export const sectionSchema = (_intl: IntlShape) => sharedFieldSchema(_intl);

export const fieldsFormSchema = (_intl: IntlShape) =>
  z.discriminatedUnion('fieldType', [
    z.object({
      fieldType: z.literal('simpleValue'),
      field: simpleValueSchema(_intl),
    }),
    z.object({
      fieldType: z.literal('lineItemSimpleValue'),
      field: simpleValueSchema(_intl),
    }),
    z.object({
      fieldType: z.literal('multivalue'),
      field: multivalueSchema(_intl),
    }),
    z.object({
      fieldType: z.literal('lineItems'),
      field: lineItemsSchema(_intl),
    }),
    z.object({
      fieldType: z.literal('button'),
      field: buttonSchema(_intl),
    }),
    z.object({
      fieldType: z.literal('lineItemButton'),
      field: buttonSchema(_intl),
    }),
    z.object({
      fieldType: z.literal('section'),
      field: sectionSchema(_intl),
    }),
  ]);

type TFieldsFormModel = z.TypeOf<ReturnType<typeof fieldsFormSchema>>;

// Just a trick to not unpack `FieldsFormModel` in TS hints
export type FieldsFormModel = TFieldsFormModel;

// What the user types into the form, different from what zod outputs
export type FieldsFormModelInput = z.input<ReturnType<typeof fieldsFormSchema>>;

// TODO: Better utils for this?
// Get all keys of an object type that is potentially a union of more object types
type AllUnionKeys<T> = T extends T ? keyof T : never;

// For an ObjectType that may be a union type, get type of value at Key (if present in at least one)
type TypeAt<ObjectType, Key> = ObjectType extends ObjectType
  ? Key extends keyof ObjectType
    ? ObjectType[Key]
    : never
  : never;

// Input type of the form - it needs to contain _all_ the fields with their defaults
// otherwise we get into 'field changing its value from `undefined` to something else error
export type FieldsFormValues = {
  fieldType: FieldsFormModelInput['fieldType'];
  field: {
    [K in AllUnionKeys<FieldsFormModelInput['field']>]: Exclude<
      TypeAt<FieldsFormModelInput['field'], K>,
      null
    >;
  };
};

// This is basically a default for creating a new field (unless we override for specific cases
// which we probably should do (e.g. sections)
export const emptyDefaultValues: FieldsFormValues = {
  fieldType: 'simpleValue',
  field: {
    id: '',
    label: '',
    description: '',
    hidden: false,
    exported: true,
    required: false,
    valueSource: 'captured',
    editing: 'enabled',
    aiEngineFields: [],
    threshold: '',
    dataType: 'string',
    minLength: '',
    maxLength: '',
    exactLength: '',
    regex: '',
    options: [],
    formula: '',
    format: '',
    url: '',
    canObtainToken: false,
  },
};

import { Menu, MenuItem, Typography } from '@rossum/ui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { minimizeString } from '../../../lib/helpers';
import { useCopyToClipboard } from '../../../ui/copy-to-clipboard/useCopyToClipboard';
import { provideFileNameLimit } from '../helpers';

type Props = {
  name: string | undefined;
  onOpen: React.Dispatch<React.SetStateAction<boolean>>;
  icons: { messagesExist: boolean; triageRulesFileName: boolean };
  restrictedAccess: boolean;
};

const Filename = ({ name, onOpen, icons, restrictedAccess }: Props) => {
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const intl = useIntl();

  const { copyToClipboard: copyFileNameToClipboard, copied: fileNameCopied } =
    useCopyToClipboard(name || '');
  const { copyToClipboard: copyAnnotationUrl, copied: annotationUrlCopied } =
    useCopyToClipboard(window.location.href.split('?')[0]);

  const closeContextMenu = () => {
    setContextMenu(null);
    onOpen(false);
  };

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();

    if (contextMenu === null) {
      setContextMenu({
        mouseX: event.clientX + 2,
        mouseY: event.clientY - 6,
      });

      onOpen(true);
    } else {
      closeContextMenu();
    }
  };

  const { messagesExist, triageRulesFileName } = icons;

  const shortName = minimizeString(name || '', provideFileNameLimit(icons));

  return (
    <>
      <Typography
        onContextMenu={handleContextMenu}
        sx={{
          cursor: 'context-menu',
          lineHeight: 1.8,
          whiteSpace: 'nowrap',
          opacity: fileNameCopied || annotationUrlCopied ? 0.5 : 1,
          transition: 'opacity .15s',
          overflow: 'hidden',
          position: 'relative',
          width:
            messagesExist && triageRulesFileName
              ? 260
              : messagesExist || triageRulesFileName
                ? 280
                : 310,
          '&::after': {
            position: 'absolute',
            content: '""',
            display: 'block',
            right: 0,
            top: 0,
            height: 36,
            width: 18,
            background: theme =>
              `linear-gradient(to right, rgba(0,0,0,0) 0%,  ${
                theme.palette.mode === 'dark'
                  ? '#18161e' // FIXME: how to replace with theme color?
                  : theme.palette.common.white
              } 100%)`,
          },
        }}
        variant="h5"
        title={name}
      >
        {shortName}
      </Typography>

      <Menu
        open={contextMenu !== null}
        onClose={closeContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={event => {
            event.stopPropagation();
            copyFileNameToClipboard();
            closeContextMenu();
          }}
          disabled={restrictedAccess}
        >
          {intl.formatMessage({
            id: 'components.annotationOverview.contextMenu.copyName',
          })}
        </MenuItem>
        <MenuItem
          onClick={event => {
            event.stopPropagation();
            copyAnnotationUrl();
            closeContextMenu();
          }}
        >
          {intl.formatMessage({
            id: 'components.annotationOverview.contextMenu.copyAnnotationURL',
          })}
        </MenuItem>
      </Menu>
    </>
  );
};

export default Filename;

import clsx from 'clsx';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { FormattedMessage } from 'react-intl';
import Dropdown, { ItemPropsT } from '../../../../../components/UI/Dropdown';
import { SuggestedEditValue } from '../../../../../types/queue';
import styles from './styles.module.sass';

type NonNullableSuggestedEditValue = NonNullable<SuggestedEditValue>;
type SuggestedEditOption = { value: NonNullableSuggestedEditValue };

type Props = {
  disabled: boolean;
  options: Array<SuggestedEditOption>;
  setValue: (value: NonNullableSuggestedEditValue) => void;
  value?: SuggestedEditValue;
};

const SuggestedEditDropdown = ({
  disabled,
  options,
  setValue,
  value,
}: Props) => (
  <Dropdown
    listClassName={styles.SuggestedEditDropdownList}
    withIcons={false}
    position="down"
    align="left"
    items={options.map(
      ({ value: optionValue }) =>
        ({ className, ...props }: ItemPropsT) => (
          <div
            {...props}
            className={styles.ItemWrapper}
            onClick={() => setValue(optionValue)}
            key={`suggested-edit-${optionValue}`}
          >
            <span className={styles.ItemLabel}>
              <FormattedMessage
                id={`containers.settings.queues.splitBatchFiles.${optionValue}.title`}
              />
            </span>
            <span className={styles.ItemDescription}>
              <FormattedMessage
                id={`containers.settings.queues.splitBatchFiles.${optionValue}.description`}
              />
            </span>
          </div>
        )
    )}
  >
    {({ open }: Record<'open', boolean>) => (
      <div
        className={clsx(
          styles.SuggestedEditValue,
          open && styles.IsOpen,
          value && styles.SuggestedEditValueSelected,
          disabled && styles.DropdownDisabled
        )}
        onClick={e => disabled && e.stopPropagation()}
      >
        {value ? (
          <FormattedMessage
            id={`containers.settings.queues.splitBatchFiles.${value}.title`}
          />
        ) : (
          <FormattedMessage id="containers.settings.queues.splitBatchFiles.placeholder" />
        )}
        <ChevronDownIcon
          className={clsx(styles.Chevron, open && styles.ChevronUp)}
        />
      </div>
    )}
  </Dropdown>
);

export default SuggestedEditDropdown;

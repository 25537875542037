import { UniqueIdentifier } from '@dnd-kit/core';
import { zodResolver } from '@hookform/resolvers/zod';
import { getIDFromUrl } from '@rossum/api-client';
import { Queue } from '@rossum/api-client/queues';
import {
  isSchemaTableMultiValue,
  Schema,
  SchemaItem,
} from '@rossum/api-client/schemas';
import { Add } from '@rossum/ui/icons';
import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  ListItem,
  MenuItem,
  Paper,
  Stack,
  useTheme,
} from '@rossum/ui/material';
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router';
import * as R from 'remeda';
import { CustomTextField } from '../../../../components/ReactHookForm/controls/CustomTextField';
import SwitchControl from '../../../../components/ReactHookForm/controls/SwitchControl';
import TextFieldControl from '../../../../components/ReactHookForm/controls/TextFieldControl';
import FormTypeahead from '../../../../components/ReactHookForm/FormTypeahead';
import { EXTRACTED_FIELD_TYPES_LINK } from '../../../../constants/values';
import EnumEditorInDrawer from '../../../../containers/Queue/containers/QueueSchema/components/EnumEditorInDrawer';
import { boldText, linebreak, link } from '../../../../lib/formaterValues';
import { restrictedEngineEditEnabledSelector } from '../../../../redux/modules/organizationGroup/selectors';
import { ContentGroup } from '../../../../ui/content-group/ContentGroup';
import { AutocompleteGroup } from '../../../engines/components/autocomplete/AutocompleteGroup';
import { CreateFieldDialogContent } from '../../../engines/components/CreateFieldDialogContent';
import { useEngine } from '../../../engines/hooks/useEngine';
import { useUnpaginatedEngineFields } from '../../../engines/hooks/useUnpaginatedEngineFields';
import {
  dataTypeOptions,
  dateFieldFormatOptions,
  DELETE_FIELD_FORM_ID,
  editingOptions,
  FIELD_FORM_ID,
  formulaEditingOptions,
  getLinkStyles,
  IDENTIFICATION_SECTION_LINK,
  legacyValueSourceOptions,
  multivalueDataTypeOptions,
  numberFieldFormatOptions,
  properValueSourceOptions,
  uiValidFieldTypeOptions,
  uiValidLineItemFieldTypeOptions,
} from '../constants';
import { fieldsFormErrorMap } from '../form-model/errors/formErrorMap';
import {
  FieldsFormModel,
  fieldsFormSchema,
  FieldsFormValues,
} from '../form-model/formModels';
import { toFormValues } from '../form-model/transformations';
import {
  isSchemaItemChildPath,
  retrieveFromSchema,
} from '../form-model/transformations/toSchema.utils';
import {
  fieldsIconsMap,
  getChildListPath,
  getFlattenSchemaFields,
  getSchemaObjectPaths,
} from '../helpers';
import { useConfirmationDialog } from '../hooks/useConfirmationDialog';
import {
  getUnsafeSchemaConcept,
  useSchemaConcept,
} from '../hooks/useSchemaContept';
import { useValidateSchema } from '../hooks/useValidateSchema';
import { isValidFieldId } from '../utils';
import { AiEngineSection } from './form/AiEngineSection';
import {
  isCanObtainTokenAvailable,
  isChildrenAvailable,
  isDataTypeAvailable,
  isEditingAvailable,
  isFieldFromEngine,
  isFormatAvailable,
  isFormulaEditorAvailable,
  isOptionsAvailable,
  isTabularAvailable,
  isUrlAvailable,
  isValueSourceAvailable,
} from './form/conditions';
import { ContentOption } from './form/ContentOption';
import { FormulaSection } from './form/FormulaSection';
import PreFillFormSection from './form/PreFillFormSection';
import { QuickActionIcon } from './form/QuickActionIcon';
import {
  TableMultiValueChildren,
  TableMultiValueChildrenProps,
} from './TableMultiValueChildren';

const supportedGroups = ['used', 'unused'] as const;

export type ItemContentProps = {
  data: SchemaItem | null;
  onSubmit: (formModel: FieldsFormModel) => void;
  onDelete: (itemId: string) => void;
  onChildrenReorder: (
    from: readonly [UniqueIdentifier, number],
    to: readonly [UniqueIdentifier, number]
  ) => void;
  onQuickAction: (
    parentId: UniqueIdentifier,
    items: Record<
      UniqueIdentifier,
      {
        prop: 'hidden' | 'canExport' | 'required';
        value: boolean;
      }
    >
  ) => void;
  schema: Schema;
  parentId: string;
  supportLegacyUser?: boolean;
  formulaFieldsEnabled: boolean;
  queues: Queue[];
};

export const ItemContent = ({
  data,
  onSubmit,
  onDelete,
  onChildrenReorder,
  onQuickAction,
  schema,
  parentId,
  supportLegacyUser,
  formulaFieldsEnabled,
  queues,
}: ItemContentProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const history = useHistory();

  // If we have multiple queues that share a schema, they all use the same engine by design
  // so it's okay to only look at the first one
  const auroraEngineUrl = queues[0] ? queues[0].engine ?? undefined : undefined;

  const auroraEngineId = auroraEngineUrl ? getIDFromUrl(auroraEngineUrl) : null;

  const isRestrictedEngineEditEnabled = useSelector(
    restrictedEngineEditEnabledSelector
  );

  const { data: auroraEngine } = useEngine(auroraEngineId ?? -1);

  const usedSchemaIds = useMemo(
    () =>
      Object.keys(getFlattenSchemaFields(schema.content ?? [])).filter(
        schemaId => schemaId !== data?.id
      ),
    [data?.id, schema.content]
  );

  const { data: engineFields, isLoading: isEngineFieldsLoading } =
    useUnpaginatedEngineFields(
      { engine: auroraEngineId ?? undefined },
      {
        enabled: typeof auroraEngineId === 'number',
        select: fields =>
          fields
            .map(({ id, name, label }) => ({
              id,
              name,
              label,
              used: usedSchemaIds.includes(name),
            }))
            .sort((o1, o2) => +o1.used - +o2.used),
      }
    );

  const { dialog, setDialogState, dialogState } = useConfirmationDialog();

  const { mutateAsync: validateSchema } = useValidateSchema();

  const schemaObjectPaths = useMemo(
    () => getSchemaObjectPaths(schema),
    [schema]
  );

  const parentSchemaObjectPath = useMemo(
    () => schemaObjectPaths[parentId] ?? null,
    [parentId, schemaObjectPaths]
  );

  const itemSchemaObjectPath = useMemo(() => {
    const childListPath = parentSchemaObjectPath
      ? getChildListPath(parentSchemaObjectPath)
      : null;
    const currentSchemaObjectPath = data && schemaObjectPaths[data.id];

    if (currentSchemaObjectPath) {
      return currentSchemaObjectPath;
    }

    if (childListPath) {
      const lengthOfSiblings = retrieveFromSchema(
        schema,
        childListPath
      )?.length;

      if (lengthOfSiblings !== undefined) {
        return [...childListPath, lengthOfSiblings];
      }
    }

    return null;
  }, [data, parentSchemaObjectPath, schema, schemaObjectPaths]);

  // RHF cannot infer this from `resolver`, sheesh
  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    setError,
    formState: { errors, isDirty },
  } = useForm<FieldsFormValues, undefined, FieldsFormModel>({
    defaultValues: toFormValues(data, itemSchemaObjectPath),
    resolver: zodResolver(fieldsFormSchema(intl), {
      errorMap: fieldsFormErrorMap(intl),
    }),
  });

  const { handleSubmit: handleDeleteFormSubmit } = useForm({
    defaultValues: {
      id: data?.id ?? null,
    },
  });

  const formValues = getValues();

  const { field, fieldType } = watch();

  // We are on an Aurora enabled queue if we have auroraEngineId + we check the field is Captured
  const isAuroraCapturedField =
    isFieldFromEngine(formValues) && typeof auroraEngineId === 'number';

  const valueSourceOptions = [
    ...properValueSourceOptions.filter(option =>
      option === 'formula' ? formulaFieldsEnabled : true
    ),
    ...(supportLegacyUser ? legacyValueSourceOptions : []),
  ];

  const typeOptions = isSchemaItemChildPath(itemSchemaObjectPath)
    ? uiValidLineItemFieldTypeOptions
    : uiValidFieldTypeOptions;

  const nestedItemsData = useMemo(() => {
    if (isSchemaTableMultiValue(data)) {
      return data;
    }

    return null;
  }, [data]);

  const schemaConcept = useSchemaConcept(schema, parentId, data, formValues);

  // resets on remounts and this component is being re-mounted every time `data` changes
  // not just rerendered (`key` from parent)
  const skipPrompt = useRef(false);

  const deleteHandler: SubmitHandler<{ id: string | null }> = useCallback(
    ({ id }) => {
      if (id) {
        skipPrompt.current = true;
        onDelete(id);
      }
    },
    [onDelete]
  );

  const submitHandler: SubmitHandler<FieldsFormModel> = useCallback(
    formModel => {
      if (itemSchemaObjectPath) {
        // TODO: Proper validation with errors on correct fields, this is just a quick fix
        validateSchema(schemaConcept)
          .then(response => {
            if (!R.isDeepEqual(response, {})) {
              setError('root', {
                message: JSON.stringify(response, undefined, 2),
              });
            } else {
              skipPrompt.current = true;
              onSubmit(formModel);
            }
          })
          .catch(() => {
            skipPrompt.current = false;
          });
      }
    },
    [itemSchemaObjectPath, onSubmit, schemaConcept, setError, validateSchema]
  );

  const childrenReorderHandler: TableMultiValueChildrenProps['onChildrenReorder'] =
    useCallback(
      (from, to) => {
        onChildrenReorder(from, to);
      },
      [onChildrenReorder]
    );

  useEffect(() => {
    if (fieldType === 'multivalue' && field.valueSource === 'formula') {
      setValue('field.valueSource', 'captured');
    }

    if (fieldType === 'multivalue') {
      setValue('field.dataType', 'string');
    }
  }, [fieldType, setValue, field.valueSource]);

  useEffect(() => {
    if (
      field.valueSource === 'captured' &&
      typeof auroraEngineId !== 'number' &&
      !isEngineFieldsLoading &&
      !engineFields?.some(f => f.name === field.id)
    ) {
      setValue('field.id', '');
    }
  }, [
    auroraEngineId,
    engineFields,
    field.id,
    field.valueSource,
    isEngineFieldsLoading,
    setValue,
  ]);

  const [errorExpanded, setErrorExpanded] = useState(false);

  // Do not change field ID before input gets blurred.
  // Otherwise, it would fetch engine field on every keystroke.
  // TODO: This should be done better though, maybe some throttling on refetches, rather than extra state
  // it also looks weird with the dropdown variant
  const [fieldId, setFieldId] = useState(field.id);

  // Create field dialog
  const [fieldDialogOpen, setFieldDialogOpen] = useState(false);

  const schemaContent = useMemo(
    () =>
      getUnsafeSchemaConcept({
        schema,
        parentId,
        apiModel: data,
        formValues,
      })?.content ?? [],
    [data, formValues, parentId, schema]
  );

  if (!itemSchemaObjectPath) {
    return null;
  }

  const shouldDisableExportAndRequired = formValues.fieldType === 'lineItems';

  return (
    <>
      <Stack
        p={4}
        spacing={4}
        id={FIELD_FORM_ID}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
      >
        {errors.root ? (
          <Alert
            variant="filled"
            severity="error"
            action={
              <Button
                color="inherit"
                variant="text"
                onClick={() => setErrorExpanded(expanded => !expanded)}
                sx={{ flexShrink: 0 }}
              >
                {intl.formatMessage({
                  id: 'features.queueSettings.fields.form.errors.encountered.more',
                })}
              </Button>
            }
          >
            {intl.formatMessage({
              id: 'features.queueSettings.fields.form.errors.encountered',
            })}
            <Collapse in={errorExpanded}>
              <pre>{errors.root.message}</pre>
            </Collapse>
          </Alert>
        ) : null}
        <Stack direction="row" spacing={4}>
          <ContentOption
            title={intl.formatMessage({
              id: 'features.queueSettings.fields.form.visible.label',
            })}
            helperText={intl.formatMessage({
              id: 'features.queueSettings.fields.form.visible.helperText',
            })}
            icon={<QuickActionIcon fieldKey="hidden" state={!field.hidden} />}
          >
            <SwitchControl
              ControllerProps={{ control, name: 'field.hidden' }}
              label=""
              size="small"
              showInvertedValue
            />
          </ContentOption>
          <ContentOption
            title={intl.formatMessage({
              id: 'features.queueSettings.fields.form.exported.label',
            })}
            helperText={intl.formatMessage({
              id: 'features.queueSettings.fields.form.exported.helperText',
            })}
            icon={
              <QuickActionIcon fieldKey="exported" state={!!field.exported} />
            }
          >
            <SwitchControl
              ControllerProps={{ control, name: 'field.exported' }}
              label=""
              size="small"
              disabled={shouldDisableExportAndRequired}
              tooltipTitle={
                shouldDisableExportAndRequired
                  ? intl.formatMessage({
                      id: 'features.queueSettings.fields.form.exported.disabledTooltip',
                    })
                  : undefined
              }
            />
          </ContentOption>
          <ContentOption
            title={intl.formatMessage({
              id: 'features.queueSettings.fields.form.required.label',
            })}
            helperText={intl.formatMessage({
              id: 'features.queueSettings.fields.form.required.helperText',
            })}
            icon={
              <QuickActionIcon fieldKey="required" state={!!field.required} />
            }
          >
            <SwitchControl
              ControllerProps={{ control, name: 'field.required' }}
              label=""
              size="small"
              disabled={shouldDisableExportAndRequired}
              tooltipTitle={
                shouldDisableExportAndRequired
                  ? intl.formatMessage({
                      id: 'features.queueSettings.fields.form.required.disabledTooltip',
                    })
                  : undefined
              }
            />
          </ContentOption>
        </Stack>
        <ContentGroup
          title={intl.formatMessage({
            id: 'features.queueSettings.fields.form.type.title',
          })}
          description={intl.formatMessage(
            {
              id: 'features.queueSettings.fields.form.type.description',
            },
            {
              linebreak,
              link: link(IDENTIFICATION_SECTION_LINK, getLinkStyles(theme)),
            }
          )}
        >
          <TextFieldControl
            autoFocus
            ControllerProps={{ control, name: 'fieldType' }}
            label={intl.formatMessage({
              id: 'features.queueSettings.fields.form.fieldType.label',
            })}
            select
            autoComplete="off"
            FieldLabelProps={{
              layout: 'floating',
            }}
          >
            {typeOptions.map(o => {
              const Icon = fieldsIconsMap[o];
              return (
                <MenuItem key={o} value={o}>
                  <Stack direction="row" gap={1} alignItems="center">
                    <Icon
                      fontSize="small"
                      sx={{ color: t => t.palette.text.secondary }}
                    />
                    {intl.formatMessage({
                      id: `features.queueSettings.fields.form.fieldType.option.${o}`,
                    })}
                  </Stack>
                </MenuItem>
              );
            })}
          </TextFieldControl>
          {isValueSourceAvailable(formValues) && (
            <Controller
              control={control}
              name="field.valueSource"
              render={FieldProps => (
                <CustomTextField
                  FieldProps={{
                    ...FieldProps,
                    field: {
                      ...FieldProps.field,
                      onChange: e => {
                        FieldProps.field.onChange(e);
                        setValue(
                          'field.editing',
                          e.target.value === 'formula' ? 'disabled' : 'enabled'
                        );
                      },
                    },
                  }}
                  label={intl.formatMessage({
                    id: 'features.queueSettings.fields.form.valueSource.label',
                  })}
                  select
                  autoComplete="off"
                  FieldLabelProps={{
                    layout: 'floating',
                  }}
                  helperText={intl.formatMessage({
                    id: `features.queueSettings.fields.form.valueSource.option.${field.valueSource}.helperText`,
                  })}
                >
                  {valueSourceOptions.map(o => {
                    const Icon = fieldsIconsMap[o];

                    // TODO: get correct options from the one place and remove this condition from here
                    // formula is not supported for multivalue fields
                    if (fieldType === 'multivalue' && o === 'formula') {
                      return null;
                    }

                    return (
                      <MenuItem key={o} value={o}>
                        <Stack direction="row" gap={1} alignItems="center">
                          <Icon
                            fontSize="small"
                            sx={{ color: t => t.palette.text.secondary }}
                          />
                          {intl.formatMessage({
                            id: `features.queueSettings.fields.form.valueSource.option.${o}`,
                          })}
                        </Stack>
                      </MenuItem>
                    );
                  })}
                </CustomTextField>
              )}
            />
          )}
          {isEditingAvailable(formValues) && (
            <TextFieldControl
              ControllerProps={{ control, name: 'field.editing' }}
              label={intl.formatMessage({
                id: 'features.queueSettings.fields.form.editing.label',
              })}
              select
              autoComplete="off"
              FieldLabelProps={{
                layout: 'floating',
              }}
              helperText={intl.formatMessage(
                {
                  id: `features.queueSettings.fields.form.editing.option.${field.editing}.helperText`,
                },
                { linebreak }
              )}
            >
              {(isFormulaEditorAvailable(formValues)
                ? formulaEditingOptions
                : editingOptions
              ).map(o => (
                <MenuItem key={o} value={o}>
                  {intl.formatMessage({
                    id: `features.queueSettings.fields.form.editing.option.${o}`,
                  })}
                </MenuItem>
              ))}
            </TextFieldControl>
          )}
          {isUrlAvailable(formValues) && (
            <TextFieldControl
              ControllerProps={{ control, name: 'field.url' }}
              label={intl.formatMessage({
                id: 'features.queueSettings.fields.form.url.label',
              })}
              FieldLabelProps={{
                layout: 'floating',
              }}
              helperText={intl.formatMessage({
                id: 'features.queueSettings.fields.form.url.helperText',
              })}
            />
          )}
          {isCanObtainTokenAvailable(formValues) && (
            <SwitchControl
              ControllerProps={{ control, name: 'field.canObtainToken' }}
              label={intl.formatMessage({
                id: 'features.queueSettings.fields.form.canObtainToken.label',
              })}
              size="small"
            />
          )}
        </ContentGroup>
        <ContentGroup
          title={intl.formatMessage({
            id: 'features.queueSettings.fields.form.identification.title',
          })}
          description={intl.formatMessage({
            id: 'features.queueSettings.fields.form.identification.description',
          })}
        >
          <TextFieldControl
            ControllerProps={{ control, name: 'field.label' }}
            label={intl.formatMessage({
              id: 'features.queueSettings.fields.form.label.label',
            })}
            FieldLabelProps={{
              layout: 'floating',
            }}
          />
          {isAuroraCapturedField ? (
            <FormTypeahead
              id="field.id"
              name="field.id"
              control={control}
              options={engineFields ?? []}
              getOptionLabel={option =>
                typeof option === 'string' ? option : option?.name
              }
              label={intl.formatMessage({
                id: `features.queueSettings.fields.form.id.label`,
              })}
              helperText={
                isValidFieldId(formValues.field.id)
                  ? ''
                  : intl.formatMessage({
                      id: 'features.queueSettings.fields.form.id.helperText',
                    })
              }
              disablePortal
              disableClearable
              autoSelect
              groupBy={option =>
                !usedSchemaIds.includes(option.name) ? 'unused' : 'used'
              }
              renderGroup={params => {
                const group = supportedGroups.find(
                  group => group === params.group
                );
                return (
                  <Fragment key={params.key}>
                    {group ? (
                      <AutocompleteGroup>
                        {intl.formatMessage({
                          id: `features.queueSettings.fields.form.id.group.${group}`,
                        })}
                      </AutocompleteGroup>
                    ) : null}
                    {params.children}
                  </Fragment>
                );
              }}
              getOptionValue={option => option.name}
              onChange={(_, __, ___, option) => {
                if (option?.option.name) {
                  setValue('field.id', option?.option.name);
                }
              }}
              renderOption={(props, option) => (
                <MenuItem key={option.name} disabled={option.used} {...props}>
                  {option.name}
                </MenuItem>
              )}
              size="small"
              PaperComponent={({ children }) => (
                <Paper>
                  {children}
                  {isRestrictedEngineEditEnabled ? null : (
                    <ListItem sx={{ justifyContent: 'center' }}>
                      <Button
                        color="secondary"
                        startIcon={<Add />}
                        onMouseDown={() => setFieldDialogOpen(true)}
                        data-cy="create-engine-field-button"
                      >
                        {intl.formatMessage({
                          id: 'features.queueSettings.fields.form.id.newFieldLabel',
                        })}
                      </Button>
                    </ListItem>
                  )}
                </Paper>
              )}
            />
          ) : (
            <TextFieldControl
              ControllerProps={{ control, name: 'field.id' }}
              label={intl.formatMessage({
                id: 'features.queueSettings.fields.form.id.label',
              })}
              helperText={
                isValidFieldId(formValues.field.id)
                  ? ''
                  : intl.formatMessage({
                      id: 'features.queueSettings.fields.form.id.helperText',
                    })
              }
              FieldLabelProps={{
                layout: 'floating',
              }}
              onBlur={e => {
                return setFieldId(e.currentTarget.value);
              }}
            />
          )}
          <TextFieldControl
            ControllerProps={{ control, name: 'field.description' }}
            label={intl.formatMessage({
              id: 'features.queueSettings.fields.form.description.label',
            })}
            FieldLabelProps={{
              layout: 'floating',
            }}
          />
          {isDataTypeAvailable(formValues) && (
            <TextFieldControl
              ControllerProps={{ control, name: 'field.dataType' }}
              label={intl.formatMessage({
                id: 'features.queueSettings.fields.form.dataType.label',
              })}
              select
              autoComplete="off"
              FieldLabelProps={{
                layout: 'floating',
              }}
            >
              {(formValues.fieldType === 'multivalue'
                ? multivalueDataTypeOptions
                : dataTypeOptions
              ).map(o => (
                <MenuItem key={o} value={o}>
                  {intl.formatMessage({
                    id: `features.queueSettings.fields.form.dataType.option.${o}`,
                  })}
                </MenuItem>
              ))}
            </TextFieldControl>
          )}
          {isFormatAvailable(formValues) && (
            <FormTypeahead
              name="field.format"
              control={control}
              options={
                field.dataType === 'number'
                  ? numberFieldFormatOptions
                  : dateFieldFormatOptions
              }
              getOptionLabel={option =>
                typeof option === 'string' ? option : option?.label
              }
              label={intl.formatMessage({
                id: `features.queueSettings.fields.form.format.label`,
              })}
              disablePortal
              autoSelect
              freeSolo
              onInputChange={(_, value) => setValue('field.format', value)}
              getOptionValue={option => option.value}
              helperText={
                (field.dataType === 'number'
                  ? numberFieldFormatOptions
                  : dateFieldFormatOptions
                ).find(o => o.value === field.format)?.helpText ?? ''
              }
              renderOption={(props, option) => (
                <MenuItem key={option.value} {...props}>
                  {option.label}
                </MenuItem>
              )}
              size="small"
            />
          )}
        </ContentGroup>
        <AiEngineSection
          engineUrl={auroraEngineUrl}
          control={control}
          formValues={formValues}
          fieldId={
            // for the select variant, relying on the "debounce-faking" state does not make sense
            // the UI should update immediately, not just after blur
            isFieldFromEngine(formValues) && typeof auroraEngineId === 'number'
              ? field.id
              : fieldId
          }
        />
        <PreFillFormSection control={control} formValues={formValues} />
        {isOptionsAvailable(formValues) && (
          <Box sx={{ minHeight: '250px' }}>
            {/* TODO: Remove this wrapper after redesign EnumEditorInDrawer: AC-4611  */}
            <ContentGroup
              title={intl.formatMessage({
                id: 'features.queueSettings.fields.form.options.title',
              })}
              description={intl.formatMessage(
                {
                  id: 'features.queueSettings.fields.form.options.description',
                },
                {
                  boldText,
                  linebreak,
                  link: link(EXTRACTED_FIELD_TYPES_LINK, getLinkStyles(theme)),
                }
              )}
            >
              <Controller
                control={control}
                name="field.options"
                render={({ field: { value, onChange } }) => (
                  <EnumEditorInDrawer
                    options={value || []}
                    onCurrentSchemaPartChange={options => onChange(options)}
                    showPasteText={false}
                  />
                )}
              />
            </ContentGroup>
          </Box>
        )}

        {isFormulaEditorAvailable(formValues) && (
          <FormulaSection
            control={control}
            queues={queues}
            schemaContent={schemaContent}
          />
        )}
      </Stack>
      {nestedItemsData && isChildrenAvailable(formValues) ? (
        <TableMultiValueChildren
          parentId={field.id}
          data={nestedItemsData}
          onChildrenReorder={childrenReorderHandler}
          onQuickAction={onQuickAction}
        />
      ) : null}
      <Stack
        display="none"
        component="form"
        id={DELETE_FIELD_FORM_ID}
        onSubmit={e => {
          e.preventDefault();
          setDialogState({
            key: 'deleteSchemaField',
            onConfirm: () => handleDeleteFormSubmit(deleteHandler)(e),
          });
        }}
      />
      <Prompt
        message={location => {
          if (dialogState) {
            setDialogState(null);
            return true;
          }

          setDialogState({
            key: 'notSavedChanges',
            onConfirm: () => history.replace(location),
          });

          return false;
        }}
        when={!skipPrompt.current && isDirty}
      />
      {dialog}
      {auroraEngine ? (
        <Dialog
          open={fieldDialogOpen}
          onClose={() => setFieldDialogOpen(false)}
          sx={{ transition: 'smooth' }}
          data-cy="create-field-dialog"
          PaperProps={{
            elevation: 2,
          }}
        >
          {fieldDialogOpen ? (
            <CreateFieldDialogContent
              engine={auroraEngine}
              onSubmit={createdFieldName => {
                if (createdFieldName) {
                  setValue('field.id', createdFieldName);
                }
                setFieldDialogOpen(false);
              }}
              onClose={() => setFieldDialogOpen(false)}
              tabular={isTabularAvailable(formValues)}
              name=""
              label={formValues.field.label}
            />
          ) : null}
        </Dialog>
      ) : null}
    </>
  );
};

import { useTheme } from '@rossum/ui/material';
import { Control } from 'react-hook-form';
import { useIntl } from 'react-intl';
import TextFieldControl from '../../../../../components/ReactHookForm/controls/TextFieldControl';
import FormTypeahead from '../../../../../components/ReactHookForm/FormTypeahead';
import { EXTRACTED_FIELD_TYPES_LINK } from '../../../../../constants/values';
import { boldText, linebreak, link } from '../../../../../lib/formaterValues';
import { ContentGroup } from '../../../../../ui/content-group/ContentGroup';
import { FieldConfiguration } from '../../../../engines/components/FieldConfiguration';
import { getLinkStyles } from '../../constants';
import { FieldsFormValues } from '../../form-model/formModels';
import {
  isAiEngineAvailable,
  isFormerAiEngineAvailable,
  isThresholdAvailable,
} from './conditions';

type AiEngineSectionProps = {
  control: Control<FieldsFormValues> | undefined;
  formValues: FieldsFormValues;
  fieldId: string;
  engineUrl?: string;
};

export const AiEngineSection = ({
  control,
  formValues,
  fieldId,
  engineUrl,
}: AiEngineSectionProps) => {
  const intl = useIntl();
  const theme = useTheme();

  if (!engineUrl && isFormerAiEngineAvailable(formValues)) {
    return (
      <ContentGroup
        title={intl.formatMessage({
          id: 'features.queueSettings.fields.form.capturedAiEngine.title',
        })}
        description={intl.formatMessage(
          {
            id: 'features.queueSettings.fields.form.capturedAiEngine.description',
          },
          {
            boldText,
            linebreak,
            link: link(EXTRACTED_FIELD_TYPES_LINK, getLinkStyles(theme)),
          }
        )}
      >
        <FormTypeahead
          name="field.aiEngineFields"
          control={control}
          label={intl.formatMessage({
            id: 'features.queueSettings.fields.form.aiEngine.label',
          })}
          helperText={intl.formatMessage({
            id: 'features.queueSettings.fields.form.aiEngine.helperText',
          })}
          disablePortal
          options={[]}
          freeSolo
          multiple
          size="small"
        />

        {isThresholdAvailable(formValues) && (
          <TextFieldControl
            ControllerProps={{ control, name: 'field.threshold' }}
            label={intl.formatMessage({
              id: 'features.queueSettings.fields.form.threshold.label',
            })}
            helperText={intl.formatMessage({
              id: 'features.queueSettings.fields.form.threshold.helperText',
            })}
            type="number"
            FieldLabelProps={{
              layout: 'floating',
            }}
          />
        )}
      </ContentGroup>
    );
  }

  return engineUrl && isAiEngineAvailable(formValues) ? (
    <FieldConfiguration engineFieldName={fieldId} engineUrl={engineUrl}>
      <TextFieldControl
        ControllerProps={{ control, name: 'field.threshold' }}
        label={intl.formatMessage({
          id: 'features.queueSettings.fields.form.threshold.label',
        })}
        helperText={intl.formatMessage({
          id: 'features.queueSettings.fields.form.threshold.helperText',
        })}
        type="number"
        FieldLabelProps={{
          layout: 'floating',
        }}
      />
    </FieldConfiguration>
  ) : null;
};

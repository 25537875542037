import { EditRelation } from '@rossum/api-client/relations';
import { AnnotationStatus, Message } from '@rossum/api-client/shared';
import { SnakeToCamel, Url } from '@rossum/api-client/utils';
import { History } from '@rossum/ui/icons';
import { Stack, Tooltip } from '@rossum/ui/material';
import { GridRenderCellParams } from '@rossum/ui/x-data-grid-pro';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Duplicate } from '../../../components/icons/Duplicate';
import { AutomationIconWithTooltip } from '../../../components/UI/AutomationIcon';
import Email from '../../../components/UI/Email';
import { startValidation } from '../../../redux/modules/ui/actions';
import { attachmentsFeatureSelector } from '../../pricing/selectors';
import { duplicateHandlingFeatureSelector } from '../../pricing/selectors';
import { SupportedAnnotationView } from '../supportedAnnotationViews';
import { SimpleColumnsLocation } from '../types';
import AttachmentsIndicator from './AttachmentsIndicator';
import { DocumentWasEdited } from './DocumentWasEdited';
import Note from './Note';
import SuggestedEdit from './SuggestedEdit';

export type WorkflowRuns = { annotationUrl: string }[];

type Props = {
  componentLocation?: SimpleColumnsLocation;
  hasWorkflowRun: boolean;
  handleSelectAnnotation: (params: {
    annotationUrl: string;
    view: SupportedAnnotationView;
  }) => void;
} & GridRenderCellParams<
  {
    id: number;
    url: string;
    automated: boolean;
    automaticallyRejected: boolean;
    status: SnakeToCamel<AnnotationStatus>;
    hasAttachments: boolean;
    messages: Message[] | null;
    duplicates: unknown[];
    emailThread: Url | null;
    hasEmailThreadWithReplies?: boolean;
    hasEmailThreadWithNewReplies?: boolean;
    email: Url | null;
    suggestedEdit: Url | null;
    edit?: EditRelation;
  },
  string[]
>;

export const RenderDetails = ({
  row,
  api,
  hasWorkflowRun,
  handleSelectAnnotation,
  componentLocation,
}: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  // AUTOMATION
  const showAutomatedIcon = row.automated || row.automaticallyRejected;

  // SUGGESTED EDIT
  const shouldShowSuggestedEditStatuses =
    row.status === 'postponed' ||
    row.status === 'toReview' ||
    row.status === 'reviewing';

  const annotate = (url: string) =>
    dispatch(startValidation(url, api.getAllRowIds().map(Number)));

  // ATTACHMENTS
  const { hasAttachments, messages } = row;

  const isAttachmentsEnabled = useSelector(attachmentsFeatureSelector);

  const isDuplicateFeaturePurchased = useSelector(
    duplicateHandlingFeatureSelector
  );

  return (
    <Stack
      alignItems="center"
      direction="row"
      width="100%"
      color="text.disabled"
      spacing={1.5}
    >
      {isDuplicateFeaturePurchased &&
        row.duplicates.length > 0 &&
        componentLocation !== 'duplicatesDrawer' && (
          <Stack
            onClick={() =>
              handleSelectAnnotation({
                annotationUrl: row.url,
                view: 'duplicates',
              })
            }
            data-cy="details-column-duplicate"
            sx={{ cursor: 'pointer' }}
          >
            <Duplicate
              fontSize="small"
              sx={{
                color: theme => theme.palette.warning.main,
                zIndex: 1,
              }}
            />
          </Stack>
        )}
      {!!row.emailThread && (
        <Email
          onClick={() =>
            handleSelectAnnotation({
              annotationUrl: row.url,
              view: 'emails',
            })
          }
          isOriginal={!!row.email}
          hasReplies={row.hasEmailThreadWithReplies ?? false}
          hasNewReplies={row.hasEmailThreadWithNewReplies ?? false}
          dataCy="details-column-email"
        />
      )}
      {hasWorkflowRun && (
        <Stack
          onClick={() =>
            handleSelectAnnotation({
              annotationUrl: row.url,
              view: 'activities',
            })
          }
          sx={{ cursor: 'pointer' }}
        >
          <Tooltip
            title={intl.formatMessage({
              id: 'components.workflowActivityDrawer.title',
            })}
            placement="top"
          >
            <History fontSize="small" color="inherit" />
          </Tooltip>
        </Stack>
      )}

      {isAttachmentsEnabled &&
        hasAttachments &&
        componentLocation !== 'attachmentsDrawer' && (
          <AttachmentsIndicator
            onClick={() =>
              handleSelectAnnotation({
                annotationUrl: row.url,
                view: 'attachments',
              })
            }
            dataCy="details-column-attachment"
          />
        )}
      {showAutomatedIcon && (
        <AutomationIconWithTooltip
          isAutomaticallyRejected={row.automaticallyRejected}
        />
      )}

      {shouldShowSuggestedEditStatuses && row.suggestedEdit && (
        <SuggestedEdit
          annotationId={row.id}
          onClick={(e, linkUrl) => {
            if (!e.metaKey) annotate(linkUrl);
          }}
          dataCySuffix="all-documents-dashboard"
        />
      )}

      {row.edit && row.status !== 'importing' && (
        <DocumentWasEdited
          edit={row.edit}
          childAnnotationId={row.id}
          onClick={event => {
            event.stopPropagation();
          }}
          dataCySuffix="all-documents-dashboard"
        />
      )}
      {messages?.length ? <Note messages={messages} /> : null}
    </Stack>
  );
};

import { Url } from '@rossum/api-client';
import { GridRenderCellParams } from '@rossum/ui/x-data-grid-pro';
import { compact } from 'lodash';
import LabelsColumn from '../../labels/components/LabelsColumn';
import { useRequestUnpaginatedLabels } from '../../labels/hooks/useRequestLabels';
import { toDict } from '../helpers/toDict';
import { TransformedAnnotation } from '../hooks/useTransformAnnotation';

export type SimpleLabelsRowContext = Pick<TransformedAnnotation, 'labels'>;

export const SimpleLabels = (
  cellProps: GridRenderCellParams<SimpleLabelsRowContext, Url[]>
) => {
  const { data: labels } = useRequestUnpaginatedLabels({ select: toDict });
  return (
    <LabelsColumn
      labels={compact(
        cellProps.row.labels.map(labelUrl => (labels ?? {})[labelUrl])
      )}
      maxVisibleLabelCount={1}
    />
  );
};

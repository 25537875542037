import { IntlShape } from 'react-intl';
import { GridRowModel } from '../field-detail/rows/rowTypes';

export type FieldOptions = Array<{ value: string; label: string }>;

export const getCategoryOptions = (intl: IntlShape): FieldOptions => {
  return [
    {
      value: 'section',
      label: intl.formatMessage({
        id: 'features.fieldManager.categoryOptions.option.section',
      }),
    },
    {
      value: 'multivalue',
      label: intl.formatMessage({
        id: 'features.fieldManager.categoryOptions.option.multivalue',
      }),
    },
    {
      value: 'tuple',
      label: intl.formatMessage({
        id: 'features.fieldManager.categoryOptions.option.tuple',
      }),
    },
    {
      value: 'datapoint',
      label: intl.formatMessage({
        id: 'features.fieldManager.categoryOptions.option.datapoint',
      }),
    },
  ];
};

export const getTypeOptions = (intl: IntlShape): FieldOptions => {
  return [
    {
      value: 'string',
      label: intl.formatMessage({
        id: 'features.fieldManager.typeOptions.option.string',
      }),
    },
    {
      value: 'number',
      label: intl.formatMessage({
        id: 'features.fieldManager.typeOptions.option.number',
      }),
    },
    {
      value: 'date',
      label: intl.formatMessage({
        id: 'features.fieldManager.typeOptions.option.date',
      }),
    },
    {
      value: 'enum',
      label: intl.formatMessage({
        id: 'features.fieldManager.typeOptions.option.enum',
      }),
    },
    {
      value: 'button',
      label: intl.formatMessage({
        id: 'features.fieldManager.typeOptions.option.button',
      }),
    },
    {
      value: 'undefined',
      label: intl.formatMessage({
        id: 'features.fieldManager.typeOptions.option.undefined',
      }),
    },
  ];
};

export const getUiConfigurationTypeOptions = (
  intl: IntlShape
): FieldOptions => {
  return [
    {
      value: 'captured',
      label: intl.formatMessage({
        id: 'features.fieldManager.uiConfigurationTypeOptions.option.captured',
      }),
    },
    {
      value: 'data',
      label: intl.formatMessage({
        id: 'features.fieldManager.uiConfigurationTypeOptions.option.data',
      }),
    },
    {
      value: 'manual',
      label: intl.formatMessage({
        id: 'features.fieldManager.uiConfigurationTypeOptions.option.manual',
      }),
    },
    {
      value: 'formula',
      label: intl.formatMessage({
        id: 'features.fieldManager.uiConfigurationTypeOptions.option.formula',
      }),
    },
    {
      value: 'null',
      label: intl.formatMessage({
        id: 'features.fieldManager.uiConfigurationTypeOptions.option.null',
      }),
    },
    {
      value: 'undefined',
      label: intl.formatMessage({
        id: 'features.fieldManager.uiConfigurationTypeOptions.option.undefined',
      }),
    },
  ];
};

export const getUiConfigurationEditOptions = (
  intl: IntlShape,
  row: GridRowModel | undefined
): FieldOptions => {
  const isFormula =
    row && 'uiConfiguration' in row && row.uiConfiguration?.type === 'formula';
  return [
    {
      value: 'enabled',
      label: intl.formatMessage({
        id: 'features.fieldManager.uiConfigurationEditOptions.option.enabled',
      }),
    },
    {
      value: 'enabled_without_warning',
      label: intl.formatMessage({
        id: 'features.fieldManager.uiConfigurationEditOptions.option.enabled_without_warning',
      }),
    },
    {
      value: 'disabled',
      label: intl.formatMessage({
        id: 'features.fieldManager.uiConfigurationEditOptions.option.disabled',
      }),
    },
  ].filter(item =>
    isFormula ? item.value !== 'enabled_without_warning' : true
  );
};

import { useHistory } from 'react-router';

const extractKeyAndSubpagesFromUrl = (url: string) => {
  const regex = /\/settings\/([^/]+)(\/.*)?/;
  const match = url.match(regex);
  if (match) {
    const key = match[1];
    const subPageKeys = match[2]
      ? match[2].split('/').filter(subpage => subpage.length > 0)
      : [];
    return { key, subPageKeys };
  }
  return { key: null, subPageKeys: [] };
};

export const usePageKey = () => {
  const history = useHistory();
  const currentUrl = history.location.pathname;

  const { subPageKeys } = extractKeyAndSubpagesFromUrl(currentUrl);

  return { subPageKeys };
};

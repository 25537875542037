import { yupResolver } from '@hookform/resolvers/yup';
import { Tooltip } from '@rossum/rossum-ui/Tooltip';
import { Alert, Stack, Switch, Typography } from '@rossum/ui/material';
import clsx from 'clsx';
import { compact, find, get, isEqual, map, omit } from 'lodash';
import InfoIcon from 'mdi-react/InfoCircleOutlineIcon';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import * as yup from 'yup';
import RadioGroupControl from '../../../components/ReactHookForm/controls/RadioGroupControl';
import TextFieldControl from '../../../components/ReactHookForm/controls/TextFieldControl';
import SelectItems from '../../../components/ReactHookForm/SelectItems';
import ValidationInput from '../../../components/ReactHookForm/ValidationInput';
import HiddingButton from '../../../components/UI/HiddingButton';
import { docsLinks } from '../../../constants/values';
import { localeOptions } from '../../../i18n/config';
import { linebreak, link } from '../../../lib/formaterValues';
import {
  getRoleOptions,
  getRoleUrl,
  isApproverRole,
} from '../../../redux/modules/groups/helpers';
import { isSSOEnable } from '../../../redux/modules/organization/selectors';
import { approvalWorkflowsEnabledSelector } from '../../../redux/modules/organizationGroup/selectors';
import { splitFullName } from '../../../redux/modules/user/helpers';
import { userSelector } from '../../../redux/modules/user/selectors';
import {
  UpdateUserDetail,
  updateUserDetail as updateUserDetailAction,
} from '../../../redux/modules/users/actions';
import { PASSWORD, SSO } from '../../../redux/modules/users/values';
import { GroupRole } from '../../../types/group';
import { State } from '../../../types/state';
import { AuthType, User } from '../../../types/user';
import PaperSection from '../../../ui/paper-section/PaperSection';
import FormLabel from '../components/FormLabel';
import RoleSelect, { RoleOptions } from '../components/RoleSelect';
import RoleTooltip from '../components/RoleTooltip';
import styles from '../styles.module.sass';

const ORG_GROUP_ADMIN: GroupRole = 'organization_group_admin';

type StateProps = {
  SSOEnabled: boolean;
  loggedInUserId: number;
  roleOptions: RoleOptions;
  userRoleUrl: string;
  isApprover: boolean;
};

type DispatchProps = {
  updateUserDetail: UpdateUserDetail;
};

type OwnProps = {
  selectedUser: User;
  intl: IntlShape;
};

type Props = OwnProps & DispatchProps & StateProps;

const userValidationSchema = (intl: IntlShape) =>
  yup.object().shape({
    name: yup.string().required(
      intl.formatMessage({
        id: 'containers.settings.users.addUser.name.error',
      })
    ),
    role: yup.string().required(
      intl.formatMessage({
        id: 'containers.settings.users.addUser.role.error',
      })
    ),
    oidcId: yup
      .string()
      .nullable()
      .when('authType', {
        is: SSO,
        then: yup
          .string()
          .nullable()
          .max(
            255,
            intl.formatMessage({
              id: 'containers.settings.users.addUser.oidcId.error',
            })
          ),
      }),
    canApprove: yup.boolean().optional(),
  });

type FormParams = {
  authType: AuthType | undefined;
  oidcId: string | null;
  locale: string;
  name: string;
  role: string;
  canApprove: boolean;
};

const Info = ({
  intl,
  loggedInUserId,
  roleOptions,
  selectedUser,
  updateUserDetail,
  userRoleUrl,
  SSOEnabled,
  isApprover,
}: Props) => {
  const enableApproverRole = useSelector(approvalWorkflowsEnabledSelector);

  const roleOptionsWithFlag = roleOptions.filter(role =>
    enableApproverRole || isApprover ? role : role.name !== 'approver'
  );

  const locale = get(selectedUser, 'uiSettings.locale') as string;
  const role = get(
    find(roleOptionsWithFlag, ['value', userRoleUrl]),
    'name',
    ''
  );
  const name = compact([selectedUser.firstName, selectedUser.lastName]).join(
    ' '
  );

  const currentUser = useSelector(userSelector);

  const { authType, oidcId = null, url: selectedUserUrl } = selectedUser;

  const {
    handleSubmit,
    watch,
    control,
    formState: { isValid },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      authType,
      oidcId,
      locale,
      name,
      role,
      canApprove: isApprover,
    },
    resolver: yupResolver(userValidationSchema(intl)),
  });

  const rawWatchValues = watch();
  const watchValues = {
    ...rawWatchValues,
    canApprove: rawWatchValues.canApprove || rawWatchValues.role === 'approver',
  };

  const isRoleSelectDisabled = loggedInUserId === selectedUser.id;
  const isUserOrgGroupAdmin = watchValues.role === ORG_GROUP_ADMIN;

  const ssoActive = watchValues.authType === SSO;

  const canSubmit =
    !isEqual(
      ssoActive ? watchValues : omit(watchValues, 'oidcId'),
      ssoActive
        ? {
            name,
            locale,
            role,
            authType,
            oidcId,
            canApprove: isApprover,
          }
        : {
            name,
            locale,
            role,
            authType,
            canApprove: isApprover,
          }
    ) && isValid;

  const onSubmit = ({
    name: newName,
    locale: newLocale,
    role: newRole,
    authType: newAuthType,
    oidcId: newOidcId,
  }: {
    authType: AuthType | undefined;
    locale: string;
    name: string;
    oidcId: string | null;
    role: string;
  }) => {
    if (!canSubmit) return null;

    const { firstName, lastName } = splitFullName(newName);
    return updateUserDetail(
      selectedUser.id,
      {
        firstName,
        lastName,
        groups: watch().canApprove
          ? [
              get(find(roleOptions, ['name', newRole]), 'value', ''),
              get(find(roleOptions, ['name', 'approver']), 'value', ''),
            ]
          : [get(find(roleOptions, ['name', newRole]), 'value', '')],
        ...(newLocale && { uiSettings: { locale: newLocale } }),
        authType: newAuthType,
        oidcId: newAuthType === SSO ? newOidcId : oidcId,
      },
      { withMessage: true }
    );
  };

  const displayLogoutWarning =
    watchValues.authType !== authType && currentUser.url === selectedUserUrl;

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-page-title="user-info">
      <PaperSection
        title={intl.formatMessage({
          id: 'containers.settings.queues.email.general',
        })}
      >
        <div className={styles.Row}>
          <FormLabel>
            <FormattedMessage id="containers.settings.users.addUser.email.label" />
          </FormLabel>
          <div className={styles.Name}>
            {selectedUser.email || selectedUser.username}
          </div>
        </div>
        <div className={styles.Row}>
          <FormLabel>
            <FormattedMessage id="containers.settings.users.addUser.name.label" />
          </FormLabel>
          <TextFieldControl
            ControllerProps={{ control, name: 'name' }}
            placeholder={intl.formatMessage({
              id: 'containers.settings.users.addUser.name.placeholder',
            })}
            FieldLabelProps={{ layout: 'none' }}
            inputProps={{
              'data-cy': 'user-info-name-input',
            }}
          />
        </div>
        <div className={styles.Row}>
          <FormLabel>
            <FormattedMessage id="containers.settings.users.addUser.locale.label" />
          </FormLabel>
          <SelectItems<FormParams, (typeof localeOptions)[number]['value']>
            withDescription={false}
            name="locale"
            control={control}
            options={localeOptions}
            getLabel={selectedValue =>
              localeOptions.find(({ value }) => value === selectedValue)
                ?.label ?? selectedValue
            }
            placeholderKey="containers.settings.users.addUser.locale.placeholder"
            dataCy="locale-drop-down"
          />
        </div>
        <div className={styles.Row}>
          <FormLabel>
            <FormattedMessage id="containers.settings.users.addUser.role.label" />
            <RoleTooltip roles={map(roleOptionsWithFlag, 'name')} />
          </FormLabel>
          <div className={clsx(isRoleSelectDisabled && styles.SelectDisabled)}>
            <Tooltip
              content={
                <FormattedMessage
                  id={
                    isUserOrgGroupAdmin
                      ? 'containers.settings.users.roleSelectDisabled.isGroupAdmin'
                      : 'containers.settings.users.roleSelectDisabled.isOwnRole'
                  }
                  values={{
                    linebreak,
                    link: link('mailto:support@rossum.ai'),
                  }}
                />
              }
              contentClassName={styles.Tooltip}
              disabled={!isRoleSelectDisabled}
            >
              <RoleSelect<FormParams>
                name="role"
                control={control}
                roleOptions={roleOptionsWithFlag}
                getErrorMessage={(_, { message }) => message ?? ''}
                disabled={isRoleSelectDisabled}
              />
            </Tooltip>
          </div>
        </div>
        {enableApproverRole && (
          <Stack direction="row" spacing={4.5} sx={{ width: '416px' }}>
            <FormLabel>
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'containers.settings.users.addUser.canApprove',
                })}
              </Typography>
            </FormLabel>
            <Controller
              control={control}
              name="canApprove"
              render={({ field }) => (
                <Switch
                  data-cy="can-approve-user-settings-togle"
                  checked={field.value || watchValues.role === 'approver'}
                  disabled={watchValues.role === 'approver'}
                  onChange={(_, value) => field.onChange(value)}
                  size="small"
                />
              )}
            />
          </Stack>
        )}
      </PaperSection>
      {SSOEnabled && (
        <PaperSection
          title={intl.formatMessage({
            id: 'containers.settings.users.authentication',
          })}
        >
          <Stack spacing={2}>
            <div className={styles.Row}>
              <FormLabel>
                <FormattedMessage id="containers.settings.users.addUser.authType" />
              </FormLabel>
              <RadioGroupControl
                ControllerProps={{ control, name: 'authType' }}
                getOptionLabel={option => (
                  <Stack mt="18px">
                    <Typography variant="body2" fontWeight="bold">
                      {option.label}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {option.tooltip}
                    </Typography>
                  </Stack>
                )}
                getOptionValue={option => option.value}
                options={([SSO, PASSWORD] as const).map(value => ({
                  value,
                  label: intl.formatMessage({
                    id: `containers.settings.users.authType.${value}`,
                  }),
                  tooltip: intl.formatMessage({
                    id: `containers.settings.users.authType.${value}.tooltip`,
                  }),
                }))}
              />
            </div>
            {ssoActive && (
              <div className={styles.Row}>
                <FormLabel>
                  <FormattedMessage id="containers.settings.users.addUser.oidcId.label" />
                  <Tooltip
                    innerPopperClassName={styles.Tooltip}
                    content={
                      <FormattedMessage
                        id="containers.settings.users.addUser.oidcId.tooltip"
                        values={{ link: link(`${docsLinks.sso}`) }}
                      />
                    }
                  >
                    <InfoIcon size={20} />
                  </Tooltip>
                </FormLabel>
                <ValidationInput
                  control={control}
                  getErrorMessage={(_, { message }) => message ?? ''}
                  name="oidcId"
                  placeholder={intl.formatMessage({
                    id: 'containers.settings.users.addUser.oidcId.placeholder',
                  })}
                />
              </div>
            )}
            {displayLogoutWarning && (
              <Alert
                severity="warning"
                variant="filled"
                sx={{ alignItems: 'center' }}
              >
                <Typography variant="body1">
                  {intl.formatMessage({
                    id: 'containers.settings.users.user.logoutWarning',
                  })}
                </Typography>
              </Alert>
            )}
          </Stack>
        </PaperSection>
      )}
      <div className={styles.Center}>
        <HiddingButton
          hideCondition={!canSubmit}
          messageId="containers.settings.fields.save"
          type="submit"
        />
      </div>
    </form>
  );
};

const mapStateToProps = (state: State, { intl, selectedUser }: OwnProps) => ({
  SSOEnabled: isSSOEnable(state),
  loggedInUserId: state.user.id,
  roleOptions: getRoleOptions(state.groups, intl),
  userRoleUrl: getRoleUrl(selectedUser.groups, state.groups),
  isApprover: isApproverRole(selectedUser.groups, state.groups),
});

const mapDispatchToProps = {
  updateUserDetail: updateUserDetailAction,
};

const InfoContainer = connect(mapStateToProps, mapDispatchToProps)(Info);

const InfoRoute = (props: OwnProps) =>
  props.selectedUser ? <InfoContainer {...props} /> : null;

export default injectIntl(InfoRoute);

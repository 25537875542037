import { useDispatch } from 'react-redux';
import { Redirect, Switch, useRouteMatch } from 'react-router';
import { EngineRoutes } from '../../features/engines/EngineRoutes';
import DedicatedEngineDetail from '../../features/legacy-engines/containers/DedicatedEngineDetail';
import { enterQueueList } from '../../redux/modules/ui/actions';
import Route from '../../routes/Route';
import Automation from '.';
import {
  automationEnginesPath,
  AutomationParams,
  automationPath,
  dedicatedEnginesDetailRoute,
} from './helpers';

export const AutomationRoutes = () => {
  const dispatch = useDispatch();
  const dedicatedEngineMatch = useRouteMatch<AutomationParams>(
    dedicatedEnginesDetailRoute
  );

  return (
    <Switch>
      <Route
        exact
        path={automationPath()}
        component={Automation}
        onEnter={() => dispatch(enterQueueList())}
      />

      {dedicatedEngineMatch && Number(dedicatedEngineMatch.params.id) && (
        <Route
          exact
          path={dedicatedEnginesDetailRoute}
          component={DedicatedEngineDetail}
        />
      )}

      {/* Engine routes are managed by the feature itself. */}
      <Route path={automationEnginesPath()} render={() => <EngineRoutes />} />

      <Redirect to={automationPath()} />
    </Switch>
  );
};

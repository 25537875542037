import { endpoints, getIDFromUrl } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';

export const usePagePreview = (pageUrl: string | undefined) =>
  useQuery({
    queryKey: [pageUrl],

    queryFn: async () => {
      if (!pageUrl) {
        return null;
      }

      const response = await api.request(
        endpoints.pages.preview(getIDFromUrl(pageUrl))
      );

      return URL.createObjectURL(response);
    },

    staleTime: 1000 * 60 * 60 * 24,
  });

import { useDispatch, useSelector } from 'react-redux';
import { rejectEmail } from '../../redux/modules/email/actions';
import { isUserViewer as isUserViewerSelector } from '../../redux/modules/user/selectors';
import EmailDrawer from '../email-drawer';
import { AttachmentT } from '../email-drawer/components/EmailAttachment/helpers';

type DocumentEmailDrawerProps = {
  emailThreadUrl: string | null | undefined;
  queueUrl: string;
  onClose: () => void;
  defaultAttachment?: AttachmentT;
};

const DocumentEmailDrawer = ({
  emailThreadUrl,
  queueUrl,
  onClose,
  defaultAttachment,
}: DocumentEmailDrawerProps) => {
  const dispatch = useDispatch();
  const isUserViewer = useSelector(isUserViewerSelector);
  return (
    <EmailDrawer
      canSendEmails={!isUserViewer}
      onSend={({ mode, rootEmail }) => {
        if (mode === 'rejectEmail' && rootEmail) {
          dispatch(rejectEmail({ rootEmailId: rootEmail.id }));
        }
      }}
      DrawerProps={{
        open: !!emailThreadUrl,
        onClose,
      }}
      queueUrl={queueUrl}
      emailThreadUrl={emailThreadUrl ?? undefined}
      defaultAttachment={defaultAttachment ?? undefined}
    />
  );
};

export default DocumentEmailDrawer;

// Generated by ts-to-zod
import { z } from 'zod';
import {
  engineFieldTypeSchema,
  multilineSchema,
} from './engineFieldType.schema';

export const preTrainedFieldSchema = z.object({
  name: z.string(),
  label: z.string(),
  section: z.string(),
  type: engineFieldTypeSchema,
  subtype: z.string(),
  tabular: z.boolean(),
  description: z.string(),
  multiline: multilineSchema,
});

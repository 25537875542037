import { Survey } from '@rossum/api-client/surveys';
import { useMemo } from 'react';
import { getUnsafe } from '../../../../lib/helpers';
import { getNumber } from '../../helpers';
import { useSubmitSurvey } from '../../hooks/useSubmitSurvey';

export const useSurveyActions = (survey: Survey) => {
  const submitSurvey = useSubmitSurvey();
  return useMemo(() => {
    return {
      /** Used for counting how many times survey has been skipped  */
      increaseSkipCount: () =>
        submitSurvey(
          {
            surveyId: survey.id,
            surveyPatchModel: {
              additionalData: {
                ...(typeof survey.additionalData === 'object'
                  ? survey.additionalData
                  : {}),
                skipCount:
                  getNumber(getUnsafe(survey.additionalData, 'skipCount')) + 1,
              },
            },
          },
          true
        ),
      /** Used for counting page views or feature usage */
      increaseUsageCount: () =>
        submitSurvey({
          surveyId: survey.id,
          surveyPatchModel: {
            additionalData: {
              ...(typeof survey.additionalData === 'object'
                ? survey.additionalData
                : {}),
              usageCount:
                getNumber(getUnsafe(survey.additionalData, 'usageCount')) + 1,
            },
          },
        }),
      /** Used for submitting survey answers */
      answer: (answers: { value: unknown }[]) =>
        submitSurvey(
          {
            surveyId: survey.id,
            surveyPatchModel: {
              // probably not needed here
              additionalData: {
                ...(typeof survey.additionalData === 'object'
                  ? survey.additionalData
                  : {}),
              },
              answers,
            },
          },
          true
        ),
    } as const;
  }, [submitSurvey, survey.additionalData, survey.id]);
};

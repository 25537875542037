import { connect } from 'react-redux';
import { State } from '../../../types/state';
import ListFooter, {
  ListFooterProps,
} from '../../AnnotationList/components/ListFooter';

const mapStateToProps = (state: State): ListFooterProps => ({
  totalPages: state.users.pagination.totalPages,
  listLength: state.users.pagination.total,
});

export default connect<ListFooterProps, null, Record<string, never>, State>(
  mapStateToProps
)(ListFooter);

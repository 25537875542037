import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Header, HeaderProps } from '../../../ui/header/Header';
import { QueueSettingsBreadcrumbs } from '../components/QueueSettingsBreadcrumbs';
import { QueueSettingsTabs } from '../components/QueueSettingsTabs';

type EmailsHeaderProps = { parentPath: string; queueName: string } & Pick<
  HeaderProps,
  'scrollableDivRef'
>;

export const EmailsHeader = ({
  parentPath,
  queueName,
  scrollableDivRef,
}: EmailsHeaderProps) => {
  const intl = useIntl();

  const history = useHistory();

  // TODO: This should probably not be copy-pasted around
  const onBackButtonClicked = useCallback(() => {
    // If the queue settings page was opened in a new tab, there is no history stack to go back, so fallbacking to "/documents" path instead
    if (history.length > 1) {
      return history.goBack();
    }
    return history.push('/documents');
  }, [history]);

  return (
    <Header
      scrollableDivRef={scrollableDivRef}
      tabs={<QueueSettingsTabs value="emails" parentPath={parentPath} />}
      breadcrumbs={
        <QueueSettingsBreadcrumbs
          queueName={queueName}
          settingsPath={parentPath}
          breadcrumbs={[
            {
              label: intl.formatMessage({
                id: 'containers.settings.queues.queue.emails',
              }),
              to: `${parentPath}/emails`,
            },
          ]}
        />
      }
      title={intl.formatMessage({
        id: 'containers.settings.queues.queue.emails',
      })}
      description={intl.formatMessage({
        id: 'containers.settings.queues.queue.emails.description',
      })}
      onBackButtonClicked={onBackButtonClicked}
    />
  );
};

import { GridFilterModel, GridLogicOperator } from '@rossum/ui/x-data-grid-pro';
import { useCallback, useMemo } from 'react';
import { z } from 'zod';
import { parseAndValidate } from '../../../../../utils/jsonParse';

const gridLogicOperator = [
  GridLogicOperator.And,
  GridLogicOperator.Or,
] as const;

const gridFilterModelSchema = z.object({
  items: z.array(
    z.object({
      id: z.number().or(z.string()).optional(),
      field: z.string(),
      value: z.any(),
      operator: z.string(),
    })
  ),
  logicOperator: z.enum(gridLogicOperator),
  quickFilterValues: z.any().array().optional(),
  quickFilterLogicOperator: z.enum(gridLogicOperator).optional(),
  quickFilterExcludeHiddenColumns: z.boolean().optional(),
});

const SESSION_STORAGE_KEY_FOR_FILTER_MODEL = 'fieldManagerFilterModel:overview';

// why is this even a hook? :thinking:
export const useStoredFilterModel = () => {
  const setFilterModel = useCallback(
    (model: GridFilterModel) =>
      sessionStorage.setItem(
        SESSION_STORAGE_KEY_FOR_FILTER_MODEL,
        JSON.stringify(model)
      ),
    []
  );

  const storedFilterModel = useMemo(
    () =>
      parseAndValidate(
        sessionStorage.getItem(SESSION_STORAGE_KEY_FOR_FILTER_MODEL),
        gridFilterModelSchema,
        {
          items: [],
          logicOperator: GridLogicOperator.And,
        }
      ),
    []
  );

  return [storedFilterModel, setFilterModel] as const;
};

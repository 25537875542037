import {
  listResponse,
  unpaginatedListResponse,
} from '../../utils/listResponse';
import { preTrainedFieldSchema } from '../models/preTrainedField.schema';

export const listPreTrainedFields = () => {
  return {
    endpoint: `/engine_fields/pre_trained_fields`,
    method: 'GET',
    responseSchema: unpaginatedListResponse(preTrainedFieldSchema),
  } as const;
};

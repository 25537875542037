import {
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { SettingsBreadcrumbs } from '../../../../containers/Settings/SettingsBreadcrumbs';
import { Header, HeaderProps } from '../../../../ui/header/Header';
import { fieldManagerPath } from '../../constants';
import { getFieldTitleFromPath } from '../routes';

type FieldDetailHeaderProps = {
  handleSubmitChanges: () => void;
  taskQueue: number;
  schemasToSaveLength: number;
  mutationIsLoading: boolean;
} & Pick<HeaderProps, 'scrollableDivRef'>;

export const FieldDetailHeader = ({
  handleSubmitChanges,
  taskQueue,
  schemasToSaveLength,
  mutationIsLoading,
  ...rest
}: FieldDetailHeaderProps) => {
  const intl = useIntl();
  const history = useHistory();

  const {
    location: { pathname },
  } = useHistory();

  const subtitle = getFieldTitleFromPath(pathname);

  return (
    <Header
      {...rest}
      breadcrumbs={
        <SettingsBreadcrumbs
          breadcrumbs={[
            {
              to: fieldManagerPath(),
              label: intl.formatMessage({
                id: 'features.fieldManager.overview.ui.header.title',
              }),
            },
            {
              label: intl.formatMessage({
                id: 'features.fieldManager.fieldDetail.title',
              }),
            },
          ]}
        />
      }
      title={intl.formatMessage({
        id: 'features.fieldManager.fieldDetail.title',
      })}
      description={subtitle}
      buttons={[
        mutationIsLoading && (
          <Stack key="loading-state">
            <Typography variant="caption" color="text.secondary">
              {intl.formatMessage({
                id: 'features.fieldManager.fieldDetail.proggress.saving',
              })}{' '}
              {Math.min(
                Math.max(schemasToSaveLength - taskQueue + 1, 1),
                schemasToSaveLength
              )}{' '}
              {intl.formatMessage(
                {
                  id: 'features.fieldManager.fieldDetail.proggress.schemasInfo',
                },
                { count: schemasToSaveLength }
              )}
            </Typography>
          </Stack>
        ),
        <Button
          key="save-changes"
          startIcon={
            mutationIsLoading && <CircularProgress size={16} color="inherit" />
          }
          data-cy="fm-save-changes-button"
          variant="contained"
          disabled={!schemasToSaveLength || mutationIsLoading}
          onClick={handleSubmitChanges}
        >
          {intl.formatMessage(
            {
              id: 'features.fieldManager.fieldDetail.button.saveChanges',
            },
            { count: schemasToSaveLength }
          )}
        </Button>,
      ]}
      onBackButtonClicked={() => history.push(fieldManagerPath())}
    />
  );
};

import { WarningAmberRounded } from '@rossum/ui/icons';
import { Tooltip, TooltipProps } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

export const restrictedAccessIconLabel = 'restricted-access-actions-icon';

export const RestrictedAccessIcon = ({
  placement = 'left',
}: Pick<TooltipProps, 'placement'>) => {
  const intl = useIntl();

  return (
    <Tooltip
      title={intl.formatMessage({
        id: 'features.pricing.documentArchive.blurredAnnotation.text',
      })}
      placement={placement}
    >
      <WarningAmberRounded
        className={restrictedAccessIconLabel}
        data-cy={restrictedAccessIconLabel}
        color="warning"
      />
    </Tooltip>
  );
};

import { createSelector } from 'reselect';
import { isNotNullOrUndefined } from '../../../lib/typeGuards';
import { annotationSelector } from '../../../redux/modules/annotation/selectors';
import { isTrialSelector } from '../../../redux/modules/organization/selectors';
import { complexLineItemsEnabledSelector } from '../../../redux/modules/ui/selectors';
import { userSelector } from '../../../redux/modules/user/selectors';
import {
  isEmbeddedBlocker,
  isInternalUserBlocker,
  isNotTrialBlocker,
} from '../blockers';

const areComplexLineItemsDisabled = (complexLineItemsEnabled: boolean) =>
  complexLineItemsEnabled ? undefined : 'no_cli';

const isSampleDocument = (
  currentAnnotation: ReturnType<typeof annotationSelector>
) => {
  const sampleDocument = currentAnnotation?.metadata?.sampleDocument;
  return sampleDocument ? 'sample_document' : undefined;
};

const dataCaptureSurveysBlockersSelector = createSelector(
  annotationSelector,
  userSelector,
  (currentAnnotation, currentUser) => {
    return [
      // no survey in embedded mode
      isEmbeddedBlocker(),
      // no survey for sample documents
      isSampleDocument(currentAnnotation),
      // do not show to internal users
      isInternalUserBlocker(currentUser),
    ];
  }
);

export const dcSatisfactionSurveyBlockersSelector = createSelector(
  dataCaptureSurveysBlockersSelector,
  isTrialSelector,
  (sharedBlockers, isTrial) => {
    return [...sharedBlockers, isNotTrialBlocker(isTrial)].filter(
      isNotNullOrUndefined
    );
  }
);

export const cliSurveyBlockersSelector = createSelector(
  dataCaptureSurveysBlockersSelector,
  complexLineItemsEnabledSelector,
  (sharedBlockers, complexLineItemsEnabled) => {
    return [
      ...sharedBlockers,
      areComplexLineItemsDisabled(complexLineItemsEnabled),
    ].filter(isNotNullOrUndefined);
  }
);

import { Stack, SxProps, Theme } from '@rossum/ui/material';
import { GridRow, GridRowProps } from '@rossum/ui/x-data-grid-pro';
import { restrictedAccessIconLabel } from './RestrictedAccessIcon';

export const disabledContainerStyles: SxProps<Theme> = {
  pointerEvents: 'none',
  userSelect: 'none',
  // select all except class and class' children
  [`*:not(.${restrictedAccessIconLabel}, .${restrictedAccessIconLabel} *)`]: {
    color: t => `${t.palette.action.disabled} !important`,
  },
  [`& .${restrictedAccessIconLabel}`]: {
    pointerEvents: 'auto',
  },
};

export const DisabledRow = (
  props: Omit<GridRowProps, 'row'> & {
    row?: unknown;
  }
) => {
  return (
    <Stack data-cy="annotation-row-disabled" sx={disabledContainerStyles}>
      <GridRow {...props} />
    </Stack>
  );
};

import { Stack, Typography } from '@rossum/ui/material';
import { ReactNode } from 'react';

type SplitContentProps = {
  title: ReactNode;
  description: ReactNode;
  children: ReactNode;
  disabled?: boolean;
};

export const SplitContent = ({
  title,
  description,
  children,
  disabled,
}: SplitContentProps) => (
  <Stack direction="row" spacing={4}>
    <Stack
      width="50%"
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between"
      spacing={2}
    >
      <Stack spacing={1}>
        <Typography
          variant="h5"
          color={disabled ? 'text.disabled' : 'text.primary'}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          color={disabled ? 'text.disabled' : 'text.secondary'}
          component="span"
        >
          {description}
        </Typography>
      </Stack>
    </Stack>
    <Stack width="50%" spacing={3}>
      {children}
    </Stack>
  </Stack>
);

import { Url } from '@rossum/api-client';
import { Box, LinearProgress, Stack } from '@rossum/ui/material';
import {
  DataGridPro,
  gridClasses,
  GridFilterItem,
  GridFilterModel,
  GridPaginationModel,
  GridRowParams,
  GridSortModel,
} from '@rossum/ui/x-data-grid-pro';
import { LicenseInfo } from '@rossum/ui/x-license-pro';
import { debounce } from 'lodash';
import { memo, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { NonAdminRestrictor } from '../../components/Restrictors';
import { muiDataGridLicenseKey } from '../../constants/config';
import { commonDataGridStyles } from '../../ui/data-grid/styles';
import {
  ExpandableSearch,
  SEARCH_MIN_CHAR_LENGTH,
} from '../../ui/expandable-search/ExpandableSearch';
import DocumentEmailDrawer from '../document/DocumentEmailDrawer';
import {
  allowedPageSizes,
  DEFAULT_PAGE_SIZE,
} from '../document-list-base/hooks/usePagination';
import { absoluteMaxSizeStyles } from '../document-upload/components/helpers';
import { useWorkspacesWithQueues } from '../queues/hooks/useWorkspacesWithQueues';
import BreadCrumbs from './components/BreadCrumbs';
import { EmptyStates } from './components/EmptyStates';
import { QueueDetails } from './components/QueueDetails';
import SearchResults from './components/SearchResults';
import { ToggleSidebarButton } from './components/ToggleSidebarButton';
import ViewSwitch from './components/ViewSwitch';
import {
  contentWrapperStyles,
  navigationPanelInnerStyles,
  navigationPanelOuterStyles,
  toolbarStyles,
} from './constants';
import { SettingsButton } from './cta-actions/SettingsButton';
import { TransformedEmailThread } from './emails/helpers';
import { useEmailsColumns } from './emails/hooks/useEmailsColumns';
import { useFetchEmailThreads } from './emails/hooks/useFetchEmailThreads';
import { useFetchEmailThreadsCounts } from './emails/hooks/useFetchEmailThreadsCounts';
import { FilterPanel } from './filtering/FilterPanel';
import { getActiveQueue, getPageSizeFromQuery } from './helpers';
import {
  DashboardQuery,
  LevelOptions,
  ViewOptions,
} from './hooks/useDashboardQuery';
import { useDataGridLocaleText } from './hooks/useDataGridLocaleText';
import { useSafelyParsedFiltering } from './hooks/useSafelyParsedFiltering';
import { useTimeoutSearchQuery } from './hooks/useTimeoutSearchQuery';

LicenseInfo.setLicenseKey(muiDataGridLicenseKey);

type DrawerState = {
  emailThreadUrl: Url;
  queueUrl: Url;
};

type Props = {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  view: ViewOptions;
  setView: (view: ViewOptions) => void;
  level: LevelOptions | null;
  activeQueueId: number | undefined;
  newEmailsExist: boolean;
  onPaginationChange: (newPaginationModel: GridPaginationModel) => void;
  query: DashboardQuery;
  sortingProps: {
    onSortModelChange: (sortModel: GridSortModel) => void;
    sortModel: GridSortModel;
  };
  onSearchSubmit: (searchValue: string | undefined) => void;
  existingFilter: GridFilterModel | null;
  handleFilterModelChange: (newFilterItems: GridFilterItem[]) => void;
  handleClearAllFilters: () => void;
  isSidebarEnabled: boolean;
};

const EmailsContent = memo(
  ({
    activeQueueId,
    isSidebarOpen,
    toggleSidebar,
    view,
    setView,
    level,
    newEmailsExist,
    onPaginationChange,
    query,
    sortingProps: { onSortModelChange, sortModel },
    onSearchSubmit,
    existingFilter,
    handleFilterModelChange,
    handleClearAllFilters,
    isSidebarEnabled,
  }: Props) => {
    const intl = useIntl();
    const [drawerState, setDrawerState] = useState<DrawerState | undefined>(
      undefined
    );
    const [searchState, setSearchState] = useState(query.search ?? '');

    const { queues } = useWorkspacesWithQueues({
      enableQueries: true,
    });

    const activeQueue = getActiveQueue({
      level: query.level,
      queues,
      queueId: activeQueueId,
    });

    useEffect(() => {
      // when queue has no inbox emails view are not available
      if (activeQueue && !activeQueue.inbox) {
        setView('documents');
      }
    }, [activeQueue, setView]);

    const safelyParsedFiltering = useSafelyParsedFiltering(
      query.filtering ?? ''
    );

    const { data: emailThreads, isLoading: emailThreadsAreLoading } =
      useFetchEmailThreads(
        {
          queue: activeQueueId,
          hasRootEmail: true,
          page: query.page ? parseInt(query.page, 10) : 1,
          pageSize: getPageSizeFromQuery({
            query,
            fallbackValue: DEFAULT_PAGE_SIZE,
          }),
          ...(query.search ? { search: query.search } : {}),
        },
        safelyParsedFiltering,
        query.ordering ?? ''
      );

    const { data: counts } = useFetchEmailThreadsCounts({
      queueId: activeQueueId,
    });

    const debouncedSearching = useMemo(
      () => debounce(onSearchSubmit, 400),
      [onSearchSubmit]
    );

    const localeText = useDataGridLocaleText();

    const columns = useEmailsColumns(counts);

    const rowCount = emailThreads?.pagination.total ?? 0;

    const paginationModel: GridPaginationModel = useMemo(
      () => ({
        page: !query.page ? 0 : parseInt(query.page, 10) - 1,
        pageSize: getPageSizeFromQuery({
          query,
          fallbackValue: DEFAULT_PAGE_SIZE,
        }),
      }),
      [query]
    );

    const filterableColumns = useMemo(
      () => columns.flatMap(column => (column.filterable ? [column] : [])),
      [columns]
    );

    const search = useTimeoutSearchQuery(query.search);

    return (
      <Stack {...contentWrapperStyles} data-page-title="email-list">
        <Stack {...navigationPanelOuterStyles}>
          <Stack {...navigationPanelInnerStyles}>
            {isSidebarEnabled && (
              <ToggleSidebarButton
                isSidebarOpen={isSidebarOpen}
                onClick={toggleSidebar}
              />
            )}
            <BreadCrumbs
              activeQueueId={activeQueueId}
              allDocumentsLevelIsActive={level === 'all'}
            />
            <ViewSwitch
              isVisible={level === 'queue'}
              view={view}
              setView={setView}
              newEmailsExist={newEmailsExist}
            />
          </Stack>
          <Stack direction="row">
            {activeQueue ? <QueueDetails activeQueue={activeQueue} /> : null}
            <Stack {...toolbarStyles}>
              <ExpandableSearch
                onChange={search => {
                  setSearchState(search);
                  debouncedSearching(
                    search.length >= SEARCH_MIN_CHAR_LENGTH ? search : undefined
                  );
                }}
                value={searchState}
              />
              {activeQueueId ? (
                <NonAdminRestrictor>
                  <SettingsButton queueId={activeQueueId} view="emails" />
                </NonAdminRestrictor>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
        <Stack gap={2} mt={2}>
          <FilterPanel
            columns={filterableColumns}
            existingFilter={existingFilter}
            handleFilterModelChange={handleFilterModelChange}
            handleClearAllFilters={handleClearAllFilters}
          />
          <SearchResults querySearch={query.search} />
        </Stack>
        <Box
          position="relative"
          flexGrow={1}
          sx={{
            backgroundColor: 'inherit',
          }}
        >
          <DataGridPro
            loading={emailThreadsAreLoading}
            columns={columns}
            columnVisibilityModel={{
              options: false, // column options exists only for filtering purposes
            }}
            rows={emailThreads?.results?.length ? emailThreads.results : []}
            localeText={localeText}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnResize
            pagination
            onRowClick={(row: GridRowParams<TransformedEmailThread>) => {
              setDrawerState({
                emailThreadUrl: row.row.url,
                queueUrl: row.row.queueUrl,
              });
            }}
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={onPaginationChange}
            pageSizeOptions={allowedPageSizes}
            rowCount={rowCount}
            onSortModelChange={onSortModelChange}
            sortModel={sortModel}
            slots={{
              noRowsOverlay: () => (
                <EmptyStates
                  isEmailView
                  searchQuery={search}
                  queue={activeQueue}
                  existingFilter={existingFilter}
                  onClearFilters={handleClearAllFilters}
                />
              ),
              loadingOverlay: LinearProgress,
            }}
            slotProps={{
              row: { 'data-cy': 'email-row' },
              pagination: {
                labelRowsPerPage: intl.formatMessage({
                  id: 'containers.allDocuments.pagination.label',
                }),
                'data-cy': 'emails-pagination',
                backIconButtonProps: {
                  // @ts-expect-error
                  'data-cy': 'emails-pagination-prev-btn',
                },
                nextIconButtonProps: {
                  // @ts-expect-error
                  'data-cy': 'emails-pagination-next-btn',
                },
                sx: {
                  // hide counter when there are no rows
                  display: rowCount === 0 ? 'none' : 'inherit',
                  [`> * p`]: {
                    margin: 0,
                  },
                },
              },
              baseSwitch: {
                color: 'secondary',
              },
            }}
            sx={{
              ...commonDataGridStyles,
              ...absoluteMaxSizeStyles,
              [`.${gridClasses.row}`]: {
                cursor: 'pointer',
              },
            }}
          />
        </Box>

        <DocumentEmailDrawer
          emailThreadUrl={drawerState ? drawerState.emailThreadUrl : null}
          queueUrl={drawerState ? drawerState.queueUrl : ''}
          onClose={() => setDrawerState(undefined)}
        />
      </Stack>
    );
  }
);

export default EmailsContent;

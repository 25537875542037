// Generated by ts-to-zod
import { z } from 'zod';
import { schemaSectionSchema } from '../../../schemas/models/schemaSection.schema';

export const formulaSummaryPayloadSchema = z.object({
  schemaContent: z.array(schemaSectionSchema),
  fieldSchemaId: z.string(),
});

export const formulaSummaryResultSchema = z.object({
  summary: z.string(),
});

export const formulaSummaryResponseSchema = z.object({
  results: z.array(formulaSummaryResultSchema),
});

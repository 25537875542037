// Generated by ts-to-zod
import { z } from 'zod';
import { schemaDatapointBaseSchema } from './schemaDatapointBase.schema';

export const schemaDatapointEnumOptionSchema = z.object({
  value: z.string(),
  label: z.string(),
});

export const schemaDatapointEnumOptionsSchema = z.array(
  schemaDatapointEnumOptionSchema
);

export const schemaDatapointEnumSchema = z
  .object({
    type: z.literal('enum'),
    options: schemaDatapointEnumOptionsSchema,
    enumValueType: z
      .union([z.literal('string'), z.literal('number'), z.literal('date')])
      .optional(),
  })
  .and(schemaDatapointBaseSchema);

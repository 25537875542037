import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { LocalizationKeys } from '../i18n';
import { resolvePageTitle } from './helpers';

const fallbackTitle = 'Rossum';

export const HelmetComponent = ({
  translationKey,
  dynamicName,
}: {
  translationKey?: LocalizationKeys;
  dynamicName?: string;
}) => {
  const intl = useIntl();

  const location = useLocation();

  if (translationKey)
    return (
      <Helmet
        title={intl.formatMessage(
          {
            id: translationKey,
            defaultMessage: fallbackTitle,
          },
          { name: dynamicName ? `${dynamicName} •` : null }
        )}
        defer={false}
        titleTemplate="%s - Rossum"
      />
    );

  const key = resolvePageTitle(location.pathname);

  const title = key
    ? intl.formatMessage({
        id: `features.routes.pageTitles.${key}`,
        defaultMessage: fallbackTitle,
      })
    : fallbackTitle;

  return (
    <Helmet
      title={title}
      defer={false}
      titleTemplate={key ? '%s - Rossum' : ''}
    />
  );
};

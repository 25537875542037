import { endpoints } from '@rossum/api-client';
import { Annotation } from '@rossum/api-client/annotations';
import { AnnotationStatus } from '@rossum/api-client/shared';
import { getIDFromUrl, SnakeToCamel, Url } from '@rossum/api-client/utils';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';

const PAGE_SIZE = 10;
const ALL_DOCS_WORKFLOW_QUERY_KEY = 'all-docs-workflow';

export const useWorkflowStepNames = (
  annotationId: Annotation['id'],
  status: SnakeToCamel<AnnotationStatus> | undefined
) => {
  return useQuery({
    queryKey: [ALL_DOCS_WORKFLOW_QUERY_KEY, annotationId] as const,

    queryFn: async () => {
      const workflowRuns = await api.request(
        endpoints.workflowRuns.list({
          annotation: annotationId,
          workflowStatus: 'pending',
          // We expect every annotation to have exactly one pending workflow run.
          pageSize: PAGE_SIZE,
        })
      );

      const workflowStepIds = workflowRuns.results.flatMap(workflowRun =>
        workflowRun.currentStep ? [getIDFromUrl(workflowRun.currentStep)] : []
      );

      const workflowSteps = await api.request(
        endpoints.workflowSteps.list({
          id: workflowStepIds,
          pageSize: workflowStepIds.length,
        })
      );

      const stepNames = workflowRuns.results.reduce<Record<Url, string>>(
        (acc, curr) => {
          const stepName = workflowSteps.results.find(
            workflowStep => workflowStep.url === curr.currentStep
          )?.name;
          return stepName
            ? {
                ...acc,
                [curr.annotation]: stepName,
              }
            : acc;
        },
        {}
      );

      return { stepNames };
    },
    enabled: !!annotationId && status === 'inWorkflow',
  });
};

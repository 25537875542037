import { ChevronRight } from '@rossum/ui/icons';
import { Breadcrumbs as MUIBreadcrumbs } from '@rossum/ui/material';
import { Breadcrumb } from '../../ui/breadcrumb/Breadcrumb';

export type Breadcrumb = {
  label: string;
  to?: string;
};

type BreadcrumbsProps = {
  breadcrumbs: Breadcrumb[];
};

export const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => {
  return (
    <MUIBreadcrumbs
      separator={<ChevronRight fontSize="small" color="disabled" />}
    >
      {breadcrumbs.map(({ label, to }) => (
        <Breadcrumb key={label} to={to}>
          {label}
        </Breadcrumb>
      ))}
    </MUIBreadcrumbs>
  );
};

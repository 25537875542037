import { ActionType, createAction } from 'typesafe-actions';

export const ANNOTATION_STACK = 'annotationStack';
export const ANNOTATIONS_QUERY = 'annotationsQuery';
export const ANNOTATIONS_QUERY_FOR_BROWSER_TAB =
  'annotationsQueryForBrowserTab';
export const NAVIGATE_TO_NEXT = 'navigateToNext';
export const WORKSPACES = 'workspaces';
export const STATISTICS_QUERY = 'statisticsQuery';
export const BILLING_USAGE_QUERY = 'billingUsageQuery';
export const BILLING_OVERVIEW_QUERY = 'billingOverviewQuery';
export const EXTENSIONS_ADVANCED_SETTINGS_EXPANDED =
  'extensions:advancedSettingsExpanded';
export const EXTENSIONS_JSON_CONFIG_EXPANDED = 'extensions:jsonConfigExpanded';
export const EXTENSIONS_LOGS_QUERY = 'extensions:logsQuery';
export const HOOK_LIST_QUERY = 'hookListQuery';
export const DOCUMENTS_QUERY = 'documents:query';
export const EMAILS_QUERY = 'emails:query';
export const DOCUMENTS_SEARCH_AFTER_KEY = 'documents:nextCursors';
export const DOCUMENTS_QUERY_FOR_BROWSER_TAB = 'documents:queryForBrowserTab';
export const DOCUMENTS_SIDEBAR_IS_OPEN = 'documents:sidebarIsOpen';
export const FIELDS_SECTIONS_EXPANDED = 'fields:sectionsExpanded';
export const MEMBERSHIP_ID = 'membership:id';

export const storedUserData: StorageKey[] = [
  ANNOTATION_STACK,
  ANNOTATIONS_QUERY,
  BILLING_USAGE_QUERY,
  BILLING_OVERVIEW_QUERY,
  HOOK_LIST_QUERY,
  STATISTICS_QUERY,
  WORKSPACES,
  EXTENSIONS_LOGS_QUERY,
  DOCUMENTS_QUERY,
  DOCUMENTS_SEARCH_AFTER_KEY,
  MEMBERSHIP_ID,
];

export type StorageKey =
  | 'annotationStack'
  | 'annotationsQuery'
  | 'navigateToNext'
  | 'locale'
  | 'workspaces'
  | 'statisticsQuery'
  | 'billingUsageQuery'
  | 'billingOverviewQuery'
  | typeof EXTENSIONS_ADVANCED_SETTINGS_EXPANDED
  | typeof EXTENSIONS_JSON_CONFIG_EXPANDED
  | typeof EXTENSIONS_LOGS_QUERY
  | typeof HOOK_LIST_QUERY
  | typeof DOCUMENTS_QUERY
  | typeof EMAILS_QUERY
  | typeof DOCUMENTS_SEARCH_AFTER_KEY
  | typeof MEMBERSHIP_ID;

export const setItem = createAction(
  'STORAGE_SET_ITEM',
  (_key: StorageKey, value: string) => value,
  (key: StorageKey, _value: string) => ({ key })
)<string, { key: StorageKey }>();

export const removeItem = createAction(
  'STORAGE_REMOVE_ITEM',
  undefined,
  (key: StorageKey) => ({
    key,
  })
)<undefined, { key: StorageKey }>();

export const removeItems = createAction(
  'STORAGE_REMOVE_ITEMS',
  undefined,
  (keys: StorageKey[]) => ({
    keys,
  })
)<undefined, { keys: StorageKey[] }>();

export type LocalStorageActions = ActionType<
  typeof setItem | typeof removeItem | typeof removeItems
>;

import * as R from 'remeda';
import { createSelector } from 'reselect';
import { isTrialSelector } from '../../../redux/modules/organization/selectors';
import { formulaFieldsEnabledSelector } from '../../../redux/modules/organizationGroup/selectors';
import { userSelector } from '../../../redux/modules/user/selectors';
import {
  isEmbeddedBlocker,
  isInternalUserBlocker,
  isTrialBlocker,
} from '../blockers';

const isFormulaDisabledBlocker = (formulaFieldsEnabled: boolean) => {
  return formulaFieldsEnabled ? undefined : 'formula_fields_disabled';
};

export const fieldsSurveysBlockersSelector = createSelector(
  userSelector,
  isTrialSelector,
  (currentUser, isTrial) => {
    return [
      // no survey in embedded mode
      isEmbeddedBlocker(),
      // do not show to internal users
      isInternalUserBlocker(currentUser),
      // do not show to trial users
      isTrialBlocker(isTrial),
    ].filter(R.isTruthy);
  }
);

export const formulaFieldsSurveyBlockersSelector = createSelector(
  fieldsSurveysBlockersSelector,
  formulaFieldsEnabledSelector,
  (blockers, formuleFieldsEnabled) => {
    return [
      ...blockers,
      // do not show when formula fields are disabled
      isFormulaDisabledBlocker(formuleFieldsEnabled),
    ].filter(R.isTruthy);
  }
);

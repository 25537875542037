import { useTheme } from '@rossum/ui/material';

const RossumLogo = () => {
  const { palette } = useTheme();

  return (
    <svg
      fill="none"
      height="18"
      viewBox="0 0 67 18"
      width="67"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <linearGradient
        id="logo-linearGradient"
        gradientUnits="userSpaceOnUse"
        x1="12.3247"
        x2="26.4179"
        y1="4.43801"
        y2="4.43801"
      >
        <stop offset="0" stopColor="var(--color-primary)" />
        <stop offset="1" stopColor="var(--color-primary-bright)" />
      </linearGradient>
      <g fill={palette.text.primary}>
        <path d="m3.38237 8.38955c1.5415 0 2.46228-.49823 2.46228-2.39002v-1.66813c0-1.56903-.57256-2.39002-2.01497-2.39002h-1.88903v6.44817h1.4424zm.49754-8.216088c2.73549 0 3.87923 1.394238 3.87923 3.983828v1.41832c0 1.91656-.67097 3.11192-2.16361 3.58538 1.59174.47277 2.18838 1.76721 2.18838 3.63421v2.7135c0 .7721.02547 1.494.34822 2.092h-1.96473c-.22366-.4982-.32344-.9462-.32344-2.1168v-2.7389c0-1.9661-.92009-2.5889-2.53592-2.5889h-1.36808v7.4439h-1.93996v-17.426538z" />
        <path d="m62.1205 17.6116h-1.865l-2.8848-13.86662v13.89212h-1.6915v-17.427261h2.7114l2.8594 13.916861 2.7616-13.916861h2.7107v17.427261h-1.8409v-14.01668z" />
        <path d="m49.8638 17.836h.044c2.6701-.0096 3.882-1.7755 3.882-4.4064v-13.219761h-1.8147v13.318861c0 1.5691-.6215 2.5146-2.0143 2.5146-1.4177 0-2.0391-.9462-2.0391-2.5146v-13.318861h-1.9158v13.219761c0 2.6316 1.2118 4.3968 3.8579 4.4064z" />
        <path d="m40.4583.0357666h.053c2.6185.0116989 3.8537 1.7266234 3.8537 4.3561334v.47346h-1.8408v-.57256c0-1.56903-.5967-2.48981-1.9888-2.48981-1.3929 0-1.9902.92146-1.9902 2.46503 0 3.90882 5.8446 4.28181 5.8446 9.18708 0 2.6385-1.2683 4.3561-3.9294 4.3561-2.6612 0-3.9047-1.7176-3.9047-4.3561v-.9965h1.8154v1.0956c0 1.5684.6468 2.4898 2.0397 2.4898 1.3922 0 2.0391-.9214 2.0391-2.4898 0-3.88402-5.8199-4.25701-5.8199-9.16162 0-2.67974 1.2353-4.3458026 3.8283-4.3568134z" />
        <path d="m31.2073.0357666h.0537c2.6178.0116989 3.8531 1.7266234 3.8531 4.3561334v.47346h-1.8409v-.57256c0-1.56903-.5966-2.48981-1.9888-2.48981-1.3929 0-1.9902.92146-1.9902 2.46503 0 3.90882 5.8446 4.28181 5.8446 9.18708 0 2.6385-1.2683 4.3561-3.9294 4.3561-2.6612 0-3.9047-1.7176-3.9047-4.3561v-.9965h1.8154v1.0956c0 1.5684.6469 2.4898 2.0397 2.4898 1.3929 0 2.0391-.9214 2.0391-2.4898.0007-3.88402-5.8192-4.25701-5.8192-9.16162 0-2.67974 1.2346-4.3458026 3.8276-4.3568134z" />
      </g>
      <path
        d="M17.64 1.912c-3.837 0-6.957 3.125-6.957 6.965 0 3.838 3.12 6.962 6.956 6.962 3.835 0 6.956-3.124 6.956-6.963 0-3.84-3.12-6.964-6.956-6.964zm0 15.753c-4.842 0-8.78-3.944-8.78-8.788 0-4.848 3.938-8.79 8.78-8.79 4.84 0 8.778 3.943 8.778 8.788 0 4.846-3.938 8.788-8.779 8.788z"
        fill="url(#logo-linearGradient)"
      />
    </svg>
  );
};

export default RossumLogo;

import { Reducer } from 'redux';
import Immutable, {
  Immutable as ImmutableType,
  ImmutableObject,
} from 'seamless-immutable';
import { getType } from 'typesafe-actions';
import { getCurrentRelationShape } from '../../../features/duplicates/helpers';
import { AnnotationFromRelation } from '../../../features/duplicates/types';
import { RootActionType } from '../../rootActions';
import { clearAnnotationData, exitAnnotation } from '../annotation/actions';
import { deleteAnnotations } from '../annotations/actions';
import { deleteQueueFulfilled } from '../queues/actions';
import { fetchRelationsFulfilled } from './actions';
import { RelationState } from './types';

const initialState = Immutable({
  list: [],
  currentRelation: undefined,
});

const relationsReducer: Reducer<
  ImmutableType<RelationState>,
  RootActionType
> = (state = initialState, action) => {
  switch (action.type) {
    case getType(fetchRelationsFulfilled): {
      const currentRelation = getCurrentRelationShape(
        action.payload,
        action.meta.urls
      );

      return state.set('currentRelation', currentRelation);
    }

    case getType(deleteAnnotations): {
      if (!state.currentRelation) return state;

      return state.updateIn<ImmutableObject<AnnotationFromRelation>[]>(
        ['currentRelation', 'annotations'],
        annotations =>
          annotations.map(annotation =>
            action.meta.urls.find(url => url === annotation.url)
              ? { ...annotation, status: 'deleted' }
              : annotation
          )
      );
    }

    case getType(deleteQueueFulfilled):
    case getType(exitAnnotation):
    case getType(clearAnnotationData):
      return initialState;

    default:
      return state;
  }
};

export default relationsReducer;

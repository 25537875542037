import { useEffect, useState } from 'react';

export const usePlaceholderSwitcher = (
  placeholders: string[],
  duration: number,
  fieldValue: string
) => {
  const [currentPlaceholderIndex, setCurrentPlaceholderIndex] = useState(0);
  const [placeholderStyle, setPlaceholderStyle] = useState({
    opacity: 1,
    color: 'text.secondary',
    transition: '',
  });

  useEffect(() => {
    if (fieldValue) {
      return undefined;
    }

    const interval = setInterval(() => {
      // Start fade out
      setPlaceholderStyle({
        opacity: 0,
        color: 'text.secondary',
        transition: 'opacity 0.5s ease-in-out',
      });

      setTimeout(() => {
        // Change placeholder after fade out is complete
        setCurrentPlaceholderIndex(
          prevIndex => (prevIndex + 1) % placeholders.length
        );

        // Start fade in
        setPlaceholderStyle({
          opacity: 1,
          color: 'text.secondary',
          transition: 'opacity 0.5s ease-in-out',
        });
      }, 500); // 500ms matches the transition duration for a smooth swap
    }, duration);

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [placeholders.length, duration, fieldValue]);

  return {
    placeholder: placeholders[currentPlaceholderIndex],
    placeholderStyle,
  };
};

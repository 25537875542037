import { Engine } from '@rossum/api-client/engines';
import { Alert, Stack } from '@rossum/ui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ActionDialog from '../../../components/UI/ActionDialog';
import ActionDialogActions from '../../../components/UI/ActionDialog/ActionDialogActions';
import { restrictedEngineEditEnabledSelector } from '../../../redux/modules/organizationGroup/selectors';
import { useCreateEngineField } from '../hooks/useCreateEngineField';
import { engineFieldKeys } from '../keys';
import { useFieldForm } from '../pages/FieldDetailPage';
import { FieldForm } from './FieldForm';

export const FIELD_INLINE_FORM_ID = 'engine_field_inline_form';

type CreateFieldDialogContentProps = {
  engine: Engine;
  name: string;
  label: string;
  onClose: () => void;
  onSubmit: (createdFieldName: string) => void;
  tabular: boolean;
};

export const CreateFieldDialogContent = ({
  engine,
  name,
  label,
  onClose,
  onSubmit,
  tabular,
}: CreateFieldDialogContentProps) => {
  const intl = useIntl();

  const { mutateAsync, error, isLoading } = useCreateEngineField(engine.url);

  const queryClient = useQueryClient();

  const isRestrictedEngineEditEnabled = useSelector(
    restrictedEngineEditEnabledSelector
  );

  const { control, handleSubmit } = useFieldForm({
    error,
    defaultValues: {
      label,
      name,
      preTrainedFieldId: null,
      type: 'string',
      subtype: null,
      tabular,
      multiline: 'false',
    },
  });

  return (
    <ActionDialog
      dialogTitle={intl.formatMessage({
        id: 'features.engines.dialog.createEngineField.title',
      })}
      onCancel={onClose}
      dialogColor="secondary"
      dialogActions={
        <ActionDialogActions
          dataCyConfirm="createFieldDialog-confirm-btn"
          confirmButtonText={intl.formatMessage({
            id: 'features.engines.dialog.createEngineField.confirm',
          })}
          confirmButtonProps={{
            color: 'primary',
            type: 'submit',
            form: FIELD_INLINE_FORM_ID,
            disabled: isLoading,
          }}
        />
      }
      dataCy="createFieldDialog-cancel-btn"
    >
      <Stack
        pt={0.5}
        spacing={3}
        component="form"
        id={FIELD_INLINE_FORM_ID}
        onSubmit={handleSubmit(({ field }) => {
          mutateAsync(field).then(newField => {
            queryClient.invalidateQueries(engineFieldKeys.all());
            onSubmit(newField.name);
            onClose();
          });
        })}
      >
        <Alert severity="info">
          {intl.formatMessage(
            {
              id: 'features.engines.dialog.createEngineField.description',
            },
            {
              engineName: engine.name,
            }
          )}
        </Alert>
        <FieldForm
          control={control}
          isCompact
          tabular={tabular}
          disabled={isRestrictedEngineEditEnabled}
        />
      </Stack>
    </ActionDialog>
  );
};

import { find, includes, sortBy } from 'lodash';
import { isEmbedded, reactAppLocale } from '../constants/config';
import { IntlLocale } from ".";

export const LOCALE_LOCALSTORAGE_KEY = 'locale';

export const fallbackLocale = 'en';
export const defaultLocaleKeys: IntlLocale[] = [
  fallbackLocale,
  'cs',
  'de',
  'es',
  'fr',
  'ja',
  'pt-br',
  'zh',
];
const onlyEmbeddedLocaleKeys: IntlLocale[] = [
  'da',
  'fi',
  'hu',
  'it',
  'nb',
  'nl',
  'pt',
  'sv',
  'tr',
];

export const supportedLocales = [
  ...defaultLocaleKeys,
  ...onlyEmbeddedLocaleKeys,
];
const locales = process.env.REACT_APP_LOCALES || supportedLocales;

const browserLocale = window.navigator.languages.reduce<string | undefined>(
  (bestLocale, preferredLocale) =>
    bestLocale ||
    find(locales, supportedLocale =>
      includes(preferredLocale.toLowerCase(), supportedLocale)
    ),
  undefined
);

export const getDefaultLocale = ({
  omitStorage,
}: { omitStorage?: boolean } = {}): IntlLocale =>
  getLocaleKey(omitStorage ? undefined : localStorage.getItem(LOCALE_LOCALSTORAGE_KEY)) ||
  getLocaleKey(browserLocale) ||
  getLocaleKey(reactAppLocale) ||
  getLocaleKey(process.env.REACT_APP_LOCALE) ||
  fallbackLocale;

  export const getLocaleKey = (
    locale: string | undefined | null
  ): IntlLocale | undefined => {
    if (!locale) return undefined;

    const availableLocales = isEmbedded() ? supportedLocales : defaultLocaleKeys;
    return availableLocales.find(
      availableLocale => availableLocale.toLowerCase() === locale.toLowerCase()
    );
  };

  const localeLabels = {
    cs: 'Czech',
    de: 'German',
    en: 'English',
    es: 'Spanish',
    fr: 'French',
    ja: 'Japanese',
    zh: 'Chinese Simplified',
    'pt-br': 'Portuguese (Brazil)',
  } as Record<string, string>;

  export const localeOptions = sortBy(
    defaultLocaleKeys.map(value => {
      const label =
        localeLabels[value] ||
        // eslint-disable-next-line no-console
        console.warn(`Locale label missing for ${value}`) ||
        value;

      return { value, label };
    }),
    'label'
  );

import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { EmailThread } from '../models/emailThreads';
import { emailThreadSchema } from '../models/emailThreads.schema';
import { emailThreadPatchPayloadSchema } from './patch.schema';

export type EmailThreadPatchPayload = {
  hasNewReplies?: boolean;
  rootEmailRead?: boolean;
};

export const patch = (emailId: ID, payload: EmailThreadPatchPayload) => {
  return {
    endpoint: `email_threads/${emailId}`,
    method: 'PATCH',
    responseSchema: emailThreadSchema,
    payloadSchema: emailThreadPatchPayloadSchema,
    payload,
  } as const;
};

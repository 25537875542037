import { ID } from '../../utils/codecUtils';
import {
  engineCheckTemplateCompatibilityPayloadSchema,
  engineCheckTemplateCompatibilityResponseSchema,
} from './checkTemplateCompatibility.schema';

export type EngineCheckTemplateCompatibilityPayload = {
  name: string;
};

export type EngineCheckTemplateCompatibilityResponse = {
  compatible: boolean;
};

export const checkTemplateCompatibility = (
  engineId: ID,
  payload: EngineCheckTemplateCompatibilityPayload
) => {
  return {
    endpoint: `/engines/${engineId}/check_template_compatibility`,
    method: 'POST',
    responseSchema: engineCheckTemplateCompatibilityResponseSchema,
    payload,
    payloadSchema: engineCheckTemplateCompatibilityPayloadSchema,
  } as const;
};

import { Email } from '@rossum/api-client/email';
import { User } from '@rossum/api-client/users';
import { ExpandMore } from '@rossum/ui/icons';
import {
  AccordionDetails,
  AccordionSummary,
  CardHeader,
  Divider,
  Stack,
} from '@rossum/ui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { ActivityAccordion } from '../../../../ui/activity-accordion/ActivityAccordion';
import { CardInfo } from '../../../../ui/card-info/CardInfo';
import { emailSummaryIcon } from '../icons';

type EmailCardProps = {
  email: Email;
  user: User | undefined;
  children: ReactNode;
};

// TODO: This appears on multiple places, we might want a general component.
const getName = (user: User) =>
  user.firstName && user.lastName
    ? `${user.firstName} ${user.lastName}`
    : user.email;

export const EmailCard = ({ email, user, children }: EmailCardProps) => {
  const intl = useIntl();
  const title =
    email.subject ||
    intl.formatMessage({
      id: 'components.emailCard.noSubject',
    });

  const firstLabel = email.labels[0];
  const avatar = firstLabel
    ? emailSummaryIcon[firstLabel]
    : emailSummaryIcon.default;

  return (
    <ActivityAccordion elevation={8}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <CardHeader
          title={title}
          subheader={
            <CardInfo
              date={email.createdAt}
              fromText={user ? getName(user) : undefined}
              fromTooltip={user?.email}
            />
          }
          avatar={avatar}
        />
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={3} pl={5} pr={5}>
          <Divider />
          {children}
        </Stack>
      </AccordionDetails>
    </ActivityAccordion>
  );
};

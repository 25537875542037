import { Url } from '@rossum/api-client';
import { Stack } from '@rossum/ui/material';
import { DataGridPro, GridRowSelectionModel } from '@rossum/ui/x-data-grid-pro';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import useDebounce from '../../../utils/hooks/useDebounce';
import { useSimpleColumns } from '../../document-list-base/hooks/useSimpleColumns';
import { encodeMqlQuery } from '../../document-list-base/mql/mql';
import { ACCESSIBLE_ANNOTATIONS_FILTER_ITEM } from '../../pricing/utils';
import {
  AttachmentModalDataGridStyles,
  getFilterItemForExcludeAnnotations,
  ROW_HEIGHT,
} from '../helpers';
import {
  searchAttachmentsPagination,
  useSearchAttachments,
} from '../hooks/useSearchAttachments';
import { HandleOnRowSelectionModelChange } from '../types';
import { AttachmentsModalSubtitle } from './AttachmentsModalSubtitle';
import { NoAttachmentsFound } from './NoAttachmentsFound';
import { SkeletonLoaders } from './SkeletonLoaders';

type AddAttachmentsGridProps = {
  searchValue: string;
  rowSelectionModel: GridRowSelectionModel;
  handleOnRowSelectionModelChange: HandleOnRowSelectionModelChange;
  rootAnnotationUrl: Url;
  existingAttachments: Url[];
  isSearchValid: boolean;
};

export const AddAttachmentsGrid = ({
  searchValue,
  handleOnRowSelectionModelChange,
  rowSelectionModel,
  rootAnnotationUrl,
  existingAttachments,
  isSearchValid,
}: AddAttachmentsGridProps) => {
  const intl = useIntl();

  const excludedAnnotationsForFilter = useMemo(
    () =>
      getFilterItemForExcludeAnnotations(
        rootAnnotationUrl,
        existingAttachments
      ),
    [rootAnnotationUrl, existingAttachments]
  );

  const query = encodeMqlQuery({
    items: [excludedAnnotationsForFilter, ACCESSIBLE_ANNOTATIONS_FILTER_ITEM],
  });

  const debouncedSearchValue = useDebounce(
    isSearchValid ? searchValue : '',
    350
  );

  const {
    attachments,
    isLoading,
    isError,
    paginationContext: {
      rowCount,
      paginationModel,
      handlePaginationModelChange,
    },
  } = useSearchAttachments({
    payload: {
      query,
      queryString: { string: debouncedSearchValue },
    },
  });

  const columns = useSimpleColumns({ columnsLocation: 'attachmentsModal' });

  return (
    <>
      <AttachmentsModalSubtitle
        isVisible={isSearchValid}
        value={intl.formatMessage(
          {
            id: `components.modal.addAttachments.subTitle.search`,
          },
          {
            searchQuery: searchValue,
          }
        )}
      />
      <Stack height={1}>
        <DataGridPro
          loading={isLoading}
          columns={columns}
          rows={attachments && !isError ? attachments : []}
          rowSelectionModel={rowSelectionModel}
          rowHeight={ROW_HEIGHT}
          onRowSelectionModelChange={newModel =>
            handleOnRowSelectionModelChange(attachments, newModel)
          }
          isRowSelectable={params => !params.row.hasAttachmentRelation}
          checkboxSelection
          disableColumnMenu
          disableColumnResize
          disableRowSelectionOnClick
          keepNonExistentRowsSelected
          hideFooterSelectedRowCount
          pageSizeOptions={[searchAttachmentsPagination.pageSize]}
          rowCount={rowCount}
          pagination
          paginationMode="server"
          onPaginationModelChange={handlePaginationModelChange}
          paginationModel={paginationModel}
          slots={{
            loadingOverlay: () => <SkeletonLoaders isLoading={isLoading} />,
            noRowsOverlay: () => (
              <NoAttachmentsFound
                translationKey={
                  isError ? 'genericError' : 'emptyAttachmentsList'
                }
              />
            ),
          }}
          sx={AttachmentModalDataGridStyles}
        />
      </Stack>
    </>
  );
};

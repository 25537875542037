import { useEffect, useState } from 'react';
import z from 'zod';
import { FIELDS_SECTIONS_EXPANDED } from '../../../redux/modules/localStorage/actions';
import { parseAndValidate } from '../../../utils/jsonParse';

// eg. { "123": { "payment_instructions": false, "lineItems": true } }
const sectionsAccordionsStateSchema = z.record(z.record(z.boolean()));

export const useSectionsAccordions = ({ schemaId }: { schemaId: string }) => {
  const [state, setState] = useState(() =>
    parseAndValidate(
      localStorage.getItem(FIELDS_SECTIONS_EXPANDED),
      sectionsAccordionsStateSchema,
      {}
    )
  );

  useEffect(() => {
    localStorage.setItem(FIELDS_SECTIONS_EXPANDED, JSON.stringify(state));
  }, [state]);

  const toggleSectionAccordion = (section: string) => {
    setState(prevState => {
      return {
        ...prevState,
        [schemaId]: {
          ...prevState[schemaId],
          [section]: !(prevState[schemaId]?.[section] ?? true),
        },
      };
    });
  };

  const sectionsAccordionsState = state[schemaId];

  return { sectionsAccordionsState, toggleSectionAccordion };
};

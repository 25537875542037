import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, useLocation } from 'react-router';
import { transformUrlToNewDashboardUrl } from '../../features/document-list/transformers';
import { userRoleNameSelector } from '../../redux/modules/user/selectors';
import { State as ReduxState } from '../../types/state';

type OwnProps = RouteComponentProps<{ queueId?: string }>;

type StateProps = {
  queueId?: number;
  redirectToRequests: boolean;
};

type RedirectToNewDashboardProps = OwnProps & StateProps;

const RedirectToNewDashboard = ({
  queueId,
  redirectToRequests,
}: RedirectToNewDashboardProps) => {
  const { search, pathname } = useLocation();

  const query = pathname.includes('emails') ? '?view=emails' : search;

  const redirectUrl = redirectToRequests
    ? '/requests'
    : transformUrlToNewDashboardUrl({
        queueId: queueId?.toString(),
        query,
      });

  return <Redirect to={redirectUrl} />;
};

const mapStateToProps = (
  state: ReduxState,
  {
    match: {
      params: { queueId: _queueId },
    },
  }: OwnProps
): StateProps => {
  const queueId = _queueId ? Number(_queueId) : undefined;
  const isUserApprover = userRoleNameSelector(state) === 'approver';
  const hasNoQueues = !state.user.queues?.length;

  return {
    redirectToRequests: isUserApprover && hasNoQueues,
    queueId,
  };
};

export default connect<StateProps, null, OwnProps, ReduxState>(mapStateToProps)(
  RedirectToNewDashboard
);

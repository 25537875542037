import { ThemeType } from '@rossum/rossum-ui/theme';
import { docsUrl } from './config';

export const collectionSize = 5000;
export const FETCH_INTERVAL = 10000;
export const FOCUS_CHANGE_DEBOUNCE = 100;
export const CONTROL_BAR_HEIGHT = 48;
export const MESSAGE_MAX_LENGTH = 280;
export const SIDEBAR_WIDTH = 400;

export const docsLinks = {
  webhooks: `${docsUrl}/#webhook-extension`,
  inbox: `${docsUrl}/#inbox`,
  export: `${docsUrl}/#export`,
  schema: `${docsUrl}/#document-schema`,
  validatingPayloads: `${docsUrl}/#validating-payloads-from-rossum`,
  webhookEvents: `${docsUrl}/#webhook-events`,
  codeEditorApiDocs: `${docsUrl}/#serverless-function-extension`,
  sso: `${docsUrl}/#single-sign-on-sso`,
  webhookVsConnector: `${docsUrl}/#webhook-vs-connector`,
  hookConfiguration: `${docsUrl}/#hook`,
  hookSecrets: `${docsUrl}/#hook-secrets`,
  tokenOwner: `${docsUrl}/#access-to-rossum-api`,
  userRole: `${docsUrl}/#user-role`,
  runtimeDeprecations: `${docsUrl}/#runtime-deprecations`,
  rirFieldName: `${docsUrl}/#below-rir-field-names`,
};

export const DEVELOPER_HUB_LINK = 'https://developers.rossum.ai/';
export const HELP_CENTER_LINK = 'https://rossum.ai/help';
export const CONTACT_FORM_LINK = 'https://rossum.ai/contact/';
export const DOC_SPLIT_LINK = `${HELP_CENTER_LINK}/article/splitting-pdf-into-multiple-documents/`;
export const AUTOMATION_DATA_CAPTURE_LINK = `${HELP_CENTER_LINK}/article/set-up-automation-of-documents-in-rossum/`;
export const CONFIRMED_STATE_LINK = `${HELP_CENTER_LINK}/article/batch-export-confirmed-tab/`;
export const ENUM_EDITOR_LINK = `${HELP_CENTER_LINK}/article/fill-dropdown-field-from-spreadsheet/`;
export const WEBHOOKS_LINK = `${HELP_CENTER_LINK}/article/using-webhook-for-push-notifications/`;
export const FUNCTIONS_LINK = `${HELP_CENTER_LINK}/article/customize-rossum-logic-with-serverless-function`;
export const KEYBOARD_SHORTCUTS_LINK = `${HELP_CENTER_LINK}/article/using-keyboard-shortcuts/`;
export const CONFIDENCE_THRESHOLDS_LINK = `${DEVELOPER_HUB_LINK}docs/using-ai-confidence-thresholds-for-automation-in-rossum`;
export const CODE_EDITOR_OVERVIEW_LINK = `${HELP_CENTER_LINK}/article/customize-rossum-logic-with-serverless-function/`;
export const HOWTO_CREATE_CUSTOM_FIELD_LINK = `${HELP_CENTER_LINK}/article/capturing-a-new-data-field-in-rossum/`;
export const HOWTO_CREATE_CUSTOMIZE_TABLE_LINK = `${HELP_CENTER_LINK}/article/capturing-custom-table-data-in-rossum/`;
export const HOWTO_CREATE_MANAGE_ENUM_FIELD_LINK = `${HELP_CENTER_LINK}/article/fill-dropdown-field-from-spreadsheet/`;
export const DEVELOPER_ARTICLE_LINK = `${DEVELOPER_HUB_LINK}docs/how-to-use-serverless-functions`;
export const HOWTO_ACCESS_PUBLIC_API_FROM_FUNCTION = `${DEVELOPER_HUB_LINK}docs/how-to-access-public-api-on-internet-with-rossum-function`;
export const HOWTO_TEST_FUNCTION = `${DEVELOPER_HUB_LINK}docs/how-to-test-serverless-functions`;
export const AUTOMATION_PROCESS = `${HELP_CENTER_LINK}/article/automation-process-in-rossum/`;
export const FIELDS_VALUE_NOT_FOUND = `${DEVELOPER_HUB_LINK}docs/automation-rules-overview`;
export const EMAIL_HELP_LINK = `${DEVELOPER_HUB_LINK}docs/set-up-email-forwarding-to-your-rossum-account`;
export const FIRST_EXTENSION_LINK = `${DEVELOPER_HUB_LINK}docs/first-extension-steps`;
export const AUTOMATION_BUILT_IN_CHECKS = `${DEVELOPER_HUB_LINK}docs/automation-built-in-checks`;
export const EXTRACTED_FIELD_TYPES_LINK = `${docsUrl}#extracted-field-types`;
export const DEDICATED_ENGINES_TRAINING_LINK = `${HELP_CENTER_LINK}/article/how-dedicated-ai-engine-training-works/`;
export const QUICK_START_GUIDE = `${HELP_CENTER_LINK}/article/quick-start-guide/`;
export const ROSSUM_EMAIL_INBOX = `${HELP_CENTER_LINK}/article/sending-documents-to-rossum-email-inbox/`;
export const GETTING_FILES_IN_ROSSUM = `${docsUrl}/#import-and-export`;
export const ROSSUM_VIDEOS_LINK = 'https://rossum.ai/resources/demo-hub/';
export const CLI_HELP_LINK =
  'https://rossum.ai/help/article/aurora-for-complex-tables';

type DevHubLinks =
  | 'docs/automation-rules-overview'
  | 'docs/implementing-custom-checks-for-automation-in-rossum'
  | 'docs/history-based-data-checks'
  | 'docs/automation-built-in-checks'
  | 'docs/using-ai-confidence-thresholds-for-automation-in-rossum'
  | 'docs/data-capture-automation';

type HelpCenterLinks =
  | '/article/making-fields-required-validation-screen/'
  | '/article/do-you-need-generic-or-dedicated-ai-engine/'
  | '/article/fields-with-grey-or-green-ticks/';

export const devHub = (link: DevHubLinks) => `${DEVELOPER_HUB_LINK}${link}`;
export const helpCenter = (link: HelpCenterLinks) =>
  `${HELP_CENTER_LINK}${link}`;

export const GITHUB_REGEX2_DOCS =
  'https://github.com/google/re2/wiki/Syntax/c6f0696bfef6b98252894469d6ef8eb70a9c9590';
export const WIKIPEDIA_MIME_TYPE_DOCS =
  'https://en.wikipedia.org/wiki/Media_type';
export const EXTERNAL_CRONTAB_VALIDATOR = 'https://crontab.guru/';

export const recentActionsLength = 100;
export const localStorageVersion = 'localstorage/v3';
export const DEFAULT_ROW_TYPE = 'data';
export const pageSizes = ['10', '15', '20', '25', '50', '100'] as const;
export const modalWidth = 436;

export const DISPLAYED_VALUE_CLASSNAME = 'displayedValue';

export const defaultTheme: ThemeType = 'dark';
export const themeTypes: ThemeType[] = [defaultTheme, 'white'];

export const statusPageURL = 'https://status.rossum.ai';
export const contactSupportLink = 'mailto:support@rossum.ai';
export const termsAndConditionsURL = 'https://rossum.ai/terms';

export const UPLOAD_HASH = '#upload';

export const defaultWorkspaceSorting = 'alphabeticalAsc';

import { getIDFromUrl } from '@rossum/api-client';
import { KeyboardArrowDown, Settings, Warning } from '@rossum/ui/icons';
import { DeleteSweep as DeleteSweepIcon } from '@rossum/ui/icons';
import {
  Box,
  Button,
  ButtonBaseActions,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { compact } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { replace } from 'redux-first-history';
import { canManageAnnotation } from '../../../components/AnnotationInformation/helpers';
import { RoleProps, RoleProvider } from '../../../components/Restrictors';
import { tooltip } from '../../../lib/formaterValues';
import { snakeToCamel } from '../../../lib/keyConvertor';
import { constructDocumentUrl, parse } from '../../../lib/url';
import { annotationSelector } from '../../../redux/modules/annotation/selectors';
import { deleteAnnotations } from '../../../redux/modules/annotations/actions';
import { isDeleteRecommendationInPathSelector } from '../../../redux/modules/datapoints/selector';
import { schemaMapSelector } from '../../../redux/modules/schema/schemaMapSelector';
import {
  MatchedTriggerRule,
  MatchedTriggerRulesPerLevel,
} from '../../../types/datapoints';
import { State } from '../../../types/state';

// A non-changing reference for equality functions in selectors and memo hooks
const NO_DATAPOINTS = undefined as undefined;

type TriageRulesData = Array<{
  type: MatchedTriggerRule['type'];
  id?: number;
  ruleValue?: number | string;
  label?: string;
}>;

type ConditionStringProps =
  | {
      conditionType: Exclude<MatchedTriggerRule['type'], 'datapoint'>;
      value: string | number | undefined;
    }
  | {
      conditionType: Exclude<
        MatchedTriggerRule['type'],
        'page_count' | 'filename'
      >;
      value: string | number | undefined;
      enumLabel: string | undefined;
    };

const ConditionString = (props: ConditionStringProps) => {
  const intl = useIntl();
  return (
    <ListItem sx={{ display: 'list-item', ml: 2 }}>
      <Stack direction="row" spacing={0.5} alignItems="center" ml={-2}>
        <Typography variant="caption" color="text.secondary">
          {intl.formatMessage({
            id: 'components.sidebar.deleteRecommendation.ruleList.condition.ifik',
          })}
        </Typography>
        <Typography variant="caption" color="warning.main">
          {props.conditionType === 'datapoint'
            ? props.enumLabel
            : intl.formatMessage({
                id: `components.sidebar.deleteRecommendation.ruleList.condition.${props.conditionType}`,
              })}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {intl.formatMessage({
            id: `components.sidebar.deleteRecommendation.ruleList.condition.comparison.${props.conditionType}`,
          })}
        </Typography>
        <Typography variant="caption" color="warning.main">
          {props.value ?? 'N/A'}
        </Typography>
      </Stack>
    </ListItem>
  );
};

const recommendationTitleItem = (
  intl: IntlShape,
  conditionType: MatchedTriggerRule['type'],
  value: string | number | undefined,
  enumLabel?: string
) => {
  return [
    conditionType === 'datapoint'
      ? `‘${enumLabel}’`
      : `‘${intl.formatMessage({
          id: `components.sidebar.deleteRecommendation.ruleList.condition.${conditionType}`,
        })}’`,
    intl.formatMessage({
      id: `components.sidebar.deleteRecommendation.ruleList.condition.comparison.${conditionType}`,
    }),
    `‘${value ?? 'N/A'}’`,
  ].join(' ');
};

const recommendationTitle = (data: TriageRulesData, intl: IntlShape) => {
  const stringList = data.map(rule =>
    recommendationTitleItem(intl, rule.type, rule.ruleValue, rule.label)
  );

  const convertedStringList = [
    stringList.slice(0, -2).join(', '),
    stringList.slice(-2).join(' and '),
  ];

  return compact(convertedStringList).join(', ');
};

type RuleListProps = {
  data: TriageRulesData;
};

const RuleList = ({ data }: RuleListProps) => (
  <List
    disablePadding
    dense
    sx={{
      listStyleType: 'disc',
      color: 'text.secondary',
    }}
  >
    {!!data.length &&
      data.map(rule => (
        <ConditionString
          key={`${rule.id}-${rule.type}`}
          conditionType={rule.type}
          value={rule.ruleValue}
          enumLabel={rule.label}
        />
      ))}
  </List>
);

type DeleteRecommendationProps = {
  matchedTriggerRules: MatchedTriggerRulesPerLevel;
};

const DeleteRecommendation = ({
  matchedTriggerRules,
}: DeleteRecommendationProps) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const deleteButtonRef = useRef<HTMLButtonElement>(null);
  const actionRef = useRef<ButtonBaseActions>(null);

  const shouldFocusDeleteButton = useSelector(
    isDeleteRecommendationInPathSelector
  );

  const { pathname, search } = useLocation();

  const annotation = useSelector(annotationSelector);
  const schemaMap = useSelector(schemaMapSelector);

  const [deleteRecommendationExpanded, setDeleteRecommendationExpanded] =
    useState(true);

  // Each memo and selector hook returns the same reference variable
  // in case that it should not cause re-render
  const datapointIds = useMemo(
    () =>
      Object.keys(matchedTriggerRules.datapointLevel).length
        ? Object.keys(matchedTriggerRules.datapointLevel)
        : NO_DATAPOINTS,
    [matchedTriggerRules.datapointLevel]
  );

  const datapointsContent = useSelector((state: State) =>
    datapointIds !== NO_DATAPOINTS ? state.datapoints.content : NO_DATAPOINTS
  );

  const matchedDatapoints = useMemo(
    () =>
      datapointsContent !== NO_DATAPOINTS
        ? datapointsContent.filter(({ id }) =>
            datapointIds?.includes(id.toString())
          )
        : NO_DATAPOINTS,
    [datapointIds, datapointsContent]
  );

  const matchedRules = useMemo(
    () => [
      ...matchedTriggerRules.annotationLevel.map(rule => ({
        type: rule.type,
        ruleValue: rule.type === 'page_count' ? rule.threshold : rule.regex,
      })),
      ...(matchedDatapoints !== NO_DATAPOINTS ? matchedDatapoints : []).map(
        datapoint => {
          const schema = schemaMap.get(datapoint.schemaId);
          return {
            type: 'datapoint' as const,
            id: datapoint.id,
            label: schema?.label ?? datapoint.schemaId,
            ruleValue: schema?.options
              ? schema.options.find(option => {
                  return (
                    option.value ===
                    matchedTriggerRules.datapointLevel[datapoint.id]?.[0]?.value
                  );
                })?.label
              : matchedTriggerRules.datapointLevel[datapoint.id]?.[0]?.value,
          };
        }
      ),
    ],
    [matchedDatapoints, matchedTriggerRules, schemaMap]
  );

  // Focus delete button when all other focuses in dp navigation are disabled
  useEffect(() => {
    if (
      shouldFocusDeleteButton &&
      deleteButtonRef.current &&
      actionRef.current
    ) {
      deleteButtonRef.current.focus();
      actionRef.current.focusVisible();
    }
  }, [shouldFocusDeleteButton]);

  const onCollapseButtonClick = () => {
    setDeleteRecommendationExpanded(!deleteRecommendationExpanded);
    if (deleteRecommendationExpanded) {
      const { deleteRecommendation, ..._search } = parse(search);
      if (deleteRecommendation)
        dispatch(
          replace(
            constructDocumentUrl({
              pathname,
              query: _search,
            })
          )
        );
    }
  };

  const queueId = getIDFromUrl(annotation?.queue || '');

  return (
    <Box position="relative">
      <Card
        square
        sx={{
          pl: 1,
        }}
      >
        <CardHeader
          title={recommendationTitle(matchedRules, intl)}
          titleTypographyProps={{
            variant: 'subtitle1',
          }}
          avatar={<Warning color="warning" />}
          onClick={onCollapseButtonClick}
          action={
            <IconButton size="small">
              <KeyboardArrowDown
                sx={{
                  transform: deleteRecommendationExpanded
                    ? 'rotate(180deg)'
                    : 'none',
                  transition: theme => theme.transitions.create('transform'),
                }}
              />
            </IconButton>
          }
        />
        <Collapse in={deleteRecommendationExpanded}>
          <CardContent sx={{ pt: 0, '&:last-child': { pb: 1, pr: 1 } }}>
            <Stack spacing={1}>
              <Stack direction="row" spacing={0.5}>
                <Typography variant="caption" color="text.secondary">
                  {intl.formatMessage(
                    {
                      id: 'components.sidebar.deleteRecommendation.subtitle',
                    },
                    {
                      tooltip: tooltip(
                        {
                          title: intl.formatMessage({
                            id: 'components.sidebar.deleteRecommendation.tooltip.content',
                          }),
                          placement: 'right-start',
                          arrow: false,
                        },
                        {
                          variant: 'caption',
                          color: 'text.secondary',
                          sx: { textDecoration: 'underline' },
                        }
                      ),
                    }
                  )}
                </Typography>
              </Stack>
              <RuleList data={matchedRules} />
              <Typography variant="caption" color="text.secondary">
                {intl.formatMessage({
                  id: 'components.sidebar.deleteRecommendation.recommendation',
                })}
              </Typography>
            </Stack>
            {annotation && (
              <CardActions sx={{ justifyContent: 'flex-end', mt: 1 }}>
                <Stack direction="row" spacing={1}>
                  <Button
                    ref={deleteButtonRef}
                    action={actionRef}
                    variant="contained"
                    color="warning"
                    data-cy="triage-rules-button-delete"
                    startIcon={<DeleteSweepIcon />}
                    onClick={() =>
                      dispatch(deleteAnnotations([annotation.url]))
                    }
                  >
                    {intl.formatMessage({
                      id: 'containers.sidebar.components.deleteRecomendation.button.deleteDocument',
                    })}
                  </Button>
                  <RoleProvider>
                    {({ role }: RoleProps) => {
                      const displaySettingsLink = canManageAnnotation(
                        role,
                        snakeToCamel(annotation.status)
                      );
                      return displaySettingsLink ? (
                        <Button
                          component={Link}
                          to={{
                            pathname: `/queues/${queueId}/settings/basic`,
                            state: {
                              backLink: `/document/${annotation.id}/`,
                              openTriageRules: true,
                            },
                          }}
                          data-cy="queue-settings-triage"
                          color="secondary"
                          size="medium"
                          startIcon={<Settings />}
                          sx={{
                            '&:hover': {
                              color: theme => theme.palette.secondary.main,
                            },
                          }}
                        >
                          {intl.formatMessage({
                            id: 'containers.sidebar.components.deleteRecomendation.button.editCriteria',
                          })}
                        </Button>
                      ) : null;
                    }}
                  </RoleProvider>
                </Stack>
              </CardActions>
            )}
          </CardContent>
        </Collapse>
      </Card>
      <Divider />
    </Box>
  );
};

export default DeleteRecommendation;

import { Button, Tooltip } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  restrictedEngineCreationEnabledSelector,
  restrictedEngineEditEnabledSelector,
} from '../../../redux/modules/organizationGroup/selectors';
import { useInfiniteEngines } from '../hooks/useInfiniteEngines';
import { engineDetailPath } from '../paths';
import AuroraEngineTile from './AuroraEngineTile';
import { Paragraphs } from './Paragraphs';
import ShowMoreButton from './ShowMoreButton';
import TilesList from './TilesList';
import TilesListEmptyState from './TilesListEmptyState';

const AddEngineButton = () => {
  const intl = useIntl();

  const isRestrictedEngineCreationEnabled = useSelector(
    restrictedEngineCreationEnabledSelector
  );

  return (
    <Tooltip
      title={
        isRestrictedEngineCreationEnabled
          ? intl.formatMessage({
              id: 'features.engines.creation.disabled',
            })
          : ''
      }
    >
      <span>
        <Button
          data-cy="add-engine-button"
          component={Link}
          disabled={isRestrictedEngineCreationEnabled}
          to={engineDetailPath('new')}
          variant="contained"
          color="primary"
          sx={{
            '&:hover': { color: theme => theme.palette.primary.contrastText },
          }}
        >
          {intl.formatMessage({
            id: 'features.engines.list.addEngine',
          })}
        </Button>
      </span>
    </Tooltip>
  );
};

const ExtractorsList = () => {
  const intl = useIntl();

  const isRestrictedEngineEditEnabled = useSelector(
    restrictedEngineEditEnabledSelector
  );

  const {
    data: engines,
    fetchNextPage,
    hasNextPage,
    isFetching,
    status,
  } = useInfiniteEngines({
    pageSize: 10,
    ordering: ['-id'],
    type: 'extractor',
  });

  const allEngines = engines?.pages.flatMap(page => page.results);

  return (
    <TilesList
      title={intl.formatMessage({
        id: 'features.engines.list.extractor.title',
      })}
      items={allEngines}
      renderTile={engine => (
        <AuroraEngineTile key={engine.id} engine={engine} />
      )}
      status={status}
      emptyState={
        <TilesListEmptyState
          title={intl.formatMessage({
            id: 'features.engines.list.noEngines.title',
          })}
          subtitle={
            <Paragraphs>
              {intl.formatMessage({
                id: 'features.engines.list.extractor.noEngines',
              })}
            </Paragraphs>
          }
        >
          <AddEngineButton />
        </TilesListEmptyState>
      }
      buttons={[
        hasNextPage ? (
          <ShowMoreButton
            key="show-more-results"
            onClick={fetchNextPage}
            isFetching={isFetching}
          />
        ) : null,
        isRestrictedEngineEditEnabled ? null : (
          <AddEngineButton key="add-engine" />
        ),
      ]}
    />
  );
};

export default ExtractorsList;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmailDrawer, { EmailDrawerProps } from '../../features/email-drawer';
import { snakeToCamel } from '../../lib/keyConvertor';
import {
  refetchAnnotationEmails,
  rejectAnnotation,
} from '../../redux/modules/annotation/actions';
import {
  annotationSelector,
  annotationSideloadsSelector,
} from '../../redux/modules/annotation/selectors';
import { postponeAnnotations } from '../../redux/modules/annotations/actions';
import { pauseValidation } from '../../redux/modules/beforeLeave/actions';
import { isUserViewer as isUserViewerSelector } from '../../redux/modules/user/selectors';
import { timeSpent } from '../../timeSpent/timeSpent';
import { State } from '../../types/state';

export type DrawerConfig = Partial<
  Pick<EmailDrawerProps, 'defaultFormMode' | 'highlightedEmailUrl'>
>;

export const ValidationEmailDrawer = (
  props: Pick<
    EmailDrawerProps,
    'DrawerProps' | 'highlightedEmailUrl' | 'defaultFormMode'
  >
) => {
  const dispatch = useDispatch();
  const annotation = useSelector(annotationSelector);
  const isUserViewer = useSelector(isUserViewerSelector);
  const annotationSideloads = useSelector(annotationSideloadsSelector);

  const annotationModifier = useSelector(
    (state: State) => state.annotation.sideloads.modifier
  );
  const queueUrl = useSelector((state: State) => state.annotation.queue);

  useEffect(() => {
    if (props.DrawerProps.open) {
      timeSpent.openEmails();
    }
  }, [props.DrawerProps.open]);

  return annotation && queueUrl ? (
    <EmailDrawer
      canSendEmails={!isUserViewer}
      // Attach a current annotation from annotation screen.
      defaultAttachment={
        annotationSideloads.document
          ? {
              annotation: {
                ...annotation,
                status: snakeToCamel(annotation.status),
              },
              document: annotationSideloads.document,
              annotationModifier: annotationModifier?.user,
            }
          : undefined
      }
      onSend={({ mode }) => {
        if (mode === 'rejectDocument') {
          dispatch(rejectAnnotation(annotation.url, true));
        } else if (mode === 'postponeDocument') {
          dispatch(
            pauseValidation({
              nextAction: postponeAnnotations([annotation.url], true),
              trigger: 'postponeAnnotation',
              reason: 'surveys',
            })
          );
        }

        // Update mini email card & reload thread iff this is the first email in convo.
        dispatch(refetchAnnotationEmails(annotation.id));
      }}
      queueUrl={queueUrl}
      emailThreadUrl={annotation.emailThread ?? undefined}
      {...props}
      DrawerProps={{
        ...props.DrawerProps,
        onClose: (...args) => {
          timeSpent.closeEmails(annotation.id);
          props.DrawerProps.onClose?.(...args);
        },
      }}
    />
  ) : null;
};

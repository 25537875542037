import { SimpleFilterComponentProps } from '../../../document-list-base/mql/types';
import { getModifierValueOptions } from '../../columns/helpers';
import { useFetchUsers } from '../../hooks/useFetchUsers';
import { FilterMultiSelect } from './FilterMultiSelect';

export const FilterUserSelect = ({
  filterItem,
  ...props
}: SimpleFilterComponentProps) => {
  const { data: allModifiers, isLoading } = useFetchUsers();

  const valueOptions = getModifierValueOptions(allModifiers ?? {});
  const loadedFilterItem: typeof filterItem = {
    ...filterItem,
    column: { ...filterItem.column, valueOptions, areValuesLoading: isLoading },
  };
  return <FilterMultiSelect filterItem={loadedFilterItem} {...props} />;
};

import { Skeleton, Stack, Tooltip, Typography } from '@rossum/ui/material';
import { Dictionary, random } from 'lodash';
import { useRef } from 'react';
import { useIntl } from 'react-intl';
import StyledLink from '../../document-list-base/components/StyledLink';
import { createNewFilterQuery } from '../../document-list-base/mql/transformers';
import { CellProps } from '../types';

const TruncatedLink = ({ name, to }: { name: string; to: string }) => {
  const wrapperEl = useRef<HTMLDivElement>(null);
  const textEl = useRef<HTMLDivElement>(null);

  const shouldRenderTooltip =
    wrapperEl.current && textEl.current
      ? wrapperEl.current.clientWidth < textEl.current.scrollWidth
      : false;

  return (
    <Tooltip title={shouldRenderTooltip ? name : ''} enterDelay={600}>
      <Stack ref={wrapperEl} maxWidth="100%">
        <Typography
          component="div"
          sx={{
            fontSize: 'inherit',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '100%',
          }}
          ref={textEl}
        >
          <StyledLink
            to={to}
            color="text.secondary"
            target="_blank"
            rel="noopener noreferrer"
          >
            {name}
          </StyledLink>
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export type WorkspacesProps = {
  workspacesWithQueuesMap: Dictionary<{
    url: string;
    queueId: number;
    queueName: string;
    workspaceName: string;
  }>;
  workspacesAreLoading: boolean;
};

const WorkspacesQueuesCell = ({
  value,
  workspacesProps,
}: CellProps<string> & {
  workspacesProps: WorkspacesProps;
}) => {
  // the cell is rerendered several times during loading, so we need to keep the width in ref
  const { current: width } = useRef(random(60, 80));

  const { workspacesAreLoading, workspacesWithQueuesMap } = workspacesProps;
  const data = workspacesWithQueuesMap[value || ''];

  const intl = useIntl();

  const query = data?.queueId
    ? createNewFilterQuery([
        {
          field: 'queue',
          operator: 'isAnyOf',
          value: [data.queueId],
        },
      ])
    : null;

  return (
    <Stack width="100%">
      {workspacesAreLoading ? (
        <Skeleton width={`${width}%`} />
      ) : data ? (
        <TruncatedLink
          to={
            query
              ? `/documents?filtering=${encodeURIComponent(query)}&level=queue`
              : '/documents'
          }
          name={[data.workspaceName, data.queueName].join(' > ')}
        />
      ) : (
        intl.formatMessage({ id: 'components.duplicates.unknownQueue' })
      )}
    </Stack>
  );
};

export default WorkspacesQueuesCell;

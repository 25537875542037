import { ActionType, createAction } from 'typesafe-actions';
import { OriginalAnyDatapointSchema, Schema } from '../../../types/schema';
import {
  SchemaFulfilledPayload,
  UpdateSchemaMeta,
  UpdateSchemaPayload,
  ValidateSchemaFulfilledMeta,
  ValidateSchemaFulfilledPayload,
} from './types';

export const fetchSchema = createAction(
  'FETCH_SCHEMA',
  undefined,
  (schemaUrl: string) => ({
    schemaUrl,
  })
)<undefined, { schemaUrl: string }>();

export const fetchSchemaFulfilled = createAction(
  'FETCH_SCHEMA_FULFILLED',
  (payload: SchemaFulfilledPayload) => payload
)<SchemaFulfilledPayload>();

export const updateSchema = createAction(
  'UPDATE_SCHEMA',
  (payload: Schema, _: UpdateSchemaMeta) => payload,
  (_: Schema, meta: UpdateSchemaMeta) => meta
)<Schema, UpdateSchemaMeta>();

export const updateSchemaContent = createAction(
  'UPDATE_SCHEMA_CONTENT',
  (payload: UpdateSchemaPayload, _?: UpdateSchemaMeta) => payload,
  (_: UpdateSchemaPayload, meta: UpdateSchemaMeta = { withMessage: true }) =>
    meta
)<UpdateSchemaPayload, UpdateSchemaMeta>();

export const updateSchemaFulfilled = createAction(
  'UPDATE_SCHEMA_FULFILLED',
  (payload: SchemaFulfilledPayload, _: UpdateSchemaMeta) => payload,
  (_: SchemaFulfilledPayload, meta: UpdateSchemaMeta) => meta
)<SchemaFulfilledPayload, UpdateSchemaMeta>();

export const validateSchema = createAction(
  'VALIDATE_SCHEMA',
  (payload: { content: OriginalAnyDatapointSchema[]; url: string }) => payload
)<{ content: OriginalAnyDatapointSchema[]; url: string }>();

export const validateSchemaFulfilled = createAction(
  'VALIDATE_SCHEMA_FULFILLED',
  (
    response: ValidateSchemaFulfilledPayload,
    _content: OriginalAnyDatapointSchema[]
  ) => ({ response }),
  (_response, content) => ({ schema: content })
)<{ response: ValidateSchemaFulfilledPayload }, ValidateSchemaFulfilledMeta>();

export type UpdateSchemaContent = typeof updateSchemaContent;
export type ValidateSchema = typeof validateSchema;

export type SchemaActions = ActionType<
  | typeof fetchSchema
  | typeof fetchSchemaFulfilled
  | typeof updateSchema
  | UpdateSchemaContent
  | typeof updateSchemaFulfilled
  | ValidateSchema
  | typeof validateSchemaFulfilled
>;

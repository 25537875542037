import { schemaDatapointEnumOptionsSchema } from '@rossum/api-client/schemas';
import { GridRowModel } from '@rossum/ui/x-data-grid-pro';
import { parseAndValidate } from '../../../../../utils/jsonParse';
import { isNonEmptyObject } from '../getProcessRowUpdate';
import { optionsToHash } from '../rows/optionsToHash';
import { GridRowModelKeys } from '../rows/rowTypes';

export const getVariantLabel = (
  row: GridRowModel | undefined,
  field: Extract<GridRowModelKeys, 'options' | 'formula'>
): string => {
  if (row) {
    if (field === 'options') {
      if (
        'options' in row &&
        isNonEmptyObject(row.meta.metadata.optionsHashLabelMap)
      ) {
        const count = row.options.length;
        const hashKey = optionsToHash(row.options);
        return count === 0
          ? 'Empty options'
          : `${row.meta.metadata.optionsHashLabelMap[hashKey]}`;
      }
    }

    if (field === 'formula') {
      if (
        'formula' in row &&
        isNonEmptyObject(row.meta.metadata.formulaHashMap)
      ) {
        const count = row.formula?.length;

        const hashKey = row.formula ?? '';

        return count === 0
          ? 'Empty formula'
          : `${row.meta.metadata.formulaHashMap[hashKey]}`;
      }
    }
  }
  return 'Not set';
};

export const getOptions = (options: string) =>
  parseAndValidate(options, schemaDatapointEnumOptionsSchema, []);

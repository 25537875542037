import { Survey } from '@rossum/api-client/surveys';
import { useMemo } from 'react';
import { getUnsafe } from '../../../../lib/helpers';
import { getNumber } from '../../helpers';
import { useSubmitSurvey } from '../../hooks/useSubmitSurvey';

export const useDataCaptureSurveyActions = (
  survey: Survey,
  additionalData: Record<string, unknown>
) => {
  const submitSurvey = useSubmitSurvey();

  return useMemo(() => {
    return {
      /** Used for tracking how many times survey has been viewed */
      increaseViewCount: () =>
        submitSurvey({
          surveyId: survey.id,
          surveyPatchModel: {
            additionalData: {
              ...(typeof survey.additionalData === 'object'
                ? survey.additionalData
                : {}),
              ...additionalData,
              viewCount:
                getNumber(getUnsafe(survey.additionalData, 'viewCount')) + 1,
            },
          },
        }),
      /** Used for counting how many times survey has been skipped  */
      increaseSkipCount: () =>
        submitSurvey(
          {
            surveyId: survey.id,
            surveyPatchModel: {
              additionalData: {
                ...(typeof survey.additionalData === 'object'
                  ? survey.additionalData
                  : {}),
                ...additionalData,
                skipCount:
                  getNumber(getUnsafe(survey.additionalData, 'skipCount')) + 1,
              },
            },
          },
          true
        ),
      /** Used for counting of CLI and suggestions usage */
      increaseAuroraUsageCount: () =>
        submitSurvey({
          surveyId: survey.id,
          surveyPatchModel: {
            additionalData: {
              ...(typeof survey.additionalData === 'object'
                ? survey.additionalData
                : {}),
              ...additionalData,
              auroraUsageCount:
                getNumber(
                  getUnsafe(survey.additionalData, 'auroraUsageCount')
                ) + 1,
            },
          },
        }),
      /** Used for submitting survey answers */
      answer: (answers: { value: unknown }[]) =>
        submitSurvey(
          {
            surveyId: survey.id,
            surveyPatchModel: {
              additionalData: {
                ...(typeof survey.additionalData === 'object'
                  ? survey.additionalData
                  : {}),
                ...additionalData,
              },
              answers,
            },
          },
          true
        ),
    } as const;
  }, [additionalData, submitSurvey, survey.additionalData, survey.id]);
};

import { GridLogicOperator } from '@rossum/ui/x-data-grid-pro';
import { useMemo } from 'react';
import { parseAndValidate } from '../../../utils/jsonParse';
import {
  DataGridConditionModel,
  filteringSchema,
} from '../../document-list-base/mql/mql';

export const useSafelyParsedFiltering = (filteringFromQuery: string) => {
  const safelyParsedFiltering = useMemo<DataGridConditionModel>(() => {
    // try to parse stored query and validate it against schema
    // return default shape if it is not valid in catch
    return parseAndValidate(filteringFromQuery, filteringSchema, {
      items: [],
      logicOperator: GridLogicOperator.And,
    });
  }, [filteringFromQuery]);

  return safelyParsedFiltering;
};

import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { ControlledFieldProps } from '../utils';
import { CustomTextField, CustomTextFieldProps } from './CustomTextField';

type TextFieldControlProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<CustomTextFieldProps<TFieldValues, TName>, 'FieldProps'> &
  ControlledFieldProps<TFieldValues, TName>;

const TextFieldControl = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  ControllerProps,
  ...customTextFieldProps
}: TextFieldControlProps<TFieldValues, TName>) => {
  const { control, name } = ControllerProps;

  return (
    <Controller
      control={control}
      name={name}
      render={FieldProps => (
        <CustomTextField FieldProps={FieldProps} {...customTextFieldProps} />
      )}
    />
  );
};

export default TextFieldControl;

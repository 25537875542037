import { Dialog, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import ActionDialog from '../../../components/UI/ActionDialog';
import ActionDialogActions from '../../../components/UI/ActionDialog/ActionDialogActions';

type DeleteConfirmationDialogProps = {
  type: 'deleteEngine' | 'deleteEngineField';
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const DeleteConfirmationDialog = ({
  type,
  open,
  onClose,
  onConfirm,
}: DeleteConfirmationDialogProps) => {
  const intl = useIntl();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ transition: 'smooth' }}
      PaperProps={{
        sx: { width: 480, minHeight: 213, position: 'fixed' },
        elevation: 2,
      }}
    >
      {open && (
        <ActionDialog
          dialogTitle={intl.formatMessage({
            id: `features.engines.dialog.${type}.title`,
          })}
          onCancel={onClose}
          dialogColor="secondary"
          dialogActions={
            <ActionDialogActions
              dataCyConfirm={`${type}-confirm-btn`}
              confirmButtonText={intl.formatMessage({
                id: `features.engines.dialog.${type}.confirm`,
              })}
              confirmButtonProps={{
                onClick: onConfirm,
                color: 'secondary',
              }}
            />
          }
          dataCy={`${type}-cancel-btn`}
        >
          <Typography variant="body1">
            {intl.formatMessage({
              id: `features.engines.dialog.${type}.text`,
            })}
          </Typography>
        </ActionDialog>
      )}
    </Dialog>
  );
};

export default DeleteConfirmationDialog;

import { FilterOperator } from '../../../../document-list-base/mql/operators';

export const resolveValueBasedOnBooleanOperator = (
  operator: FilterOperator,
  value?: unknown
) =>
  operator.value === 'isEmpty'
    ? true
    : operator.value === 'isNotEmpty'
      ? false
      : value;

export const isOperatorEmpty = (operator: FilterOperator) =>
  operator.value === 'isEmpty' || operator.value === 'isNotEmpty';

import {
  IconContainerProps,
  Rating,
  RatingProps,
  SvgIcon,
} from '@rossum/ui/material';
import { ReactComponent as Angry } from './icons/angry.svg';
import { ReactComponent as Frowning } from './icons/frowning.svg';
import { ReactComponent as Grinning } from './icons/grinning.svg';
import { ReactComponent as InLove } from './icons/inlove.svg';
import { ReactComponent as Neutral } from './icons/neutral.svg';

const customIcons: Record<
  string,
  {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    label: string;
  }
> = {
  1: {
    icon: Angry,
    label: 'Angry',
  },
  2: {
    icon: Frowning,
    label: 'Frowning',
  },
  3: {
    icon: Neutral,
    label: 'Neutral',
  },
  4: {
    icon: Grinning,
    label: 'Grinning',
  },
  5: {
    icon: InLove,
    label: 'In love',
  },
};

const IconContainer = (props: IconContainerProps) => {
  const { value, ...rest } = props;

  return (
    <span {...rest} data-cy={`smiley-rating-icon-${value}}`}>
      <SvgIcon
        component={customIcons[value].icon}
        inheritViewBox
        sx={{ width: 45, height: 45, mx: 2 }}
      />
    </span>
  );
};

export const SmileyRating = (props: RatingProps) => (
  <Rating
    IconContainerComponent={IconContainer}
    getLabelText={value => customIcons[value].label}
    data-cy={`smiley-rating-${props.name}`}
    {...props}
  />
);

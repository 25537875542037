import { Redirect, Route, Switch } from 'react-router-dom';
import { EngineAddPage, EngineDetailPage } from './pages/EngineDetailPage';
import { EngineListPage } from './pages/EngineListPage';
import { FieldAddPage, FieldDetailPage } from './pages/FieldDetailPage';
import {
  basePath,
  engineDetailRoute,
  engineFieldDetailRoute,
  engineFieldNewRoute,
  engineNewRoute,
} from './paths';

export const EngineRoutes = () => {
  return (
    <Switch>
      <Route exact path={engineNewRoute} component={EngineAddPage} />
      <Route exact path={engineDetailRoute} component={EngineDetailPage} />
      <Route exact path={engineFieldNewRoute} component={FieldAddPage} />
      <Route exact path={engineFieldDetailRoute} component={FieldDetailPage} />
      <Route path={basePath} component={EngineListPage} />
      <Redirect to={basePath} />
    </Switch>
  );
};

import { Reducer } from 'redux';
import Immutable from 'seamless-immutable';
import { getType } from 'typesafe-actions';
import { isEmbedded } from '../../../constants/config';
import { getDefaultLocale } from '../../../i18n/config';
import { RootActionType } from '../../rootActions';
import { logoutUser } from '../auth/actions';
import { setLocale } from './actions';

const getInitialState = () =>
  Immutable({ locale: getDefaultLocale({ omitStorage: isEmbedded() }) });

const reducer: Reducer<ReturnType<typeof getInitialState>, RootActionType> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case getType(setLocale):
      return state.set('locale', action.payload);

    case getType(logoutUser): {
      return getInitialState();
    }
    default:
      return state;
  }
};

export default reducer;

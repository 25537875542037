import { getIDFromUrl, Url } from '@rossum/api-client';
import { Search } from '@rossum/ui/icons';
import {
  Autocomplete,
  CircularProgress,
  Skeleton,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@rossum/ui/material';
import { forwardRef, useMemo, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { useIntl } from 'react-intl';
import PaperSection from '../../../ui/paper-section/PaperSection';
import { useEngine } from '../hooks/useEngine';
import { useEngines } from '../hooks/useEngines';

export type EngineQueueSettingsValue = {
  engine: Url | null;
  trainingEnabled?: boolean;
};

type EngineQueueSettingsProps = {
  queueEngine: Url;
  value: EngineQueueSettingsValue;
  onChange: (value: EngineQueueSettingsValue) => void;
  error?: FieldError;
  disabled?: boolean;
};

export const EngineQueueSettings = forwardRef(
  (
    { queueEngine, value, onChange, error, disabled }: EngineQueueSettingsProps,
    ref
  ) => {
    const [open, setOpen] = useState(false);

    const queueEngineId = getIDFromUrl(queueEngine);

    const { data: engine, isFetching: engineIsFetching } =
      useEngine(queueEngineId);

    const { data: engines, isFetching: enginesAreFetching } = useEngines(
      undefined,
      open
    );

    const intl = useIntl();

    const labelProps = {
      size: 'small' as const,
      label: intl.formatMessage({
        id: 'features.engines.queueSettings.engine.label',
      }),
    };

    const selectedValue = useMemo(
      () =>
        engine?.url === value.engine
          ? engine
          : engines?.results.find(option => option.url === value.engine),
      [engine, engines?.results, value.engine]
    );

    return (
      <PaperSection
        dataCy="engine-queue-settings"
        title={intl.formatMessage({
          id: 'features.engines.queueSettings.title',
        })}
      >
        <Stack
          spacing={2}
          sx={{
            width: '400px',
            maxWidth: '100%',
            justifyContent: 'flex-start',
          }}
        >
          {engineIsFetching ? (
            <TextField
              {...labelProps}
              disabled
              InputProps={{
                startAdornment: <Skeleton width="250px" />,
              }}
            />
          ) : (
            <Autocomplete
              ref={ref}
              disabled={disabled}
              loading={enginesAreFetching}
              loadingText={intl.formatMessage({
                id: 'features.engines.queueSettings.loading',
              })}
              id="engineGroup.engine"
              clearIcon={false}
              fullWidth
              disableClearable
              value={selectedValue}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              isOptionEqualToValue={(option, value) =>
                option.url === value?.url
              }
              onChange={(_, option) =>
                onChange({
                  engine: option?.url ?? null,
                  trainingEnabled: value.trainingEnabled,
                })
              }
              getOptionLabel={engine => engine.name}
              renderInput={params => (
                <TextField
                  helperText={error ? error.message : ''}
                  error={!!error}
                  {...params}
                  {...labelProps}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: value.engine ? null : <Search />,
                    endAdornment: (
                      <>
                        {enginesAreFetching ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              options={engines?.results ?? []}
            />
          )}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ flexGrow: 1 }}
          >
            <Typography variant="subtitle1">
              {intl.formatMessage({
                id: 'features.engines.queueSettings.learning.label',
              })}
            </Typography>
            <Stack>
              <Switch
                size="small"
                disabled={disabled}
                checked={!!value.trainingEnabled}
                onChange={(_e, trainingEnabled) =>
                  onChange({
                    engine: value.engine,
                    trainingEnabled,
                  })
                }
              />
            </Stack>
          </Stack>
        </Stack>
      </PaperSection>
    );
  }
);

import { ArrowDropDownRounded, ArrowDropUpRounded } from '@rossum/ui/icons';
import {
  Button,
  Menu,
  MenuItem,
  Stack,
  SxProps,
  Typography,
} from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { getOperatorLocalisationKey } from '../../../../document-list-base/mql/helpers';
import { FilterOperator } from '../../../../document-list-base/mql/operators';

export type OperatorsProps = {
  headerName?: string;
  operators: FilterOperator[];
  operator: FilterOperator;
  onOperatorChange: (operator: FilterOperator) => void;
};

export const operatorWrapperStyles: SxProps = {
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

const Operators = ({
  operators,
  headerName,
  operator,
  onOperatorChange,
}: OperatorsProps) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const selectedOperatorKey = getOperatorLocalisationKey(operator);

  const isOpen = !!anchorEl;

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      {headerName ? (
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary', textWrap: 'nowrap' }}
        >
          {headerName}
        </Typography>
      ) : null}
      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {operators.map(o => {
          const operatorKey = getOperatorLocalisationKey(o);
          return operatorKey ? (
            <MenuItem
              key={o.value}
              onClick={() => {
                setAnchorEl(null);
                onOperatorChange(o);
              }}
              data-cy={`all-documents-operator-${o.value}-for-${headerName}`}
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: `containers.filtering.operators.${operatorKey}`,
                })}
              </Typography>
            </MenuItem>
          ) : null;
        })}
      </Menu>
      {selectedOperatorKey ? (
        <Button
          color="secondary"
          onClick={e => setAnchorEl(e.currentTarget)}
          sx={{ px: 0.5, py: 0, fontWeight: 'bold' }}
        >
          {intl.formatMessage({
            id: `containers.filtering.operators.${selectedOperatorKey}`,
          })}
          {isOpen ? <ArrowDropUpRounded /> : <ArrowDropDownRounded />}
        </Button>
      ) : null}
    </Stack>
  );
};

export default Operators;

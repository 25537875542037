import { get, includes } from 'lodash';
import { getFromUnion } from '../../lib/helpers';
import {
  AnyDatapointData,
  AnyDatapointDataST,
  SimpleDatapointDataST,
  ValidationSources,
} from '../../types/datapoints';
import { AnyDatapointSchema } from '../../types/schema';

export const validationTickColor = (
  data: AnyDatapointDataST,
  active: boolean,
  suggestedOperation?: boolean,
  hasError?: boolean
): 'white' | 'green' | null => {
  const validationSources = getFromUnion(data, 'validationSources');

  if (
    get(data, ['schema', 'type']) === 'button' ||
    hasError ||
    suggestedOperation
  )
    return null;

  if (data?.meta?.sidebarDatapoint) {
    if (data.meta.isHumanValidated) return active ? 'white' : 'green';

    if (data.meta.isValidated) return 'white';
  }

  if (includes(validationSources, 'human')) return active ? 'white' : 'green';

  if (validationSources?.length) return 'white';

  return null;
};

export const irrelevantTickInfo = (
  validationSources: Array<ValidationSources>,
  documentAutomated: boolean | undefined
) =>
  validationSources?.length === 1 &&
  validationSources.includes('not_found') &&
  !documentAutomated;

export const isLoggableDatapoint = (
  dp: AnyDatapointDataST | AnyDatapointData | undefined
): dp is SimpleDatapointDataST => {
  return dp !== undefined && dp.category === 'datapoint' && !!dp.content;
};

export const getLoggableDatapointValue = (
  dp: AnyDatapointDataST | AnyDatapointData | undefined
) => (isLoggableDatapoint(dp) ? dp.content?.value : undefined);

export const isEditableFormulaField = (
  fieldSchema: AnyDatapointSchema | undefined
) =>
  fieldSchema &&
  'uiConfiguration' in fieldSchema &&
  fieldSchema.uiConfiguration?.edit === 'enabled' &&
  fieldSchema.uiConfiguration.type === 'formula';

export const isEditedFormulaField = (
  dp: AnyDatapointDataST | AnyDatapointData | undefined,
  fieldSchema: AnyDatapointSchema | undefined
) =>
  dp &&
  'noRecalculation' in dp &&
  dp.noRecalculation === true &&
  fieldSchema &&
  'uiConfiguration' in fieldSchema &&
  fieldSchema.uiConfiguration?.type === 'formula';

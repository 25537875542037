import {
  ActionsObservable,
  combineEpics,
  StateObservable,
} from 'redux-observable';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { apiUrl } from '../../../constants/config';
import { QUERY_KEY_EMAIL_THREADS } from '../../../features/document-list/emails/hooks/useFetchEmailThreads';
import { EMAIL_THREAD_EMAILS } from '../../../features/email-drawer/hooks/useEmailThreadEmails';
import { errorHandler } from '../../../lib/api';
import { EpicDependencies } from '../../../lib/apiTypes';
import { queryClient } from '../../../lib/queryClient';
import { EmailData } from '../../../types/email';
import { State } from '../../../types/state';
import { invalidateQueryClientCache } from '../../middlewares/queryClientMiddleware';
import { RootActionType } from '../../rootActions';
import {
  rejectEmail,
  sendEmailFulfilled,
  updateEmail,
  updateEmailFulfilled,
} from './actions';

const updateEmailsAfterSendAndUpdateEpic = (
  action$: ActionsObservable<RootActionType>
) =>
  action$.pipe(
    filter(isActionOf([updateEmailFulfilled, sendEmailFulfilled])),
    map(() => invalidateQueryClientCache([EMAIL_THREAD_EMAILS]))
  );

const updateEmailEpic = (
  action$: ActionsObservable<RootActionType>,
  _state$: StateObservable<State>,
  { authPatch$ }: EpicDependencies
) =>
  action$.pipe(
    filter(isActionOf(updateEmail)),
    mergeMap(({ payload, meta: { id } }) =>
      authPatch$<EmailData>(`${apiUrl}/emails/${id}`, payload).pipe(
        switchMap(email => of(updateEmailFulfilled(email))),
        tap(() => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY_EMAIL_THREADS],
          });
        }),
        catchError(errorHandler)
      )
    )
  );

const rejectEmailEpic = (
  action$: ActionsObservable<RootActionType>,
  _state$: StateObservable<State>
) =>
  action$.pipe(
    filter(isActionOf(rejectEmail)),
    switchMap(action => {
      const { payload } = action;
      return of(updateEmail({ labels: ['rejected'] }, payload.rootEmailId));
    })
  );

export default combineEpics(
  rejectEmailEpic,
  updateEmailEpic,
  updateEmailsAfterSendAndUpdateEpic
);

import { Button, CircularProgress } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { AutomationBreadcrumbs } from '../../../containers/Automation/AutomationBreadcrumbs';
import { automationEnginesPath } from '../../../containers/Automation/helpers';
import { Header, HeaderProps } from '../../../ui/header/Header';
import { ENGINE_FORM_ID } from '../constants';
import { basePath } from '../paths';

type EngineAddHeaderProps = { isSaving: boolean } & Pick<
  HeaderProps,
  'scrollableDivRef'
>;

export const EngineAddHeader = ({
  isSaving,
  ...rest
}: EngineAddHeaderProps) => {
  const intl = useIntl();

  const history = useHistory();

  return (
    <Header
      {...rest}
      breadcrumbs={
        <AutomationBreadcrumbs
          breadcrumbs={[
            {
              label: intl.formatMessage({
                id: 'components.appBar.menu.automation.aiEngines',
              }),
              to: automationEnginesPath(),
            },
            {
              label: intl.formatMessage({
                id: 'features.engines.header.title.newEngine',
              }),
            },
          ]}
        />
      }
      title={intl.formatMessage({
        id: 'features.engines.header.title.newEngine',
      })}
      description=""
      buttons={[
        <Button
          key="new_engine"
          variant="contained"
          type="submit"
          data-cy="submit-form"
          form={ENGINE_FORM_ID}
          startIcon={isSaving && <CircularProgress color="inherit" size={20} />}
          disabled={isSaving}
        >
          {intl.formatMessage({ id: 'features.engines.header.buttons.save' })}
        </Button>,
      ]}
      onBackButtonClicked={() => history.push(basePath)}
    />
  );
};

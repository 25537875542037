import { Schema } from '@rossum/api-client/schemas';
import { Workspace } from '@rossum/api-client/workspaces';
import { Button } from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { settingsPath } from '../../../../containers/Settings/helpers';
import { SettingsBreadcrumbs } from '../../../../containers/Settings/SettingsBreadcrumbs';
import { Header, HeaderProps } from '../../../../ui/header/Header';
import { Queue } from '../../types/queue';
import AddFieldDialog from './AddFieldDialog';

type OverviewHeaderProps = {
  workspaces: Workspace[];
  queues: Queue[];
  schemas: Schema[];
  isLoading: boolean;
} & Pick<HeaderProps, 'scrollableDivRef'>;

export const OverviewHeader = ({
  workspaces,
  queues,
  schemas,
  isLoading,
  ...rest
}: OverviewHeaderProps) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const intl = useIntl();
  const history = useHistory();

  return (
    <>
      <Header
        {...rest}
        breadcrumbs={
          <SettingsBreadcrumbs
            breadcrumbs={[
              {
                label: intl.formatMessage({
                  id: 'features.fieldManager.overview.ui.header.title',
                }),
              },
            ]}
          />
        }
        title={intl.formatMessage({
          id: 'features.fieldManager.overview.ui.header.title',
        })}
        description={intl.formatMessage({
          id: 'features.fieldManager.overview.ui.header.subTitle',
        })}
        buttons={[
          <Button
            key="add-field"
            data-cy="fm-add-field-btn"
            variant="contained"
            disabled={isLoading}
            onClick={() => setShowDialog(true)}
          >
            {intl.formatMessage({
              id: 'features.fieldManager.overview.addFieldDialog.button.addField',
            })}
          </Button>,
        ]}
        onBackButtonClicked={() => history.push(settingsPath())}
      />
      <AddFieldDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        schemas={schemas}
        queues={queues}
        workspaces={workspaces}
      />
    </>
  );
};

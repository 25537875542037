import {
  isSchemaSimpleMultiValue,
  isSchemaTableMultiValue,
  SchemaItem,
  SchemaSection,
  SchemaSimpleMultivalue,
  SchemaTableMultivalue,
} from '@rossum/api-client/schemas';
import * as R from 'remeda';

// we should change the ID of the multivalue only when the child ID has changed
const idsHaveChanged =
  (originalItem: SchemaSection | SchemaItem | null) =>
  (simpleMultivalue: SchemaSimpleMultivalue) => {
    const originalId = isSchemaSimpleMultiValue(originalItem)
      ? originalItem.children.id
      : null;

    return simpleMultivalue.children.id !== originalId;
  };

const setMultivalueId = (
  simpleMultivalue: SchemaSimpleMultivalue
): SchemaSimpleMultivalue => ({
  ...simpleMultivalue,
  id: `${simpleMultivalue.children.id}_multi`,
});

const hasOriginalId = (originalId: string | undefined) => !!originalId;
const setOriginalId =
  (originalId: string) => (multivalueDatapoint: SchemaSimpleMultivalue) =>
    R.setPath(multivalueDatapoint, ['id'], originalId);

const setTupleId =
  (originalItem: SchemaSection | SchemaItem | null) =>
  (tableMultivalue: SchemaTableMultivalue): SchemaTableMultivalue =>
    // do not overwrite existing tuple ID
    isSchemaTableMultiValue(originalItem) && originalItem.children.id
      ? tableMultivalue
      : R.setPath(
          tableMultivalue,
          ['children', 'id'],
          `${tableMultivalue.id}_tuple`
        );

export const adjustIds =
  (originalItem: SchemaSection | SchemaItem | null) =>
  <T>(obj: T): T => {
    const originalId = originalItem?.id;
    return R.pipe(
      obj,
      R.conditional(
        [
          isSchemaSimpleMultiValue,
          R.conditional(
            [idsHaveChanged(originalItem), setMultivalueId],
            [() => hasOriginalId(originalId), setOriginalId(originalId!)],
            R.conditional.defaultCase(R.constant(obj))
          ),
        ],
        // TODO: this is podozrivé
        [
          isSchemaTableMultiValue,
          tableMultivalue => setTupleId(originalItem)(tableMultivalue),
        ],
        R.conditional.defaultCase(R.constant(obj))
      )
    ) as T;
  };

import { format } from 'date-fns';
import { snakeCase } from 'lodash';
import { parse, stringify } from 'query-string';
import { z } from 'zod';
import { typeSafeObjectEntries } from '../../lib/fromEntries';
import { DATE_FORMAT } from '../document-list-base/mql/dateUtils';
import { TypedFilterItem } from '../document-list-base/mql/types';
import { LevelOptions, ViewOptions } from './hooks/useDashboardQuery';

// we ignore pagination as in the new dashboard the pagination is of a different structure
const annotationUrlSchema = z.object({
  view: z
    .union([z.literal('documents'), z.literal('emails')])
    .optional()
    .default('documents'),
  level: z
    .union([z.literal('all'), z.literal('queue'), z.literal('initial')])
    .optional()
    .default('queue'),
  ordering: z.string().default('created_at'),
  status: z.string().optional().nullable().default(null),
  labels: z.string().optional().nullable().default(null),
  created_at_before: z.string().optional().nullable().default(null),
  created_at_after: z.string().optional().nullable().default(null),
  modified_at_before: z.string().optional().nullable().default(null),
  modified_at_after: z.string().optional().nullable().default(null),
  exported_at_after: z.string().optional().nullable().default(null),
  exported_at_before: z.string().optional().nullable().default(null),
  confirmed_at_after: z.string().optional().nullable().default(null),
  confirmed_at_before: z.string().optional().nullable().default(null),
  rejected_at_after: z.string().optional().nullable().default(null),
  rejected_at_before: z.string().optional().nullable().default(null),
  deleted_at_after: z.string().optional().nullable().default(null),
  deleted_at_before: z.string().optional().nullable().default(null),
});
type AnnotationUrlSchema = z.TypeOf<typeof annotationUrlSchema>;

export type NewDashboardQuery = {
  filtering: TypedFilterItem[];
  ordering: string;
  level: LevelOptions;
  view: ViewOptions;
};

export const normalizeDate = (value: string) =>
  format(new Date(value), DATE_FORMAT);

const filterNormalizers: Partial<
  Record<keyof AnnotationUrlSchema, (value: string) => TypedFilterItem>
> = {
  status: value => ({
    field: 'status',
    value: value.split(',').map(snakeCase),
    operator: 'isAnyOf',
  }),
  labels: value => ({
    field: 'labels',
    value: value.split(','),
    operator: 'isAnyOf',
  }),
  created_at_after: value => ({
    field: 'created_at',
    value: normalizeDate(value),
    operator: 'after',
  }),
  created_at_before: value => ({
    field: 'created_at',
    value: normalizeDate(value),
    operator: 'before',
  }),
  modified_at_after: value => ({
    field: 'modified_at',
    value: normalizeDate(value),
    operator: 'after',
  }),
  modified_at_before: value => ({
    field: 'modified_at',
    value: normalizeDate(value),
    operator: 'before',
  }),
  exported_at_after: value => ({
    field: 'exported_at',
    value: normalizeDate(value),
    operator: 'after',
  }),
  exported_at_before: value => ({
    field: 'exported_at',
    value: normalizeDate(value),
    operator: 'before',
  }),
  confirmed_at_after: value => ({
    field: 'confirmed_at',
    value: normalizeDate(value),
    operator: 'after',
  }),
  confirmed_at_before: value => ({
    field: 'confirmed_at',
    value: normalizeDate(value),
    operator: 'before',
  }),
  rejected_at_after: value => ({
    field: 'rejected_at',
    value: normalizeDate(value),
    operator: 'after',
  }),
  rejected_at_before: value => ({
    field: 'rejected_at',
    value: normalizeDate(value),
    operator: 'before',
  }),
  deleted_at_after: value => ({
    field: 'deleted_at',
    value: normalizeDate(value),
    operator: 'after',
  }),
  deleted_at_before: value => ({
    field: 'deleted_at',
    value: normalizeDate(value),
    operator: 'before',
  }),
};

export const fallbackUrl = '/documents';

export const transformQueryToNewDashboardQuery = ({
  query,
  queueId,
}: {
  query: string;
  queueId: string;
}) => {
  const parsedUrl = parse(query);

  const schemaParse = annotationUrlSchema.safeParse(parsedUrl);
  const queueFilter: TypedFilterItem = {
    field: 'queue',
    value: [queueId],
    operator: 'isAnyOf',
  };
  if (schemaParse.success) {
    try {
      return typeSafeObjectEntries(schemaParse.data)
        .filter(([_, value]) => !!value)
        .reduce<NewDashboardQuery>(
          (acc, [key, value]) => {
            const filterNormalizer = filterNormalizers[key];
            if (filterNormalizer)
              return {
                ...acc,
                filtering: [...acc.filtering, filterNormalizer(value)],
              };

            return acc;
          },
          {
            filtering: [queueFilter],
            ordering: snakeCase(schemaParse.data.ordering),
            level: schemaParse.data.level,
            view: schemaParse.data.view,
          }
        );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        'There has been a problem transforming the annotations query: ',
        error
      );
      return null;
    }
  }
  return null;
};

// date, labels, status, queueId will be filtered
export const transformUrlToNewDashboardUrl = ({
  query,
  queueId,
}: {
  queueId: string | undefined;
  query: string;
}) => {
  if (!queueId) return fallbackUrl;

  const searchQueryObject = transformQueryToNewDashboardQuery({
    query,
    queueId,
  });

  if (searchQueryObject) {
    const { filtering, ...searchQuery } = searchQueryObject;

    return `/documents?filtering=${JSON.stringify({
      items: filtering,
    })}&${stringify(searchQuery)}`;
  }

  return fallbackUrl;
};

import { create } from './create';
import { deleteEndpoint } from './delete';
import { get } from './get';
import { list } from './list';
import { patch } from './patch';
import { duplicate } from './duplicate';
import { queueStats } from './queueStats';
import { checkTemplateCompatibility } from './checkTemplateCompatibility';
export { type EngineCreatePayload } from './create';
export { type EngineDuplicatePayload } from './duplicate';
export { type EngineListQuery } from './list';
export { type EnginePatchPayload } from './patch';
export { type EngineCheckTemplateCompatibilityPayload } from './checkTemplateCompatibility';

export const engines = {
  create,
  delete: deleteEndpoint,
  get,
  list,
  patch,
  duplicate,
  queueStats,
  checkTemplateCompatibility,
};

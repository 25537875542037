import { endpoints, getIDFromUrl, withFields } from '@rossum/api-client';
import { ListResponse } from '@rossum/api-client/utils';
import { useMutation } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';
import { asScalar, parse } from '../../../lib/url';
import {
  DataGridConditionModel,
  encodeMqlQuery,
} from '../../document-list-base/mql/mql';
import { reviewableStatuses } from './utils';

export const GATHER_DOCUMENTS_QUERY_KEY = 'gather-documents';
const DEFAULT_ORDERING = '-created_at';

export type DocumentListQuery = {
  query: {
    pageSize: number;
    ordering?: string;
  };
  searchQuery: string | undefined;
  dataGridFiltering: DataGridConditionModel;
};

// Helper to fetch multiple cursor based pages in one promise.
const fetchPagesRecursively = async <T>(
  fetchPage: ({
    pageParam,
  }: {
    pageParam?: string;
  }) => Promise<ListResponse<T>>,
  numberOfPages: number,
  results: T[] = [],
  pageParam?: string
): Promise<T[]> => {
  const nextPage = await fetchPage({ pageParam });
  const newResults = [...results, ...nextPage.results];

  if (numberOfPages > 1 && nextPage.pagination.next) {
    const searchAfter = parse(nextPage.pagination.next).search_after;
    return fetchPagesRecursively(
      fetchPage,
      numberOfPages - 1,
      newResults,
      searchAfter ? asScalar(searchAfter) : undefined
    );
  }

  return newResults;
};

export const useStartReviewing = ({
  query,
  searchQuery,
  dataGridFiltering,
  numberOfPages,
  queueId,
}: {
  numberOfPages: number;
  queueId: number;
} & DocumentListQuery) => {
  const resolvedQuery = {
    pageSize: query.pageSize,
    ordering: DEFAULT_ORDERING,
  };

  const mqlQuery = encodeMqlQuery(dataGridFiltering);

  const fetchPageOfAnnotations = ({
    pageParam,
  }: { pageParam?: string } = {}) => {
    const queryWithSearchAfter = {
      ...resolvedQuery,
      ...(pageParam ? { searchAfter: pageParam } : {}),
    };

    const endpoint = endpoints.annotations.search(queryWithSearchAfter, {
      query: mqlQuery,
      ...(searchQuery && { queryString: { string: searchQuery } }),
    });

    return api.request(withFields(endpoint, { url: true }));
  };

  return useMutation({
    mutationKey: [
      GATHER_DOCUMENTS_QUERY_KEY,
      resolvedQuery,
      mqlQuery,
      searchQuery,
    ] as const,
    mutationFn: async () => {
      const annotations = await fetchPagesRecursively(
        fetchPageOfAnnotations,
        numberOfPages
      );
      const startedAnnotation = await api.request(
        endpoints.queues.nextAnnotation(queueId, {
          annotationIds: annotations.map(annotation =>
            getIDFromUrl(annotation.url)
          ),
          statuses: reviewableStatuses,
        })
      );
      return { annotations, startedAnnotation };
    },
  });
};

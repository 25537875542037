import { Queue } from '@rossum/api-client/queues';
import { Stack } from '@rossum/ui/material';
import { debounce } from 'lodash';
import { memo, useMemo, useState } from 'react';
import { NonAdminRestrictor } from '../../../components/Restrictors';
import {
  ExpandableSearch,
  SEARCH_MIN_CHAR_LENGTH,
} from '../../../ui/expandable-search/ExpandableSearch';
import { MetaColDef, SchemaColDef } from '../../document-list-base/mql/types';
import { ColumnsPanel } from '../columns/panel/ColumnsPanel';
import { toolbarStyles } from '../constants';
import { SettingsButton } from '../cta-actions/SettingsButton';
import { HideFiltersButton } from './HideFiltersButton';

type Props = {
  selectionActive: boolean;
  onSearchSubmit: (searchValue: string | undefined) => void;
  search: string | undefined;
  columns: Array<SchemaColDef | MetaColDef>;
  filterChipsCount: number;
  toggleFilterPanel: () => void;
  showFilterPanel: boolean;
  activeQueue: Queue | null;
};

const Toolbar = ({
  selectionActive,
  onSearchSubmit,
  search,
  columns,
  filterChipsCount,
  toggleFilterPanel,
  showFilterPanel,
  activeQueue,
}: Props) => {
  const [searchState, setSearchState] = useState(search ?? '');

  const debouncedSearching = useMemo(
    () => debounce(onSearchSubmit, 400),
    [onSearchSubmit]
  );

  return (
    <Stack {...toolbarStyles}>
      <ExpandableSearch
        onChange={search => {
          setSearchState(search);
          debouncedSearching(
            search.length >= SEARCH_MIN_CHAR_LENGTH ? search : undefined
          );
        }}
        value={searchState}
      />

      <ColumnsPanel
        selectionActive={selectionActive}
        columns={columns}
        activeQueue={activeQueue}
      />

      <HideFiltersButton
        onClick={toggleFilterPanel}
        filterChipsCount={filterChipsCount}
        showFilterPanel={showFilterPanel}
      />
      {activeQueue ? (
        <NonAdminRestrictor>
          <SettingsButton queueId={activeQueue?.id} view="documents" />
        </NonAdminRestrictor>
      ) : null}
    </Stack>
  );
};

export default memo(Toolbar);

import { Url } from '@rossum/api-client';
import { Stack } from '@rossum/ui/material';
import { DataGridPro, GridRowSelectionModel } from '@rossum/ui/x-data-grid-pro';
import { useIntl } from 'react-intl';
import { useSimpleColumns } from '../../document-list-base/hooks/useSimpleColumns';
import {
  AttachmentModalDataGridStyles,
  PAGE_SIZE,
  ROW_HEIGHT,
} from '../helpers';
import { useFetchSuggestedAttachments } from '../hooks/useFetchSuggestedAttachments';
import { HandleOnRowSelectionModelChange } from '../types';
import { AttachmentsModalSubtitle } from './AttachmentsModalSubtitle';
import { NoAttachmentsFound } from './NoAttachmentsFound';
import { SkeletonLoaders } from './SkeletonLoaders';

type SuggestedAttachmentsProps = {
  rootAnnotationUrl: Url;
  existingAttachments: Url[];
  rowSelectionModel: GridRowSelectionModel;
  handleOnRowSelectionModelChange: HandleOnRowSelectionModelChange;
};

export const SuggestedAttachments = ({
  rootAnnotationUrl,
  existingAttachments,
  rowSelectionModel,
  handleOnRowSelectionModelChange,
}: SuggestedAttachmentsProps) => {
  const intl = useIntl();

  const { suggestedAttachments, isLoading, isError } =
    useFetchSuggestedAttachments({
      rootAnnotationUrl,
      existingAttachments,
    });

  const columns = useSimpleColumns();

  const paginationModel = { pageSize: PAGE_SIZE, page: 0 };

  return (
    <>
      <AttachmentsModalSubtitle
        isVisible={!!(!isLoading && !isError && suggestedAttachments?.length)}
        value={intl.formatMessage({
          id: `components.modal.addAttachments.subTitle.suggested`,
        })}
      />
      <Stack height={1}>
        <DataGridPro
          loading={isLoading}
          columns={columns}
          rows={suggestedAttachments && !isError ? suggestedAttachments : []}
          rowSelectionModel={rowSelectionModel}
          rowHeight={ROW_HEIGHT}
          keepNonExistentRowsSelected
          onRowSelectionModelChange={newModel =>
            handleOnRowSelectionModelChange(suggestedAttachments, newModel)
          }
          checkboxSelection
          disableColumnMenu
          disableColumnResize
          disableRowSelectionOnClick
          hideFooterSelectedRowCount
          pageSizeOptions={[PAGE_SIZE]}
          pagination
          initialState={{ pagination: { paginationModel } }}
          slots={{
            loadingOverlay: () => <SkeletonLoaders isLoading={isLoading} />,
            noRowsOverlay: () => (
              <NoAttachmentsFound
                translationKey={
                  isError ? 'suggestionsError' : 'emptyAttachmentsList'
                }
              />
            ),
          }}
          sx={AttachmentModalDataGridStyles}
        />
      </Stack>
    </>
  );
};

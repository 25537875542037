import { Workspace } from '@rossum/api-client/workspaces';
import { Box, Chip, Stack } from '@rossum/ui/material';
import { GridRenderCellParams } from '@rossum/ui/x-data-grid-pro';
import { useMemo, useState } from 'react';
import { useGetWorkspaces } from '../../../../hooks/useGetWorkspaces';
import { Queue } from '../../../../types/queue';
import { Dialog } from '../../../ui/Dialog';
import { LinkWithNameAndId } from '../../../ui/LinkWithNameAndId';
import { StringWithOccurrences } from '../../../ui/StringWithOccurrences';
import { GridRowModel } from '../../rows/rowTypes';

type WorkspaceWithQueuesProps = GridRenderCellParams<
  GridRowModel,
  Array<Queue>
>;

const WorkspaceWithQueues = ({ row }: WorkspaceWithQueuesProps) => {
  const rowQueues = row.meta.schema_queues;
  const workspaceQueries = useGetWorkspaces();
  const workspaces: Workspace[] = useMemo(
    () => workspaceQueries.data?.pages.flatMap(page => page.results) || [],
    [workspaceQueries.data]
  );

  const [dialogOpen, setDialogOpen] = useState(false);

  if (Array.isArray(rowQueues)) {
    const [firstQueue] = rowQueues;
    const workspaceName = workspaces.find(
      w => w.url === firstQueue?.workspace
    )?.name;

    if (firstQueue) {
      return (
        <>
          <Stack width="100%" direction="row" spacing={1} alignItems="center">
            <Box
              width="100%"
              data-cy="wrapper"
              fontWeight="fontWeightMedium"
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              <StringWithOccurrences
                primaryValue={`${workspaceName} > ${firstQueue.name}`}
                secondaryValue={firstQueue.id}
                key={`${workspaceName} > ${firstQueue.name}`}
                PrimaryValueSx={{ fontWeight: 700 }}
              />
            </Box>

            {rowQueues.length > 1 ? (
              <Chip
                size="small"
                color="secondary"
                variant="outlined"
                label={rowQueues?.length}
                onClick={e => {
                  e.stopPropagation();
                  setDialogOpen(true);
                }}
                data-cy="fm-queues-dialog-link"
              />
            ) : null}
          </Stack>

          <Dialog
            onClose={() => setDialogOpen(false)}
            open={dialogOpen}
            title={workspaceName}
          >
            {rowQueues.map(queue => (
              <LinkWithNameAndId
                link={`/queues/${queue.id}/settings/fields`}
                name={queue.name}
                id={String(queue.id)}
                key={queue.id}
              />
            ))}
          </Dialog>
        </>
      );
    }
  }

  return null;
};

export const renderWorkspaceWithQueues = (params: WorkspaceWithQueuesProps) => (
  <WorkspaceWithQueues {...params} />
);

import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  inputClasses,
  outlinedInputClasses,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@rossum/ui/material';
import { useCallback, useState } from 'react';
import { AuroraSuggest } from '../icons/AuroraSuggest';
import { usePlaceholderSwitcher } from './usePlaceholderSwitcher';

type CopilotDialogProps = {
  title: string;
  subtitle: string;
  errorText: string;
  open: boolean;
  placeholders: string[];
  onSubmit: (value: string) => void;
  onClose: () => void;
  isLoading: boolean;
  isError: boolean;
  closeText: string;
  confirmText: string;
};

export const CopilotDialog = ({
  title,
  subtitle,
  errorText,
  open,
  placeholders,
  onSubmit,
  onClose,
  isLoading,
  isError,
  closeText,
  confirmText,
}: CopilotDialogProps) => {
  const [value, setValue] = useState('');

  const { placeholderStyle, placeholder } = usePlaceholderSwitcher(
    placeholders,
    5000,
    value
  );

  const handleKeyDownInMultilineInput = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        onSubmit(value);
      }
    },
    [onSubmit, value]
  );

  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        elevation: 0,
        sx: { position: 'relative', overflow: 'visible' },
      }}
    >
      <Avatar
        sx={{
          bgcolor: theme => theme.palette.background.paper,
          zIndex: 1,
          position: 'absolute',
          right: 0,
          left: 0,
          margin: 'auto',
          top: '-32px',
          width: 64,
          height: 64,
        }}
      >
        <AuroraSuggest fontSize="large" stroke={theme.palette.aurora.light} />
      </Avatar>

      <DialogContent>
        <Stack
          spacing={2}
          pt={2}
          component="form"
          onSubmit={e => {
            onSubmit(value);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Stack spacing={0.5} textAlign="center">
            <Typography variant="h5">{title}</Typography>

            <Typography variant="body2" color="text.secondary" component="span">
              {subtitle}
            </Typography>
          </Stack>

          <TextField
            onChange={e => setValue(e.currentTarget.value)}
            size="small"
            autoComplete="off"
            multiline
            maxRows={12}
            helperText={isError ? errorText : undefined}
            FormHelperTextProps={
              isError
                ? { sx: { color: theme => theme.palette.warning.main } }
                : undefined
            }
            autoFocus
            placeholder={placeholder}
            onKeyDown={handleKeyDownInMultilineInput}
            InputProps={{
              sx: {
                [`&.${inputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
                  {
                    borderColor: theme => theme.palette.aurora.light,
                  },
              },
              inputProps: {
                sx: {
                  '&::placeholder': placeholderStyle,
                },
              },
            }}
          />

          <Stack direction="row" alignSelf="center" spacing={1}>
            <Button color="inherit" onClick={onClose}>
              {closeText}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="aurora"
              disabled={isLoading}
              startIcon={
                isLoading && <CircularProgress color="inherit" size={20} />
              }
            >
              {confirmText}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

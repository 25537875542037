import { combineEpics } from 'redux-observable';
import { distinctUntilChanged, filter, map, pluck } from 'rxjs/operators';
import { isEmbedded } from '../../../constants/config';
import {
  getDefaultLocale,
  getLocaleKey,
  LOCALE_LOCALSTORAGE_KEY,
} from '../../../i18n/config';
import { removeItem, setItem } from '../localStorage/actions';
import { fetchUserFulfilled, updateUserFulfilled } from '../user/actions';
import { isActionOf, makeEpic } from '../utils';
import { setLocale } from './actions';

const setLocaleEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf([fetchUserFulfilled, updateUserFulfilled])),
    map(({ payload }) => payload.uiSettings.locale),
    distinctUntilChanged(),
    map(
      locale => getLocaleKey(locale) || getDefaultLocale({ omitStorage: true })
    ),
    filter(
      locale => locale !== getDefaultLocale({ omitStorage: isEmbedded() })
    ),
    map(setLocale)
  )
);

const cacheLocaleEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(setLocale)),
    filter(() => !isEmbedded()),
    pluck('payload'),
    filter(locale => locale !== localStorage.getItem(LOCALE_LOCALSTORAGE_KEY)),
    map(locale =>
      locale
        ? setItem(LOCALE_LOCALSTORAGE_KEY, locale)
        : removeItem(LOCALE_LOCALSTORAGE_KEY)
    )
  )
);

export default combineEpics(setLocaleEpic, cacheLocaleEpic);

import { Stack } from '@rossum/ui/material';
import { get } from 'lodash';
import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { Dispatch } from 'redux';
import { PageLayoutV2 } from '../../components/PageLayoutV2/PageLayoutV2';
import { getRoleName } from '../../redux/modules/groups/helpers';
import { updateUserDetail } from '../../redux/modules/users/actions';
import { Groups } from '../../types/group';
import { State } from '../../types/state';
import { OrganizationUser, User as UserT } from '../../types/user';
import Toggle from '../Users/components/Toggle';
import { getActiveUserPage } from '../Users/helpers';
import { UserHeader } from './components/UserHeader';

type StateProps = {
  loggedInUserId: number;
  groups: Groups;
};

type DispatchProps = {
  toggleUserActive: (id: number, isActive: boolean) => void;
};

type OwnProps = {
  children: ReactNode;
  selectedUser: OrganizationUser | UserT;
} & Pick<RouteChildrenProps, 'location'>;

type Props = StateProps & DispatchProps & OwnProps;

const User = ({
  children,
  groups,
  loggedInUserId,
  selectedUser,
  toggleUserActive,
  location,
}: Props) => {
  const activePage = getActiveUserPage(location.pathname);

  return (
    <PageLayoutV2
      renderHeader={params =>
        activePage ? (
          <UserHeader
            {...params}
            pageKey={activePage}
            user={selectedUser}
            toggle={
              <Toggle
                disabled={
                  loggedInUserId === get(selectedUser, 'id') ||
                  getRoleName(get(selectedUser, 'groups', []), groups) ===
                    'organization_group_admin'
                }
                active={get(selectedUser, 'isActive')}
                onToggle={() =>
                  toggleUserActive(
                    get(selectedUser, 'id'),
                    get(selectedUser, 'isActive')
                  )
                }
                isUserOrgGroupAdmin={
                  getRoleName(get(selectedUser, 'groups', []), groups) ===
                  'organization_group_admin'
                }
              />
            }
          />
        ) : null
      }
    >
      <Stack px={4} py={4}>
        {children}
      </Stack>
    </PageLayoutV2>
  );
};

const mapStateToProps = (state: State) => ({
  loggedInUserId: state.user.id,
  groups: state.groups,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    toggleUserActive: (id: number, isActive: boolean) =>
      dispatch(updateUserDetail(id, { isActive: !isActive })),
  };
};

export default connect<StateProps, DispatchProps, OwnProps, State>(
  mapStateToProps,
  mapDispatchToProps
)(User);

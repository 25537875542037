// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

export const emailThreadSchema = z.object({
  id: idSchema,
  url: urlSchema,
  organization: urlSchema,
  queue: urlSchema,
  rootEmail: urlSchema.nullable(),
  hasReplies: z.boolean(),
  hasNewReplies: z.boolean(),
  rootEmailRead: z.boolean(),
  lastEmailCreatedAt: z.string().nullable(),
  subject: z.string().nullable().optional(),
  createdAt: z.string().nullable().optional(),
  from: z
    .object({
      email: z.string(),
      name: z.string().optional(),
    })
    .nullable(),
  labels: z.array(z.string()).optional(),
  annotationCounts: z.object({
    annotations: z.number(),
    annotationsProcessed: z.number(),
    annotationsRejected: z.number(),
    annotationsUnprocessed: z.number(),
    annotationsPurged: z.number().nullable(),
    relatedAnnotations: z.number().nullable(),
  }),
});

export const emailThreadCountsSchema = z.object({
  withReplies: z.number(),
  withNewReplies: z.number(),
  recentWithNoDocumentsNotReplied: z.number(),
});

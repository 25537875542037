import { Page } from '@rossum/api-client/pages';
import { Skeleton, Stack } from '@rossum/ui/material';
import { useRef } from 'react';
import { browserVersion, isChrome } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { Rectangle2D } from '../../document-canvas/utils/geometry';
import { EmptyStateTexts } from './EmptyStateTexts';
import { PageContent } from './PageContent';
import { usePageContent } from './usePageContent';

type PageContainerProps = {
  page: Page;
  pageDimensions: Rectangle2D | undefined;
  isVisible: boolean;
  cursor?: 'crosshair';
};

export const PageContainer = ({
  page,
  pageDimensions,
  isVisible,
  cursor,
}: PageContainerProps) => {
  const { content, id } = page;

  const { data, isSuccess, error } = usePageContent(content, id, {
    enabled: isVisible,
  });
  const intl = useIntl();

  const overlayRef = useRef<SVGSVGElement | null>(null);

  // Logic below is here just to ensure compatibility with older broswers
  // There is a bug in chrome v69 with svg having a viewbox and a relative sizes.
  // By making the height as large as possible (larger then the largest screen),
  // We ensure that the viewport has its absolute height, which is being snapped to
  // the actual height of the screen viewport
  // https://issues.chromium.org/issues/41007188
  const isOldChromeVersion = isChrome && Number(browserVersion) < 75;

  // It would be good to refactor this.
  if (!pageDimensions || !isVisible) {
    return null;
  }

  return (
    <svg
      cursor={cursor}
      ref={overlayRef}
      x={pageDimensions.x}
      y={pageDimensions.y}
      width={pageDimensions.width}
      height={pageDimensions.height}
      viewBox={`0 0 ${pageDimensions.width} ${pageDimensions.height}`}
      style={{ overflow: 'visible' }}
    >
      {isSuccess ? (
        <PageContent
          page={page}
          canvasCoordinates={pageDimensions}
          pageRef={overlayRef}
          contentBlob={data}
        />
      ) : isOldChromeVersion ? null : (
        <foreignObject
          x={0}
          y={0}
          width={pageDimensions.width}
          height={pageDimensions.height}
          overflow="visible"
          spacing={2}
        >
          <Stack width={pageDimensions.width} height={pageDimensions.height}>
            {error ? (
              <EmptyStateTexts
                title={intl.formatMessage({
                  id: 'features.document.preview.error.title',
                })}
                intl={intl}
              />
            ) : (
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height="100%"
              />
            )}
          </Stack>
        </foreignObject>
      )}
    </svg>
  );
};

import { Workspace } from '@rossum/api-client/workspaces';
import { useIsFetching } from '@tanstack/react-query';
import { uniq } from 'lodash';
import { useMemo } from 'react';
import { isNotNullOrUndefined } from '../field-manager/typeguards';
import { useGetQueues } from '../hooks/useGetQueues';
import { useGetSchemas } from '../hooks/useGetSchemas';
import { useGetWorkspaces } from '../hooks/useGetWorkspaces';
import { useMemoFlatSchemasWithQueues } from '../hooks/useMemoFlatSchemasWithQueues';

export const useFieldManagerData = () => {
  const queuesQuery = useGetQueues();

  const schemaUrls = useMemo(() => {
    if (!queuesQuery.isFetching && !queuesQuery.hasNextPage) {
      return uniq(
        queuesQuery.data?.pages.flatMap(page =>
          page.results.map(result => result.schema)
        )
      );
    }
    return [];
  }, [
    queuesQuery.isFetching,
    queuesQuery.hasNextPage,
    queuesQuery.data?.pages,
  ]);

  const schemaQueries = useGetSchemas(schemaUrls);

  const queues = useMemo(
    () => queuesQuery.data?.pages.flatMap(page => page.results) || [],
    [queuesQuery.data]
  );

  const schemaQueriesLoadedTimestamp = schemaQueries.some(
    query => query.isFetching
  )
    ? 0
    : schemaQueries.reduce((acc, { dataUpdatedAt }) => acc + dataUpdatedAt, 0);

  const schemas = useMemo(
    () => schemaQueries.map(({ data }) => data).filter(isNotNullOrUndefined),
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [schemaQueriesLoadedTimestamp]
  );

  const workspaceQueries = useGetWorkspaces();

  const workspaces: Workspace[] = useMemo(
    () => workspaceQueries.data?.pages.flatMap(page => page.results) || [],
    [workspaceQueries.data]
  );

  const flatSchemasWithQueues = useMemoFlatSchemasWithQueues(queues, schemas);

  const queuesAreLoading = !!useIsFetching({
    queryKey: ['queues-paginated'],
  });

  const workspacesAreLoading = !!useIsFetching({
    queryKey: ['workspaces-paginated'],
  });

  const schemasAreLoading = !!useIsFetching({
    queryKey: ['schema'],
  });

  const gridIsLoading =
    queuesAreLoading || workspacesAreLoading || schemasAreLoading;

  return {
    flatSchemasWithQueues,
    schemas,
    queues,
    workspaces,
    isLoading: gridIsLoading,
  };
};

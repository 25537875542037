import { ErrorOutline } from '@rossum/ui/icons';
import {
  Box,
  Button,
  Card,
  Stack,
  Typography,
  useTheme,
} from '@rossum/ui/material';
import { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useIntl } from 'react-intl';
import { isEmbedded } from '../../../../constants/config';
import { history } from '../../../../redux/configureStore';

type GlobalErrorFallbackScreenProps = Pick<
  FallbackProps,
  'resetErrorBoundary'
> & {
  lastError: string | null;
};

export const GlobalErrorFallbackScreen = ({
  resetErrorBoundary,
  lastError,
}: GlobalErrorFallbackScreenProps) => {
  const intl = useIntl();

  // If a user hits browser back button, hide error boundary
  // Right now this calls onReset in GlobalErrorBoundary which reloads page on `/` any time boundary is breached
  // It's probably better anyway if an error occured
  // TODO: Not ideal but at least the boundary disappears - we can set up a better way down the line
  useEffect(() => {
    const unregisterListener = history.listen((_, action) => {
      if (action === 'POP') {
        resetErrorBoundary();
      }
    });

    return unregisterListener;
  }, [resetErrorBoundary]);

  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        minHeight: '100vh',
      }}
    >
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Card
          sx={{
            p: 4,
          }}
        >
          <Stack
            alignItems="center"
            sx={{
              mt: 1,
              mb: 2,
            }}
          >
            <ErrorOutline fontSize="large" sx={{ color: 'error.light' }} />
          </Stack>

          <Stack
            alignItems="center"
            sx={{
              mb: 1,
            }}
          >
            <Typography variant="h5">
              {intl.formatMessage({
                id: 'components.errorBoundaries.globalErrorScreen.errorMessage.heading',
              })}
            </Typography>
          </Stack>
          <Stack
            alignItems="center"
            sx={{
              maxWidth: 400,
              minWidth: 400,
              px: 4,
              mb: 3,
            }}
          >
            <Typography align="center">
              {isEmbedded()
                ? intl.formatMessage({
                    id: 'components.errorBoundaries.globalErrorScreen.errorMessage.embeddedModeContent',
                  })
                : intl.formatMessage({
                    id: 'components.errorBoundaries.globalErrorScreen.errorMessage.content',
                  })}
            </Typography>
            {lastError !== null && (
              <Typography
                align="center"
                variant="caption"
                color="text.secondary"
                sx={{ pt: 2 }}
              >
                {intl.formatMessage({
                  id: 'components.errorBoundaries.globalErrorScreen.errorID',
                })}
                : {lastError}
              </Typography>
            )}
          </Stack>
          <Stack alignItems="center">
            <Button variant="contained" onClick={resetErrorBoundary}>
              {isEmbedded()
                ? intl.formatMessage({
                    id: 'components.errorBoundaries.globalErrorScreen.errorMessage.embeddedModeRefreshButtonLabel',
                  })
                : intl.formatMessage({
                    id: 'components.errorBoundaries.globalErrorScreen.errorMessage.refreshButtonLabel',
                  })}
            </Button>
          </Stack>
        </Card>
      </Stack>
    </Box>
  );
};

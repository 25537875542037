import { zodResolver } from '@hookform/resolvers/zod';
import { Queue } from '@rossum/api-client/queues';
import { QueueSettingsUploadValues } from '@rossum/api-client/queues';
import { queueSettingsUploadValuesSchema } from '@rossum/api-client/queues';
import { Divider, Stack, Typography } from '@rossum/ui/material';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { entries, fromEntries, partition } from 'remeda';
import { z, ZodOptional, ZodString } from 'zod';
import TextFieldControl from '../../../components/ReactHookForm/controls/TextFieldControl';
import { ListSelect } from '../../../components/UI/ListSelect';
import { QueueList } from '../../queues/select-queue/QueueList';
import { WorkspaceWithQueues } from '../../queues/select-queue/types';
import { AdditionalUploadValues, determineSelectInputError } from './helpers';

const FORM_WIDTH = 350;

type Props = {
  onConfirm: (params: AdditionalUploadValues) => void;
  selectedQueue: Queue | undefined;
  setSelectedQueue: (_queue: Queue | undefined) => void;
  workspacesWithQueues?: WorkspaceWithQueues[];
};

const uploadValueTypeSchema = z.object({
  type: z
    .union([z.literal('values'), z.literal('metadata')])
    .default('values')
    .catch('values'),
});

const uploadValuesWithDefaultsSchema = queueSettingsUploadValuesSchema
  .omit({
    type: true,
  })
  .merge(uploadValueTypeSchema);

const uploadValuesSchema = z
  .array(uploadValuesWithDefaultsSchema)
  .default([])
  .catch([]);

const createValidationSchema = (
  fields: QueueSettingsUploadValues[] = [],
  intl: IntlShape
) => {
  const schemaFields = fields.reduce<
    Record<string, ZodString | ZodOptional<ZodString>>
  >((acc, field) => {
    const validator = field.required
      ? z
          .string()
          .trim()
          .min(
            1,
            intl.formatMessage(
              {
                id: 'containers.annotationList.upload.additionalValues.fieldRequired',
              },
              {
                fieldLabel: field.label,
              }
            )
          )
      : z.string().optional();
    return { ...acc, [field.id]: validator };
  }, {});

  return z.object(schemaFields);
};

export const ValuesFooter = ({
  onConfirm,
  workspacesWithQueues,
  selectedQueue,
  setSelectedQueue,
}: Props) => {
  const intl = useIntl();

  const queueAdditionalValues = selectedQueue?.settings.uploadValues;

  const parsedUploadValues = uploadValuesSchema.parse(queueAdditionalValues);

  const schema = useMemo(
    () => createValidationSchema(parsedUploadValues, intl),
    [parsedUploadValues, intl]
  );

  // metadata vs values identification is necessary: https://elis.rossum.ai/api/docs/#create-upload
  // Since type information has no business in the form as it is not exposed to the user
  // we keep `type` out of the form and utilise parsedUploadValues to identify the type after submit
  const normalizeFieldValues = (fieldValues: z.TypeOf<typeof schema>) => {
    const validFieldEntries = entries(fieldValues).flatMap(([key, value]) =>
      value ? [[key, value] as const] : []
    );

    const [valuesEntries, metadataEntries] = partition(
      validFieldEntries,
      ([key]) =>
        parsedUploadValues.some(
          uploadValue => uploadValue.type === 'values' && uploadValue.id === key
        )
    );
    return {
      values: fromEntries(valuesEntries),
      metadata: fromEntries(metadataEntries),
    };
  };

  const {
    control,
    handleSubmit,
    formState: { submitCount },
  } = useForm({
    mode: 'onTouched',
    resolver: zodResolver(schema),
  });

  const inputError = useMemo(
    () => determineSelectInputError(submitCount, Boolean(selectedQueue), intl),
    [intl, selectedQueue, submitCount]
  );

  return (
    <Stack mt="auto" width="100%">
      <Stack
        spacing={3}
        component="form"
        onSubmit={handleSubmit(fieldValues =>
          onConfirm(normalizeFieldValues(fieldValues))
        )}
        id="upload-additional-values-form"
      >
        {workspacesWithQueues ? (
          <ListSelect
            isLoading={workspacesWithQueues.length === 0}
            value={selectedQueue}
            selectPlaceholder={intl.formatMessage({
              id: 'containers.annotationList.upload.queueSelect.placeholder',
            })}
            label={intl.formatMessage({
              id: 'containers.annotationList.upload.queueSelect.label',
            })}
            onCloseClick={e => {
              e.stopPropagation();
              setSelectedQueue(undefined);
            }}
            selectWidth={FORM_WIDTH}
            error={inputError}
          >
            {({ close }) => (
              <QueueList
                onChange={selected => {
                  if (selected === selectedQueue) {
                    // deselect the option but do not close the menu
                    return setSelectedQueue(undefined);
                  }
                  setSelectedQueue(selected);
                  return close();
                }}
                value={selectedQueue}
                workspaces={workspacesWithQueues}
              />
            )}
          </ListSelect>
        ) : null}
        {parsedUploadValues?.length ? (
          <Stack spacing={2} direction="column">
            <Divider />
            <Typography color="text.secondary">
              {intl.formatMessage({
                id: 'containers.annotationList.upload.additionalValues',
              })}
            </Typography>

            {parsedUploadValues.map(field => (
              <TextFieldControl
                sx={{ width: FORM_WIDTH }}
                key={field.id}
                FieldLabelProps={{ layout: 'floating' }}
                ControllerProps={{ control, name: field.id }}
                label={field.label}
                size="small"
              />
            ))}
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  );
};

import { Queue } from '@rossum/api-client/queues';
import { Workspace } from '@rossum/api-client/workspaces';
import { ArrowBack, KeyboardArrowDown } from '@rossum/ui/icons';
import {
  AccordionDetails,
  AccordionSummary,
  alpha,
  Button,
  Divider,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@rossum/ui/material';
import { Accordion } from '@rossum/ui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'redux-first-history';
import { isEmbedded } from '../../../constants/config';
import { useDocumentStore } from '../../../features/annotation-view/document-store/DocumentStore';
import { snakeToCamel } from '../../../lib/keyConvertor';
import { annotationSelector } from '../../../redux/modules/annotation/selectors';
import { pauseValidation } from '../../../redux/modules/beforeLeave/actions';
import { leaveValidation } from '../../../redux/modules/ui/actions';
import { ModifierType } from '../../../types/annotation';
import { SnakeCaseStatus } from '../../../types/annotationStatus';
import StatusChip from '../../../ui/status/StatusChip';
import { NonAdminRestrictor } from '../../Restrictors';
import Filename from './Filename';
import { Modifier } from './Modifier';
import Row from './Row';
import { getAnnotationBacklink } from './useAnnotationBacklink';
import { useShowWorkflowActivity } from './useShowWorkflowActivity';

type Props = {
  backLink: string;
  name: string | undefined;
  status: SnakeCaseStatus | undefined;
  icons: { messagesExist: boolean; triageRulesFileName: boolean };
  modifier?: ModifierType;
  disableClipboard?: boolean;
  disabled?: boolean;
  isOwned: boolean;
  currentDatapointRef: HTMLElement | null;
  restrictedAccess: boolean;
  queue: Queue | undefined;
  workspace: Workspace | undefined;
};

const FileAndQueueNameWrapper = (props: Props) => {
  const {
    currentDatapointRef,
    backLink,
    queue,
    workspace,
    status,
    modifier,
    isOwned,
    ...rest
  } = props;

  const annotation = useSelector(annotationSelector);
  const showWorkflowActivity = useShowWorkflowActivity(annotation?.id);

  const dispatch = useDispatch();
  const intl = useIntl();
  const isEmbeddedMode = isEmbedded();
  const [expanded, setExpanded] = useState(false);
  const [contextMenuIsOpen, setContextMenuIsOpen] = useState(false);

  const selectionStyle: SxProps<Theme> = {
    '&::selection': {
      backgroundColor: theme =>
        theme.palette.mode === 'dark'
          ? alpha(theme.palette.common.white, 0.2)
          : alpha(theme.palette.common.black, 0.15),
    },
  };

  const sharedRowContentStyle = {
    display: 'block',
    marginBottom: '4px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textAlign: 'right',
    maxWidth: '290px',
    ...selectionStyle,
  };

  const handleExpanded = (_event: React.SyntheticEvent) => {
    // allow expanding accordion only when is not open context menu (otherwise each click on context menu item will open/close accordion)
    if (!contextMenuIsOpen) {
      setExpanded(!expanded);
    }
  };

  const leaveAnnotation = () => {
    if (isEmbedded()) {
      dispatch(leaveValidation());
    } else {
      const backArrowLink = getAnnotationBacklink();
      dispatch(
        pauseValidation({
          nextAction: push(backArrowLink),
          trigger: 'cancelAnnotation',
          reason: 'surveys',
        })
      );
    }
  };

  const openDrawer = useDocumentStore(state => state.openDrawer);

  return (
    <Stack data-cy="file-name">
      <Accordion
        expanded={expanded}
        onChange={handleExpanded}
        elevation={0}
        sx={{
          backgroundColor: theme =>
            alpha(
              theme.palette.mode === 'dark'
                ? theme.palette.common.black
                : theme.palette.common.white,
              0.1
            ),
          borderRadius: 'unset !important',
        }}
      >
        <AccordionSummary
          expandIcon={
            <IconButton size="small">
              <KeyboardArrowDown />
            </IconButton>
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            alignItems: 'center',
            paddingRight: '7px',
            paddingLeft: !isEmbeddedMode ? 0 : '16px',
            height: '48px',
            '&.Mui-expanded': {
              minHeight: 'unset',
            },
          }}
        >
          <IconButton
            size="small"
            data-cy="exit-route-button"
            onClick={e => {
              e.stopPropagation();
              return leaveAnnotation();
            }}
            sx={{ ml: 1 }}
          >
            <ArrowBack
              sx={{
                transform: 'scale(0.8, 0.8)',
              }}
            />
          </IconButton>

          <Filename onOpen={setContextMenuIsOpen} {...rest} />
        </AccordionSummary>
        <AccordionDetails>
          {!isEmbeddedMode && workspace && (
            <Row
              label={intl.formatMessage({
                id: 'components.annotationOverview.workspace.label',
              })}
            >
              <Typography sx={{ pl: 4, ...sharedRowContentStyle }} noWrap>
                {workspace.name}
              </Typography>
            </Row>
          )}
          {!isEmbeddedMode && queue && (
            <Row
              label={intl.formatMessage({
                id: 'components.annotationOverview.queue.label',
              })}
            >
              <Stack
                display="inline-block"
                role="button"
                onClick={leaveAnnotation}
                sx={{
                  ...sharedRowContentStyle,
                  borderBottom: theme =>
                    `1px solid ${
                      theme.palette.mode === 'dark'
                        ? alpha(theme.palette.common.white, 0.2)
                        : alpha(theme.palette.common.black, 0.15)
                    }`,
                  '&:hover': {
                    borderBottom: theme =>
                      `1px solid ${
                        theme.palette.mode === 'dark'
                          ? alpha(theme.palette.common.white, 0.4)
                          : alpha(theme.palette.common.black, 0.3)
                      }`,
                  },
                }}
              >
                <Typography noWrap>{queue.name}</Typography>
              </Stack>

              <NonAdminRestrictor>
                {queue && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    to={{
                      pathname: `/queues/${queue?.id}/settings/fields`,
                      state: { backLink },
                    }}
                    component={Link}
                    sx={{
                      mt: 0.5,
                      flexShrink: 0,
                      '&:hover': {
                        color: theme => theme.palette.secondary.main,
                      },
                    }}
                    data-cy="queue-settings-link"
                  >
                    {intl.formatMessage({
                      id: 'components.appBar.settings',
                    })}
                  </Button>
                )}
              </NonAdminRestrictor>
            </Row>
          )}

          <Row
            label={intl.formatMessage({
              id: 'components.annotationOverview.status.label',
            })}
          >
            {status && status !== 'purged' && (
              <Stack component="span" data-cy={`status-name-${status}`}>
                <StatusChip
                  status={snakeToCamel(status)}
                  isOwned={isOwned}
                  tooltipIsHidden
                />
              </Stack>
            )}
            {!isEmbeddedMode && (
              <Stack
                direction="row"
                sx={{
                  mt: 1,
                }}
              >
                <Stack
                  component="span"
                  sx={{
                    color: theme => theme.palette.text.secondary,
                    ...selectionStyle,
                  }}
                >
                  {intl.formatMessage({
                    id: 'components.annotationOverview.status.by',
                  })}
                  &nbsp;
                </Stack>
                <Stack component="span" sx={selectionStyle}>
                  <Modifier modifier={modifier} />
                </Stack>
              </Stack>
            )}
            {!isEmbeddedMode && annotation && showWorkflowActivity && (
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  mt: 0.5,
                  flexShrink: 0,
                  '&:hover': {
                    color: theme => theme.palette.secondary.main,
                  },
                }}
                onClick={() =>
                  openDrawer({
                    drawer: 'activities',
                    annotationId: annotation.id,
                  })
                }
                data-cy="view-history-button"
              >
                {intl.formatMessage({
                  id: 'components.fileAndQueueNameWrapper.showHistory',
                })}
              </Button>
            )}
          </Row>
        </AccordionDetails>
      </Accordion>

      {expanded && <Divider />}
    </Stack>
  );
};

export default FileAndQueueNameWrapper;

import { get, isString } from 'lodash';
import { push, replace } from 'redux-first-history';
import { combineEpics } from 'redux-observable';
import { merge, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import {
  catchError,
  delay,
  filter,
  first,
  map,
  mapTo,
  mergeMap,
  pluck,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { getAnnotationBacklink } from '../../../components/AnnotationInformation/components/useAnnotationBacklink';
import { apiUrl } from '../../../constants/config';
import { logoutFromWorkflows } from '../../../features/workflows';
import { errorHandler } from '../../../lib/api';
import {
  getAuthorizationHeader,
  jsonWithTrackingHeaders,
} from '../../../lib/apiHelpers';
import { queryClient } from '../../../lib/queryClient';
import { clearAuthToken, setAuthToken } from '../../../lib/token';
import { parse, removeTokenFromLocationHash } from '../../../lib/url';
import {
  cancelAnnotationFulfilled,
  exitAnnotation,
} from '../annotation/actions';
import { leaveValidation } from '../ui/actions';
import { isActionOf, makeEpic } from '../utils';
import {
  authSignIn,
  authSignInFulfilled,
  fetchMembershipToken,
  fetchMembershipTokenFulfilled,
  FetchMembershipTokenFulfilledPayload,
  logoutUser,
  logoutUserFulfilled,
  signOut,
  switchOrganization,
  switchOrganizationError,
} from './actions';

const logoutUserEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(signOut)),
    mergeMap(({ meta: { handled } }) => {
      return handled
        ? of(logoutUserFulfilled())
        : ajax({
            method: 'POST',
            url: `${apiUrl}/auth/logout`,
            headers: {
              ...getAuthorizationHeader(),
              ...jsonWithTrackingHeaders,
            },
          }).pipe(mapTo(logoutUserFulfilled()), catchError(errorHandler));
    }),
    tap(() => window.Beamer?.destroy?.())
  )
);

const inAnnotationSignOutEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(logoutUser)),
    map(() => state$.value),
    switchMap(({ ui: { readOnly }, annotation: { id, url } }) =>
      !readOnly && url && id
        ? of(leaveValidation(), exitAnnotation(id))
        : of(signOut())
    )
  )
);

const annotationSignOutEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(logoutUser)),
    switchMap(() =>
      action$.pipe(filter(isActionOf(cancelAnnotationFulfilled))).pipe(
        takeUntil(action$.pipe(filter(isActionOf(authSignIn)))),
        map(() => signOut())
      )
    )
  )
);

const signInEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(authSignIn)),
    pluck('payload', 'token'),
    tap(token => setAuthToken(token)),
    mergeMap(() => of(authSignInFulfilled()))
  )
);

const signInRedirectEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(authSignInFulfilled)),
    map(() => {
      const { hash, ...location } = state$.value.router.location;
      if (hash) {
        return replace(location);
      }

      const referrer = get(state$.value.router.location, ['state', 'referrer']);

      if (referrer) {
        return push(removeTokenFromLocationHash(referrer));
      }

      return push('/annotations');
    })
  )
);

const signOutEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(signOut)),
    tap(() => queryClient.clear()),
    tap(() => clearAuthToken()),
    tap(() => logoutFromWorkflows()),
    mergeMap(() =>
      of(push({ pathname: '/', state: state$.value.router.location.state }))
    )
  )
);

const signInWithAuthTokenFromUrl = makeEpic((_, state$) =>
  state$.pipe(
    first(),
    pluck('router', 'location', 'hash'),
    map(hash => parse(hash).authToken),
    filter(
      (authToken): authToken is string => isString(authToken) && !!authToken
    ),
    map(token => {
      // When a user comes with active `authToken`, clear workflows session
      logoutFromWorkflows();
      return authSignIn(token);
    })
  )
);

const switchOrganizationEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(switchOrganization)),
    switchMap(({ payload: organizationUrl, meta: organizationName }) => {
      const {
        ui: { readOnly },
        annotation: { url, id },
      } = state$.value;

      return !readOnly && url && id
        ? of(leaveValidation(), exitAnnotation(id))
        : of(fetchMembershipToken(organizationUrl, organizationName));
    })
  )
);

const inAnnotationSwitchOrganizationEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(switchOrganization)),
    switchMap(({ payload: organizationUrl, meta: organizationName }) =>
      action$.pipe(
        filter(isActionOf(cancelAnnotationFulfilled)),
        takeUntil(
          action$.pipe(
            filter(
              isActionOf([
                fetchMembershipTokenFulfilled,
                switchOrganizationError,
              ])
            )
          )
        ),
        map(() => fetchMembershipToken(organizationUrl, organizationName))
      )
    )
  )
);

const fetchMembershipEpic = makeEpic((action$, _, { authPost$ }) =>
  action$.pipe(
    filter(isActionOf(fetchMembershipToken)),
    pluck('payload'),
    mergeMap(({ organization }) =>
      authPost$<FetchMembershipTokenFulfilledPayload>(
        `${apiUrl}/auth/membership_token`,
        { organization, origin: 'rossum_ui' }
      ).pipe(
        delay(2000), // time for route /organizationLoading to be displayed
        map(fetchMembershipTokenFulfilled),
        catchError(error =>
          merge(errorHandler(error), of(switchOrganizationError()))
        )
      )
    )
  )
);

const redirectAfterSwitchOrganizationEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(fetchMembershipToken)),
    pluck('meta'),
    map(organizationName =>
      replace({
        state: { organizationName },
        pathname: '/organizationLoading',
      })
    )
  )
);

const redirectAfterSwitchOrganizationErrorEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(switchOrganizationError)),
    map(() => {
      const defaultListLocation = getAnnotationBacklink();
      return replace(defaultListLocation);
    })
  )
);

const membershipFulfilledEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(fetchMembershipTokenFulfilled)),
    pluck('payload', 'key'),
    switchMap(token => of(signOut({ handled: true }), authSignIn(token)))
  )
);

export default combineEpics(
  annotationSignOutEpic,
  fetchMembershipEpic,
  inAnnotationSignOutEpic,
  inAnnotationSwitchOrganizationEpic,
  logoutUserEpic,
  membershipFulfilledEpic,
  redirectAfterSwitchOrganizationEpic,
  redirectAfterSwitchOrganizationErrorEpic,
  signInEpic,
  signInRedirectEpic,
  signInWithAuthTokenFromUrl,
  signOutEpic,
  switchOrganizationEpic
);

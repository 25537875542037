import { Button, Chip, Stack } from '@rossum/ui/material';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { asScalar, parse, setQueryCreator } from '../../../lib/url';
import { Header, HeaderProps, HeaderTitle } from '../../../ui/header/Header';
import SearchInput from '../../../ui/search-input/SearchInput';
import { settingsPath } from '../../Settings/helpers';
import { SettingsBreadcrumbs } from '../../Settings/SettingsBreadcrumbs';
import AddUserDialog from './add-user/AddUserDialog';

type UsersHeaderProps = { activeUsersCount: number } & Pick<
  HeaderProps,
  'scrollableDivRef'
>;

export const UsersHeader = ({
  activeUsersCount,
  ...rest
}: UsersHeaderProps) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const intl = useIntl();
  const history = useHistory();

  const onSearchChange = (_search: string) =>
    setQueryCreator(history)({ search: _search });

  const search = useMemo(
    () => parse(history.location.search).search ?? '',
    [history.location.search]
  );

  return (
    <>
      <Header
        {...rest}
        breadcrumbs={
          <SettingsBreadcrumbs
            breadcrumbs={[
              {
                label: intl.formatMessage({
                  id: 'containers.settings.users.header.title',
                }),
              },
            ]}
          />
        }
        title={hasScrolled => (
          <Stack direction="row" alignItems="center" gap={1}>
            <HeaderTitle
              hasScrolled={hasScrolled}
              title={intl.formatMessage({
                id: 'containers.settings.users.header.title',
              })}
            />
            <Chip
              label={activeUsersCount}
              size={hasScrolled ? 'small' : 'medium'}
            />
          </Stack>
        )}
        description={intl.formatMessage({
          id: 'containers.settings.users.description',
        })}
        buttons={[
          <SearchInput
            key="search"
            value={asScalar(search) || ''}
            onChange={onSearchChange}
          />,
          <Button
            key="add"
            variant="contained"
            color="primary"
            onClick={() => setShowDialog(true)}
            data-cy="users-add-user-button"
          >
            {intl.formatMessage({
              id: 'containers.settings.sidebar.users.addUser',
            })}
          </Button>,
        ]}
        onBackButtonClicked={() => history.push(settingsPath())}
      />
      <AddUserDialog open={showDialog} onClose={() => setShowDialog(false)} />
    </>
  );
};

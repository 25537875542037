import {
  differenceBy,
  find,
  findIndex,
  includes,
  partition,
  uniqBy,
} from 'lodash';
import { Reducer } from 'redux';
import Immutable, { asMutable } from 'seamless-immutable';
import { getType } from 'typesafe-actions';
import { QueueState } from '../../../types/queue';
import { RootActionType } from '../../rootActions';
import { fetchMembershipTokenFulfilled, logoutUser } from '../auth/actions';
import { createInboxFulfilled, updateInboxFulfilled } from '../inbox/actions';
import { initialPagination } from '../utils';
import {
  clearQueues,
  deleteQueueFulfilled,
  fetchQueueFulfilled,
  fetchQueuesFulfilled,
  updateQueueDetailFailed,
  updateQueueDetailFulfilled,
} from './actions';

const initialState = Immutable<QueueState>({
  list: [],
  pagination: initialPagination,
  loaded: false,
  reloading: false,
});

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchQueuesFulfilled): {
      const [queues, deletedQueues] = partition(
        action.payload.results,
        queue => !queue.deleteAfter
      );

      const prevQueues = differenceBy(state.list, deletedQueues, 'id');

      const allQueues = [...prevQueues, ...queues];
      const uniqQueues = allQueues.filter(
        (queue, i) =>
          !includes(
            // fresh queue from payload.results should take precedence over queue already in state
            allQueues.slice(i + 1, allQueues.length).map(({ id }) => id),
            queue.id
          )
      );
      // also attach generic and dedicated engines
      const builtQueues = uniqQueues.map(queue => ({
        ...queue,
        ...(queue.settings?.columns && {
          settings: {
            ...queue.settings,
            columns: uniqBy(queue.settings.columns ?? [], 'schemaId'),
          },
        }),
        inboxObject:
          // First, try if inbox object wasn't already loaded in the epic
          // Next, try to get it from sideload
          ('inboxObject' in queue ? queue.inboxObject : undefined) ??
          (queue.inbox
            ? find(action.payload.inboxes, { url: queue.inbox })
            : null),
      }));
      return state
        .set('list', builtQueues)
        .set('pagination', action.payload.pagination)
        .update('loaded', loaded => loaded || !action.payload.pagination.next)
        .set('reloading', false);
    }

    case getType(fetchQueueFulfilled): {
      const queueIndex = findIndex(state.list, { id: action.payload.id });

      return state.setIn(
        ['list', (queueIndex > -1 ? queueIndex : state.list.length).toString()],
        {
          ...action.payload,
          settings: {
            ...action.payload.settings,
            columns: uniqBy(action.payload.settings.columns ?? [], 'schemaId'),
          },
        }
      );
    }

    case getType(deleteQueueFulfilled):
      return state.set(
        'list',
        state.list.filter(queue => queue.id !== action.payload.id)
      );

    case getType(updateQueueDetailFulfilled): {
      const { id } = action.meta;
      const index = findIndex(state.list, { id });

      return state
        .updateIn(['list', index], queue =>
          queue.merge(asMutable(action.payload), { deep: true })
        )
        .updateIn(['errors'], errors => errors?.without(id))
        .set('reloading', true);
    }

    case getType(updateQueueDetailFailed): {
      const { id } = action.meta;

      return state.updateIn(['errors'], () => ({
        [id]: action.payload,
      }));
    }

    case getType(fetchMembershipTokenFulfilled):
    case getType(logoutUser):
    case getType(clearQueues): {
      return initialState;
    }

    case getType(createInboxFulfilled): {
      const { queueUrl } = action.meta;
      const index = findIndex(state.list, { url: queueUrl });

      return state
        .setIn(['list', index, 'inbox'], action.payload.url)
        .setIn(['list', index, 'inboxObject'], action.payload);
    }

    case getType(updateInboxFulfilled): {
      const { inbox } = action.payload;
      const index = findIndex(state.list, { url: inbox.queues[0] });

      return state
        .setIn(['list', index, 'inbox'], inbox.url)
        .setIn(['list', index, 'inboxObject'], inbox);
    }
    default:
      return state;
  }
};

export default reducer;

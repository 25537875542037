import { AnyDatapointDataST } from '../../../types/datapoints';

export const useFieldsRoute = (
  datapoint: AnyDatapointDataST | undefined,
  queueId: number | null
) => {
  if (datapoint && datapoint.schema && queueId) {
    return `/queues/${queueId}/settings/fields/${datapoint.schema.id}` as const;
  }

  return undefined;
};

import { endpoints, getIDFromUrl, Url } from '@rossum/api-client';
import { EmailThreadPatchPayload } from '@rossum/api-client/emailThreads';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '../../../lib/apiClient';
import { EMAIL_THREAD } from './useEmailThread';

export const useUpdateEmailThread = () => {
  const api = useApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      emailThreadUrl,
      payload,
    }: {
      emailThreadUrl: Url;
      payload: EmailThreadPatchPayload;
    }) => {
      return api.request(
        endpoints.emailThreads.patch(getIDFromUrl(emailThreadUrl), payload)
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries([EMAIL_THREAD]);
    },
  });
};

import { ArrowBackRounded } from '@rossum/ui/icons';
import { Button, Stack } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { SIMPLE_COMPONENT_WIDTH } from '../../../document-list-base/mql/constants';
import ActionButtons from './ActionButtons';

type Props = {
  isSubmitDisabled: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  onBackClick?: () => void;
  onClickOnMore?: () => void;
  disableOnMoreClick?: boolean;
  children: ReactNode;
  columnField: string;
  hasFixedWidth?: boolean;
};

const FilterActionsWrapper = ({
  isSubmitDisabled,
  onCancel,
  onSubmit,
  onBackClick,
  onClickOnMore,
  disableOnMoreClick,
  children,
  hasFixedWidth = true,
  columnField,
}: Props) => {
  const intl = useIntl();

  return (
    <Stack
      p={2}
      alignItems="flex-start"
      spacing={2}
      component="form"
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        onSubmit();
      }}
      sx={{ width: hasFixedWidth ? SIMPLE_COMPONENT_WIDTH : 'auto' }}
    >
      {onBackClick && (
        <Button
          variant="text"
          size="small"
          color="inherit"
          startIcon={<ArrowBackRounded />}
          onClick={() => onBackClick()}
          data-cy="all-documents-filter-back-button"
        >
          {intl.formatMessage({ id: 'containers.filtering.buttons.back' })}
        </Button>
      )}
      {children}
      <ActionButtons
        onCancel={onCancel}
        isSubmitDisabled={isSubmitDisabled}
        secondaryButton={
          onClickOnMore ? (
            <Button
              color="secondary"
              variant="text"
              disabled={disableOnMoreClick}
              onClick={onClickOnMore}
              sx={{ mr: 'auto' }}
              data-cy={`all-documents-${columnField}-switch-to-advanced-button`}
            >
              {intl.formatMessage({ id: 'containers.filtering.buttons.more' })}
            </Button>
          ) : undefined
        }
        dataCyKey={columnField}
      />
    </Stack>
  );
};

export default FilterActionsWrapper;
